//NG+
//
import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';

//
import { AppSettings } from "../app-settings";
import { ILayer, ISelectedFeatures, IItemNT, IAnimate, IAnimateTimeRaster, IMeasure, ToolButtonType, IUserSettings, featureGeometryTypes, ESearchCondition, authAs, authOpt, authType, featureId, featureType, featureTypeForRaster, featureTypeForVector, ICategory, IGridDefaults, ILayerSourceLoading, IMapConfig, IMenuFeatureItem, IMenuLayerItem, IPositionData, ISearchSettings, ITranzitData, layerMenuItems, menuAction, searchType, wayRestrictTypeList, wayRoutingTypeList } from "../definitions";
import { RouteDataService } from "../routes/route-data-service";
import { ILayerFileInStore } from "../services/dbstore-leyerfiles";
import { GisDataService } from "../services/gis-data-service";
import { GreentopDataService } from "../services/greentop-data-service";
import { IntegrateLandsatDataService } from "../services/integrate-landsat-data-service";
import { PopoverService } from "../services/popover-service";
import { IRouteResult, RouteGenStatus } from "../services/route-message-service";
import { TransportDataService } from "../services/transport-data-service";
import { CQLFilterService } from "../services/user-filter-service";
import { UserSettingsService } from "../services/user-settings-service";
import { WindowMessageService } from "../services/window-message-service";
import { MapAutorefreshLayers } from "./map-autorefresh";
import { IIntegrateLandsatHotspotData , IIntegrateLandsatDownloadData, listaSursaDateClasificareIL,
    IIntegrateLandsatProcesareDateSezoniereData, IIntegrateLandsatProcesareIndiceConexData} from "./map-components/map-integrate.definitions";
import { MapCtrlAnimateRoute } from "./map-ctrl-animate-route";
import { MapCtrlAnimateTimeRaster } from "./map-ctrl-animate-timeraster";
import { MapCtrlEditFeature } from "./map-ctrl-editfeature";
import { IGreentopClassifyData, IGreentopDownloadData, listaSursaDateClasificare } from "./map-components/map-greentop-definitions";
import { IGreentopTrainingData } from "./map-components/map-greentop-definitions";
import { MapCtrlLayerIsLoading } from "./map-ctrl-layerisloading";
import { MapCtrlLegend } from "./map-ctrl-legend";
import { MapCtrlMeasure } from "./map-ctrl-measure";
import { MapCtrlSelectBox } from "./map-ctrl-selectbox";
import { MapCtrlSelectFeature } from "./map-ctrl-selectfeature";
import { MapCtrlTools } from "./map-ctrl-tools";
import { MapCtrlTransportRoute } from "./map-ctrl-transportroute";
import { MapDialogs } from "./map-dialogs";
import { MapMenuLayers } from "./map-menu-layers";
import { MapOlFeatures } from "./map-ol-features";
import { MapOlInitialization } from "./map-ol-init";
import { MapOlLayerStyle } from "./map-ol-layerstyle";
import { InfoDialogsServiceNg } from "../features/info-dialogs-services-ng";
import { graphType } from "../features/features-components/info-click-graph.component";
import { LayerDialogsServiceNg } from "../layer/layer-dialog-service-ng";
import { RouteDialogServiceNg } from "../routes/route-dialogs-service-ng";
import { AuthDialogsServiceNg } from "../auth/auth-dialogs-service-ng";
import { mapCtrlDefaults } from "./map-ctrl-defaults";
import { SettingsDialogsServiceNg } from "../roles/settings-dialogs-services";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isArray, isObject } from './map-utils';
import { InternalMessageService } from '../services/internal-message-service';
import { Subscription } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

import Map from 'ol/Map';
import BaseLayer from 'ol/layer/Base';
import Control from 'ol/control/Control';
import Overlay from 'ol/Overlay';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import DragBox from 'ol/interaction/DragBox';
import ExtentInter from 'ol/interaction/Extent';
import Select from 'ol/interaction/Select';
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import Geolocation from 'ol/Geolocation';


@Component({
    selector: 'map-controller',
    providers: [],
    templateUrl: './map-controller.html',
    //styleUrls: ['./app/map/map-controller.css']
})
    export class MapController implements OnInit, OnDestroy{
        //
        //components
        //
        public mapDialogs: MapDialogs;
        public mapOlInit: MapOlInitialization;
        public mapOlFeatures: MapOlFeatures;
        public mapOlLayerstyle: MapOlLayerStyle;
        //
        public mapMenuLayers: MapMenuLayers;
        //
        public mapCtrlTools: MapCtrlTools;
        //
        public mapCtrlAnimateRoute: MapCtrlAnimateRoute;
        public mapCtrlMeasure: MapCtrlMeasure;
        public mapCtrlEditFeature: MapCtrlEditFeature;
        public mapCtrlSelectFeature: MapCtrlSelectFeature;
        public mapCtrlSelectBox: MapCtrlSelectBox;
        public mapCtrlTransportRoute: MapCtrlTransportRoute;
        public mapCtrlLayerIsLoading: MapCtrlLayerIsLoading;
        public mapCtrlAnimateTimeRaster: MapCtrlAnimateTimeRaster;
        public mapCtrlLegend: MapCtrlLegend;
        public mapAutorefreshLayers: MapAutorefreshLayers;
        
        //
        //data
        //
        public static appLayer = "appLayer";
        public static searchFilterOut = "searchFilterOut";

        public mapConfig: IMapConfig;
        public map: Map;
        
        public basemap: BaseLayer;

        public categories: ICategory[];
        public gridDefaultsList: Array<IGridDefaults>;

        public searchText: string = "";
        public searchSettings: ISearchSettings = { type: searchType.multilayer, layer: null, geometry: null, feature: null, bufferDistance: '10' }

        public isMenuCollapsed: boolean = true;
        public positionMarkerButton: HTMLButtonElement;
        public positionButtonStateOn: boolean = false;
        public positionMarkerButtonCtrl: Control;
        public positionMarkerOverlay: Overlay;
        public infoOverlay: Overlay;

        public selectButton: HTMLButtonElement;
        public selectButtonCtrl: Control;
        public selectButtonStateOn: boolean = false;
        public selectLayer: VectorLayer<any>;
        public selectDrawInteraction: Draw;
        public editLayerFeature: VectorLayer<any>;
        public selectModifyInteraction: Modify;
        public showEditFeature: boolean = false;
        public showMainMenu: boolean = false;
        public routeAnimate: boolean = false;
        public timeRasterAnimate: boolean = false;
        public editFeatureReferenceLayer: ILayer;
        public showAddFeature: boolean = false;
        public newFeature: Feature;
        public addFeatureData: Array<{key: string, value: any}> = null;
        public mousePositionCtrl: Control;
        public selectCluster: any = null;

        public legendButton: HTMLButtonElement;
        public legendButtonCtrl: Control;
        public legendButtonStateOn: boolean = false;
        public legendContent: HTMLDivElement;

        public autorefreshButton: HTMLButtonElement;
        public autorefreshButtonCtrl: Control;
        public autorefreshButtonStateOn: boolean = true;
        public autorefreshButtonStateVisible: boolean = true;

        public oneActiveLayerButton: HTMLButtonElement;
        public oneActiveLayerButtonCtrl: Control;
        public oneActiveLayerButtonStateOn: boolean = false;
        public oneActiveLayerButtonStateVisible: boolean = true;

        public dragBox: DragBox;
        public selectionExtent: ExtentInter;

        public selectedFeaturesOnLayers: Collection<ISelectedFeatures>;
        public searchActive: boolean = false;
        public geoLocation: Geolocation;

        public enableUserButton: boolean = false;
        public enableSettingButton: boolean = false;

        public mapImgUrl: string;
        //
        public routeShowType = {
            show: 'show',
            hide: 'hide',
            disable: 'disable',
            previewResult: 'previewResult'
        }
        public routeLayerFeature: VectorLayer<any>;
        public routeDrawInteraction: Draw;
        public routeModifyInteraction: Modify;
        public routeFeatureReferenceLayer: ILayer;
        public routeShowEdit: string = this.routeShowType.hide;
        public routeFeature: Feature;
        public transportRouteShowEdit: string = this.routeShowType.hide;
        public routeLayerPreview: VectorLayer<any>;
        public routeIdPreview: number = null;
        //
        public restrictByClientArea: boolean = true;
        public restrictTypeSelected: IItemNT = wayRestrictTypeList[0];
        public restrictTypeList: Array<IItemNT> = wayRestrictTypeList;
        public routingTypeSelected: IItemNT = wayRoutingTypeList[0];
        public routingTypeList: Array<IItemNT> = wayRoutingTypeList;
        //
        public tranzitButton: HTMLButtonElement;
        public tranzitButtonCtrl: Control;
        public tranzitButtonStateOn: boolean = false;
        public tranzitButtonStateVisible: boolean = false;
        //greentopDwonloadButton
        public greentopDwonloadButton: HTMLButtonElement;
        public greentopDwonloadButtonCtrl: Control;
        public greentopDwonloadButtonStateOn: boolean = false;
        public greentopDwonloadButtonStateVisible: boolean = false;
        //
        public greentopTrainingButton: HTMLButtonElement;
        public greentopTrainingButtonCtrl: Control;
        public greentopTrainingButtonStateOn: boolean = false;
        public greentopTrainingButtonStateVisible: boolean = false;
        //
        public greentopClassifyButton: HTMLButtonElement;
        public greentopClassifyButtonCtrl: Control;
        public greentopClassifyButtonStateOn: boolean = false;
        public greentopClassifyButtonStateVisible: boolean = false;
        public greentopClassifyMinimizeStateOn: boolean = false;
        //
        public integrateLandsatDwonloadButton: HTMLButtonElement;
        public integrateLandsatDwonloadButtonCtrl: Control;
        public integrateLandsatDwonloadButtonStateOn: boolean = false;
        public integrateLandsatDwonloadButtonStateVisible: boolean = false;
        //
        public integrateLandsatHotspotButton: HTMLButtonElement;
        public integrateLandsatHotspotButtonCtrl: Control;
        public integrateLandsatHotspotButtonStateOn: boolean = false;
        public integrateLandsatHotspotButtonStateVisible: boolean = false;
        //
        public integrateLandsatProcIndConexButton: HTMLButtonElement;
        public integrateLandsatProcIndConexButtonCtrl: Control;
        public integrateLandsatProcIndConexButtonStateOn: boolean = false;
        public integrateLandsatProcIndConexButtonStateVisible: boolean = false;
        //
        public integrateLandsatProcDateSezoniereButton: HTMLButtonElement;
        public integrateLandsatProcDateSezoniereButtonCtrl: Control;
        public integrateLandsatProcDateSezoniereButtonStateOn: boolean = false;
        public integrateLandsatProcDateSezoniereButtonStateVisible: boolean = false;
        //
        public selectInteraction: Select;
        public selectedItemBuffer: Array<{ feature: Feature, layer: VectorLayer<any> }>;
        public selectGrupFeatureOnLayer: ISelectedFeatures = null;
        //from defaults
        public animate: IAnimate = mapCtrlDefaults.animate;
        //
        public animateTimeRasterData: IAnimateTimeRaster = mapCtrlDefaults.animateTimeRasterData;
        //
        public measure: IMeasure = mapCtrlDefaults.measure; 
        //
        public tranzitData: ITranzitData = mapCtrlDefaults.tranzitData;
        //
        public positionData: IPositionData = mapCtrlDefaults.positionData;
        //
        public greentopDownloadData: IGreentopDownloadData = mapCtrlDefaults.greentopDownloadData;
        //
        public greentopTrainingData: IGreentopTrainingData = mapCtrlDefaults.greentopTrainingData;
        //
        public greentopClassifyData: IGreentopClassifyData = mapCtrlDefaults.greentopClassifyData;
        //
        public integrateLandsatDownloadData: IIntegrateLandsatDownloadData = mapCtrlDefaults.integrateLandsatDownloadData;
        //
        public integrateLandsatHotspotData: IIntegrateLandsatHotspotData = mapCtrlDefaults.integrateLandsatHotspotData;
        //
        public integrateLandsatProcIndConexData : IIntegrateLandsatProcesareIndiceConexData = mapCtrlDefaults.integrateLandsatProcIndConexData;
        //
        public integrateLandsatProcDateSezoniereData : IIntegrateLandsatProcesareDateSezoniereData = mapCtrlDefaults.integrateLandsatProcDateSezoniereData;
        //
        public layerSourceLoadingList: Array<ILayerSourceLoading>;
        //
        public treeCatMenu: any[] = mapCtrlDefaults.treeCatMenu;
        //end def
        public iReportImageCache: {imagename: string, imageurl: string}[] = [];
        public layerImagesCache: ILayerFileInStore[] = [];
        public infoSearchRasterStyle = 
        `nir = src[8]* 1.0;
        vir = src[4] * 1.0;
        dest = (nir - vir) / (nir + vir);`;
        public multiLayerStyleImageCache: Array<any> = [];
        public vm: MapController;
        private subscriptions = new Subscription();

        //
        constructor(
            @Inject(NGXLogger) private $log: NGXLogger,
            @Inject(NgbModal) private modalService: NgbModal,
            @Inject(UserSettingsService) public userSettingsSrvs: UserSettingsService,
            @Inject(SettingsDialogsServiceNg) public settingsDialogsServiceNg: SettingsDialogsServiceNg,
            @Inject(AuthDialogsServiceNg) public authDialogsServiceNg: AuthDialogsServiceNg,
            @Inject(InfoDialogsServiceNg) public infoDialogsServiceNg: InfoDialogsServiceNg,
            @Inject(CQLFilterService) public cqlFilterService: CQLFilterService,
            @Inject(RouteDialogServiceNg) public routeDialogServiceNg: RouteDialogServiceNg,
            @Inject(RouteDataService) public routeDataService: RouteDataService,
            @Inject(LayerDialogsServiceNg) public layerDialogServiceNg: LayerDialogsServiceNg,
            @Inject(PopoverService) public popoverService: PopoverService,
            @Inject(WindowMessageService) public windowMessageService: WindowMessageService,
            @Inject(TransportDataService) public transportDataService: TransportDataService,
            @Inject(GreentopDataService) public greentopDataService: GreentopDataService,
            @Inject(IntegrateLandsatDataService) public integrateLandsatDataService: IntegrateLandsatDataService,
            @Inject(GisDataService) public gisDataService: GisDataService,
            @Inject(InternalMessageService) public interalMessageService: InternalMessageService,
            ) {

            this.vm = this;
            this.layerSourceLoadingList = [];
            //
            this.mapDialogs = new MapDialogs(this, this.$log);
            this.mapOlInit = new MapOlInitialization(this, this.$log);
            this.mapOlFeatures = new MapOlFeatures(this, this.$log);
            this.mapOlLayerstyle = new MapOlLayerStyle(this, this.$log);
            //
            this.mapMenuLayers = new MapMenuLayers(this, this.$log);
            //
            this.mapCtrlTools = new MapCtrlTools(this, this.$log);
            //
            this.mapCtrlAnimateRoute = new MapCtrlAnimateRoute(this, this.$log);
            this.mapCtrlMeasure = new MapCtrlMeasure(this, this.$log);
            this.mapCtrlEditFeature = new MapCtrlEditFeature(this, this.$log);
            this.mapCtrlSelectBox = new MapCtrlSelectBox(this, this.$log);
            this.mapCtrlSelectFeature = new MapCtrlSelectFeature(this, this.$log);
            this.mapCtrlTransportRoute = new MapCtrlTransportRoute(this, this.$log);
            this.mapCtrlLayerIsLoading = new MapCtrlLayerIsLoading(this, this.$log);
            this.mapCtrlAnimateTimeRaster = new MapCtrlAnimateTimeRaster(this, this.$log);
            this.mapCtrlLegend = new MapCtrlLegend(this, this.$log);
            this.mapAutorefreshLayers = new MapAutorefreshLayers(this, this.$log);
            //this.mapCtrlTranzit = new MapCtrlTranzit(this);
            //
            
        }

        public ngOnInit(): void {
            //events communication
            this.initMessageEvents();
            //
            this.selectedFeaturesOnLayers = new Collection<ISelectedFeatures>();
            //implicit utilizator anonim
            let tmpToken = '';
            try {
                let querystring = window.location.hash;
                if(querystring.startsWith("#!")){
                    querystring = querystring.substring(2);
                }
                const urlParams = new URLSearchParams(querystring);
                tmpToken = urlParams.get("token");
                let tmpFilter = urlParams.get("userfilter");
                //
                if (tmpFilter) {
                    let userFilter = JSON.parse(tmpFilter) as Array<any>;
                    this.cqlFilterService.parseUserFilterString(userFilter);
                }
            } catch (e) {
                this.$log.error('eroare extragere date initiale');
            }
            //
            this.mapCtrlLayerIsLoading.initLayerLoadingState();
            this.showMainMenu = false;
            //
            this.initMenuHeightCheck();
            //
            Promise.resolve(true)
                .then(() => {
                    let tmpUser: IUserSettings = null;
                    //daca avem  token extern verifica id pt a testa token
                    if (tmpToken) {
                        this.userSettingsSrvs.getCurrentUser().token = tmpToken;
                        return this.userSettingsSrvs.getCurrentUserIdFromStorage().
                            then((user) => {
                                if (user) {
                                    tmpUser = user;
                                    tmpUser.token = tmpToken;
                                } else {
                                    this.$log.error("utilizatorul extern nu exista");
                                }
                                return tmpUser;
                            });
                    } else {
                        return tmpUser;
                    }
                })
                .then((remoteUser) => {
                    if (remoteUser) {
                        return remoteUser;
                    } else {
                        return this.userSettingsSrvs.authenticateUser(
                            AppSettings.anonimUser, AppSettings.anonimPass);
                    }
                })
                .then((authUser: IUserSettings) => {
                    return this.userSettingsSrvs.setCurrentUserToAuthUserAndUpdateAllFromStorage(authUser);
                })
                .catch((reason) => {
                    alert("nu se poate autentifica");
                })
                .then((result) => {
                    return this.initialize();
                })
                .catch((reason) => {
                    this.$log.error(reason);
                    alert("eroare initializare");
                })
                .finally(() => {
                    this.showMainMenu = true;
                })
            //
        }

        public ngAfterViewInit(){
            this.$log.log("init done");
        }

        public ngOnDestroy(){
            this.subscriptions.unsubscribe();
        }

        private initMessageEvents(){
           this.subscriptions.add( this.interalMessageService.sfBroadcast$
            .subscribe((message)=>{
                if (message.name === "userAuthChanged"){
                    this.$log.log('reload layers');
                    this.loadConfig();
                    this.reloadLayers();
                    this.mapOlInit.setMapView();
                }
                else if(message.name === "sendMapClick"){
                    this.$log.log('sendMapClick');
                    this.mapOlFeatures.doClickMapOnMessage(message.content.mode, message.content.layer, message.content.coordinates, message.content.crsCoordinates, message.content.properties);
                }
                else if(message.name === "cqlFilterChanged"){
                    this.loadAllLayersCQLFilter();
                }
                else if(message.name === "routeGenMessage"){
                    this.filterRouteForMessage(message.content);
                }
                else if(message.name === "sendAnimateRoute"){
                    this.mapCtrlAnimateRoute.animateRouteByMessage(message.content.layer, message.content.properties, message.content.startPointIndex, message.content.startAnimation);
                }
                else if(message.name === "sendMapView"){
                    this.mapOlFeatures.setMapViewByMessage(message.content.center, message.content.crsCenter, message.content.zoom, message.content.centerByFeature);
                }
                else if(message.name === "sendLayerMenuAction"){
                    this.mapMenuLayers.menuActionFromMessage(message.content.layer, message.content.action, message.content.data);
                }
                else if(message.name === "sendFeatureMenuAction"){
                    this.mapCtrlSelectFeature.featureActionFromMessage(message.content.layer, message.content.action, message.content.properties);
                }
                else if(message.name === "sendLayerViews"){
                    this.mapMenuLayers.layerViewsFromMessage(message.content);
                }
                 
            }));
            //
            window.addEventListener('resize', () => {
                this.map.updateSize();
            });
        }

        //
        //Map initialization
        //
        private initialize(): Promise<any> {
            this.loadConfig();
            this.loadLayers();
            this.loadAllLayersReports();
            return this.loadAllLayersColumnsInfosDefaults()
                .then(() => { return true })
                .catch((reason) => {
                    this.$log.error("eroare info defaults");
                })
                .then(() => {
                    this.initMap();
                    this.loadAllLayersGridColumnAndMenuActionsDefaults();
                    this.loadAllLayersCQLFilter();
                    this.enableControls();
                    return true;
                });
        }
        //
        public enableControls(){
            this.mapCtrlTools.addCtrlMousePosition();
            this.mapCtrlLegend.doLegendDefaultOpen();
            this.mapCtrlTools.showTranzitButtonIfHasAccess();
            this.mapCtrlTools.showGreentopDwButtonIfHasAccess();
            this.mapCtrlTools.showGreentopTrButtonIfHasAccess();
            this.mapCtrlTools.showGreentopClButtonIfHasAccess();
            this.mapCtrlTools.showIntegrateLandsatDwButtonIfHasAccess();
            this.mapCtrlTools.showIntegrateLandsatHotspotButtonIfHasAccess();
            this.mapCtrlTools.showIntegrateLandsatPrButtonIfHasAccess();
            this.mapCtrlTools.showIntegrateLandsatDateSezoniereButtonIfHasAccess();
            this.mapMenuLayers.showOneActiveLayerIfHasAccess();
            this.enableUserSettingsButtonsIfHasAccess();
        }
        //
        public loadConfig() {
            let currentUser = this.userSettingsSrvs.getCurrentUser()
            this.mapConfig = currentUser.mapConfig;
            this.categories = currentUser.categories.map(item => item);
            this.treeCatMenu.length = 0;

            //order categories by options settings
            let catOptions = this.userSettingsSrvs.isAuthForItemOptionsAllInfo(authOpt.in_index_menu_category, authAs.menu_category_index, authType.object);
            if (this.categories && this.categories.length > 0) {
                for (let cat of this.categories) {
                    //default value
                    cat.defaultIndex = 300 + this.categories.indexOf(cat);
                    //value from options
                    if (catOptions && catOptions.length > 0) {
                        let resopt = catOptions.filter((item) => item.descriere === cat.code);
                        if (resopt && resopt.length > 0) {
                            cat.defaultIndex = resopt[0].idItem;
                        }
                    }
                }
                this.categories.sort((a, b) => a.defaultIndex - b.defaultIndex);
            }
             
            //link category to parent
            let childrenCat = this.categories.filter(cat => cat.parentCode);
            for (let child of childrenCat) {
                let parent = this.categories.filter(cat => cat.code == child.parentCode);
                if (parent && parent.length > 0) {
                    if (parent[0].children == null) {
                        parent[0].children = [];
                    }
                    parent[0].children.push(child);
                }
            }

            //set categories to main ones without parent
            this.categories.filter(cat => cat.parentCode == null)
                .forEach(item => this.treeCatMenu.push(item));

            //sort children
            this.categories.forEach(item => {
                if (item.children && item.children.length > 0) {
                    item.children.sort((a, b) => a.defaultIndex - b.defaultIndex);
                }
            });
            //try update reference
            this.treeCatMenu = [...this.treeCatMenu];
            //
            this.gridDefaultsList = [];
            if (currentUser.mapConfig['configurations'] && currentUser.mapConfig['configurations']['gridDefaultsList']) {
                this.gridDefaultsList = currentUser.mapConfig['configurations']['gridDefaultsList'];
            }

            this.setMenuCollapsed();
           
        }

        public initMap() {
            this.mapOlInit.buildOlMap();
            //this.loadLayers();
            this.mapOlInit.addTileAndVectorLayers();
            this.mapCtrlSelectFeature.addInfoOverlay();
            this.mapCtrlTools.addPositionMarkerButton();
            this.mapCtrlTools.buildPositionMarkerOverlay();
            this.mapCtrlTools.addCtrlMousePosition();
            this.mapCtrlSelectBox.addBoxSelection();
            this.mapCtrlSelectBox.addSelectButton();
            this.mapCtrlTools.addLegendButton();
            this.mapCtrlMeasure.addMeasureDistanceButton();
            this.mapCtrlMeasure.addMeasureAreaButton();
            this.mapAutorefreshLayers.addAutorefreshButton();
            this.mapMenuLayers.addOneActiveLayerButton();
            this.mapCtrlTools.addTranzitButton();
            this.mapCtrlTools.addGreentopDownloadButton();
            this.mapCtrlTools.addGreentopTrainingButton();
            this.mapCtrlTools.addGreentopClassifyButton();
            this.mapCtrlTools.addIntegrateLandsatDownloadButton();
            this.mapCtrlTools.addIntegrateLandsatHotspotButton();
            this.mapCtrlTools.addIntegrateLandsatProcIndConexButton();
            this.mapCtrlTools.addIntegrateLandsatProcDateSezoniereButton();
            //
            document["map"] = this.map;
        }

        public clearLayers() {
            this.categories.forEach((category) => {
                if (category.layers) {
                    category.layers = [];
                }
            });
        }

        public reloadUserConfigAndLayers() {
            this.userSettingsSrvs.updateCurrentUserAllFromStorage()
                .then((success) => {
                    this.loadConfig();
                    this.clearLayers();
                    this.mapOlInit.clearTileAndVectorLayers();
                    this.mapOlInit.addBasemap();
                    this.loadLayers();
                    this.loadAllLayersReports();
                    return this.loadAllLayersColumnsInfosDefaults()
                })
                .then((success) => {
                    this.mapOlInit.addTileAndVectorLayers();
                    this.loadAllLayersGridColumnAndMenuActionsDefaults();
                    this.loadAllLayersCQLFilter();
                    this.mapCtrlTools.addCtrlMousePosition();
                    this.mapCtrlLegend.doLegendDefaultOpen();
                    //
                    return true;
                })
                .catch((reason) => {
                    this.$log.error("eroare la reinitializare harta");
                })
        }

       
        public reloadLayers() {
            if (this.map) {
                try {
                    this.clearLayers();
                    this.mapOlInit.clearTileAndVectorLayers();
                    this.mapOlInit.addBasemap();
                    this.loadLayers();
                    this.loadAllLayersReports();
                    //
                    this.loadAllLayersColumnsInfosDefaults()
                        .catch((reason) => {
                            this.$log.error("eroare la incarcare informatii coloane");
                        })
                        .then((result) => {
                            this.mapOlInit.addTileAndVectorLayers();
                            this.loadAllLayersGridColumnAndMenuActionsDefaults();
                            this.loadAllLayersCQLFilter();
                        })
                        .catch((reason) => {
                            this.$log.error("eroare la reinitializare harta");
                        })

                } catch (e) {
                    this.$log.error("eroare la reinitializare harta");
                }


            }
            else {
                this.initialize()
                    .catch((reason) => {
                        this.$log.error("eroare la initializare harta");
                    })
            }
        }

        public loadLayers() {
            var layers = this.userSettingsSrvs.getCurrentUser().layers;
            for (var i = 0; i < layers.length; i++) {
                let layer = layers[i];
                var matching = this.categories.filter((cat) => cat.code === layer.category);
                if (matching.length != 0) {
                    var category = matching[0];
                    let catindex = this.categories.indexOf(category);
                    if (!category.layers) {
                        category.layers = [];
                    }
                    layer.visible = this.userSettingsSrvs.isAuthForOption(authOpt.active_layer_at_init, layer.name, authType.layer);
                    layer.defaultIndex = (catindex * 1000) + 300 + i;
                    let indexOption = this.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.in_layer_menu_index, layer.name, authType.layer);
                    if (indexOption && indexOption.idItem) {
                        layer.defaultIndex = (catindex * 1000) + indexOption.idItem;
                    }
                    layer.opacity = 10;
                    category.layers.push(layer);
                }
            }
            //sorteaxa straturile dupa index
            this.categories.forEach((icat) => {
                icat.layers.sort((a, b) => { return a.defaultIndex - b.defaultIndex; })
            })
        }



        //
        //Layers Info Columns and menuItems
        //
        public loadAllLayersGridColumnAndMenuActionsDefaults() {
            this.categories.forEach((catItem) => {
                catItem.layers.forEach((layItem) => {
                    if (layItem.featureType !== featureType.tile) {
                        this.loadLayerGridColumnDefaults(layItem);
                    }
                    this.loadLayerMenuActions(layItem);
                })
            });
        }

        private loadLayerGridColumnDefaults(layer: ILayer) {
            try {
                let gridDef = this.gridDefaultsList.filter((grid) => grid.name === layer.name)[0];
                if (gridDef && gridDef.columns && gridDef.columns.length > 0) {
                    layer.gridDefaultColumns = gridDef.columns;
                }

            } catch (e) {
                this.$log.error("Eroare in setarea coloanelor afisate pentru " + layer.name);
            }
        }


        private loadLayerMenuActions(layer: ILayer) {
            layer.menuLayerItems = [];
            //default actions
            for (let menuItem in layerMenuItems) {
                if (this.userSettingsSrvs.isAuthForOption(layerMenuItems[menuItem].auth, layer.name)) {
                    layer.menuLayerItems.push(layerMenuItems[menuItem]);
                }
            }

            //
            this.loadLayerMenuActionsFromConfig(layer);
        }

        //todo change load from config
        private loadLayerMenuActionsFromConfig(layer: ILayer) {
            //add menu actions if something is defined in config
            if (this.gridDefaultsList) {
                let layerConfig = this.gridDefaultsList.filter((ilayer) => { return ilayer.name === layer.name });
                if (layerConfig && layerConfig.length > 0) {
                    let layerConfigItem = layerConfig[0];
                    if ("menuLayerItems" in layerConfigItem && isArray(layerConfigItem["menuLayerItems"])) {
                        let menuItemsConfig = layerConfigItem["menuLayerItems"] as Array<IMenuLayerItem>;
                        if (menuItemsConfig.length < 1) {
                            return;
                        }
                        menuItemsConfig.forEach((imenu, index) => {
                            try {
                                let tmpMenuItem: IMenuLayerItem = {
                                    id: index,
                                    name: imenu.name,
                                    active: imenu.active,
                                    action: imenu.action,
                                    auth: imenu.auth,
                                    data: imenu.data || {}
                                };
                                layer.menuLayerItems.push(tmpMenuItem);
                            } catch (e) {
                                this.$log.error("eroare incarcare actiune pe strat" + layer.name)
                            }
                        });
                    }
                }
            }

        }

        //todo change
        private loadFeatureMenuActions(layer: ILayer) {
            layer.menuFeatureItems = [];
            //add menu actions if something is defined in config
            if (this.gridDefaultsList) {
                let layerConfig = this.gridDefaultsList.filter((ilayer) => { return ilayer.name === layer.name });
                if (layerConfig && layerConfig.length > 0) {
                    let layerConfigItem = layerConfig[0];
                    if ("menuFeatureItems" in layerConfigItem && isArray(layerConfigItem["menuFeatureItems"])) {
                        let menuItemsConfig = layerConfigItem["menuFeatureItems"] as Array<IMenuLayerItem>;
                        if (menuItemsConfig.length < 1) {
                            return;
                        }
                        menuItemsConfig.forEach((imenu, index) => {
                            try {
                                let tmpMenuItem: IMenuLayerItem = {
                                    id: index,
                                    name: imenu.name,
                                    active: imenu.active,
                                    action: imenu.action,
                                    auth: imenu.auth,
                                    data: imenu.data || {}
                                };
                                layer.menuFeatureItems.push(tmpMenuItem);
                            } catch (e) {
                                this.$log.error("eroare incarcare actiune pe element" + layer.name)
                            }
                        });
                    }
                }
            }
        }

        private loadAllLayersColumnsInfosDefaults(): Promise<any> {
            //
            var promises: any = [];
            this.categories.forEach((catItem) => {
                catItem.layers.forEach((layItem) => {
                    if (featureTypeForVector(layItem.featureType)) {
                        promises.push(this.gisDataService.loadLayerColumnInfo(layItem));
                        promises.push(this.loadLayerMenuActions(layItem));
                        promises.push(this.loadFeatureMenuActions(layItem));
                        //todo change cql filter access to options
                        //promises.push(this.cqlFilterService.initIsWithCQLFilter(layItem));
                    }
                    if (featureTypeForRaster(layItem.featureType)) {
                        promises.push(this.gisDataService.loadRasterLegend(layItem));
                        //
                        let authRasterScript = this.userSettingsSrvs.isAuthForOption(authOpt.raster_with_style_script, layItem.name, authType.layer);
                        if (authRasterScript == true) {
                            promises.push(this.loadAndPrepareRasterStyle(layItem));
                        }
                       
                    }
                })
            });

            return Promise.all(promises)
            .then(() => { return true; });
        }


        public loadAndPrepareRasterStyle(layer: ILayer){
            return this.gisDataService.loadRasterStyle(layer)
            .then((status) =>{
                if (status === true){
                    this.mapOlInit.prepareRasterStyle(layer);
                } else {
                    return null;
                }
            })
        }

        public loadAllLayersCQLFilter() {
            this.categories.forEach((catItem) => {
                catItem.layers.forEach((layItem) => {
                    if (layItem.featureType !== featureType.tile) {
                        this.cqlFilterService.initIsWithCQLFilter(layItem);
                    }
                })
            });
        }

        public loadAllLayersReports() {
            this.categories.forEach((catItem) => {
                catItem.layers.forEach((layItem) => {
                    if (layItem.featureType === featureType.polyReport) {
                       layItem.reports = [];
                       let results = this.userSettingsSrvs.getCurrentUser().reports.filter((ritem) => ritem.idResReport === layItem.id);
                       if (results && results.length > 0) {
                           layItem.reports = results;
                       }
                    }
                })
            });
        }

        //
        //Search
        //
        public searchTextInFeature(searchText: string, feature: Feature, layer: ILayer): boolean {
            let found = false;
            if (layer.search && layer.search.conditions && layer.search.conditions.length > 0) {
                //filtrare dupa cautare avansata toate conditile trebuiesc indeplinite
                found = MapController.advanceSearchInFeature(feature, layer);
            }
            else if (this.searchText.length === 0) {
                return found = true;
            }
            else {
                //filtrare simpla dupa Id si text in coloanele info
                if (feature.getId().toString().toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0) {
                    return found = true;
                }
                if (layer && layer.internalLayer && layer.infoColumns && layer.infoColumns.length > 0) {
                    layer.infoColumns.forEach((infoItem) => {
                        let featureValue = feature.get(infoItem.name);
                        if (featureValue
                            && (featureValue.toString().toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0)) {
                            found = true;
                        }
                    });
                }
            }
            return found;
        }

        public static advanceSearchInFeature(feature: Feature, layer: ILayer): boolean {
            let found = false;
            if (layer.search && layer.search.conditions && layer.search.conditions.length > 0) {
                //filtrare dupa cautare avansata toate conditile trebuiesc indeplinite
                found = true;
                layer.search.conditions.forEach((srcItem) => {
                    let featureValue = feature.get(srcItem.propertyName) || '';
                    if (srcItem.propertyName === featureId) {
                        featureValue = feature.getId();
                    }
                    if (found) {
                        switch (srcItem.condition.name) {
                            case ESearchCondition.contain:
                                if (srcItem.searchText == '' || featureValue.toString().toLowerCase().indexOf(srcItem.searchText.toString().toLowerCase()) >= 0) {
                                    found = true;
                                }
                                else { found = false; }
                                break;
                            case ESearchCondition.notContain:
                                if (featureValue.toString().toLowerCase().indexOf(srcItem.searchText.toLowerCase()) < 0) {
                                    found = true;
                                }
                                else {
                                    found = false;
                                }
                                break;
                            case ESearchCondition.same:
                                if (featureValue.toString() === srcItem.searchText) {
                                    found = true;
                                }
                                else { found = false; }
                                break;
                            case ESearchCondition.notTheSame:
                                if (featureValue.toString() !== srcItem.searchText) {
                                    found = true;
                                }
                                else { found = false; }
                                break;
                            default:
                                break;
                        }
                    }
                })
            }
            return found;
        }

        public search(): void {
            //filtrarea este pusa in functia de style pentru layer
            this.map.getLayers().forEach((litem: BaseLayer, index: number) => {
                if (litem instanceof VectorLayer && litem.getVisible()) {
                    litem.changed();
                }
            });
        }

        

       
        //
        //feature menu actions
        //
        public featureMenuAction(layer: ILayer, feature: Feature, menuFeatureItem: IMenuFeatureItem) {
            if (layer && feature && menuFeatureItem) {
                switch (menuFeatureItem.action) {
                    case menuAction.editFeature: {
                        this.mapDialogs.showEditStationDialog(layer, feature);
                    }
                        break;
                    default:

                }
            }
        }

        //
        //main menu

        //old
        private getSourceLayerFromAction(vm: MapController, action: IMenuLayerItem): ILayer {
            let sourceLayer: ILayer = null;
            if (action.data && isObject(action.data) && 'source' in action.data) {
                let sourceName = action.data['source'];
                vm.categories.forEach((catItem) => {
                    if (sourceLayer == null) {
                        catItem.layers.forEach((layItem) => {
                            if (sourceLayer == null
                                && layItem.featureType !== "tile"
                                && layItem.name === sourceName) {
                                sourceLayer = layItem;
                            }
                        });
                    }
                });
            } else {
                this.$log.log("stratul sursa nu a fost definit in configurarile actiunii");
            }
            return sourceLayer
        }

        public showtools() {
            return this.userSettingsSrvs.isAuthForResource(authAs.menu_admin);
        }
        public layersAndMenuIsVisible(){
            return this.toolboxStateIsOn() === false;
        }
        //
        public menuIsVisible() {
            if (this.greentopClassifyMinimizeStateOn === true){
                return false;
            }
            return this.userSettingsSrvs.isAuthForResource(authAs.menu_visible, authType.object);
        }
        public layerMenuIsVisible(layer: any){
            if (this.greentopClassifyMinimizeStateOn === true){
                return false;
            }
            return true;
        }
        //
        public searchIsVisible() {
            return this.userSettingsSrvs.isAuthForResource(authAs.search_visible, authType.object);
        }
        public setMenuCollapsed(){
            if (this.userSettingsSrvs.isAuthForResource(authAs.menu_default_expanded, authType.object)) {
                this.isMenuCollapsed = false;
            } else {
                this.isMenuCollapsed = true;
            }
        }
       
        //
        public showLayerLoading() {
            let result = false;
            if (this.layerSourceLoadingList && this.layerSourceLoadingList.length > 0) {
                return true;
            }
            return result;
        }
        //
        public filterRouteForMessage(data: IRouteResult) {
            if (data.status === RouteGenStatus.finish) {
                let routeLayer: ILayer = null;
                this.categories.forEach(icat => {
                    if (routeLayer === null) {
                        icat.layers.forEach(ilay => {
                            if (routeLayer === null) {
                                if (ilay.name === data.message.layerName) {
                                    routeLayer = ilay;
                                }
                            }
                        })
                    }
                    
                });
                if (routeLayer) {
                    this.mapCtrlTransportRoute.searchRouteResultOnLayers(data.routeNr, routeLayer);
                }
            }
            //
        }

        //
        private initMenuHeightCheck(){
            setInterval(() => {
                let sideMenuDiv = $('.nav-side-menu');
                let menuDiv = $('#menu-content');
                this.setOverflowForDynamicMenuHeight(sideMenuDiv, menuDiv, 50, 100);
                //
                if (this.greentopDwonloadButtonStateOn){
                    let containerDiv = $('.greentop-dw-menu');
                    let contentDiv = $('#greentop-dw-content');
                    this.setOverflowForDynamicMenuHeight(containerDiv, contentDiv, 50, 100);
                }
                if (this.greentopTrainingButtonStateOn){
                    let containerDiv = $('.greentop-tr-menu');
                    let contentDiv = $('#greentop-tr-content');
                    this.setOverflowForDynamicMenuHeight(containerDiv, contentDiv, 50, 100);
                }
                if (this.greentopClassifyButtonStateOn){
                    let containerDiv = $('.greentop-cl-menu');
                    let contentDiv = $('#greentop-cl-content');
                    this.setOverflowForDynamicMenuHeight(containerDiv, contentDiv, 50, 100);
                }
                if (this.integrateLandsatProcIndConexButtonStateOn){
                    let containerDiv = $('.integrate-landsat-ic-menu');
                    let contentDiv = $('#integrate-landsat-ic-content');
                    this.setOverflowForDynamicMenuHeight(containerDiv, contentDiv, 50, 100);
                }
                if (this.integrateLandsatProcDateSezoniereButtonStateOn){
                    let containerDiv = $('.integrate-landsat-ds-menu');
                    let contentDiv = $('#integrate-landsat-ds-content');
                    this.setOverflowForDynamicMenuHeight(containerDiv, contentDiv, 50, 100);
                }
                if (this.integrateLandsatDwonloadButtonStateOn){
                    let containerDiv = $('.integrate-landsat-dw-menu');
                    let contentDiv = $('#integrate-landsat-dw-content');
                    this.setOverflowForDynamicMenuHeight(containerDiv, contentDiv, 50, 100);
                }
                if (this.integrateLandsatHotspotButtonStateOn){
                    let containerDiv = $('.integrate-landsat-hs-menu');
                    let contentDiv = $('#integrate-landsat-hs-content');
                    this.setOverflowForDynamicMenuHeight(containerDiv, contentDiv, 50, 100);
                }
            }, 300);
        }

        private setOverflowForDynamicMenuHeight(container: any, content: any, maxDif: number, minDif: number) {
            let scrollHeight = content.prop("scrollHeight");

            if (scrollHeight > (window.innerHeight - maxDif)) {
                if (container.css("overflow-x") !== "hidden" || container.css("overflow-y") !== "scroll") {
                    container.css({ "overflow-x": "hidden", "overflow-y": "scroll" });
                }
            } else if (scrollHeight < (window.innerHeight - minDif)) {
                if (container.css("overflow-x") !== "visible" || container.css("overflow-y") !== "visible") {
                    container.css({ "overflow-x": "visible", "overflow-y": "visible" });
                }
            }
        }

        //
        public hasActiveDialogs(): boolean {
            try {
                return this.modalService.hasOpenModals();
            } catch (error) {
                return false;
            }
        }
        //
        public hasActiveDrawInteractions(): boolean {
            try {
                let intmap = this.map.getInteractions()
                    .getArray()
                    .filter(item => item.getActive() && (item instanceof Draw || item instanceof Modify));
                return intmap.length > 0
            } catch (error) {
                return false;
            }
        }
        //
        public hasActiveLowerBandMenu(): boolean {
            try {
               return this.showEditFeature === true
               || this.showAddFeature === true
               || this.routeShowEdit !== 'hide'
               || this.routeAnimate === true
               || this.timeRasterAnimate === true
               || this.transportRouteShowEdit !== 'hide'

            } catch (error) {
                return false;
            }
        }
        //
        public clearSearchSettings(){
            let vectLayers: Array<ILayer> = [];
            this.map.getLayers().forEach((litem) => {
                if (litem instanceof VectorLayer && MapController.appLayer in litem) {
                    vectLayers.push(litem[MapController.appLayer]);
                }
            });
            vectLayers.forEach((litem) => {
                if (litem.search && litem.search.conditions) {
                    litem.search.conditions = [];
                }
                litem.internalLayer.changed();
            });
            this.searchSettings.type = searchType.multilayer;
            this.searchActive = false;
            this.map.render();
        }

        public doColapseMenu(){
            this.isMenuCollapsed = !this.isMenuCollapsed;
            if (this.tranzitButtonStateOn){
                this.tranzitData.ctrlSearchIsCollapsed = this.isMenuCollapsed;
                this.tranzitData.ctrlRuteIsCollapsed = this.isMenuCollapsed;
            }
        }

        public toolboxStateIsOn(){
            return this.positionButtonStateOn 
                || this.tranzitButtonStateOn
                || this.greentopDwonloadButtonStateOn
                || this.greentopTrainingButtonStateOn
                || this.greentopClassifyButtonStateOn
                || this.integrateLandsatDwonloadButtonStateOn
                || this.integrateLandsatHotspotButtonStateOn
                || this.integrateLandsatProcIndConexButtonStateOn
                || this.integrateLandsatProcDateSezoniereButtonStateOn
        }

        public enableUserSettingsButtonsIfHasAccess(){
            this.enableUserButton = this.mapDialogs.isAuthForUserDialog();
            this.enableSettingButton = this.mapDialogs.isAuthForSettingDialog();
        }
        
    }
