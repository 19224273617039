
<div class="">
    <div class="modal-header" *ngIf="selectedAction == null">
        <h5 class="modal-title" ngbAutoFocus >Setări resurse și opțiuni</h5>
    </div>
    <div class="modal-header" *ngIf="selectedAction != null">
        <h5 class="modal-title">{{selectedAction.text}}</h5>
    </div>
    <!---->
    <div class="modal-body" *ngIf="selectedAction == null">
        <div  class="list-group list-group-flush">
            <div *ngFor="let actionitem of actionList" class="list-group-item">
                <div class="d-flex" (click)="onClickAction(actionitem)">
                    <div class="flex-fill" >
                        {{actionitem.text}}
                    </div>
                    <div><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
    </div>


    <div >
        <!-- ng10 -->
        <div *ngIf="selectedAction" class="modal-body">
            <!--cat-->
            <div *ngIf="selectedAction.name === 'add-cat'">
                <add-edit-category [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-category>
            </div>
            <div *ngIf="selectedAction.name === 'edit-cat'">
                <add-edit-category [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-category>
            </div>
            <!--client cat -->
            <div *ngIf="selectedAction.name === 'client-cat'">
                <edit-client-categories [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></edit-client-categories>
            </div>
            <!--role-->
            <div *ngIf="selectedAction.name === 'rol-add'">
                <add-edit-role [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-role>
            </div>
            <div *ngIf="selectedAction.name === 'rol-edit'">
                <add-edit-role [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-role>
            </div>
            <!--map view config-->
            <div *ngIf="selectedAction.name === 'mapcfg-add'">
                <add-edit-map-view-config [mapData]="mapData" [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-map-view-config>
            </div>
            <div *ngIf="selectedAction.name === 'mapcfg-edit'">
                <add-edit-map-view-config [mapData]="mapData" [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-map-view-config>
            </div>
            <!--client-->
            <div *ngIf="selectedAction.name === 'client-add'">
                <add-edit-client [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-client>
            </div>
            <div *ngIf="selectedAction.name === 'client-edit'">
                <add-edit-client [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-client>
            </div>
            <!--textpredefinit-->
            <div *ngIf="selectedAction.name === 'add-grp-txt'">
                <add-edit-grup-textpredefinit [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-grup-textpredefinit>
            </div>
            <div *ngIf="selectedAction.name === 'edit-grp-txt'">
                <add-edit-grup-textpredefinit [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-grup-textpredefinit>
            </div>
            <div *ngIf="selectedAction.name === 'add-dic-grp-txt'">
                <edit-dictionar-textpredefinit [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></edit-dictionar-textpredefinit>
            </div>
            <div *ngIf="selectedAction.name === 'edit-dic-grp-txt'">
                <edit-dictionar-textpredefinit [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></edit-dictionar-textpredefinit>
            </div>
            <!--style settings-->
            <div *ngIf="selectedAction.name === 'style-add'">
                <add-edit-style-settings [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-style-settings>
            </div>
            <div *ngIf="selectedAction.name === 'style-edit'">
                <add-edit-style-settings [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-style-settings>
            </div>
            <!--internal resource-->
            <div *ngIf="selectedAction.name === 'res-add'">
                <add-edit-internal-resource [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-internal-resource>
            </div>
            <div *ngIf="selectedAction.name === 'res-edit'">
                <add-edit-internal-resource [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-internal-resource>
            </div>
            <!--internal resource-->
            <div *ngIf="selectedAction.name === 'day-task-edit'">
                <add-edit-dayroutestask [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-edit-dayroutestask>
            </div>
            <!--internal resource-->
            <div *ngIf="selectedAction.name === 'carte-func-add'">
                <add-load-cartefunciara [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-load-cartefunciara>
            </div>
            <!--doc-urb-add-->
            <div *ngIf="selectedAction.name === 'doc-urb-add'">
                <add-load-docurbanism [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-load-docurbanism>
            </div>
             <!--resursa optiuniroluri-->
             <div *ngIf="selectedAction.name === 'res-opt'">
                <edit-resursa-optrol [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></edit-resursa-optrol>
            </div>
            <div *ngIf="selectedAction.name === 'res-rol-opt'">
                <edit-resursa-optrol [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></edit-resursa-optrol>
            </div>
            <!--add-resopt-tomclientrol-->
            <div *ngIf="selectedAction.name === 'opt-res-cli-add'">
                <add-resopt-tomclientrol [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></add-resopt-tomclientrol>
            </div>
            <!--edit-rol-optres-->
            <div *ngIf="selectedAction.name === 'rol-optres-edit'">
                <edit-rol-optres [selectedAction]="selectedAction" (closeEvent)="menuToolCosed($event)"></edit-rol-optres>
            </div>

        </div>
    </div>
    <div class="form-group">
        <span *ngIf="errorResponse && errorResponse.length > 0">
            <span class="text-danger mx-3">{{errorResponse}}</span>
        </span>
    </div>
    <div class="modal-footer" *ngIf="selectedAction == null">
        <div class="form-group">
            <button type="button" class="btn btn-light" (click)="close()">Închide</button>
        </div>
    </div>

</div>
