///// <reference path="../scripts/typings/jquery/jquery.d.ts" />


import { IClient } from "./roles/settings-components/settings-definitions";
import { ICQLFilter, IViewParams } from "./services/user-filter-service";

import BaseLayer from 'ol/layer/Base';
import Control from 'ol/control/Control';
import Overlay from 'ol/Overlay';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import Feature from 'ol/Feature';
import Collection from "ol/Collection";
import Geolocation from 'ol/Geolocation';
import Geometry from 'ol/geom/Geometry';
import OlStyle from "ol/style/Style";

    export interface IMapConfig {
        projection: string;
        center: [number, number];
        extent: [number, number, number, number];
        zoom: number;
        minZoom: number;
        maxZoom: number;
        basemap: string;
        basemapConfig: any;
    }

    export interface ICategory {
        id: number;
        code: string;
        name: string;
        layers: ILayer[];
        defaultIndex?: number;
        parentCode?: any;
        parentCategory?: ICategory;
        children?: ICategory[];
        layersColapsed?: boolean;
    }

    export interface ILayer {
        id: number;
        name: string;
        description: string;
        category: string;
        url: string;
        featureType: string;
        projection: string;
        color: string;
        opacity?: number;
        fileName: string;
        asset: string;
        auth?: string;
        styleType?: string;
        styleKeyColumn?: string;
        textKeyColumn?: Array<string>;

        visible: boolean;
        //internalLayer: VectorLayer<any>;
        internalLayer: BaseLayer;
        infoColumns?: IFeatureInfo[];
        infoGeometry?: IFeatureInfo[];
        gridDefaultColumns?: Array<any>;
        targetNamespace?: string;
        targetPrefix?: string;
        typeName?: string;

        search?: ISearchOnLayer;
        cqlFilter?: ICQLFilter;
        viewParams?: IViewParams;
        menuLayerItems?: Array<IMenuLayerItem>;
        menuFeatureItems?: Array<IMenuFeatureItem>;

        style?: ILStyles;
        reports?: Array<ILayerReportSettings>;

        sourceType?: string;
        defaultIndex?: number;
        manualRefresh?: boolean;
        setAsTopLayer?: boolean;

        autorefreshTimeout?: number;
        autorefreshInterval?: number;
        autorefreshDelay?: number;

        legendUrl?: string;
        attributes?: Array<ILayerAttribute>;
        sldStyle?: ISLDStyle;
        animateGroup?: IAnimateGroup;
        disableFromZoom?: number;

        idClient?: number;
    }

    export interface IAnimateGroup{
        menuControlVisible: boolean;
        availableMomenteDateSatelitare: Array<string>;
        selectedMomentDateSatelitare: string;
        groupLayers: Array<ILayer>;
        sourceVectorColumn: string;
    }

    export interface ISLDStyle {
        xmlSrv: string,
        sld: object,
        script: string
        xmlResult: string,
        visible: boolean
    }

    export interface ILayerAttribute{
        idOrdine: number;
        nume: string;
        type: string;
        nillable: boolean;
    }

    export interface ILStyles {
        default: OlStyle[];
        defaultStyleLegenda: object;
        defaultStyleCluster: OlStyle[];
        list: Array<{ key: string, style: OlStyle[], styleOnSelect: OlStyle[] | null, legenda:string, styleLegenda: object }>;
        listCluster: Array<{key:string, style: OlStyle[]}>,
        settings: Array<any>;
    }

    export interface ISearchSettings {
        type: string; // searchType
        layer?: ILayer;
        feature?: Feature;
        geometry?: any;
        bufferDistance?: string;
    }

    export interface ISearchOnLayer {
        layer?: ILayer;
        conditions: Array<ISearchCondition>;
        newSrcProperty?: string;
        newSrcPropItems?: Array<string>;
        //features?: Array<Feature>;
    }

    export interface ISearchCondition {
        propertyName: string;
        condition: IItemNT; //searchCondition
        searchText: string;
    }

    export enum ESearchCondition {
        contain = "contain",
        notContain = "notContain",
        same = "same",
        notTheSame = "notTheSame"
    }

    export var searchConditions: Array<IItemNT> = [
        { name: ESearchCondition.contain, text: "contine" },
        { name: ESearchCondition.notContain, text: "nu contine" },
        { name: ESearchCondition.same, text: "exact" },
        { name: ESearchCondition.notTheSame, text: "diferit" }
    ]

    export interface IFeatureInfo {
        name: string;
        type: string;
        control?: string;
        value?: any;
        edit?: string;
        save?: string;
    }

    export interface ISelectedFeatures {
        layer: VectorLayer<any>;
        features: Collection<Feature>;
    }

    export interface ISelectFeatureConnected {
        layer: VectorLayer<any>;
        feature: Feature;
        connectedConstructii: Array<ISelectedFeatures>;
        connectedVegetatie: Array<ISelectedFeatures>;
    }

    export var featureId = "_id_";

    export var featureType = {
        point: "point",
        line: "line",
        poly: "poly",
        tile: "tile",
        heatmap: "heatmap",
        icon: "icon",
        image: "image",
        cluster: "cluster",
        clusterIcon: "clusterIcon",
        clusterText: "clusterText",
        pointText: "pointText",
        polyReport: "polyReport",
        polyReportCP: "polyReportCP",
        polyText: "polyText"
    }

    export const featureTypeAddEdit = {
        point: 'Point',
        line: 'LineString',
        polygon: 'Polygon',
        icon: 'Icon'
    }

    export function featureTypeForVector(featureTypeIn: string): boolean {
        return (featureTypeIn ===  featureType.point
            || featureTypeIn ===  featureType.line
            || featureTypeIn ===  featureType.poly
            || featureTypeIn ===  featureType.icon
            || featureTypeIn ===  featureType.heatmap
            || featureTypeIn ===  featureType.cluster
            || featureTypeIn ===  featureType.clusterIcon
            || featureTypeIn ===  featureType.clusterText
            || featureTypeIn ===  featureType.pointText
            || featureTypeIn ===  featureType.polyReport
            || featureTypeIn ===  featureType.polyReportCP
            || featureTypeIn ===  featureType.polyText
        )
    }

    export function featureTypeForRaster(featureTypeIn: string): boolean {
        return (featureTypeIn ===  featureType.tile
            || featureTypeIn ===  featureType.image
        )
    }

    export function isFeatureTypeForCluster(featureTypeIn: string): boolean {
        return (featureTypeIn ===  featureType.cluster
            || featureTypeIn ===  featureType.clusterIcon
            || featureTypeIn ===  featureType.clusterText)
    }

    export interface IItemNT {
        name: string;
        text: string;
    }

    export var layerTypeName: Array<IItemNT> = [
        { name:  featureType.point, text: "Puncte" },
        { name:  featureType.line, text: "Retele" },
        { name:  featureType.poly, text: "Zone" },
        { name:  featureType.icon, text: "Puncte imagine" },
        { name:  featureType.heatmap, text: "Heatmap" },
        { name:  featureType.cluster, text: "Cluster" },
        { name:  featureType.clusterIcon, text: "Cluster imagine" },
        { name:  featureType.clusterText, text: "Cluster punct cu descriere" },
        { name:  featureType.tile, text: "Raster" },
        { name:  featureType.image, text: "Imagine" },
        { name:  featureType.pointText, text: "Puncte cu descriere" },
        { name:  featureType.polyReport, text: "Zone raport cu strat conex" },
        { name:  featureType.polyReportCP, text: "Consultari publice" },
        { name:  featureType.polyText, text: "Zone cu descriere" }
    ]

    export const styleTypeName: Array<IItemNT> = [
        { name:  featureType.point, text: "Puncte" },
        { name:  featureType.line, text: "Retele" },
        { name:  featureType.poly, text: "Zone" },
        { name:  featureType.icon, text: "Puncte imagine" },
        { name:  featureType.pointText, text: "Puncte cu descriere" },
        { name:  featureType.polyText, text: "Zone cu descriere" },
        { name:  featureType.polyReport, text: "Zone raport cu strat conex" }
    ]

    //tipuri de geometrii asimilate coloanelor din gis server
    export var featureGeometryTypes = [
        "gml:Geometry",
        "gml:Point",
        "gml:MultiPoint",
        "gml:LineString",
        "gml:MultiLineString",
        "gml:Polygon",
        "gml:MultiPolygon",
    ];

    export var searchType = {
        multilayer: "multilayer",
        layerfeature: "layerfeature"
    }
    export var editFeatureInfo = {
        hide: "hide",
        read: "read",
        write: "write"
    }
    export var editFeaturePictureLink = {
        true: "true",
        false: "false"
    }

    export interface IGridDefaults {
        name: string;
        columns: Array<any>;
    }

    export interface IItem {
        id: number;
        text: string;
    }

    export interface IStatus {
        success: boolean;
        message: string;
    }

    export interface IAccessResursa {
        id: number;
        nume: string;
        descriere: string;
        type: string;
        access: boolean;
        defaultAccess?: boolean;
        optiuni?: Array<any>
    }

    export interface IOptiune {
        id: number;
        nume: string;
        idItem: number;
        descriere?: string;
        defaultAccess?: boolean;
        customAccess?: string;
        group?: string;
        idItemMin: number;
    }

    export interface IOptiuneRes extends IOptiune {
        access?: boolean;
        idResursa?: number;
    }

    export interface IOptiuneRol extends IOptiune {
        access?: boolean;
        overrideDefaults?: boolean;
        defaultOption?: IOptiune;
    }

    export interface IRoleOptiuni {
        id: number;
        nume: string;
        optiuni: Array<IOptiuneRol>
    }

    export interface ILayerStyleSettings {
        id: number;
        idResursa: number;
        styleKey: string;
        styleName: string;
        descriere: string;
        layerType: string;
        icon: string;
        style: any;
        styleOnSelect: any;
        legenda?: string;
    }

    export interface ILayerReportSettings {
        id: number;
        idResReport: number;
        nume?: string;
        reportColumns: any;
        nameResData: string;
        dataColumns: any;
        constants: any;
        reportFormula: string;
        description: string;
        dataLayer?: ILayer;
        dataFeatures?: Array<{ reportFeature: Feature, dataFeatures: Array<Feature> }>;
        check?: boolean;
    }

    export interface IUserSettings {
        name: IItem;
        token?: string;
        idClient?: string;
        roles?: Array<IItem>;
        accessResurse?: Array<IAccessResursa>;
        categories?: Array<ICategory>;
        layers?: Array<ILayer>;
        mapConfig?: any;
        mapProjections?: Array<ISridProjection>;
        layersInfoConfig?: Array<any>;
        styles?: Array<ILayerStyleSettings>;
        reports?: Array<ILayerReportSettings>;
        client?: IClient;
    }

    export interface IMenuLayerItem {
        id: number;
        name: string;
        active: boolean;
        action: string;
        auth: string;
        data?: any;
    }

    export interface IMenuFeatureItem {
        id: number;
        name: string;
        active: boolean;
        action: string;
        auth: string;
        data?: any;
    }

    export var menuAction = {
        addStation: "addStation",
        addFeature: "addFeature",
        editFeature: "editFeature",
        addRoute: "addRoute",
        editRoute: "editRoute",
        refreshLayer: "refreshLayer",
        editLayer: "editLayer",
        regenerateRoutes: "regenerateRoutes",//
        generateRoute: "generateRoute",
        addTransportRoute: "addTransportRoute",
        editTransportRoute: "editTransportRoute",
        animateTimeRaster: "animateTimeRaster",
        animateTimeRasterGroup: "animateTimeRasterGroup",
        setAsTopLayer: "setAsTopLayer",
        downloadShapefile: "downloadShapefile",
        downloadRasterfile: "downloadRasterfile",
        rasterStyleScript: "rasterStyleScript"
        //infoLocatiiConexe: "infoLocatiiConexe"
    }
    export const authType = {
        route: 'route',
        layer: 'layer',
        object: 'object'
    }

    export const authAs = {
        auth_local_register: '/auth/local-register',
        auth_local_client_register: '/auth/local-client-register',
        auth_change_current_password: '/auth/change-current-password',
        auth_change_password: '/auth/change-password',
        auth_user_is_defined: '/auth/user-is-defined',
        data_save_current_user_info: '/data/save-current-user-info',
        data_save_user_roles: '/data/save-user-roles',
        data_layer: '/data/layer',
        data_add_layer: '/data/add-layer',
        data_update_layer: '/data/update-layer',
        data_save_resursa_optiuni: '/data/save-resursa-optiuni',
        data_save_resursa_roles_optiuni: '/data/save-resursa-roles-optiuni',
        data_save_resursa_roles: '/data/save-resursa-roles',
        data_resursa_roles: '/data/resursa-roles',
        data_add_resursa_interna: '/data/add-resursa-interna',
        data_update_resursa_interna: '/data/update-resursa-interna',
        data_add_role: '/data/add-role',
        data_update_role: '/data/update-role',
        data_all_layers: '/data/all-layers',
        data_add_client: '/data/add-client',
        data_edit_client: '/data/edit-client',
        data_add_internal_resource: '/data/add-optiuni-resurse-clienti',
        data_styles_descriptions: 'data/styles-descriptions',
        data_style_settings: '/data/style-settings',
        data_add_style_settings: '/data/add-style-settings',
        data_save_style_settings: '/data/save-style-settings',
        data_delete_style_settings: '/data/delete-style-settings',
        data_add_mapview_settings: '/data/add-mapview-settings',
        data_save_mapview_settings: '/data/save-mapview-settings',
        data_delete_mapview_settings: '/data/delete-mapview-settings',
        menu_admin: 'menu-admin',
        menu_visible: 'menu-visible',
        search_visible: 'search-visible',
        menu_default_expanded: 'menu-default-expanded',
        menu_category_hide_if_empty: 'menu-category-hide-if-empty',
        menu_category_index: 'menu-category-index',
        // menu_multi_feature_view_info: 'menu-multi-feature-view-info',
        menu_multi_feature_report: 'menu-multi-feature-report',
        menu_multi_feature_details: 'menu-multi-feature-details',
        menu_grup_features_info: 'menu-grup-features-info',
        info_multi_feature_hide: 'info-multi-feature-hide',
        //info_single_feature_hide: 'info-single-feature-hide',
        legend_default_open: 'legend-default-open',
        devices_generate_devices_day_routes: '/devices/generate-devices-day-routes',
        devices_resume_devices_day_routes: '/devices/resume-devices-day-routes',
        devices_delete_devices_day_routes: '/devices/delete-devices-day-routes',
        devices_status_devices_day_routes: '/devices/status-devices-day-routes',
        devices_generate_adhoc_route: '/devices/generate-adhoc-route',
        data_save_user_info: '/data/save-user-info',
        data_user_info: '/data/user-info/',

        data_add_category: '/data/add-category',
        data_edit_category: '/data/edit-category',
        data_client_edit_category: '/data/client-edit-category',
        
        data_add_gruptext_predefinit: '/data/add-gruptext-predefinit',
        data_update_gruptext_predefinit: '/data/update-gruptext-predefinit',

        data_update_dictionar_gruptext_predefinit: '/data/update-dictionar-gruptext-predefinit',

        data_change_layer_client_access_public_pivate: '/data/change-layer-client-access-public-private',
        cadastru_load_carte_funciara: '/cadastru/load-carte-funciara',
        urbanism_load_doc_urbanism: '/urbanism/load-doc-urbanism',
        control_coordinates_visible: 'control-coordinates-visible',
        control_coordinates_use_map_projection: 'control-coordinates-use-map-projection',
        control_tranzit_button_visible: 'control-tranzit-button-visible',
        control_tranzit_default_active: 'control-tranzit-default-active',
        control_grentop_dw_button_visible: 'control-greentop-dw-button-visible',
        control_grentop_dw_default_active: 'control-grentop-dw-default-active',
        control_grentop_tr_button_visible: 'control-greentop-tr-button-visible',
        control_grentop_tr_default_active: 'control-grentop-tr-default-active',
        control_grentop_cl_button_visible: 'control-greentop-cl-button-visible',
        control_grentop_cl_default_active: 'control-grentop-cl-default-active',
        control_integrate_landsat_dw_button_visible: 'control-integrate-landsat-dw-button-visible',
        control_integrate_landsat_dw_default_active: 'control-integrate-landsat-dw-default-active',
        control_integrate_landsat_hotspot_button_visible: 'control-integrate-landsat-hotspot-button-visible',
        control_integrate_landsat_hotspot_default_active: 'control-integrate-landsat-hotspot-default-active',
        control_integrate_landsat_prindconex_button_visible: 'control-integrate-landsat-prindconex-button-visible',
        control_integrate_landsat_prindconex_default_active: 'control-integrate-landsat-prindconex-default-active',
        control_integrate_landsat_prdtsezonier_button_visible: 'control-integrate-landsat-prdtsezonier-button-visible',
        control_integrate_landsat_prdtsezonier_default_active: 'control-integrate-landsat-prdtsezonier-default-active',
        //prdtsezonier
        control_one_active_layer_button_visible: 'control-one-active-layer-button-visible',
        log_info_animate_raster_layer_all_timestamps: 'log-info-animate-raster-layer-all-timestamps',
        info_graph_timeline_classify_result: 'info-graph-timeline-classify-result',
        info_graph_utilizare_reference: 'info-graph-tip-utilizare-reference',
        info_agro_plan_classify: 'info-agro-plan-classify',
    }

    export const authOpt = {
        read_route: "read-route",
        write_route: "write-route",
        view_control: "view-control",
        edit_control: "edit-control",
        read_object: "read-object",
        write_object: "write-object",
        read_layer: "read-layer",
        //
        view_layer_config: "view-layer-config",
        edit_layer_config: "edit-layer-config",
        add_layer_feature: "add-layer-feature",
        add_layer: "add-layer",
        refresh_layer: "refresh-layer",
        //

        in_add_feature: "in-add-feature",
        in_edit_feature: "in-edit-feature",
        in_info_feature: "in-info-feature",
        in_table_details: "in-table-details",
        in_table_report: "in-table-report",
        in_feature_is_picture: "in-feature-is-picture",
        in_feature_is_id: "in-feature-is-id",
        display_readable_propery_name: "display-readable-propery-name",
        //
        msg_layer_data: "msg-layer-data",
        in_msg_data: "in-msg-data",
        cql_layer_filter: "cql-layer-filter",
        cql_client_filter: "cql-client-filter",
        in_cql_client_filter: "in-cql-client-filter",
        //
        active_layer_at_init: "active-layer-at-init",
        in_hide_menu_category: "in-hide-menu-category",
        in_index_menu_category: "in-index-menu-category",
        //
        message_selected_features_info: "message-selected-features-info",
        in_message_selected_features_info: "in-message-selected-features",
        //
        message_selected_byclick_features_info: "message-selected-byclick-features-info",
        in_message_selected_byclick_features_info: "in-message-selected-byclick-features",
        //
        hide_layer_feature_info: "hide-layer-feature-info", //hide popup for clicked feature
        edit_layer_feature_info: 'edit-layer-feature-info',
        edit_layer_feature_location: "edit-layer-feature-location",
        search_layer_feature_nearby: "search-layer-feature-nearby",
        report_layer_feature: "report-layer-feature",
        report_layer_feature_spatiuverde: "report-layer-feature-spatiuverde",
        report_spatiuverde_source_layer_constructii: "report-spatiuverde-source-layer-constructii",
        report_spatiuverde_source_layer_vegetatie: "report-spatiuverde-source-layer-vegetatie",
        //
        in_utc_to_local_convert: "in-utc-to-local-convert",
        //
        route_generare: "route-generare",
        route_points_layer: "route-points-layer",
        route_segments_layer: "route-segments-layer",
        route_disable_search_after_edit: "route-disable-search-after-edit",
        //
        in_layer_menu_index: "in-layer-menu-index",
        //"": "",

        //
        play_layer_route: 'play-layer-route',
        //
        transport_route_generare: 'transport-route-generare',
        transport_route_edit: "transport-route-edit",
        //
        select_layer_feature: 'select-layer-feature',
        generate_report: 'generate-report',
        auto_refresh_interval: 'auto-refresh-interval',
        report_refresh_delay: 'report-refresh-delay',
        report_refresh_by_layer: 'report-refresh-by-layer',
        //
        connected_layer_activate_insert: 'connected-layer-activate-insert',
        connected_layer_name: 'connected-layer-name',
        in_connected_layer_source: 'in-connected-layer-source',
        in_connected_layer_dest: 'in-connected-layer-dest',
        //
        input_rate_item: 'input-rate-item',
        input_rate_step: 'input-rate-step',
        input_rate_min: 'input-rate-min',
        input_rate_max: 'input-rate-max',
        //
        input_text_area: 'input-text-area',
        //
        input_text_predefinit: 'input-text-predefinit',
        input_text_predefinit_grup: 'input-text-predefinit-grup',
        input_text_predefinit_novalue: 'input-text-predefinit-novalue',
        //
        info_connected_features: 'info-connected-features',
        in_info_connected_features: 'in-info-connected-features',
        in_info_group_features: 'in-info-group-features',
        //
        animate_time_raster: 'animate-time-raster',
        animate_time_raster_source: 'animate-time-raster-source',
        animate_time_raster_column: 'animate-time-raster-column',
        animate_time_raster_group: 'animate-time-raster-group',
        animate_group_layers_bbox_filter: 'animate-group-layers-bbox-filter',
        //
        raster_select_info: 'raster-select-info',
        //layer menu action
        set_as_top_layer: 'set-as-top-layer',
        //layer menu action
        download_shapefile: 'download-shapefile',
        download_rasterfile: 'download-rasterfile',
        download_rasterfile_pixel_resolution: 'download-rasterfile-pixel-resolution',
        //
        info_ireport_image: 'info-ireport-image',
        //
        info_layer_images: 'info-layer-images',
        //feature action download docu
        download_documents: 'download-documents',
        download_carte_funciara_prop_name: 'download-carte-funciara-prop-name',
        //default at add
        in_add_feature_default_idclient: 'in-add-feature-default-idclient',
        in_add_feature_default_from_msg: 'in-add-feature-default-from-msg',
        selectbox_infopopup_display_propvalue: 'selectbox-infopopup-display-propvalue',
        //
        greentop_import_source_zones: 'greentop-import-source-zones',
        greentop_indice_referinta: 'greentop-codindice-referinta',
        greentop_sursa_referinta: 'greentop-sursadate-referinta',
        //
        integratelandsat_rezutat_referinta: 'integratelandsat-rezutat-referinta',
        integratelandsat_rezutat_referinta2: 'integratelandsat-rezutat-referinta2',
        integratelandsat_indice_referinta: 'integratelandsat-codindice-referinta',
        //
        integrate_indconex_indice_referinta:'integrate-indconex-indice-referinta',
        integrate_indconex_sursa_referinta:'integrate-indconex-sursa-referinta',
        //
        raster_with_style_script: 'raster-with-style-script',
        //
        hide_layer_from_zoom_level:'hide-layer-from-zoom-level',
        info_graph_timeline_mosaic: 'info-graph-timeline-mosaic',
        info_raster_null_value: 'info-raster-null-value',
        info_graph_3D: 'info-graph-3D',
        info_graph3d_width: 'info-graph3d-width',
        info_graph3d_offset: 'info-graph3d-offset',
        info_graph3d_scaling: 'info-graph3d-scaling',
        info_graph3d_osm_offset: 'info-graph3d-osm-offset',
        info_agro_plan: 'info-agro-plan'
    }

    export const optionGroupType = {
        item: "item",
        layer: "layer",
        object: "object",
        index: "index",
        control: "control",
        relation: "relation"
    }

    export const authItemAccess = {
        true: 'true',
        false: 'false',
        YView: 'YView',
        NView: 'NView',
        YEdit: 'YEdit',
        NEdit: 'NEdit',
        YSave: 'YSave',
        NSave: 'NSave'
    }

    export const authItemDateTime = {
        dateTime: "Date-Time",
        date: "Date",
        time: "Time"
    }
    
    export const basemaps: Array<IItem|{id, text, tip}> = [
        {id: -1, text: "Fără hartă de bază", tip:""},
        {id: 0, text: "OSM", tip:"osm_tiles"},
        {id: 1, text: "OSM deschis", tip:"light"},
        {id: 2, text: "OSM minimal", tip:"ultralight"},
        {id: 3, text: "OSM strazi", tip:"roads"},
        {id: 4, text: "OSM natura", tip:"nature"},
    ];

    export const layerMenuItems:
        {
            refreshLayer:  IMenuLayerItem,
            editLayer:  IMenuLayerItem,
            addFeature:  IMenuLayerItem,
            generateRoute:  IMenuLayerItem,
            addTransportRoute:  IMenuLayerItem,
            editTransportRoute:  IMenuLayerItem,
            generateReport:  IMenuLayerItem,
            animateTimeRaster:  IMenuLayerItem,
            animateTimeRasterGroup:  IMenuLayerItem,
            setAsTopLayer:  IMenuLayerItem,
            downloadShapefile:  IMenuLayerItem,
            downloadRasterfile: IMenuLayerItem,
            rasterStyleScript:  IMenuLayerItem,
        }
        =
        {
            refreshLayer: {
                id: 102,
                name: 'Refresh',
                active: true,
                action:  menuAction.refreshLayer,
                auth:  authOpt.refresh_layer,
                data: {}
            },
            editLayer: {
                id: 100,
                name: 'Modifică setări',
                active: true,
                action:  menuAction.editLayer,
                auth:  authOpt.edit_layer_config,
                data: {}
            },
            addFeature: {
                id: 101,
                name: 'Adaugă locație',
                active: true,
                action:  menuAction.addFeature,
                auth:  authOpt.add_layer_feature,
                data: {}
            },
            generateRoute: {
                id: 103,
                name: 'Crează ruta',
                active: true,
                action:  menuAction.generateRoute,
                auth:  authOpt.route_generare,
                data: {}
            },
            addTransportRoute: {
                id: 104,
                name: 'Crează ruta',
                active: true,
                action:  menuAction.addTransportRoute,
                auth:  authOpt.transport_route_generare,
                data: {}
            },
            editTransportRoute: {
                id: 105,
                name: 'Editează ruta',
                active: true,
                action:  menuAction.editTransportRoute,
                auth:  authOpt.transport_route_edit,
                data: {}
            },
            generateReport: {
                id: 106,
                name: 'Generează raport',
                active: true,
                action:  menuAction.refreshLayer,
                auth:  authOpt.generate_report,
                data: {}
            },
            animateTimeRaster: {
                id: 107,
                name: 'Animează strat',
                active: true,
                action:  menuAction.animateTimeRaster,
                auth:  authOpt.animate_time_raster,
                data: {}
            },
            animateTimeRasterGroup: {
                id: 107,
                name: 'Animează grup straturi',
                active: true,
                action:  menuAction.animateTimeRasterGroup,
                auth:  authOpt.animate_time_raster_group,
                data: {}
            },
            setAsTopLayer: {
                id: 108,
                name: 'Strat top',
                active: true,
                action:  menuAction.setAsTopLayer,
                auth:  authOpt.set_as_top_layer,
                data: {}
            },
            downloadShapefile: {
                id: 109,
                name: 'Descarcă date',
                active: true,
                action:  menuAction.downloadShapefile,
                auth:  authOpt.download_shapefile,
                data: {}
            },
            downloadRasterfile: {
                id: 110,
                name: 'Descarcă raster',
                active: true,
                action:  menuAction.downloadRasterfile,
                auth:  authOpt.download_rasterfile,
                data: {}
            },
            rasterStyleScript: {
                id: 111,
                name: 'Script stil',
                active: true,
                action:  menuAction.rasterStyleScript,
                auth:  authOpt.raster_with_style_script,
                data: {}
            }
        }

    export const WFSTActionType = {
        insertFeature: 'insertFeature',
        updateInfo: 'updateInfo',
        updateLocation: 'updateLocation'
    }

    export const windowMessageType = {
        cqlFilter: "cqlFilter",
        featureExtraInfo: "featureExtraInfo",
        featureListExtraInfo: "featureListExtraInfo",
        sendMapClick: "sendMapClick",
        generateRoute: "generateRoute",
        animateRoute: "animateRoute",
        sendMapView: "sendMapView",
        sendLayerMenuAction: "sendLayerMenuAction",
        sendFeatureMenuAction: "sendFeatureMenuAction",
        sendLayerViews: "sendLayerViews"
    }

    export const saveOptionType = {
        all: 'all',
        option: 'option'
    }

    export const styleType = {
        // color: "color",
        default: "default",
        //icon: "icon",
        multiStyle: "multiStyle",
        singleStyle: "singleStyle"
    }
    export const styleTypeList: Array<IItemNT> = [
        { name: styleType.default, text: "Stil de baza" },
        //{ name: styleType.color, text: "Culoare" },
        { name: styleType.multiStyle, text: "Stiluri multiple posibile" },
        { name: styleType.singleStyle, text: "Stil personalizat" }
    ]

    export const sourceType = {
        url: 'url',
        upload: 'upload',
        table: 'table'//tabel in baza de date
    }

    export const sourceTypeList: Array<IItemNT> = [
        { name: sourceType.url, text: 'Url strat' },
        { name: sourceType.upload, text: 'Incarcare date din fisiere shapefiles' },
        { name: sourceType.table, text: 'Tabel in baza de date' }
    ];

    export const destUploadAction = {
        create: 'CREATE',
        replace: 'REPLACE',
        append: 'APPEND'
    };
    //
    export const destUploadActionList: Array<IItemNT> = [
        { name: destUploadAction.create, text: 'Creaza resursa si date' },
        { name: destUploadAction.replace, text: 'Sterge tot si Inlocuieste datele' },
        { name: destUploadAction.append, text: 'Adauga la datele existente' }
    ];

    export interface IDayTaskState {
        id?: number;
        name?: string;
        type?: string;
        description?: string;
        status?: string;
        time?: string;
        routes?: number;
        points?: number;
    }


    export const formatDateTime = {
        dateTime: "DD/MM/YYYY HH:mm:ss",
        date: "DD/MM/YYYY",
        time: "HH:mm:ss",
    }

    export const layerAttributeType = {
        integer: "java.lang.Integer",
        float: "java.math.BigDecimal",
        double: "java.lang.Double",
        string: "java.lang.String",
        timestamp: "java.sql.Timestamp",
        date: "java.sql.Date",
        boolean: "java.lang.Boolean",
        point: "org.locationtech.jts.geom.Point",
        line: "org.locationtech.jts.geom.LineString",
        polygon: "org.locationtech.jts.geom.Polygon"

    }

    export const geomAttributeList = [
        {geom:  featureType.point, attrType:  layerAttributeType.point},
        {geom:  featureType.line, attrType:  layerAttributeType.line},
        {geom:  featureType.poly, attrType:  layerAttributeType.polygon},
    ]
    
    export const RoutingType = {
        foot: "foot",
        car: "car"
    };
    //wayRoutingTypeList
    export const wayRoutingTypeList: Array<IItemNT> = [
        { name: RoutingType.foot, text: 'Mers pe jos' },
        { name: RoutingType.car, text: 'Mers cu masina' }
    ];

    export const LocationType = {
        point: "point",
        uuid_address: "uuid_address"
    };

    export const RouteType = {
        ad_hoc: "ad-hoc",
        transport: "transport"
    };

    export const wayRestrictType = {
        none: "none",
        trafic_greu: "trafic_greu"
    };

    export const wayRestrictTypeList: Array<IItemNT> = [
        { name: wayRestrictType.none, text: 'Fara restrictie' },
        { name: wayRestrictType.trafic_greu, text: 'Restrictie trafic greu' }
    ];
    export interface IRouteCoord {
        distance: number;
        coords: [number, number]
    };
    export interface IAnimate {
        layer: ILayer,
        feature: Feature,
        styles: { route: OlStyle, start: OlStyle, finish: OlStyle, geoMarker: OlStyle[] },
        polyline: Geometry,
        route: Geometry,
        routeCoords: Array<IRouteCoord>,
        routeLength: number,
        routeFeature: Feature,
        geoMarker: Feature,
        vectorLayer: VectorLayer<any>,
        index: number,
        isAnimating: boolean,
        speed: number,
        maxSpeed: number,
        minSpeed: number,
        sliderValue: number,
        routeDist: Array<IRoutePointDist>,
        startPointIndex: number
    }

    export interface IAnimateTimeRaster {
        //timesource
        sourceVectorColumn: string,
        sourceVectorLayer: ILayer,
        sourceRasterLayer: ILayer,
        //
        index: number,
        isAnimating: boolean,
        ticks: number,
        speed: number,
        maxSpeed: number,
        minSpeed: number,
        sliderValue: number,
        steps: Array<string>,
        startPointIndex: number,
        info: string,
        stepValues?: Array<any>,
        graphType?: string,
        startInterval: string,
        endInterval: string,
        selectSteps: Array<string>,
        startSteps: Array<string>,
        endSteps: Array<string>
    }

    export interface ISridProjection {
        proiectie: string;
        srid: string;
        proj4text: string
    }

    export interface IButtonTool {
        button: HTMLButtonElement;
        buttonCtrl: Control;
        buttonStateOn: boolean;
    }
    export interface IMeasure {
        distance: IButtonTool;
        area: IButtonTool;
        layer: VectorLayer<any>;
        drawInteraction: Draw;
        type: string;
        tooltipElement: HTMLElement;
        tooltipOverlay: Overlay;
        onChangeListener: any;
    }
    export const ToolButtonType = {
        center: "center",
        select: "select",
        distance: "distance",
        area: "area"
    }
    export interface IRoutePointDist {
        idRoute: number,
        subrouteId: number,
        dist: number,
        distAgg: number,
        sfDist: number,
        sfDistAgg: number
    }
    export const MapClickMessageMode = {
        coordinates: "coordinates",
        properties: "properties",
        coordinates_propperties: "coordinates_properties",
    }
    export interface ILayerSourceLoading {
        layerId: number;
        timeout: number;
    }
    export const controlError = {
        "required": "obligatoriu",
        "step": "precizie",
    }
    //
    export function getErrorMessage(errorMessage: string): string {
        if (errorMessage == undefined || errorMessage == null || errorMessage == "") {
            return "";
        }
        return controlError[errorMessage] || errorMessage;
    }
    
    //
    export const layerFilesStatus = {
        initializat: 'initializat',
        se_salveaza: 'se_salveaza',
        incarcat: 'incarcat',
        finalizat: 'finalizat',
        inactiv: 'inactiv',
        sters: 'sters',
        eroare: 'eroare',
        //
        pt_inactivare: 'pt_inactivare'
    }
    //
    export const layerFileType = {
        image: "image",
        document: "document"
    }
    //
    export interface ILayerFile {
        id: number,
        type: string,
        idClient: number,
        idLayer: number,
        idRefLayerItem?: number,
        idColName?: string,
        fileColName?: string,
        status: string,
        description: string,
        timestamp?: string,
        fileName?: string,
        diskFileName?: string,
        fileSize?: number,
        file?: any,
        seqTime?: number
        fileLocalURL?: any,
    }
    
    export interface ITranzitData {
        tripPlan: ITripPlan,
        activeConfigTab0: boolean,
        activeConfigTab1: boolean,
        activeConfigTab: any,
        momentPlecare: string,
        input: {
            start: string,
            stop: string
        },
        coordonate: {
            start: Array<number>,
            stop: Array<number>
        }
        locatii: {
            start: Feature,
            stop: Feature
        }
        stratLocatii: VectorLayer<any>,
        stratTranzit: VectorLayer<any>,
        locatiiModifyInteraction: {
            start: Modify,
            stop: Modify
        },
        optPlecareImediata: string,
        optDataPlecare: any,
       // optOraPlecare: string,
        optOraMinPlecare: {hour: number; minute: number},
        optFormatDataPlecare: string,
        optFormatOraPlecare: string,
        optMaxWalkDistance: number,
        activeNoTrips: boolean,
        activeSearchTrips: boolean,
        geoLocation?: Geolocation,
        toGetStartOrStopFromGeolocation?: string,//'start', 'stop', null
        ctrlSearchIsCollapsed:boolean,
        ctrlRuteIsCollapsed:boolean,
        //
        funcInit: any,
        funcClear: any,
        funcSetStartCoordinates: any,
        funcSetStopCoordinates: any,
    }

    export interface ITripPlan {
        //
        activeItineraryId?: number,
        //
        date: number,
        from: IPlace,
        to: IPlace,
        itineraries: Array<IItinerary>
    }

    export interface IPlace {
        name: string,
        stopId?: IAgencyAndId,
        stopCode?: string,
        platformCode?: string,
        lon: number,
        lat: number,
        arrival?: number,
        departure?: number,
        orig?: string,
        zoneId?: string,
        stopIndex?: number,
        stopSequence?: number,
        vertexType?: string,//VertexType
        bikeShareId?: string
    }

    export const VertexType = {
        NORML: 'NORMAL',
        BIKESHARE:'BIKESHARE',
        BIKEPARK: 'BIKEPARK',
        TRANSIT: 'TRANSIT'
    }

    export interface IItinerary {
        //
        ctrlId?: number,
        ctrlIsCollapsed?: boolean,
        //
        duration: number,
        startTime: number,
        endTime: number,
        walkTime: number,
        transitTime: number,
        waitingTime: number,
        walkDistance: number,
        walkLimitExceeded: boolean,
        elevationLost: number,
        elevationGained: number,
        transfers: number,
        fare: IFare,
        legs: Array<ILeg>,
        tooSloped: boolean
    }

    export interface IFare {

    }

    export interface ILeg {
        //
        legFeature?: Feature,
        //
        startTime: number,
        endTime: number,
        departureDelay: number,
        arrivalDelay: number,
        realTime: boolean,
        isNonExactFrequency: boolean,
        headway: number,
        distance: number,
        pathway: boolean,
        mode: string,
        route: string,
        agencyName: string,
        agencyUrl: string,
        agencyTimeZoneOffset: number,
        routeColor: string,
        routeType: number,
        routeId: IAgencyAndId,
        routeTextColor: string,
        interlineWithPreviousLeg: boolean,
        tripShortName: string,
        tripBlockId: string,
        headsign: string,
        agencyId: string,
        tripId: IAgencyAndId,
        serviceDate: string,
        from: IPlace,
        to: IPlace,
        intermediateStops: Array<IPlace>,

        legGeometry: IEncodedPolylineBean,
        steps: Array<IWalkStep>,
        alerts: Array<ILocalizedAlert>,

        routeShortName: string,
        routeLongName: string,
        boardRule: string,
        alightRule: string,
        rentedBike: boolean,
        transitLeg: boolean,
        duration: number
    }
    //
    export interface IAgencyAndId {
        id: string,
        agencyId: string
    }
    //
    export interface IEncodedPolylineBean {
        points: string,
        levels: string,
        length: number
    }
    //
    export interface IWalkStep {
        distance: number,
        relativeDirection: string,//relative direction
        streetName: string,
        absoluteDirection: string,//absolute direction
        exit: string,
        stayOn: boolean,
        area: boolean,
        bogusName: boolean,
        lon: number,
        lat: number,

        alerts: Array<ILocalizedAlert>,
        elevation: string
    }
    //
    export interface ILocalizedAlert {
        alertHeaderText: string,
        alertDescriptionText: string,
        alertUrl: string,
        effectiveStartDate: number
    }
    export const legMode = {
        WALK: "WALK",
        BUS: "BUS",
        CAR: "CAR"
    }
    export const relativeDirection = {
        DEPART: "DEPART",
        HARD_LEFT: "HARD_LEFT",
        LEFT: "LEFT",
        SLIGHTLY_LEFT: "SLIGHTLY_LEFT",
        CONTINUE: "CONTINUE",
        SLIGHTLY_RIGHT: "SLIGHTLY_RIGHT",
        RIGHT: "RIGHT",
        HARD_RIGHT: "HARD_RIGHT",
        CIRCLE_CLOCKWISE: "CIRCLE_CLOCKWISE",
        CIRCLE_COUNTERCLOCKWISE: "CIRCLE_COUNTERCLOCKWISE",
        ELEVATOR: "ELEVATOR",
        UTURN_LEFT: "UTURN_LEFT",
        UTURN_RIGHT: "UTURN_RIGHT"
    }
    export const AbsoluteDirection = {
        NORTH: "NORTH",
        NORTHEAST: "NORTHEAST",
        EAST: "EAST",
        SOUTHEAST: "SOUTHEAST",
        SOUTH: "SOUTH",
        SOUTHWEST: "SOUTHWEST",
        WEST: "WEST",
        NORTHWEST: "NORTHWEST",
    }
    export interface IDebugOutput {

        precalculationTime: number,
        pathCalculationTime: number,
        pathTimes: [number, number],
        renderingTime: number,
        totalTime: number,
        timedOut: boolean

    }
    export interface IPlannerError {
        "id": number,
        "msg": string,
        "message": string,//PlanMessage,
        "missing": [string, string],
        "noPath": boolean
    }

    export const PlanMessage = {
        PLAN_OK: "PLAN_OK",
        SYSTEM_ERROR: "SYSTEM_ERROR",
        GRAPH_UNAVAILABLE: "GRAPH_UNAVAILABLE",
        OUTSIDE_BOUNDS: "OUTSIDE_BOUNDS",
        PATH_NOT_FOUND: "PATH_NOT_FOUND",
        NO_TRANSIT_TIMES: "NO_TRANSIT_TIMES",
        REQUEST_TIMEOUT: "REQUEST_TIMEOUT",
        BOGUS_PARAMETER: "BOGUS_PARAMETER",
        GEOCODE_FROM_NOT_FOUND: "GEOCODE_FROM_NOT_FOUND",
        GEOCODE_TO_NOT_FOUND: "GEOCODE_TO_NOT_FOUND",
        GEOCODE_FROM_TO_NOT_FOUND: "GEOCODE_FROM_TO_NOT_FOUND",
        TOO_CLOSE: "TOO_CLOSE",
        LOCATION_NOT_ACCESSIBLE: "LOCATION_NOT_ACCESSIBLE",
        GEOCODE_FROM_AMBIGUOUS: "GEOCODE_FROM_AMBIGUOUS",
        GEOCODE_TO_AMBIGUOUS: "GEOCODE_TO_AMBIGUOUS",
        GEOCODE_FROM_TO_AMBIGUOUS: "GEOCODE_FROM_TO_AMBIGUOUS",
        UNDERSPECIFIED_TRIANGLE: "UNDERSPECIFIED_TRIANGLE",
        TRIANGLE_NOT_AFFINE: "TRIANGLE_NOT_AFFINE",
        TRIANGLE_OPTIMIZE_TYPE_NOT_SET: "TRIANGLE_OPTIMIZE_TYPE_NOT_SET",
        TRIANGLE_VALUES_NOT_SET: "TRIANGLE_VALUES_NOT_SET"
    }

    export interface geocodingLocation{
        "place_id": number,
        "licence": string,
        "osm_type": string,
        "osm_id": number,
        "boundingbox": Array<number>,
        "lat": string,
        "lon": string,
        "display_name": string,
        "class": string,
        "type": string,
        "importance": number,
        "geojson": any,
        "feature"?: Feature
    }

    export interface IPositionData {
        selectedOption: string,
        searchText: string,
        disableSearch: boolean,
        locations: Array<geocodingLocation>,
        activeSearchLocations: boolean,
        numaiUnitatiTeritoriale: boolean
        locationLayer: VectorLayer<any>,
        //
        funcInit: any,
        funcClear: any,
    }

    export interface actionOption {
        name: string;
        description: string;
        access: string;
    }
    
    export const userActionList: Array<actionOption> = [
        { name: 'ch-pass', description: 'Schimbă parola', access: 'U' },
        { name: 'ch-pass-user', description: 'Schimbă parola pentru alt utilizator', access: 'A' },
        { name: 'ch-info', description: 'Modifică informații', access: 'U' },
        { name: 'ch-info-user', description: 'Modifică informații pentru alt utilizator', access: 'A' },
        { name: 'reg-user', description: 'Înregistrează utilizator', access: 'A' },
        { name: 'user-roles', description: 'Roluri utilizator', access: 'A' }, 
    ]

    
