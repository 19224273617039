//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { IPositionData, geocodingLocation, authAs, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { NGXLogger } from 'ngx-logger';
import { getExMessage } from '../map-utils';

import VectorLayer from 'ol/layer/Vector';
import * as olExtent from 'ol/extent';
import VectorSource from "ol/source/Vector";
import GeoJSONFormater from "ol/format/GeoJSON";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import FillStyle from "ol/style/Fill";
import * as olColor from 'ol/color';
import * as olProj from 'ol/proj';

@Component({
    selector: 'map-position',
    providers: [],
    templateUrl: './map-position.component.html',
    styleUrls: ['./map-position.component.css']
})
export class MapPositionComponent implements OnInit, OnChanges {

    @Input("mapctrl") mapCtrl: MapController = null;
    //
    public infoPositionSearch = "Introduceți adresa in formatul [numar],strada [nume_strada],nume_localitate,[nume_judet] sau coordonate geografice in format wgs84 (exemplu 46.76453, 23.57964). Campurile intre [] sunt optionale.";
    private polyStyle = new OlStyle({
        fill: new FillStyle({
            color: olColor.asArray('rgba(50,0,0,0)')
        }),
        stroke: new StrokeStyle({
            color: 'rgba(0,0,255,1)',
            width: 2
        })
    });
    public positionData: IPositionData;
    public constructor(
        @Inject(NGXLogger) private $log: NGXLogger,){

    }
    public ngOnInit(): void {
        //
        this.positionData = this.mapCtrl.positionData;
        this.mapCtrl.positionData.funcInit = this.initPositionTool;
        this.mapCtrl.positionData.funcClear = this.clearPositionTool;
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }




    //
    //Position marker for center and geoposition
    //
    
    initPositionTool = () => {
        this.mapCtrl.setMenuCollapsed();
        if (this.positionData.selectedOption == 'search') {
            this.positionData.disableSearch = false;
            this.positionData.searchText = '';
        }
        this.addLocationLayer();
    }

    public clearPositionTool = () => {
        this.mapCtrl.positionButtonStateOn = false;
        //
        if (this.positionData.selectedOption == 'center') {
            this.positionData.selectedOption = 'search';
        }
        if (this.positionData.selectedOption == 'search') {
            this.positionData.searchText = '';
            this.positionData.disableSearch = false;
            this.positionData.locations.length = 0;
        }

        if (this.mapCtrl.positionMarkerOverlay) {
            this.mapCtrl.map.removeOverlay(this.mapCtrl.positionMarkerOverlay);
        }
        this.removeLocationLayer();
    }

    public onClickPositionClose() {
        this.clearPositionTool();
    }

    public onChangePositionOption(option: string) {
        //disable search

        this.positionByGeocoding(option == 'search' ? true : false);
        this.positionByGeolocation(option === 'gps' ? true : false)
        if (option == 'center') {
            this.postionByMapCenter();
        }
        //this.mapCtrl.positionOption = option;
    }

    onClickPositionByMapCenter() {
        if (this.positionData.selectedOption === 'center') {
            this.postionByMapCenter();
        }
    }

    postionByMapCenter() {
        let mapCenter = olProj.transform(this.mapCtrl.mapConfig.center, 'EPSG:4326', this.mapCtrl.mapConfig.projection);
        this.mapCtrl.map.addOverlay(this.mapCtrl.positionMarkerOverlay);
        this.mapCtrl.positionMarkerOverlay.setPosition(mapCenter);
        this.mapCtrl.map.getView().setCenter(this.mapCtrl.positionMarkerOverlay.getPosition());
        this.mapCtrl.map.getView().setZoom(this.mapCtrl.mapConfig.zoom);
    }

    positionByGeolocation(enable: boolean) {
        //position by geolocation
        //let geoPos = this.mapCtrl.geoLocation.getPosition();
        //let mapCenter = olProj.transform(this.mapCtrl.mapConfig.center, 'EPSG:4326', this.mapCtrl.mapConfig.projection);
        //let refPosition = geoPos //|| mapCenter;
        //this.mapCtrl.positionMarkerOverlay.setPosition(refPosition)
        if (enable) {
            //add marker to map
            this.mapCtrl.map.addOverlay(this.mapCtrl.positionMarkerOverlay);
            this.mapCtrl.geoLocation.setTracking(true);
            $(this.mapCtrl.positionMarkerButtonCtrl["element"]).addClass('position-button-on');
        } else {
            this.mapCtrl.map.removeOverlay(this.mapCtrl.positionMarkerOverlay);
            this.mapCtrl.geoLocation.setTracking(false);
            $(this.mapCtrl.positionMarkerButtonCtrl["element"]).removeClass('position-button-on')
        }
    }

    positionByGeocoding(enable: boolean) {
        //todo
        if (enable) {
            this.positionData.disableSearch = false;

        } else {
            this.positionData.disableSearch = true;
            this.positionData.locations.length = 0;
            this.positionData.searchText = '';
        }
    }

    onSearchPositionByGeocoding() {
        this.positionData.locations.length = 0;
        this.positionData.locationLayer.getSource().clear();
        if (this.positionData.searchText.length == 0) {
            this.$log.error("text locatie lipseste");
            return;
        }
        this.positionData.disableSearch = true;
        this.positionData.activeSearchLocations = true;
        this.mapCtrl.userSettingsSrvs.getLocationByGeocoding(this.positionData.searchText)
            .then((data) => {
                this.positionData.locations = data;
                let gjson = new GeoJSONFormater();
                //geom features
                this.positionData.locations.forEach((litem) => {
                    try {
                        if (litem.geojson) {//gjson.readFeature
                            let feature = gjson.readFeature(litem.geojson, { dataProjection: "EPSG:4326", featureProjection: this.mapCtrl.mapConfig.projection });
                            if (feature) {
                                litem.feature = feature;
                                if (feature.getGeometry().getType() === 'Polygon' as any) {
                                    feature.setStyle(this.polyStyle)
                                }
                            }
                        }
                    } catch (reason) {
                        this.$log.error("Eroare creare locatie ", getExMessage(reason));
                    }
                })

            }).catch((reason) => {
                this.$log.error("eroare la interogare geolocatie ", getExMessage(reason));

            }).finally(() => {
                this.positionData.disableSearch = false;
                this.positionData.activeSearchLocations = false;
            })
    }

    onClickPositionResults(item: geocodingLocation) {
        try {
            let reorderextent = [Number(item.boundingbox[2]), Number(item.boundingbox[0]), Number(item.boundingbox[3]), Number(item.boundingbox[1])]
            let extentAtProj = olProj.transformExtent(reorderextent as olExtent.Extent, "EPSG:4326", this.mapCtrl.map.getView().getProjection());
            this.mapCtrl.map.getView().fit(extentAtProj);
            this.positionData.locationLayer.getSource().clear();
            if (item.feature) {
                this.positionData.locationLayer.getSource().addFeature(item.feature);
            }
        } catch (reason) {
            this.$log.error("eroare pozitionare pe locatie", getExMessage(reason));
        }
    }
    //
   
    isVisibleLocation(locatie: geocodingLocation) {
        if (this.positionData.numaiUnitatiTeritoriale === false) {
            return true;
        }
        if (this.positionData.numaiUnitatiTeritoriale === true
            && locatie.feature.getGeometry().getType() === 'Polygon'
            && (locatie.type === "administrative"
                || locatie.type === "village")) {
            return true;
        }
        return false;
    }
    public addLocationLayer() {
        this.positionData.locationLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.positionData.locationLayer);
        this.positionData.locationLayer.setZIndex(100000);
    }
    //
    public removeLocationLayer() {
        if (this.positionData.locationLayer) {
            this.mapCtrl.map.removeLayer(this.positionData.locationLayer);
            this.positionData.locationLayer = null;
        }
    }

}
