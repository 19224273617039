<div class="modal-content">
    <form #addStationForm="ngForm"  novalidate>
        <fieldset [disabled]="disableInput">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="isAddElseEdit">Adauga statie</h5>
                <h5 class="modal-title" *ngIf="!isAddElseEdit">Modificare statie</h5>
            </div>
            <!--properties-->
            <div class="modal-body">
                <div class="form-group">
                    <label for="name">Nume</label>
                    <input type="text" class="form-control" name="nume" [(ngModel)]="station.name" [disabled]="!isAddElseEdit"  required>
                </div>
                <div class="form-group">
                    <label for="name">Categorie</label>
                    <input type="text" class="form-control" name="categorie" [(ngModel)]="station.type" readonly>
                </div>
                <div class="form-group">
                    <label for="name">Longitudine</label>
                    <input type="number" class="form-control" name="longitudine" step="0.0000000001" [(ngModel)]="station.long" required>
                </div>
                <div class="form-group">
                    <label for="name">Latitudine</label>
                    <input type="number" class="form-control" name="latitudine" step="0.0000000001" [(ngModel)]="station.lat" required>
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('tip_statie')">
                    <label for="name">Tip statie</label>
                    <input type="text" class="form-control" name="tip_statie" [(ngModel)]="station.tip_statie">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('adresa')">
                    <label for="name">Adresa</label>
                    <input type="text" class="form-control" name="adresa" [(ngModel)]="station.adresa">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('telefon')">
                    <label for="name">Telefon</label>
                    <input type="text" class="form-control" name="telefon" [(ngModel)]="station.telefon">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('email')">
                    <label for="name">Email</label>
                    <input type="text" class="form-control" name="email" [(ngModel)]="station.email">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('adresa_web')">
                    <label for="name">Adresa web</label>
                    <input type="text" class="form-control" name="adresa_web" [(ngModel)]="station.adresa_web">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('orar')">
                    <label for="name">Orar</label>
                    <input type="text" class="form-control" name="orar" [(ngModel)]="station.orar">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('imagine')">
                    <label for="name">Imagine</label>
                    <input type="text" class="form-control" name="imagine" [(ngModel)]="station.imagine">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('numar_locuri')">
                    <label for="name">Numar locuri</label>
                    <input type="number" class="form-control" name="numar_locuri" step="1" [(ngModel)]="station.numar_locuri">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('tip_orientare')">
                    <label for="name">Tip orientare</label>
                    <input type="text" class="form-control" name="tip_orientare" [(ngModel)]="station.tip_orientare">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('tip_servicii')">
                    <label for="name">Tip servicii</label>
                    <input type="text" class="form-control" name="tip_servicii" [(ngModel)]="station.tip_servicii">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('tip_lucrare')">
                    <label for="name">Tip lucrare</label>
                    <input type="text" class="form-control" name="tip_lucrare" [(ngModel)]="station.tip_lucrare">
                </div>
                <div class="form-group" *ngIf="isInputInfoEnabled('durata_interventie')">
                    <label for="name">Durata_interventie</label>
                    <input type="number" class="form-control" name="durata_interventie" [(ngModel)]="station.durata_interventie">
                </div>
            </div>
            <div class="form-group">
                <ul>
                    <li *ngFor="let item of addStationForm.errors | keyvalue">
                        <strong>{{ item.key }}</strong> errors
                        <ul>
                            <li *ngFor="let e of item.value">{{ e.name }} are eroare: <strong>{{ item.key }}</strong>.</li>
                        </ul>
                    </li>
                </ul>
                <span *ngIf="errorResponse">
                    <span>{{errorResponse}}</span>
                </span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" (click)="save()" [disabled]="!enableSaveButton() || !addStationForm.valid">Salveaza</button>
                <button type="button" class="btn" (click)="cancel()">Închide</button>
            </div>
        </fieldset>
    </form>
</div>