<div class="modal-content">
    <form #editSearchForm="ngForm" novalidate>
        <fieldset [disabled]="disableInput">
            <div class="modal-header">
                <h5 class="modal-title" i18n>Filtrare informații</h5>
            </div>
            <div class="modal-body">
                <div>
                    <ul ngbNav #nav="ngbNav" [(activeId)]="searchTab" class="nav-tabs nav-fill w-100">
                        <li [ngbNavItem]="'multilayer'"
                        (click)="changeSearchType('multilayer')">
                            <a ngbNavLink i18n>Filtrare multistrat</a>
                            <ng-template ngbNavContent>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="'layerfeature'"
                        (click)="changeSearchType('layerfeature')">
                            <a ngbNavLink i18n>Filtrare după obiectiv</a>
                            <ng-template ngbNavContent>
                            </ng-template>
                        </li>
                    </ul>
                </div>

                <div class="bufferDistdiv  card card-body p-2" *ngIf="searchTab === 'layerfeature'">
                    <div class="form-inline">
                    <select id="bufDistI" name="bufferDistance" class="form-control" [(ngModel)]="bufferDistance">
                        <option value="10" selected>10 m</option>
                        <option value="20">20 m</option>
                        <option value="50">50 m</option>
                        <option value="100">100 m</option>
                        <option value="500">500 m</option>
                        <option value="1000">1000 m</option>
                    </select>
                    <label id="bufDistL" class="ml-2" i18n>Distanța împrejur</label>
                </div>
                </div>
                <div class="searchLayer card card-body p-2" *ngFor="let srcLayer of searchOnLayer; let indexSrcLayer = index">
                    <div class="form-inline" style="margin-bottom:10px">
                        <label>{{srcLayer.layer.name}} </label>
                        <button type="button" name="btnRemoveSrcLayer" class="btn btn-light ml-auto" (click)="removeSearchLayer(srcLayer)"> x </button>
                    </div>
                    <!--Search property list -->
                    <div class="searchCondition" style="margin-bottom:5px" *ngFor="let srcProp of srcLayer.conditions;let indexLayCondition = index">
                        <div class="d-flex align-items-center">
                            <button type="button" name="btnRemoveSearchProp" class="btn btn-light" (click)="removeSearchProperty(srcLayer, srcProp)"> x </button>
                            <input type="text" [name]="'propertyName'+indexSrcLayer+indexLayCondition" class="form-control flex-fill" readonly [(ngModel)]="srcProp.propertyName" />
                            <ng-select class="" id="condition" [name]="'condition'+indexSrcLayer+indexLayCondition"
                                i18n-title title="Selecteaza conditie"
                                #propcond="ngModel"
                                bindLabel="text"
                                [(ngModel)]="srcProp.condition" 
                                i18n-placeholder placeholder="Selectează conditie..">
                                <ng-option *ngFor="let item of conditionOperator" [value]="item">
                                    {{item.text}}
                                </ng-option>
                            </ng-select>
                        </div>
                        <input type="text" [name]="'searchText'+indexSrcLayer+indexLayCondition"
                         class="form-control" 
                         container="body"
                         display="dynamic"
                         [(ngModel)]="srcProp.searchText"
                         #srcpropval
                         [ngbTypeahead]="searchPropertyList(srcProp.propertyName, srcLayer, srcpropval.value)"
                         />
                        
                    </div>
                    <!-- Add new search on property-->
                    <div class="d-flex" *ngIf="buildPropertySelectList(srcLayer).length > 0">
                        <ng-select id="newSrcProp" name="newSrcProp"
                            class="flex-fill"
                            i18n-title title="Selecteaza proprietate"
                            #layprop="ngModel"
                            [(ngModel)]="srcLayer.newSrcProperty" 
                            i18n-placeholder placeholder="Selectează proprietate..">
                            <ng-option *ngFor="let item of buildPropertySelectList(srcLayer)" [value]="item">
                                {{item}}
                            </ng-option>
                        </ng-select>

                        <button type="button" name="btnAddSrcProp" class="btn btn-light ml-auto mr-0" (click)="addSearchProperty(srcLayer)" i18n>Adaugă filtru</button>
                    </div>

                </div>
                <!-- add new search on layer-->
                <div class="searchNewLayer  card card-body p-2" *ngIf="addSearchVisible()">
                   <div class="d-flex">
                    <ng-select id="newSrcLayer" name="newSrcLayer"
                        class="flex-fill"
                        i18n-title title="Selecteaza strat"
                        #styletype="ngModel"
                        [(ngModel)]="newSearchOnLayerName" 
                        i18n-placeholder placeholder="Selectează strat..">
                        <ng-option *ngFor="let item of newSearchOnLayerOptions" [value]="item">
                            {{item.text}}
                        </ng-option>
                    </ng-select>
                    <button type="button" name="addNewSrcLayer" class="btn btn-light ml-auto" (click)="addSearchLayer()" i18n>Adaugă strat</button>
                </div>
                </div>
                <!-- search sugestions -->
                <div class="searchSugestion" *ngIf="searchTab === 'layerfeature'">
                    <button type="button" class="btn btn-light" (click)="sugest()" [disabled]="!enableSugestButton()" i18n>Sugestii obiectiv</button>
                    <span i18n>Selectează un obiectiv din listă</span>
                    <div class="sugestItem card card-body p-2" *ngFor="let feature of sugestionList"  (click)="selectSugestion(feature)">
                        <p >_id_: {{feature.id}}</p>
                        <p *ngFor="let featitem of feature.values | keyvalue">{{featitem.key}}: {{featitem.value}}</p>
                    </div>
                </div>
                <!--Todo-->
            </div>
            <div class="form-group">
                <span *ngIf="errorResponse">
                    <span>{{errorResponse}}</span>
                </span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" (click)="search()" [disabled]="!enableSearchButton()" i18n>Filtrează</button>
                <button type="button" class="btn" (click)="cancel()" i18n>Renuntă</button>
            </div>
        </fieldset>
    </form>
</div>