//NG+
import { Component, OnInit, Input, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NGXLogger } from "ngx-logger";

import { ILayerReportSettings } from "../../definitions";

//
@Component({
    selector: 'edit-raport-layer-settings',
    providers: [],
    templateUrl: './edit-report-layer-settings.component.html',
    styleUrls: ['./edit-report-layer-settings.component.css']
})
export class EditRaportLayerSettingsComponent implements OnInit {
    @Input() settingsList: Array<ILayerReportSettings>
    //
    public constructor(
        @Inject(NGXLogger) private $log: NGXLogger) {
        this.settingsList = [];
    }
    //
    public ngOnInit() {

    }
    //
    public onAddReportSettings = () => {
        this.settingsList.push({
            id: this.settingsList.length,
            idResReport: null,
            reportFormula: '',
            reportColumns: '',
            nameResData: '',
            dataColumns: '',
            constants: '',
            description: null,
            nume: null
        })
    }
    //
    public onRemoveReportSettings = (item) => {
        let itmIndex = this.settingsList.indexOf(item);
        if (itmIndex >= 0) {
            this.settingsList.splice(itmIndex, 1);
        }
    }
}
