<div class="modal-content">
    <form #loginForm="ngForm" novalidate>
        <fieldset [disabled]="disableInput">
            <div class="modal-header">
                <h5 class="modal-title" i18n>Autentificare utilizator</h5>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="name" i18n>Nume utilizator</label>
                    <input type="text" class="form-control" id="username" #ui_username="ngModel" name="username" 
                    [(ngModel)]="username" (keyup.enter)="onEnterKeyPress(loginForm)" required>
                    <span *ngIf="loginForm.submitted || ui_username.touched">
                        <span *ngIf="ui_username.errors?.required" class="text-danger" i18n>Nume utilizator este obligatoriu</span>
                    </span>
                </div>
                <div class="form-group">
                    <label for="url" i18n>Parola</label>
                    <input type="password" class="form-control" id="password" #ui_password="ngModel" name="password"
                     [(ngModel)]="password" (keyup.enter)="onEnterKeyPress(loginForm)"  required>
                    <span *ngIf="loginForm.submitted || ui_password.touched">
                        <span *ngIf="ui_password.errors?.required" class="text-danger" i18n>Parola este obligatorie</span>
                    </span>
                </div>
            </div>
            <div class="form-group">
                <span *ngIf="errorResponse">
                    <span class="text-danger p-3">{{errorResponse}}</span>
                </span>
                <div *ngIf="disableInput === true" class="tool-spiner-box">
                    <i id="spinner" class="fa fa-circle-o-notch fa-spin tool-spiner"></i>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" [disabled]="!loginForm.valid" (click)="authenticate()" i18n>Autentifică</button>
                <button type="button" class="btn btn-light" (click)="authenticateAnonymous()" i18n>Acces anonim</button>
                <button type="button" class="btn" (click)="cancel()" i18n>Închide</button>
            </div>
        </fieldset>
    </form>
</div>