//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { ILayer, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { IIndiceHotspot, IIntegrateLandsatHotspotData } from './map-integrate.definitions';
import { NGXLogger } from 'ngx-logger';
import { getExMessage } from '../map-utils';

import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import TileLayer from 'ol/layer/Tile';
import VectorSource from "ol/source/Vector";
import  TileWMSSource  from "ol/source/TileWMS";
import WKTFormater from "ol/format/WKT";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import FillStyle from "ol/style/Fill";
import * as olColor from 'ol/color';


@Component({
    selector: 'integrate-landsat-hotspot',
    templateUrl: './map-integrate-landsat-hotspot.component.html',
    styleUrls: ['./map-integrate-landsat-hotspot.component.css']
})
export class IntegrateLandsatHotspotComponent implements OnInit, OnChanges {

    @Input("mapctrl") mapCtrl: MapController = null;
    public integrateLandsatHotspotData: IIntegrateLandsatHotspotData = null;
    //
    private clsStyle = new OlStyle({
        fill: new FillStyle({
            color: olColor.asArray('rgba(5,5,0,0)')
        }),
        stroke: new StrokeStyle({
            color: 'rgba(127,0,255,1)',
            width: 2
        })
    });

    public infoSearch =
        " Info indentificare hotspoturi";
    private incRef = 1;
    //
    constructor(
        @Inject(NGXLogger) private $log: NGXLogger,) {
        //
    }

    public ngOnInit(): void {
        if (this.mapCtrl && this.mapCtrl.integrateLandsatHotspotData) {
            this.integrateLandsatHotspotData = this.mapCtrl.integrateLandsatHotspotData;
            this.mapCtrl.integrateLandsatHotspotData.funcInit = this.initIntegrateLandsatHotspotTool;
            this.mapCtrl.integrateLandsatHotspotData.funcClear = this.clearIntegrateLandsatHotspotTool;

        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }

    public initIntegrateLandsatHotspotTool = () => {
        this.addSelectLayer();
        this.findStratAsociatRezultat();
        //
        this.clearLayers();
        this.integrateLandsatHotspotData.selectButtonStateOn = false;

        this.integrateLandsatHotspotData.currentHotspot = {
            momentDateSatelitare: null,
            geom: null,
            impermeabilitate: null,
            pragvMin: null,
            pragmMin: null,
            numarHotspoturi: null,
            idResult: null
        }
        //clear result layer
        this.setMomentOnTileLayer(this.integrateLandsatHotspotData.resultTileLayer, "2020-01-01T01:01:01.000Z");
        this.setMomentOnTileLayer(this.integrateLandsatHotspotData.resultTileLayer2, "2020-01-01T01:01:01.000Z");
        //
        this.integrateLandsatHotspotData.availablePraguri = [];
        this.integrateLandsatHotspotData.availableImpermeabilitate = [];
        this.integrateLandsatHotspotData.selectedGeom = null;
        this.integrateLandsatHotspotData.numarHotspoturi = 0;
        this.integrateLandsatHotspotData.selectedPrag = null;
        this.integrateLandsatHotspotData.selectedImpermeabilitate = null;
        this.integrateLandsatHotspotData.selectedMomentDateSatelitare = null;

        //
        this.integrateLandsatHotspotData.errorMessage = "";
        this.integrateLandsatHotspotData.doneActive = false;
        this.integrateLandsatHotspotData.loadingActive = true;
        this.mapCtrl.integrateLandsatDataService.getReferintaTimestampHotspotActive()
            .then((data) => {
                this.integrateLandsatHotspotData.availableMomenteDateSatelitare = data;
                return true;
            })
            .then((status) => {
                return this.mapCtrl.integrateLandsatDataService.getPraguriDetectieHotspot();
            })
            .then((data) => {
                this.integrateLandsatHotspotData.availablePraguri = data;
                return true;
            })
            .then((status) => {
                return this.mapCtrl.integrateLandsatDataService.getImpermabilitatiDetectieHotspot();
            })
            .then((data) => {
                this.integrateLandsatHotspotData.availableImpermeabilitate = data;
                return true;
            })
            .then((status) => {
                return this.mapCtrl.integrateLandsatDataService.getIndiciHotspot();
            })
            .then((data) => {
                this.integrateLandsatHotspotData.indiciHotspot = data;
                this.integrateLandsatHotspotData.indiciHotspot.forEach((indItem) => {
                    this.findStratAsociat(indItem);
                });
                return true;
            })
            .catch((reason) => {
                this.integrateLandsatHotspotData.errorMessage = "Eroare interogare unitati administrative";
                this.$log.error("Eroare interogare unitati administrative: ", getExMessage(reason));
            }).finally(() => {
                this.integrateLandsatHotspotData.loadingActive = false;
            })

    }

    public clearIntegrateLandsatHotspotTool = () => {
        this.$log.log("clear");
        this.removeSelectLayerInteraction();
        this.removeSelectLayer();
    }
    //
    private clearLayers() {
        if (this.integrateLandsatHotspotData.selectLayer) {
            this.integrateLandsatHotspotData.selectLayer.getSource().clear();
        }
    }
    public onClickClose() {
        this.clearIntegrateLandsatHotspotTool();
        this.mapCtrl.integrateLandsatHotspotButtonStateOn = false;

    }
    //
    onClickPrevMomentDateSatelitare() {
        if (this.integrateLandsatHotspotData.selectedMomentDateSatelitare == null 
            && this.integrateLandsatHotspotData.availableMomenteDateSatelitare.length > 0){
                this.integrateLandsatHotspotData.selectedMomentDateSatelitare =   this.integrateLandsatHotspotData.availableMomenteDateSatelitare[this.integrateLandsatHotspotData.availableMomenteDateSatelitare.length - 1];
                this.onChangeMomentDateSatelitare();
                return;
        } else if (this.integrateLandsatHotspotData.selectedMomentDateSatelitare.length > 0 
            && this.integrateLandsatHotspotData.availableMomenteDateSatelitare.length > 0){
                let indCurrent = this.integrateLandsatHotspotData.availableMomenteDateSatelitare.indexOf(this.integrateLandsatHotspotData.selectedMomentDateSatelitare);
                if (indCurrent > 0 && indCurrent <= this.integrateLandsatHotspotData.availableMomenteDateSatelitare.length){
                    this.integrateLandsatHotspotData.selectedMomentDateSatelitare = this.integrateLandsatHotspotData.availableMomenteDateSatelitare[indCurrent - 1];
                    this.onChangeMomentDateSatelitare();
                }
        }
    }

    onClickNextMomentDateSatelitare() {
        if (this.integrateLandsatHotspotData.selectedMomentDateSatelitare == null 
            && this.integrateLandsatHotspotData.availableMomenteDateSatelitare.length > 0){
                this.integrateLandsatHotspotData.selectedMomentDateSatelitare =   this.integrateLandsatHotspotData.availableMomenteDateSatelitare[0];
                this.onChangeMomentDateSatelitare();
                return;
        } else if (this.integrateLandsatHotspotData.selectedMomentDateSatelitare.length > 0 
            && this.integrateLandsatHotspotData.availableMomenteDateSatelitare.length > 0){
                let indCurrent = this.integrateLandsatHotspotData.availableMomenteDateSatelitare.indexOf(this.integrateLandsatHotspotData.selectedMomentDateSatelitare);
                if (indCurrent >= 0 && indCurrent < this.integrateLandsatHotspotData.availableMomenteDateSatelitare.length - 1){
                    this.integrateLandsatHotspotData.selectedMomentDateSatelitare = this.integrateLandsatHotspotData.availableMomenteDateSatelitare[indCurrent + 1];
                    this.onChangeMomentDateSatelitare();
                }
        }
    }



    //
    canContinueHotspot() {
        if (this.integrateLandsatHotspotData.loadingActive === true
            // && this.integrateLandsatHotspotData.doneActive === false
        ) {
            return false
        }
        return true;
    }
    canStopHotspot() {
        if (this.integrateLandsatHotspotData.doneActive === true) {
            return true
        }
        return false;
    }
    canRefreshHotspot() {
        if (this.integrateLandsatHotspotData.doneActive === true) {
            return true
        }
        return false;
    }
    //
    onStartHotspot(form: any) {
        this.integrateLandsatHotspotData.errorMessage = "";
        try {
            if (this.integrateLandsatHotspotData.selectedMomentDateSatelitare == null) {
                throw Error("Nu este selectat Moment date satelitare");
            }
            this.integrateLandsatHotspotData.currentHotspot.momentDateSatelitare =
                this.integrateLandsatHotspotData.selectedMomentDateSatelitare;
            //
            if (this.integrateLandsatHotspotData.selectedImpermeabilitate == null) {
                throw Error("Nu este selectat Impermeabilitate");
            }
            this.integrateLandsatHotspotData.currentHotspot.impermeabilitate =
                this.integrateLandsatHotspotData.selectedImpermeabilitate.id;
            //
            if (this.integrateLandsatHotspotData.selectedPrag == null) {
                throw Error("Nu este selectat Prag");
            }
            this.integrateLandsatHotspotData.currentHotspot.pragvMin =
                this.integrateLandsatHotspotData.selectedPrag.vMin;
            this.integrateLandsatHotspotData.currentHotspot.pragmMin =
                this.integrateLandsatHotspotData.selectedPrag.mMin;
            if (this.integrateLandsatHotspotData.numarHotspoturi == null
                || this.integrateLandsatHotspotData.numarHotspoturi == 0
            ) {
                throw Error("Nu este selectat numar Hotspoturi");
            }
            this.integrateLandsatHotspotData.currentHotspot.numarHotspoturi =
                this.integrateLandsatHotspotData.numarHotspoturi;
            if (this.integrateLandsatHotspotData.selectedGeom == null) {
                throw Error("nu este selectata zona interes");
            }
            let geomwgs = (this.integrateLandsatHotspotData.selectedGeom as Polygon).clone().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326') as any;
            let wkt = new WKTFormater();
            this.integrateLandsatHotspotData.currentHotspot.geom = wkt.writeGeometry(geomwgs as Polygon);

            //
            this.integrateLandsatHotspotData.loadingActive = true;
            this.mapCtrl.integrateLandsatDataService.setInterogareHotspot(this.integrateLandsatHotspotData.currentHotspot)
                .then((result) => {
                    if (result) {
                        //let utcTimestamp = this.mapCtrl.moment.utc(this.mapCtrl.moment(result, "YYYY-MM-DD HH:mm:ss.SSS")).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
                        //let utcTimestamp = result.replace(" ", "T") + "Z";
                        this.setMomentOnTileLayer(this.integrateLandsatHotspotData.resultTileLayer, result);
                        this.setMomentOnTileLayer(this.integrateLandsatHotspotData.resultTileLayer2, result);
                        this.integrateLandsatHotspotData.currentHotspot.idResult = result;
                        this.integrateLandsatHotspotData.doneActive = true;
                    } else {
                        //todo
                    }
                }).catch((reason) => {
                    let message = reason.message || ""
                    this.integrateLandsatHotspotData.errorMessage = "Eroare interogare detectie hotspoturi";
                    if (message.includes("No images found for specified date and geometry")) {
                        message = ("Nu sunt date pentru zona si momentul definit");
                        this.integrateLandsatHotspotData.errorMessage = message;
                    }
                    //
                    this.$log.error(("Eroare interogare detectie hotspoturi: " + message));
                    this.integrateLandsatHotspotData.loadingActive = false;
                }).finally(() => {
                    //this.integrateLandsatHotspotData.loadingActive = false;
                })


        } catch (error) {
            this.$log.error("Eroare identificare hotspoturi:", getExMessage(error));
            this.integrateLandsatHotspotData.errorMessage = "Eroare identificare hotspoturi: " + error.message || "";
            this.integrateLandsatHotspotData.loadingActive = false;
            return;
        }
        //server request post


        //
    }
    //
    onRefreshHotspot() {
        //
        if (this.integrateLandsatHotspotData.resultTileLayer){
            let srcL = (this.integrateLandsatHotspotData.resultTileLayer.internalLayer as TileLayer<any>).getSource() as any;
            srcL.tileCache.clear();
            this.incRef++;
            srcL.updateParams({ test: this.incRef });
        }
        if (this.integrateLandsatHotspotData.resultTileLayer2){
            let srcL2 = (this.integrateLandsatHotspotData.resultTileLayer2.internalLayer as TileLayer<any>).getSource() as any;
            srcL2.tileCache.clear();
            this.incRef++;
            srcL2.updateParams({ test: this.incRef });
        }
    }
    //
    onStopHotspot() {
        this.integrateLandsatHotspotData.loadingActive = false;
        this.integrateLandsatHotspotData.doneActive = false;
        this.setMomentOnTileLayer(this.integrateLandsatHotspotData.resultTileLayer, "2020-01-01T01:01:01.000Z");
        this.setMomentOnTileLayer(this.integrateLandsatHotspotData.resultTileLayer2, "2020-01-01T01:01:01.000Z");
    }


    //
    //
    //
    public addSelectLayer() {
        this.integrateLandsatHotspotData.selectLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false }),
            style: this.clsStyle
        });
        this.mapCtrl.map.addLayer(this.integrateLandsatHotspotData.selectLayer);
    }
    //
    public addSelectLayerInteraction() {
        if (this.integrateLandsatHotspotData.selectLayer == null) {
            this.addSelectLayer;
        }
        this.integrateLandsatHotspotData.selectDrawInteraction = new Draw({
            source: this.integrateLandsatHotspotData.selectLayer.getSource(),
            type: 'Polygon',
            //geometryFunction: Draw.createBox()
        });
        this.mapCtrl.map.addInteraction(this.integrateLandsatHotspotData.selectDrawInteraction);
        this.integrateLandsatHotspotData.selectDrawInteraction.on('drawend', this.onDrawSelectBoxEnd);
        this.integrateLandsatHotspotData.selectDrawInteraction.on('drawstart', this.onDrawSelectBoxStart);
    }
    //
    public removeSelectLayer() {
        if (this.integrateLandsatHotspotData.selectLayer) {
            this.mapCtrl.map.removeLayer(this.integrateLandsatHotspotData.selectLayer);
            this.integrateLandsatHotspotData.selectLayer = null;
        }
    }
    //
    public removeSelectLayerInteraction() {
        if (this.integrateLandsatHotspotData.selectDrawInteraction) {
            this.mapCtrl.map.removeInteraction(this.integrateLandsatHotspotData.selectDrawInteraction);
            this.integrateLandsatHotspotData.selectDrawInteraction = null;
        }
    }
    //
    //
    //
    public onClickSelectButton = () => {
        try {
            if (!this.integrateLandsatHotspotData.selectButtonStateOn) {
                this.integrateLandsatHotspotData.selectLayer.getSource().clear();
                this.integrateLandsatHotspotData.selectedGeom = null;
                this.addSelectLayerInteraction();
                this.integrateLandsatHotspotData.selectButtonStateOn = true;
                //this.clearSearchResults();
            } else {
                if (this.integrateLandsatHotspotData.selectDrawInteraction.getActive()) {
                    this.integrateLandsatHotspotData.selectDrawInteraction.finishDrawing();
                } else {
                    this.removeSelectLayerInteraction();
                    this.integrateLandsatHotspotData.selectButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare selectie zona");
        }
        //todo
    }
    //
    public onDrawSelectBoxStart = (event) => {
        this.integrateLandsatHotspotData.selectLayer.getSource().clear();
        this.integrateLandsatHotspotData.selectedGeom = null;
    }
    //
    public onDrawSelectBoxEnd = (event) => {
        let extentFeature = event.feature as Feature;
        this.integrateLandsatHotspotData.selectedGeom = extentFeature.getGeometry() as any || null;
        this.integrateLandsatHotspotData.selectDrawInteraction.setActive(false);
        // to do get extent list details
        if (extentFeature) {
            let extentPolygon = extentFeature.getGeometry() as Polygon;

        }
    }
    //
    public onClickResult = (layer: any) => {
        this.mapCtrl.mapMenuLayers.showHideLayer(null, layer)
    }
    //
    public onClickIndice = (layer: any) => {
        this.mapCtrl.mapMenuLayers.showHideLayer(null, layer);
    }
    //
    public onChangeMomentDateSatelitare() {
        let tmpMoment = "1000-01-01";// no data
        if (this.integrateLandsatHotspotData.selectedMomentDateSatelitare && this.integrateLandsatHotspotData.selectedMomentDateSatelitare.length > 0) {
            tmpMoment = this.integrateLandsatHotspotData.selectedMomentDateSatelitare;
        }
        this.integrateLandsatHotspotData.indiciHotspot.forEach((indInd) => {
            if (indInd.stratAsociat && indInd.stratAsociat.internalLayer) {
                this.setMomentOnTileLayer(indInd.stratAsociat, tmpMoment);
            }
        })

    }
    //
    public findStratAsociat(ind: IIndiceHotspot) {
        ind.stratAsociat = null;
        try {
            if (ind.codIndice == null || ind.codIndice.length < 2) {
                throw Error("codIndice nu e specificat");
            }
            this.mapCtrl.categories.forEach((catItem) => {
                if (ind.stratAsociat == null) {
                    catItem.layers.forEach((layItem) => {
                        if (ind.stratAsociat == null) {
                            if (layItem.featureType === featureType.tile) {
                                //to do check if config is for import
                                let infauth = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.integratelandsat_indice_referinta, layItem.name, authType.layer)
                                if (infauth && infauth.descriere
                                    && infauth.descriere.toLowerCase() === ind.codIndice.toLowerCase()) {
                                    ind.stratAsociat = layItem;
                                }
                            }
                        }
                    })
                }
            });
        } catch (error) {
            this.$log.error("Eroare asociere strat indice", getExMessage(error));
        }

    }
    //
    public findStratAsociatRezultat() {
        this.integrateLandsatHotspotData.resultTileLayer = null;
        this.integrateLandsatHotspotData.resultTileLayer2 = null;
        try {
            this.mapCtrl.categories.forEach((catItem) => {
                if (this.integrateLandsatHotspotData.resultTileLayer == null
                    || this.integrateLandsatHotspotData.resultTileLayer2 == null
                    ) {
                    catItem.layers.forEach((layItem) => {
                        if (this.integrateLandsatHotspotData.resultTileLayer == null) {
                            if (layItem.featureType === featureType.tile) {
                                //to do check if config is for import
                                if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.integratelandsat_rezutat_referinta, layItem.name, authType.layer)) {
                                    this.integrateLandsatHotspotData.resultTileLayer = layItem;
                                }
                                
                            }
                        }
                        if (this.integrateLandsatHotspotData.resultTileLayer2 == null) {
                            if (layItem.featureType === featureType.tile) {
                                //to do check if config is for import
                                if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.integratelandsat_rezutat_referinta2, layItem.name, authType.layer)) {
                                    this.integrateLandsatHotspotData.resultTileLayer2 = layItem;
                                }
                            }
                        }
                    })
                }
            });
        } catch (error) {
            this.$log.error("Eroare asociere strat referinta", getExMessage(error));
        }

    }
    //
    private setMomentOnTileLayer(layer: ILayer, moment: string) {
        if (layer == null){return;}
        let source = (layer.internalLayer as TileLayer<any>).getSource() as TileWMSSource;
        let params = source.getParams();
        params['time'] = moment;//time=2009-11-01
        source.updateParams(params);
        source.changed();
    }
}