<!--map legend-->
<div id="legendContent" class="legend-content" *ngIf="mapCtrl.legendButtonStateOn">
    <h6 class="legend-title">Legendă hartă</h6>
    <ng-container  *ngFor="let category of mapCtrl.categories" >
    <div *ngIf="!mapCtrl.mapMenuLayers.hideCategoryIfEmpty(category)">
        <ng-container *ngFor="let layer of category.layers" >
        <div class="sub-menu-item" *ngIf="layer.visible === true && layer.style">
            <h6 class="legend-layer" *ngIf="layer.styleType != 'singleStyle'"><span class="legend-color-zone" [ngStyle]="layer.style.defaultStyleLegenda"> </span>{{layer.name}} </h6>
            <h6 class="legend-layer" *ngIf="layer.styleType === 'singleStyle'"><span class="legend-color-zone" [ngStyle]="layer.style.list[0].styleLegenda || layer.style.defaultStyleLegenda"> </span>{{layer.name}} </h6>
            <!--defaultStyleLegenda-->
            <div class="legend-alignment" *ngIf="layer.styleType === 'multiStyle'">
                <div *ngFor="let style of layer.style.list">
                    <p class="legend-multistyle"><span class="legend-color-zone" [ngStyle]="style.styleLegenda"></span> {{style.legenda}}</p>
                </div>
            </div>
        </div>
        </ng-container>
    </div>
    </ng-container>
    <!--legenda strat raster-->
    <ng-container *ngFor="let category of mapCtrl.categories">
    <div  *ngIf="!mapCtrl.mapMenuLayers.hideCategoryIfEmpty(category)">
        <ng-container  *ngFor="let layer of category.layers" >
        <div class="sub-menu-item"*ngIf="layer.visible === true && layer.legendUrl">
            <h6 class="legend-layer"><span class="legend-color-zone-raster"> </span>{{layer.name}} </h6>
            <div class="legend-alignment">
                <img [src]="sanitize(layer.legendUrl)" />
            </div>
        </div>
    </ng-container>
    </div >
</ng-container>
    <!--legenda clasificare greentopClassifyData.legendaClasificareUrl-->
    <div *ngIf="mapCtrl.greentopClassifyData && mapCtrl.greentopClassifyData?.resultTileLayer?.internalLayer">
        <h6 class="legend-layer"><span class="legend-color-zone-raster"> </span>Rezultat clasificare </h6>
        <div class="legend-alignment">
            <img [src]="sanitize(mapCtrl.greentopClassifyData.legendaClasificareUrl)" />
        </div>
    </div>
</div>