<div class="greentop-tr-menu greentop-tr-height" *ngIf="mapCtrl.greentopTrainingButtonStateOn">
    <div id="greentop-tr-content">
        <div>
        <div class="h6 greentop-title-bar" (click)="greentopTrainingData.ctrlSearchIsCollapsed = !greentopTrainingData.ctrlSearchIsCollapsed"> 
            <div class="greentop-title-bar-fill" >Antrenament model clasificare</div>
            
            <div class="glyphicon glyphicon-info-sign" 
                ngbPopover="{{infoSearch}}"
                popoverTitle="Info"
                popoverClass="text-dark"
                triggers="mouseenter:mouseleave"
                placement="bottom"
                onclick="event.stopPropagation()">
            </div>
            <div class="">
                <span class="material-icons greentop-tr-menu-icon-close-colapse text-danger" *ngIf="greentopTrainingData.ctrlSearchIsCollapsed">arrow_drop_down</span>
                <span class="material-icons greentop-tr-menu-icon-close-colapse " *ngIf="!greentopTrainingData.ctrlSearchIsCollapsed">arrow_drop_up</span>
            </div>
            <div class="" (click)="onClickClose(); $event.stopPropagation()">
                <span class="material-icons greentop-tr-menu-icon-close-colapse">close</span>
            </div>
        </div>
    </div>
    <div>
        <ul ngbNav #nav="ngbNav" [(activeId)]="greentopTrainingData.activeTab" class="nav-tabs nav-fill w-100">
            <li [ngbNavItem]="1"
            (click)="changeNewExistsTab('1')">
                <a ngbNavLink>Existente</a>
                <ng-template ngbNavContent>
                </ng-template>
            </li>
            <li [ngbNavItem]="0"
            (click)="changeNewExistsTab('0')">
                <a ngbNavLink>Nou</a>
                <ng-template ngbNavContent>
                </ng-template>
            </li>
        </ul>
    </div>
        <!--existing trainings-->
        <div class="greentop-tr-existing-tr">
            <!--autocompleat search-->
            <div class="greentop-tr-existing-tr-combo">
                <div class="form-group greentop-tr-existing-combo-mb" *ngIf="greentopTrainingData.activeExistsTab1">
                    <label for="uiDesPlanificate">Antrenamente planificate</label>
                    <ng-select name="uiTrPlanificate" required class="w-100"
                    multiline="true"
                    [items]="greentopTrainingData.availableAntrenamente"
                    [(ngModel)]="greentopTrainingData.selectedAntrenament"
                    (change)="onChangeAntrenament()" 
                    bindLabel="denumire"
                    placeholder="Selectează antrenament planificat">
                        <ng-template ng-label-tmp let-item="item">
                            <div class="greentop-ng-select-wrap">
                                {{item.denumire}}
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="greentop-ng-select-wrap">
                                [{{item.id }}] {{item.denumire}}
                            </div>
                        </ng-template>
                    </ng-select>
                   
                </div>
            </div>
            <!-- options -->
            <div>
        
            </div>
        </div>
        <!--content tabs-->

        <div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="greentopTrainingData.activeDataTab" class="nav-tabs nav-fill w-100">
                <li [ngbNavItem]="0"
                (click)="changeDataTab('0')">
                    <a ngbNavLink>Detalii</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="1"
                (click)="changeDataTab('1')">
                    <a ngbNavLink>Zone</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2"
                (click)="changeDataTab('2')">
                    <a ngbNavLink>Indici</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
            </ul>
        </div>

        <!---->
        <div>
            <div [(ngbCollapse)]="greentopTrainingData.ctrlSearchIsCollapsed">
                <form #greentopTrainingForm="ngForm" novalidate>
        
                    <!-- new training-->
                    <div *ngIf="greentopTrainingData.activeNewTab0">
                        <fieldset [disabled]="greentopTrainingData.loadingActive === true">
                            <!--detalii-->
                            <div *ngIf="greentopTrainingData.activeDataTab === 0">
                                <div>
                                    <div class="form-group">
                                        <label>Denumire</label>
                                        <input class="form-control" type="text" name="uiTrDenumire"
                                            [(ngModel)]="greentopTrainingData.currentAntrenament.denumire"/>
                                    </div>
                                    <div class="form-group" >
                                        <label>Descriere</label>
                                        <textarea class="form-control textarea-noresize" rows="2" name="uiTrDescriere"
                                            [(ngModel)]="greentopTrainingData.currentAntrenament.descriere"
                                            ></textarea>
                                    </div>
                                </div>
                                <div class="greentop-tr-select-dw">
                                    <!--autocompleat search-->
                                    <div class="greentop-tr-select-dw-combo">
                                        <div class="form-group greentop-tr-existing-combo-mb">
                                            <label for="uiTrAlgoritm">Algoritm clasificare</label>

                                            <ng-select name="uiTrAlgoritm" required 
                                            [items]="greentopTrainingData.availableAlgoritmi"
                                            [(ngModel)]="greentopTrainingData.selectedAlgoritm"
                                            (change)="onChangeAlgoritm()" 
                                            bindLabel="denumire"
                                            placeholder="Selectează algoritm">
                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    [{{item.id }}] {{item.denumire}}
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="greentop-tr-select-dw">
                                    <!--autocompleat search-->
                                    <div class="greentop-tr-select-dw-combo">
                                        <div class="form-group greentop-tr-existing-combo-mb">
                                            <label for="uiTrStrucDate">Structura date algoritm</label>
                                            <ng-select name="uiTrStrucDate" required 
                                            [items]="greentopTrainingData.availableStructuriDateAlgoritmi"
                                            [(ngModel)]="greentopTrainingData.selectedStructuraDate"
                                            (change)="onChangeStructuraDate()" 
                                            bindLabel="denumire"
                                            placeholder="Selectează structura date">
                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    [{{item.id }}] {{item.denumire}}
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="greentop-tr-select-dw">
                                    <!--autocompleat search-->
                                    <div class="greentop-tr-select-dw-combo">
                                        <div class="form-group greentop-tr-existing-combo-mb">
                                            <label for="uiDesPlanificate">Descărcare planificată</label>
                                            
                                            <ng-select name="uiDesPlanificate" required 
                                            [items]="greentopTrainingData.availableDescarcari"
                                            [(ngModel)]="greentopTrainingData.selectedDescarcare"
                                            (change)="onChangeDescarcare()" 
                                            bindLabel="denumire"
                                            placeholder="Selectează descarcare date">
                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    [{{item.id }}] {{item.denumire}}
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let descarcare of greentopTrainingData.currentAntrenament.descarcariAntrenament">
                                    <div class="form-group" >
                                        <label>Perioada</label>

                                        <div class="greentop-tr-form-period-inputs">
                                            <div class="input-group">
                                                <input
                                                    class="form-control px-2"
                                                    placeholder="început"
                                                    name="uiDesStartPerioada"
                                                    [(ngModel)]="descarcare.startPerioada"
                                                    ngbDatepicker
                                                    #dstart="ngbDatepicker"
                                                    (ngModelChange)="clearSearchResults()"
                                                    disabled
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-light" (click)="dstart.toggle()" type="button" disabled>
                                                        <i class="glyphicon glyphicon-calendar"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="input-group">
                                                <input
                                                    class="form-control px-2"
                                                    placeholder="sfârșit"
                                                    name="uiDesEndPerioada"
                                                    [(ngModel)]="descarcare.endPerioada"
                                                    ngbDatepicker
                                                    placement="bottom-right"
                                                    #dend="ngbDatepicker"
                                                    (ngModelChange)="clearSearchResults()"
                                                    disabled
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-light" (click)="dend.toggle()" type="button" disabled>
                                                        <i class="glyphicon glyphicon-calendar"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
        
                                    <div class="form-group" >
                                        <label>Descriere</label>
                                        <textarea class="form-control textarea-noresize" rows="2"
                                             name="uiDesDescriere"
                                            [(ngModel)]="descarcare.descriere" 
                                            [disabled]="true"></textarea>
                                    </div>
                                </div>
                                
                                <div class="input-group">
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    (click)="onSaveTraining()" title="Salvează"><i class="fas fa-cloud-upload-alt fa-2x"></i></button>
                                    <button class="btn btn-light greentop-action-wrp-buttons"
                                     (click)="onCancelTraining()"title="Renunță"><i class="fas fa-window-close fa-2x"></i></button>
                                </div>
                            </div>
                            <!--zone-->
                            <div *ngIf="greentopTrainingData.activeDataTab === 1">
                                <div class="input-group" *ngIf="showzonetoolsButons() === true">
                                    <button class="btn btn-light" (click)="ontoolAddNeweditTrainingZone()">Adăugare zonă</button>
                                    <button class="btn btn-light" (click)="ontoolImportTrainingZone()">Import zone</button>
                                </div>
                                <!-- import from layer -->
                                <div *ngIf="greentopTrainingData.istoolActive_importTrainingZones === true">
                                    <div>
                                        <div class="greentop-tr-select-dw-combo">
                                            <div class="form-group greentop-tr-existing-combo-mb">
                                                <label for="uiImpLayer">Strat sursa import</label>
                                                <ng-select name="uiImpLayer" required 
                                                [items]="greentopTrainingData.availableImportLayers"
                                                [(ngModel)]="greentopTrainingData.selectedImportLayer"
                                                (change)="onChangeImportLayer()" 
                                                bindLabel="name"
                                                placeholder="Selectează sau cauta in lista">
                                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                        [{{item.id }}] {{item.name}}
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div *ngIf="greentopTrainingData.selectedImportLayer">
                                            <div class="greentop-tr-select-dw-combo">
                                                <div class="form-group greentop-tr-existing-combo-mb">
                                                    <label for="uiImpLayerTeren">Sursa utilizare teren</label>
                                                    
                                                    <ng-select name="uiImpLayerTeren" required 
                                                    [items]="greentopTrainingData.availableImportFields"
                                                    [(ngModel)]="greentopTrainingData.selectedImportUtilizare"
                                                    (change)="onChangeImport()" 
                                                    bindLabel="name"
                                                    placeholder="Selectează sau cauta in lista">
                                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                            [{{item.id }}] {{item.name}}
                                                        </ng-template>
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="greentop-tr-select-dw-combo">
                                                <div class="form-group greentop-tr-existing-combo-mb">
                                                    <label for="uiImpLayerSAn">Sursa an</label>
                                                    <ng-select name="uiImpLayerSAn" required 
                                                    [items]="greentopTrainingData.availableImportFields"
                                                    [(ngModel)]="greentopTrainingData.selectedImportAn"
                                                    (change)="onChangeImport()" 
                                                    bindLabel="name"
                                                    placeholder="Selectează sau cauta in lista">
                                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                            [{{item.id }}] {{item.name}}
                                                        </ng-template>
                                                    </ng-select>
                                                </div>
                                            </div>
                                            <div class="form-group" >
                                                <label>Perioada din valori default</label>
                                                <input type="checkbox" class="form-control" name="perValDef" [(ngModel)]="greentopTrainingData.importPeriodFromDefault">
                                            </div>
                                            <div *ngIf="greentopTrainingData.importPeriodFromDefault === false">
                                                <div class="greentop-tr-select-dw-combo">
                                                    <div class="form-group greentop-tr-existing-combo-mb">
                                                        <label for="uiImpLayerInPer">Sursa inceput perioada</label>

                                                        <ng-select name="uiImpLayerInPer" required 
                                                        [items]="greentopTrainingData.availableImportFields"
                                                        [(ngModel)]="greentopTrainingData.selectedImportStartPerioada"
                                                        (change)="onChangeImport()" 
                                                        bindLabel="name"
                                                        placeholder="Selectează sau cauta in lista">
                                                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                [{{item.id }}] {{item.name}}
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                                <div class="greentop-tr-select-dw-combo">
                                                    <div class="form-group greentop-tr-existing-combo-mb">
                                                        <label for="uiImpLayerSfPer">Sursa sfarsit perioada</label>
                                                        <ng-select name="uiImpLayerSfPer" required 
                                                        [items]="greentopTrainingData.availableImportFields"
                                                        [(ngModel)]="greentopTrainingData.selectedImportEndPerioada"
                                                        (change)="onChangeImport()" 
                                                        bindLabel="name"
                                                        placeholder="Selectează sau cauta in lista">
                                                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                [{{item.id }}] {{item.name}}
                                                            </ng-template>
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="greentop-tr-select-dw-combo">
                                                <div class="form-group greentop-tr-existing-combo-mb">
                                                    <label for="uiImpLayerSuDesc">Sursa descriere</label>

                                                        <ng-select name="uiImpLayerSuDesc" 
                                                        [items]="greentopTrainingData.availableImportFields"
                                                        [(ngModel)]="greentopTrainingData.selectedImportDescriere"
                                                        (change)="onChangeImport()" 
                                                        bindLabel="name"
                                                        placeholder="Selectează sau cauta in lista">
                                                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                [{{item.id }}] {{item.name}}
                                                            </ng-template>
                                                        </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 *ngIf="greentopTrainingData.resultMessageImport && greentopTrainingData.resultMessageImport.length > 0">
                                            {{greentopTrainingData.resultMessageImport}}
                                        </h6>
                                        <h6 *ngIf="greentopTrainingData.errorMessageImport && greentopTrainingData.errorMessageImport.length > 0" class="text-danger">
                                            {{greentopTrainingData.errorMessageImport}}
                                        </h6>
                                    </div>
                                    <div class="input-group">
                                        <button class="btn btn-light" (click)="onImportTrainingZone()">Importă</button>
                                        <button class="btn btn-light" (click)="onCancelImportTrainingZone()">Închide</button>
                                    </div>
                                </div>
                                <!-- new/edit zone -->
                                <div *ngIf="greentopTrainingData.istoolActive_neweditTrainingZone === true">
                                    <h6>Zonă nouă</h6>
                                    <!-- zona vegetatie-->
                                    <div>
                                        <div class="input-group">
                                            <button class="btn btn-success disabled"
                                            [ngClass]="{'btn-warning':greentopTrainingData.neweditZona.geom == null}">
                                             Zonă selecție
                                            </button>
                                            <div class="input-group-append ">
                                                <span 
                                                class="btn"
                                                 [ngClass]="{'btn-primary':greentopTrainingData.selectButtonStateOn, 'btn-light': greentopTrainingData.selectButtonStateOn == false}"
                                                 (click)="onClickSelectButton()"
                                                >
                                                    <i class="glyphicon glyphicon-edit"></i>
                                                </span>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="form-group" >
                                        <label>An utilizare</label>
                                        <input class="form-control" name="newzanut" type="number" min="2000" max="3000" [(ngModel)]="greentopTrainingData.neweditZona.anUtilizare">
                                    </div>
                                    <!--perioada vegetatie-->
                                    <div class="form-group">
                                        <label>Inceput perioadă utilizare</label>
                                        <div class="form-inline">
                                            <input class="form-control" name="newezincluna" type="number" min="1" max="12" [(ngModel)]="greentopTrainingData.neweditZona.startPerioadaUtilizare.luna">
                                            <input class="form-control" name="newezincdec" type="number" min="1" max="3"[(ngModel)]="greentopTrainingData.neweditZona.startPerioadaUtilizare.decada">
                                            <span>(luna-decada)</span>
                                        </div>
                                        <label>Sfârșit perioadă utilizare</label>
                                        <div class="form-inline">
                                            <input class="form-control"  name="newezsfluna" type="number" min="1" max="12"[(ngModel)]="greentopTrainingData.neweditZona.endPerioadaUtilizare.luna">
                                            <input class="form-control"  name="newezsfdec" type="number" min="1" max="3"[(ngModel)]="greentopTrainingData.neweditZona.endPerioadaUtilizare.decada">
                                            <span>(luna-decada)</span>
                                        </div>
                                    </div>
                                    <!-- Denumire -->
                                    <div>
                                        <div class="greentop-tr-select-dw-combo">
                                            <div class="form-group greentop-tr-existing-combo-mb">
                                                <label for="uineweditVegetatie">Utilizare zonă</label>

                                                <ng-select name="uineweditVegetatie" required 
                                                [items]="greentopTrainingData.availableUtilizariTeren"
                                                [(ngModel)]="greentopTrainingData.selectedneweditUtilizareTeren"
                                                (change)="onChangeneweditUtilizareTeren()" 
                                                bindLabel="denumire"
                                                placeholder="Selectează sau cauta in lista">
                                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                        [{{item.id }}] {{item.denumire}}
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group" >
                                        <label>Descriere</label>
                                        <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                            [(ngModel)]="greentopTrainingData.neweditZona.descriere"
                                            ></textarea>
                                    </div>
                                    <div>
                                        <h6 *ngIf="greentopTrainingData.errorMessageZona && greentopTrainingData.errorMessageZona.length > 0" class="text-danger">
                                            {{greentopTrainingData.errorMessageZona}}
                                        </h6>
                                    </div>
                                    <div class="input-group">
                                        <button class="btn btn-light" (click)="onSaveNeweditTrainingZone()">Salvează</button>
                                        <button class="btn btn-light" (click)="onCancelNeweditTrainingZone()">Renunță</button>
                                        
                                        <button class="btn btn-light" (click)="onRemoveNeweditTrainingZone()">Șterge</button>
                                    </div>

                                </div>
                                <!-- zones -->
                                <div *ngIf="showZoneTrainingList() === true">
                                    <label>Zone antrenament: </label>
                                    <div class="card card-body p-2 greentop-tr-zonelistitem"
                                    *ngFor="let zoneitem of greentopTrainingData.currentAntrenament.zoneAntrenament"
                                    (click)="onClickZoneItem(zoneitem)">
                                        <div>Utilizare: {{zoneitem.denumireUtilizareTeren}}<span class="float-right"><button class="btn-xs btn-light" (click)="onEditZoneItem(zoneitem); $event.stopPropagation()">E</button></span></div>
                                        <div>An: {{zoneitem.anUtilizare}}</div>
                                        <!-- <div>Perioada: {{zoneitem.startPerioadaUtilizare}}->{{zoneitem.endPerioadaUtilizare}}</div> -->
                                        <div>Perioada: {{periodaUtilizareTerenVizualizare(zoneitem.startPerioadaUtilizare, zoneitem.endPerioadaUtilizare)}}</div> 
                                    </div>
                                </div>
                            </div>
                            <!--indici-->
                            <div *ngIf="greentopTrainingData.activeDataTab === 2">
                                <div>
                                    <div class="greentop-tr-select-dw-combo">
                                        <div class="form-group greentop-tr-existing-combo-mb">
                                            <!-- <label for="uinewIndice">Indici</label> -->

                                            <ng-select name="uinewIndice" required 
                                            [items]="greentopTrainingData.filteredIndici"
                                            [(ngModel)]="greentopTrainingData.selectednewIndice"
                                            (change)="onChangenewIndice()" 
                                            bindLabel="codIndice"
                                            placeholder="Selectează sau cauta in lista">
                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    [{{item.id }}] {{item.codIndice}}
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div>
                                        <button class="btn btn-defalut greentop-tr-btn-dark" (click)="onAddNewIndice()">Adaugă indice</button>
                                    </div>
                                </div>
                                <div>
                                    <h6>Listă indici:</h6>
                                    <div class="greentop-tr-indice-list" *ngFor="let indice of greentopTrainingData.currentAntrenament.indiciAntrenament">
                                        
                                        
                                        <div class="greentop-tr-indice-item">
                                            <div *ngIf="indice.stratAsociat"  class="greentop-tr-indice-item-ck" >
                                                <input type="checkbox" name="ckIndice" style="zoom:1.5" [(ngModel)]="indice.stratAsociat.visible"
                                                (change)="showHideIndiceLayer(indice)" />
                                            </div>
                                            <div *ngIf="indice.stratAsociat == null" class="greentop-tr-indice-item-ck">&#160;</div>
                                            
                                            <div class="greentop-tr-indice-item-name">{{indice.codIndice}}</div>
                                            <button  *ngIf="indice.stratAsociat" class="btn-xs btn-light greentop-trindice-item-bt" (click)="onAnimateIndiceLayer(indice)">
                                                <i class="fa fa-play"></i>
                                            </button>
                                            <div class="btn-xs btn-light" (click)="onRemoveIndice(indice)">
                                                <span class="greentop-trindice-item-bt">x</span></div>
                                             
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="greentopTrainingData.loadingActive === true"class="tranz-spiner-box">
                                <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
                            </div>
                            <div>
                                <h6 *ngIf="greentopTrainingData.errorMessage && greentopTrainingData.errorMessage.length > 0" class="text-danger">
                                    {{greentopTrainingData.errorMessage}}
                                </h6>
                            </div>
                        </fieldset>
                    </div>
                    <!-- exist training-->
                    <div *ngIf="greentopTrainingData.activeExistsTab1 && greentopTrainingData.selectedAntrenament">
                        <fieldset [disabled]="greentopTrainingData.loadingActive === true">
                            <!--detalii exist-->
                            <div *ngIf="greentopTrainingData.activeDataTab === 0">
                               <div>
                                <div class="form-group">
                                    <label>Descriere</label>
                                    <span class="float-right" (click)="onClickReloadExisting(); $event.stopPropagation()">
                                        <span class="material-icons greentop-tr-menu-icon-refresh">refresh</span>
                                    </span>
                                    <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                        [(ngModel)]="greentopTrainingData.currentAntrenament.descriere" [disabled]="true"></textarea>
                                </div>
                                <div class="form-group" *ngIf="greentopTrainingData.currentAntrenament.status !== 'finalizat'">
                                    <label>Progres</label>
                                    <div>
                                        <ngb-progressbar max="100" [value]="greentopTrainingData.currentAntrenament.progresStatus" type="success"><span style="color:black; white-space:nowrap;">
                                             {{greentopTrainingData.currentAntrenament.progresStatus}}% {{greentopTrainingData.currentAntrenament.progresDetalii}}
                                            </span></ngb-progressbar>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Algoritm clasificare</label>
                                    <input class="form-control" type="text" name="denumire" 
                                        [(ngModel)]="greentopTrainingData.currentAntrenament.denumireAlgoritm"
                                        [disabled]="true"/>
                                </div>
                                <div class="form-group">
                                    <label>Structura date</label>
                                    <input class="form-control" type="text" name="structura date" 
                                        [(ngModel)]="greentopTrainingData.currentAntrenament.denumireStructuraDate"
                                        [disabled]="true"/>
                                </div>
                                <div class="form-group">
                                    <label class="label-full-width">
                                        <span>Status</span>
                                        <!-- ng-hide="greentopTrainingData.currentAntrenament.mesajStatus == null || greentopTrainingData.currentAntrenament.mesajStatus.length == 0" -->
                                        <span class="glyphicon glyphicon-info-sign float-right" 
                                        ngbPopover="{{greentopTrainingData.currentAntrenament.mesajStatus}}"
                                        popoverTitle="Info"
                                        popoverClass="text-dark"
                                        triggers="mouseenter:mouseleave"
                                        placement="bottom"
                                        onclick="event.stopPropagation()"></span>
                                     
                                    </label>
                                    <input class="form-control" type="text" name="status" 
                                        [(ngModel)]="greentopTrainingData.currentAntrenament.status"
                                        [disabled]="true"/>
                                </div>
                                <div class="form-group" *ngIf="greentopTrainingData.currentAntrenament.actiune">
                                    <label>Cerere acțiune</label>
                                    <input class="form-control" type="text" name="status"
                                        [(ngModel)]="greentopTrainingData.currentAntrenament.actiune" readonly />
                                </div>

                               </div>
                               <div *ngFor="let descarcare of greentopTrainingData.currentAntrenament.descarcariAntrenament">
                                <div class="form-group">
                                    <label>Descarcare planificata</label>
                                    <input class="form-control" type="text" name="denumire" 
                                        [(ngModel)]="descarcare.denumire"
                                        [disabled]="true"/>
                                </div>

                                <div class="form-group" >
                                    <label>Perioada</label>
                                    <div class="greentop-tr-form-period-inputs">
                                        <div class="input-group">
                                            <input
                                                disabled
                                                class="form-control px-2"
                                                placeholder="început"
                                                name="startPerioada"
                                                [(ngModel)]="descarcare.startPerioada"
                                                ngbDatepicker
                                                #dstart="ngbDatepicker"
                                            />
                                            <div class="input-group-append">
                                                <button disabled class="btn btn-light" (click)="dstart.toggle()" type="button">
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <input
                                                disabled
                                                class="form-control px-2"
                                                placeholder="sfârșit"
                                                name="endPerioada"
                                                [(ngModel)]="descarcare.endPerioada"
                                                ngbDatepicker
                                                #dend="ngbDatepicker"
                                            />
                                            <div class="input-group-append">
                                                <button disabled class="btn btn-light" (click)="dend.toggle()" type="button">
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" >
                                    <label>Descriere</label>
                                    <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                        [(ngModel)]="descarcare.descriere" 
                                        [disabled]="true"></textarea>
                                </div>
                                <div class="input-group">
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    [disabled]="canContinueTraining() === false"
                                    (click)="onContinueTraining()" title="Continuă"><i class="fas fa-play-circle fa-2x"></i></button>
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    [disabled]="canRestartTraining() === false"
                                    (click)="onRestartTraining()" title="Repornește"><i class="fas fa-redo fa-2x"></i></button>
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    [disabled]="canStopTraining() === false"
                                    (click)="onStopTraining()" title="Oprește"><i class="fas fa-stop-circle fa-2x"></i></button>
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    (click)="newFromTraining()" title="Nou"><i class="fas fa-plus-circle fa-2x"></i> </button>
                                    <button class="btn btn-light greentop-action-wrp-buttons"
                                    (click)="onDownloadWekaInfo()" title="Raport"><i class="fas fa-scroll fa-2x"></i></button>
                                </div>
                               </div>
                            </div>
                            <!--zone existing-->
                            <div *ngIf="greentopTrainingData.activeDataTab === 1">
                                <div>
                                    <!-- <label>Zone antrenament: </label> -->
                                    <div class="card card-body p-2 greentop-tr-zonelistitem"
                                    *ngFor="let zoneitem of greentopTrainingData.currentAntrenament.zoneAntrenament"
                                    (click)="onClickZoneItem(zoneitem)">
                                        <div title="{{zoneitem.id}}">Utilizare: {{zoneitem.denumireUtilizareTeren}}</div>
                                        <div>An: {{zoneitem.anUtilizare}}</div>
                                        <div>Perioada: {{periodaUtilizareTerenVizualizare(zoneitem.startPerioadaUtilizare, zoneitem.endPerioadaUtilizare)}}</div> 
                                        <div>
                                            <span>Status: {{zoneitem.status}}&#160;</span>
                                            <span class="glyphicon glyphicon-info-sign "
                                                *ngIf="zoneitem.mesajStatus && zoneitem.mesajStatus.length > 0"
                                                ngbPopover="{{zoneitem.mesajStatus}}"
                                                popoverTitle="Info"
                                                popoverClass="text-dark"
                                                triggers="mouseenter:mouseleave"
                                                placement="bottom" 
                                                  onclick="event.stopPropagation()">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--indici existing-->
                            <div *ngIf="greentopTrainingData.activeDataTab === 2">
                                <div class="greentop-tr-indice-list" *ngFor="let indice of greentopTrainingData.currentAntrenament.indiciAntrenament">
                                    <!--  -->
                                    <div class="greentop-tr-indice-item">
                                        <div *ngIf="indice.stratAsociat"  class="greentop-tr-indice-item-ck" >
                                            <input type="checkbox" name="ckIndiceex" style="zoom:1.5" [(ngModel)]="indice.stratAsociat.visible"
                                                (change)="showHideIndiceLayer(indice)" />
                                        </div>
                                        <div *ngIf="indice.stratAsociat == null" class="greentop-tr-indice-item-ck">&#160;</div>
                                        
                                        <div class="greentop-tr-indice-item-name">{{indice.codIndice}}</div>
                                        <button  *ngIf="indice.stratAsociat" class="btn-xs btn-light greentop-trindice-item-bt" (click)="onAnimateIndiceLayer(indice)">
                                            <i class="fa fa-play"></i>
                                        </button>
                                         
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <div *ngIf="greentopTrainingData.loadingActive === true"class="tranz-spiner-box">
                            <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>