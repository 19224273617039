<div class="modal-content" id="fisaSpatiuVerde">
    <!--<div class="modal-header">
        <h5 class="modal-title">Rapoarte</h5>
    </div>-->
    <div class="modal-body">
        <!-- Header -->
        <div *ngFor="let svRaport of svRaports" print-section [ngClass]="!$last ? 'pageBrakeAfter': ''">
            <div class="modal-header" style="text-align:center">
                <h5 class="modal-title">FISA SPATIULUI VERDE</h5>
            </div>
            <div print-section>
                <!--<h5>Anexa: Fisa spatiului verde</h5>-->
                <table id="tblHeaderInfo" width="100%">
                    <col width="10%" />
                    <col width="22%" />
                    <col width="14%" />
                    <col width="18%" />
                    <col width="14%" />
                    <col width="18%" />
                    <tbody>
                        <tr>
                            <td>{{denumiriColoaneHeader.judetul}}:</td>
                            <td>{{svRaport.headInfo.judet}}</td>
                            <td>{{denumiriColoaneHeader.sectiunePlan}}:</td>
                            <td>{{svRaport.headInfo.sectiunePlan}}</td>
                            <td>{{denumiriColoaneHeader.codZonaValorica}}:</td>
                            <td>{{svRaport.headInfo.codZonaValorica}}</td>
                        </tr>
                        <tr>
                            <td>{{denumiriColoaneHeader.localitatea}}:</td>
                            <td>{{svRaport.headInfo.localitatea}}</td>
                            <td>{{denumiriColoaneHeader.nrCadastral}}:</td>
                            <td>{{svRaport.headInfo.nrCadastral}}</td>
                            <td>{{denumiriColoaneHeader.codZonaProtejata}}:</td>
                            <td>{{svRaport.headInfo.codZonaProtejata}}</td>
                        </tr>
                        <tr>
                            <td>{{denumiriColoaneHeader.codsiruta}}:</td>
                            <td>{{svRaport.headInfo.codSiruta}}</td>
                            <td>{{denumiriColoaneHeader.nrCarteFunciara}}:</td>
                            <td>{{svRaport.headInfo.nrCarteFunciara}}</td>
                            <td>{{denumiriColoaneHeader.codPostal}}:</td>
                            <td>{{svRaport.headInfo.codPostal}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <!-- Create map image-->
            <div print-section class="mapImageContainer">
                <h6>1. SCHITA AMPLASAMENTULUI TERENULUI SPATIU VERDE</h6>
                <img width="100%" id="mapImage" [src]="mapImgUrl" />
            </div>

            <!-- Adresa -->
            <div print-section>
                <h6>2. ADRESA:</h6>
                <p>{{svRaport.adresa}}</p>
            </div>
            <!-- Tabel spatiu verde -->
            <div print-section>
                <h6>3. DATE DESPRE TERENUL SPATIU VERDE</h6>
                <table id="tblSpatiuVerde" width="100%">
                    <col width="11%" />
                    <col width="11%" />
                    <col width="11%" />
                    <col width="11%" />
                    <col width="11%" />
                    <col width="11%" />
                    <col width="11%" />
                    <col width="11%" />
                    <col width="11%" />
                    <thead>
                        <tr>
                            <th>{{denumiriColoaneSpatiuVerde.proprietar}}</th>
                            <th>{{denumiriColoaneSpatiuVerde.proprietate}}</th>
                            <th>{{denumiriColoaneSpatiuVerde.modAdministrare}}</th>
                            <th>{{denumiriColoaneSpatiuVerde.categorieDeFolosinta}}</th>
                            <th>{{denumiriColoaneSpatiuVerde.reglementareUrbanistica}}</th>
                            <th>{{denumiriColoaneSpatiuVerde.suprafataMasurataTerenExclusiv}}</th>
                            <th>{{denumiriColoaneSpatiuVerde.suprafataMasurataTerenIndiviziune}}</th>
                            <th>{{denumiriColoaneSpatiuVerde.totalTerenSpatiuVerde}}</th>
                            <th>{{denumiriColoaneSpatiuVerde.observatii}}</th>
                        </tr>
                        <tr>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let titem of svRaport.spatiiVerzi">
                            <td>{{ titem.proprietar }}</td>
                            <td>{{ titem.proprietate }}</td>
                            <td>{{ titem.modAdministrare }}</td>
                            <td>{{ titem.categorieDeFolosinta }}</td>
                            <td>{{ titem.reglementareUrbanistica }}</td>
                            <td>{{ titem.suprafataMasurataTerenExclusiv }}</td>
                            <td>{{ titem.suprafataMasurataTerenIndiviziune }}</td>
                            <td>{{ titem.totalTerenSpatiuVerde }}</td>
                            <td>{{ titem.observatii }}</td>
                        </tr>

                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="7">Total teren spatiu verde</td>
                            <td>{{svRaport.totalTerenSpatiiVerzi}}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <!-- Tabel spatiu verde -->
            <div print-section>
                <h6>4. DATE PRIVIND CONSTRUCTIILE EXISTENTE PE TERENUL SPATIU VERDE</h6>
                <table id="tblConstructii" width="100%">
                    <col width="14%" />
                    <col width="14%" />
                    <col width="14%" />
                    <col width="14%" />
                    <col width="14%" />
                    <col width="14%" />
                    <col width="14%" />
                    <thead>
                        <tr>
                            <th>{{denumiriColoaneConstructie.proprietar}}</th>
                            <th>{{denumiriColoaneConstructie.proprietate}}</th>
                            <th>{{denumiriColoaneConstructie.modAdministrare}}</th>
                            <th>{{denumiriColoaneConstructie.nrCorpConstructie}}</th>
                            <th>{{denumiriColoaneConstructie.codGrupaDestinatie}}</th>
                            <th>{{denumiriColoaneConstructie.suprafataConstruitaLaSol}}</th>
                            <th>{{denumiriColoaneConstructie.observatii}}</th>
                        </tr>
                        <tr>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let titem of svRaport.constructii">
                            <td>{{ titem.proprietar }}</td>
                            <td>{{ titem.proprietate }}</td>
                            <td>{{ titem.modAdministrare }}</td>
                            <td>{{ titem.nrCorpConstructie }}</td>
                            <td>{{ titem.codGrupaDestinatie }}</td>
                            <td>{{ titem.suprafataConstruitaLaSol }}</td>
                            <td>{{ titem.observatii }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="5">Total suprafata ocupata din terenul spatiu verde</td>
                            <td>{{svRaport.totalSuprafataDinTerenSpatiuVerde}}</td>
                            <td></td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <!-- echipare edilitara -->
            <div print-section>
                <h6>5. ECHIPAREA EDILITARA A TERENULUI:</h6>
                <p>{{svRaport.echipareEdilitara}}</p>
            </div>

            <!-- Date priviind vegetatia existenta in spatiul verde -->
            <div print-section>
                <h6>6. DATE PRIVIND VEGETATIA EXISTENTA PE TERENUL SPATIU VERDE:</h6>
                <div>
                    <table id="tblVegetatie" width="100%">
                        <col width="5%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="14%" />
                        <thead>
                            <tr>
                                <th>{{denumiriColoaneVegetatie.nr}}</th>
                                <th>{{denumiriColoaneVegetatie.prescurtare}}</th>
                                <th>{{denumiriColoaneVegetatie.denumireStiintifica}}</th>
                                <th>{{denumiriColoaneVegetatie.denumirePopulara}}</th>
                                <th>{{denumiriColoaneVegetatie.numarExemplare}}</th>
                                <th>{{denumiriColoaneVegetatie.diametru}}</th>
                                <th>{{denumiriColoaneVegetatie.inaltime}}</th>
                                <th>{{denumiriColoaneVegetatie.varsta}}</th>
                                <th>{{denumiriColoaneVegetatie.observatii}}</th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                                <th>8</th>
                                <th>9</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let gitem of svRaport.vegetatieGroups">
                            <tr>
                                <td colspan="5">{{gitem.categorie || '-'}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr *ngFor="let titem of gitem.vegetatie">
                                <td>{{ titem.nr }}</td>
                                <td>{{ titem.prescurtare }}</td>
                                <td>{{ titem.denumireStiintifica }}</td>
                                <td>{{ titem.denumirePopulara }}</td>
                                <td>{{ titem.numarExemplare }}</td>
                                <td>{{ titem.diametru }}</td>
                                <td>{{ titem.inaltime }}</td>
                                <td>{{ titem.varsta }}</td>
                                <td>{{ titem.observatii }}</td>
                            </tr>
                            <tr>
                                <td colspan="4">{{'Total - ' + gitem.categorie}}</td>
                                <td>{{gitem.total}}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                        <tfoot>



                            <!--<tr>
                        <td colspan="5">Total suprafata ocupata din terenul spatiu verde</td>
                        <td>{{svRaport.totalSuprafataDinTerenSpatiuVerde}}</td>
                        <td></td>
                    </tr>-->
                        </tfoot>
                    </table>
                </div>
            </div>
            <!-- Date priviind vegetatia izolata -->
            <div print-section>
                <h6>6. DATE PRIVIND VEGETATIA IZOLATA:</h6>
                <div>
                    <table id="tblVegetatie" width="100%">
                        <col width="5%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="14%" />
                        <thead>
                            <tr>
                                <th>{{denumiriColoaneVegetatie.nr}}</th>
                                <th>{{denumiriColoaneVegetatie.prescurtare}}</th>
                                <th>{{denumiriColoaneVegetatie.denumireStiintifica}}</th>
                                <th>{{denumiriColoaneVegetatie.denumirePopulara}}</th>
                                <th>{{denumiriColoaneVegetatie.numarExemplare}}</th>
                                <th>{{denumiriColoaneVegetatie.diametru}}</th>
                                <th>{{denumiriColoaneVegetatie.inaltime}}</th>
                                <th>{{denumiriColoaneVegetatie.varsta}}</th>
                                <th>{{denumiriColoaneVegetatie.observatii}}</th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                                <th>8</th>
                                <th>9</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor="let titem of svRaport.vegetatieIzolata">
                                <td>{{ titem.nr }}</td>
                                <td>{{ titem.prescurtare }}</td>
                                <td>{{ titem.denumireStiintifica }}</td>
                                <td>{{ titem.denumirePopulara }}</td>
                                <td>{{ titem.numarExemplare }}</td>
                                <td>{{ titem.diametru }}</td>
                                <td>{{ titem.inaltime }}</td>
                                <td>{{ titem.varsta }}</td>
                                <td>{{ titem.observatii }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Date priviind vegetatia ocrotita -->
            <div print-section>
                <h6>6. DATE PRIVIND VEGETATIA OCROTITA:</h6>
                <div>
                    <table id="tblVegetatie" width="100%">
                        <col width="5%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="14%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="14%" />
                        <thead>
                            <tr>
                                <th>{{denumiriColoaneVegetatie.nr}}</th>
                                <th>{{denumiriColoaneVegetatie.prescurtare}}</th>
                                <th>{{denumiriColoaneVegetatie.denumireStiintifica}}</th>
                                <th>{{denumiriColoaneVegetatie.denumirePopulara}}</th>
                                <th>{{denumiriColoaneVegetatie.numarExemplare}}</th>
                                <th>{{denumiriColoaneVegetatie.diametru}}</th>
                                <th>{{denumiriColoaneVegetatie.inaltime}}</th>
                                <th>{{denumiriColoaneVegetatie.varsta}}</th>
                                <th>{{denumiriColoaneVegetatie.observatii}}</th>
                            </tr>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                                <th>8</th>
                                <th>9</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr *ngFor="let titem of svRaport.vegetatieOcrotita">
                                <td>{{ titem.nr }}</td>
                                <td>{{ titem.prescurtare }}</td>
                                <td>{{ titem.denumireStiintifica }}</td>
                                <td>{{ titem.denumirePopulara }}</td>
                                <td>{{ titem.numarExemplare }}</td>
                                <td>{{ titem.diametru }}</td>
                                <td>{{ titem.inaltime }}</td>
                                <td>{{ titem.varsta }}</td>
                                <td>{{ titem.observatii }}</td>
                            </tr>
                        </tbody>
                        <tfoot> </tfoot>
                    </table>
                </div>
            </div>
            <!-- Documente emise cu privire la spatiul verde -->
            <div print-section>
                <h6>7. DOCUMENTE EMISE CU PRIVIRE LA TERENUL SPATIU VERDE SI CONSTRUCTIILE EXISTENTE:</h6>
                <p>{{svRaport.documenteEmise}}</p>
            </div>
            <!-- report footer-->
            <div print-section>
                <table id="tblReportFooter" width="100%">
                    <col width="60%" />
                    <col width="40%" />
                    <tr>
                        <td>Intocmit</td>
                        <td>Verificat si aprobat</td>
                    </tr>
                    <tr>
                        <td>extras din Registrul electronic al spatiilor verzi - Municipiul Baia Mare <br /> <a href="http://gis.indecosoft.net/">http://gis.indecosoft.net/</a></td>
                        <td>prin HCL 237/2018</td>
                    </tr>
                    <tr>
                        <td>Data:{{svRaport.printDate}}</td>
                        <td>Data:23.04.2018</td>
                    </tr>
                    <tr><td>&nbsp;</td></tr>
                    <tr><td>&nbsp;</td></tr>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" print-btn print-landscape><i class="fa fa-print"></i> Print</button>
        <button type="button" class="btn" (click)="cancel()">Inchide</button>
    </div>
</div>