<div class="container-full" >
    <!--map container-->
    <div print-section id="map" class="map"></div>
    <!--menu button-->
    <div id="pos-input-button-group" *ngIf="showMainMenu" >
        <span class="input-group-btn">
            <button class="btn focus collapsed toggle-btn" type="button" (click)="doColapseMenu()">
                <span class="fa fa-bars"></span>
            </button>
        </span>
    </div>
    <!--tools overlay-->
    <div id="overlay" class="overlay" *ngIf="showMainMenu">
        <!--layers config-->
        <map-layers [mapctrl]="vm"></map-layers>
        <!--tranzit-->
        <map-tranzit [mapctrl]="vm"></map-tranzit>
        <!--location-->
        <map-position [mapctrl]="vm"></map-position>
        <!--greentop-->
        <greentop-download [mapctrl]="vm"></greentop-download>
        <greentop-training [mapctrl]="vm"></greentop-training>
        <greentop-classify [mapctrl]="vm"></greentop-classify>
        <!--integrate-landsat-->
        <integrate-landsat-download [mapctrl]="vm"></integrate-landsat-download>
        <integrate-landsat-hotspot [mapctrl]="vm"></integrate-landsat-hotspot>
        <integrate-landsat-procesare-indice-conex [mapctrl]="vm"></integrate-landsat-procesare-indice-conex>
        <integrate-landsat-procesare-date-sezoniere [mapctrl]="vm"></integrate-landsat-procesare-date-sezoniere>
    </div>
   
    <!--bottom controls-->
    <map-bottomtop-controlbar [mapctrl]="vm"></map-bottomtop-controlbar>
    <!--map legend-->
    <map-legend [mapctrl]="vm"></map-legend>
    <!--data loader-->
    <div id="layerLoading" class="button_wrapper" *ngIf="showLayerLoading()">
        <i id="spinner" class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i>
        <span> Încărcare hartă </span>
    </div>
     <!-- overlay -->
     <div style="display: none">
        <div id="location-marker">
            <img src="img/location-marker.svg" alt="+" />
        </div>
    </div>
    <!-- Info feature -->
    <div id="info-popup"> </div>
</div>