//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { ILayer, authAs, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { IAntrenamentModelClasificare, IClasificareDateSatelitare, IClasificareZonaRezultat, IDescarcareDateSatelitare, IIndiceAntrenament, IIndiceDescarcare, IInfoStil, IProdusDescarcare, TaskActiune, TaskStatus } from '../../services/greentop-data-service';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter, NgbDateMomentParserFormatter } from '../../shared-components/ngb-moment-adapter-formater';
import moment, { Moment } from "moment";
import { IGreentopClassifyData} from './map-greentop-definitions';
import { createGeomFromWKT, findStratAsociatToIndice, getExMessage } from '../map-utils';
import { NGXLogger } from 'ngx-logger';

import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import TileLayer from 'ol/layer/Tile';
import * as olExtent from 'ol/extent';
import VectorSource from "ol/source/Vector";
import  TileWMSSource  from "ol/source/TileWMS";
import WKTFormater from "ol/format/WKT";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olColor from 'ol/color';

@Component({
    selector: 'greentop-classify',
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, 
             useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
        { provide: NgbDateParserFormatter, 
            useFactory: () => { return new NgbDateMomentParserFormatter("YYYY-MM-DD") } },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},
    ],
    templateUrl: './map-greentop-classify.component.html',
    styleUrls: ['./map-greentop-classify.component.css']
})
export class GreentopClassifyComponent implements OnInit, OnChanges {
    @Input("mapctrl") mapCtrl: MapController = null;
    private desStyle = new OlStyle({
        fill: new FillStyle({
            color: olColor.asArray('rgba(50,0,0,0)')
        }),
        stroke: new StrokeStyle({
            color: 'rgba(255,0,0,1)',
            width: 2
        })
    });
    private clsStyle = new OlStyle({
        fill: new FillStyle({
            color: olColor.asArray('rgba(5,5,0,0)')
        }),
        stroke: new StrokeStyle({
            color: 'rgba(127,0,255,1)',
            width: 2
        })
    });
    private resultStyle = new OlStyle({
        fill: new FillStyle({
            color: olColor.asArray('rgba(0,255,0,0)')
        }),
        stroke: new StrokeStyle({
            color: 'rgba(0,255,0,1)',
            width: 2
        }),

    });
    private resultText(text: string) {
        return new OlStyle({
            text: new TextStyle({
                text: text,
                scale: 1,
                offsetX: 0,
                offsetY: 0,
                fill: new FillStyle({
                    color: 'rgba(0, 0, 0, 1)'
                })
            })
        })
    }
    private stilGeoImplicit: IInfoStil = {
        id: -1,
        denumire: "Stil implicit",
        descriere: ""
    }


    //
    public infoSearch = "info clasificare date satelitare";
    public infoProducts = "info detalii clasificare";
    public greentopClassifyData : IGreentopClassifyData;
    public constructor(
        @Inject(NGXLogger) private $log: NGXLogger,
    ){
        //
    }

    public ngOnInit(): void {
        if (this.mapCtrl && this.mapCtrl.greentopClassifyData) {
            this.greentopClassifyData = this.mapCtrl.greentopClassifyData;
            this.mapCtrl.greentopClassifyData.funcInit = this.initGreentopClassifyTool;
            this.mapCtrl.greentopClassifyData.funcClear = this.clearGreentopClassifyTool;
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }

    

   
    //
    public onClickClose(){
        this.clearGreentopClassifyTool();
        this.mapCtrl.greentopClassifyButtonStateOn = false;
        this.mapCtrl.greentopClassifyMinimizeStateOn = false;
    }
    //
    public onClickMinimize(){
        this.mapCtrl.greentopClassifyButtonStateOn = false;
        this.mapCtrl.greentopClassifyMinimizeStateOn = true;
        $(this.mapCtrl.greentopClassifyButtonCtrl["element"]).addClass('select-button-on');
    }
    //
    //
    public changeNewExistsTab(index) {
        if (index === "0") {
            if (this.greentopClassifyData.activeNewTab0) {
                // this.greentopClassifyData.activeNewTab0 = false
            } else {
                this.greentopClassifyData.activeExistsTab1 = false;
                this.greentopClassifyData.activeNewTab0 = true;
                this.greentopClassifyData.activeTab = 0;
                this.greentopClassifyData.activeDataTab = 0;
                this.initNewClassifyData();
            }

        } else if (index === "1") {
            if (this.greentopClassifyData.activeExistsTab1) {
                // this.greentopClassifyData.activeExistsTab1 = false
            } else {

                this.greentopClassifyData.activeExistsTab1 = true;
                this.greentopClassifyData.activeNewTab0 = false;
                this.greentopClassifyData.activeTab = 1;
                this.greentopClassifyData.activeDataTab = 0;
                this.initExistingClassifyData(null);
            }
        }
    }
    
    public changeDataTab(tabid: string){
        //
    }
    
    public initGreentopClassifyTool = ()=>{
        this.addSelectLayer();
        this.addResultLayer();
        this.addDownloadAreaLayer();
        //result layer
        this.greentopClassifyData.activeExistsTab1 = true;
        this.greentopClassifyData.activeNewTab0 = false;
        this.greentopClassifyData.activeTab = 1;
        this.greentopClassifyData.activeDataTab = 2;
        //this.initNewClassifyData();
        this.initExistingClassifyData(null);
    }

    public clearGreentopClassifyTool = ()=>{
        this.removeSelectLayerInteraction();
        this.removeSelectLayer();
        this.removeResultLayer();
        this.removeResultTileLayer();
        this.removeDownloadAreaLayer();
        //result layer
    }

    public initNewClassifyData(){
        //
        this.clearLayers();
        this.greentopClassifyData.selectButtonStateOn = false;
        this.greentopClassifyData.currentClasificare = {
            denumire: "",
            descriere: "",
            startPerioada:  moment(new Date()).format(this.greentopClassifyData.optFormatDataPerioada),
            endPerioada: moment(new Date()).format(this.greentopClassifyData.optFormatDataPerioada),
            geom: null,
            idDescarcare: null,
            idAntrenament: null,
            status: "initiat",
            mesajStatus: "",
            descarcare: null,
            antrenament: null,
        }
        this.greentopClassifyData.selectedDescarcare = null;
        this.greentopClassifyData.selectedAntrenament = null;

        this.greentopClassifyData.errorMessage = "";
        this.greentopClassifyData.loadingActive = true;
        this.mapCtrl.greentopDataService.getDescarcariDateSatelitare(['finalizat'])
            .then((data) => {
                this.greentopClassifyData.availableDescarcari = data;
                return true;
            }).then((state)=>{
                return this.mapCtrl.greentopDataService.getAntrenamenteModelClasificare(['finalizat']);
            }).then((data) => {
                this.greentopClassifyData.availableAntrenamente = data;
                return true;
            }).catch((reason) => {
                this.greentopClassifyData.errorMessage = "Eroare interogare antrenamente";
                this.$log.error("Eroare interogare antrenamente ", getExMessage(reason));
            }).finally(() => {
                this.greentopClassifyData.loadingActive = false;
            })
    }

    public initExistingClassifyData(idPreselected: number){
        this.clearLayers();
        this.greentopClassifyData.selectButtonStateOn = false;
        this.greentopClassifyData.currentClasificare = {
            denumire: "",
            descriere: "",
            startPerioada:  moment(new Date()).format(this.greentopClassifyData.optFormatDataPerioada),
            endPerioada: moment(new Date()).format(this.greentopClassifyData.optFormatDataPerioada),
            geom: null,
            idDescarcare: null,
            idAntrenament: null,
            status: "initiat",
            mesajStatus: "",
            descarcare: null,
            antrenament: null,
        }
        this.greentopClassifyData.selectedClasificare = null;
        this.greentopClassifyData.selectedDescarcare = null;
        this.greentopClassifyData.selectedAntrenament = null;
        this.greentopClassifyData.availableMomenteDateSatelitare = [];
        this.greentopClassifyData.selectedMomentDateSatelitare = null;

        this.configAutorefresh();
  
        this.greentopClassifyData.errorMessage = "";
        this.greentopClassifyData.loadingActive = true;


        this.mapCtrl.greentopDataService.getClasificariiDateSatelitare(null)
            .then((data)=>{
                this.greentopClassifyData.availableClasificari = data;
                return true;
            })
            .then((status) => {
                return this.mapCtrl.greentopDataService.getAvailableStiluri();
            })
            .then((data) => {
                this.greentopClassifyData.availableStiluri = data;
                if (this.greentopClassifyData.availableStiluri.length > 0){
                    this.greentopClassifyData.availableStiluri.unshift(this.stilGeoImplicit);
                }
                return true;
            })
            .catch((reason)=>{
                this.greentopClassifyData.errorMessage = "Eroare interogare clasificari";
                this.$log.error("Eroare interogare clsificari: ", getExMessage(reason));
            }).finally(()=>{
                this.greentopClassifyData.loadingActive = false;
            }).then((state)=>{
                //load preselected
                if(state as any === true && typeof idPreselected === "number" ){
                  let tmpPres =  this.greentopClassifyData.availableClasificari.filter((item)=>item.id === idPreselected);
                  if (tmpPres && tmpPres.length > 0){
                      this.greentopClassifyData.selectedClasificare = tmpPres[0];
                      this.onChangeClasificare();
                  }
                } 
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare activare preselectie: ", getExMessage(reason))
            })
    }

    private clearLayers() {
        if (this.greentopClassifyData.selectLayer) {
            this.greentopClassifyData.selectLayer.getSource().clear();
        }
        if (this.greentopClassifyData.downloadAreaLayer) {
            this.greentopClassifyData.downloadAreaLayer.getSource().clear();
        }
        if (this.greentopClassifyData.resultLayer) {
            this.greentopClassifyData.resultLayer.getSource().clear();
        }
    }
    public onClickSelectButton = (event) => {
        try {
            if (!this.greentopClassifyData.selectButtonStateOn) {
                this.greentopClassifyData.selectLayer.getSource().clear();
                this.greentopClassifyData.currentClasificare.geom = null;
                this.addSelectLayerInteraction();
                this.greentopClassifyData.selectButtonStateOn = true;
                //this.clearSearchResults();
            } else {
                if (this.greentopClassifyData.selectDrawInteraction.getActive()) {
                    this.greentopClassifyData.selectDrawInteraction.finishDrawing();
                } else {
                    this.removeSelectLayerInteraction();
                    this.greentopClassifyData.selectButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare selectie zona");
        }
        //todo
    }
    public onAnimateIndiceLayer(indice: IIndiceAntrenament){
        //activate strat asociat
        (this.greentopClassifyData.currentClasificare.antrenament.indiciAntrenament as Array<IIndiceAntrenament>).forEach((itind)=>{
            if (itind.stratAsociat){
                if(itind.stratAsociat.id === indice.stratAsociat.id){
                    if (itind.stratAsociat.visible === false){
                        itind.stratAsociat.visible = true;
                        this.mapCtrl.mapMenuLayers.showHideLayer(null, itind.stratAsociat);
                    }
                } else {
                    if ((itind).stratAsociat.visible === true){
                        //set invisible
                        itind.stratAsociat.visible = false;
                        this.mapCtrl.mapMenuLayers.showHideLayer(null, itind.stratAsociat);
                    }
                }
            }
            
        })
        //
        this.mapCtrl.mapCtrlAnimateTimeRaster.onAnimateTimeRasterAction(indice.stratAsociat, this.greentopClassifyData.availableMomenteDateSatelitare);
    }
    //
    private setMomenteDateSatelitare(){
        this.greentopClassifyData.availableMomenteDateSatelitare.length = 0;
        this.greentopClassifyData.selectedMomentDateSatelitare = null;
        this.greentopClassifyData.currentClasificare.descarcare.produseDescarcare.forEach((itProd)=>{
            let indexEx = this.greentopClassifyData.availableMomenteDateSatelitare.indexOf(itProd.dataProdus);
                if (indexEx < 0
                    && itProd.dataProdus && itProd.dataProdus.length > 0
                    && new Date(itProd.dataProdus).getTime() >= (this.greentopClassifyData.currentClasificare.startPerioada.valueOf() as number)
                    && new Date(itProd.dataProdus).getTime() <= (this.greentopClassifyData.currentClasificare.endPerioada.valueOf() as number)
                    ){
                    this.greentopClassifyData.availableMomenteDateSatelitare.push(itProd.dataProdus);
                }
        });
        this.greentopClassifyData.availableMomenteDateSatelitare 
        if(this.greentopClassifyData.availableMomenteDateSatelitare.length > 0){
            this.greentopClassifyData.availableMomenteDateSatelitare.sort((a,b)=>new Date(a).getTime()-new Date(b).getTime());
            this.greentopClassifyData.selectedMomentDateSatelitare = this.greentopClassifyData.availableMomenteDateSatelitare[0];
           //
        }
        this.onChangeMomentDateSatelitare();
    }
    //
    public onChangeAnPerioada(){

    }
    //
    public setAniPerioada(){
        if (this.greentopClassifyData.currentClasificare.descarcare
            && this.greentopClassifyData.currentClasificare.descarcare.startPerioada 
            && this.greentopClassifyData.currentClasificare.descarcare.endPerioada){
                this.greentopClassifyData.availableAniPerioada.length = 0;
               let startYear = this.greentopClassifyData.currentClasificare.descarcare.startPerioada.year();
               let endYear = this.greentopClassifyData.currentClasificare.descarcare.endPerioada.year();
               if (startYear == endYear){
                this.greentopClassifyData.availableAniPerioada.push(startYear);
                this.greentopClassifyData.selectedAnPerioada = startYear;
               } else if (endYear > startYear){
                   for (let index = startYear; index <= endYear; index++) {
                    this.greentopClassifyData.availableAniPerioada.push(index);
                   }
               }
            }
    }
    //
    public onChangeClasificare() {
        if (this.greentopClassifyData.selectedClasificare == null) {
            return;
        }
        //
        this.greentopClassifyData.downloadAreaLayer.getSource().clear();
        this.greentopClassifyData.resultLayer.getSource().clear();
        this.removeResultTileLayer();
        this.greentopClassifyData.selectedStil = null;
        this.clearRefreshTimeout();
        //
        this.greentopClassifyData.errorMessage = "";
        this.greentopClassifyData.loadingActive = true;
        this.mapCtrl.greentopDataService.getClasificareDateSatelitare(this.greentopClassifyData.selectedClasificare.id)
            .then((data) => {
                this.greentopClassifyData.currentClasificare = data;

                //
                let wkt = new WKTFormater();
                let geomwgs = wkt.readGeometry(this.greentopClassifyData.currentClasificare.geom);
                let geom = geomwgs.transform("EPSG:4326", this.mapCtrl.mapConfig.projection) as Polygon;
                //
                let tmpFeature = new Feature(geom);
                this.greentopClassifyData.selectLayer.getSource().clear();
                this.greentopClassifyData.selectLayer.getSource().addFeature(tmpFeature);
                this.mapCtrl.map.getView().setCenter(olExtent.getCenter(geom.getExtent()));
                //
                this.greentopClassifyData.resultLayer.getSource().clear();
                this.greentopClassifyData.currentClasificare.zoneRezultat.forEach((itemdes) => {
                    let geom = createGeomFromWKT(itemdes.geom, "EPSG:4326", this.mapCtrl.mapConfig.projection);
                    itemdes.feature = new Feature(geom);
                    itemdes.feature.setStyle([
                        this.resultStyle,
                        this.resultText(itemdes.denumireUtilizareTeren)
                    ])
                    this.greentopClassifyData.resultLayer.getSource().addFeature(itemdes.feature);
                })
                this.addResultTileLayer();
                this.greentopClassifyData.currentClasificare.antrenament.indiciAntrenament.forEach((indItem)=>{
                    findStratAsociatToIndice(indItem, this.mapCtrl);
                });
                this.setMomenteDateSatelitare();
                //
                this.greentopClassifyData.resultTileLayer.visible = true;
                this.mapCtrl.mapMenuLayers.showHideLayer(this.mapCtrl, this.greentopClassifyData.resultTileLayer)
                if (this.greentopClassifyData.currentClasificare.status === "finalizat"){
                    this.greentopClassifyData.activeDataTab = 2;
                } else {
                    this.greentopClassifyData.activeDataTab = 0;
                }
            }).catch((reason) => {
                this.greentopClassifyData.errorMessage = "Eroare interogare clasificare";
                this.$log.error("Eroare interogare clasificare: ", getExMessage(reason));
            }).then((state)=>{
                return this.mapCtrl.greentopDataService.loadResultClasificareLegend(this.greentopClassifyData);
            }).catch((reason)=>{
                this.$log.error("Eroare incarcare legenda clasificare: ", getExMessage(reason));
            })
            .finally(() => {
                this.greentopClassifyData.loadingActive = false;
            })
    }
    //
    public onClickReloadExisting() {
        this.onChangeClasificare();
    }
    //
    public onChangeDescarcare() {
        
        this.greentopClassifyData.currentClasificare.descarcare = null;
        this.greentopClassifyData.errorMessage = "";
        this.greentopClassifyData.loadingActive = true;
        this.mapCtrl.greentopDataService.getDescarcareDateSatelitare(this.greentopClassifyData.selectedDescarcare.id, true)
            .then((data) => {
                this.greentopClassifyData.currentClasificare.descarcare = data;
                this.greentopClassifyData.downloadAreaLayer.getSource().clear();
                //
                let wkt = new WKTFormater();
                if (data.geom) {
                    let geomwgs = wkt.readGeometry(data.geom);
                    let geom = geomwgs.transform("EPSG:4326", this.mapCtrl.mapConfig.projection) as Polygon;
                    //
                    let tmpFeature = new Feature(geom);
                    this.greentopClassifyData.downloadAreaLayer.getSource().addFeature(tmpFeature);
                    this.mapCtrl.map.getView().setCenter(olExtent.getCenter(geom.getExtent()));
                }
                //
                if (this.greentopClassifyData.currentClasificare.descarcare.startPerioada){
                    this.greentopClassifyData.currentClasificare.startPerioada = this.greentopClassifyData.currentClasificare.descarcare.startPerioada;
                }
                if (this.greentopClassifyData.currentClasificare.descarcare.endPerioada){
                    this.greentopClassifyData.currentClasificare.endPerioada = this.greentopClassifyData.currentClasificare.descarcare.endPerioada;
                }
                this.setAniPerioada();
            }).catch((reason) => {
                this.greentopClassifyData.errorMessage = "Eroare interogare descarcare";
                this.$log.error("Eroare interogare descarcare: ", getExMessage(reason));
            }).finally(() => {
                this.greentopClassifyData.loadingActive = false;
            })
    }

    public onChangeAntrenament() {
        if (this.greentopClassifyData.selectedAntrenament) {
            ///
            //
            this.greentopClassifyData.errorMessage = "";
            this.greentopClassifyData.loadingActive = true;
            //
            this.mapCtrl.greentopDataService.getAntrenamentModelClasificare(this.greentopClassifyData.selectedAntrenament.id)
                .then((data) => {
                    if (data.id == null) {
                        throw Error("Antrenament nu exista");
                    }
                    this.greentopClassifyData.currentClasificare.antrenament = data;
                    //
                    this.setAniPerioada();
                    //
                }).catch((reason) => {
                    this.greentopClassifyData.errorMessage = "Eroare interogare antrenament";
                    this.$log.error("Eroare interogare antrenament ", getExMessage(reason));
                }).finally(() => {
                    this.greentopClassifyData.loadingActive = false;
                })
        }
    }
    public onClickZoneItem(zone: IClasificareZonaRezultat) {
        try {
            if (zone) {
                if (zone.feature) {
                    let geom = zone.feature.getGeometry().getExtent();
                    this.mapCtrl.map.getView().setCenter(olExtent.getCenter(geom));
                }
            }
        } catch (error) {
            this.$log.error("Eroare centrare pe locatie: ", getExMessage(error));
        }
    }

    public onSaveClassify() {
        this.greentopClassifyData.errorMessage = "";
        try {
            
            if (this.greentopClassifyData.currentClasificare.denumire == null
                || this.greentopClassifyData.currentClasificare.denumire == "") {
                throw Error("Denumire este obligatoriu");
            }
            if (this.greentopClassifyData.currentClasificare.geom == null) {
                throw Error("Nu exista zona de selectie");
            }
            if (this.greentopClassifyData.currentClasificare.descarcare == null
                || this.greentopClassifyData.currentClasificare.descarcare.id == null) {
                throw Error("Descarcare date satelitare este obligatoriu");
            }
            if (this.greentopClassifyData.currentClasificare.startPerioada == null
                || this.greentopClassifyData.currentClasificare.startPerioada === "") {
                throw Error("Inceput perioada nu este specificat");
            }
            if (this.greentopClassifyData.currentClasificare.antrenament.idStructuraDate === 2){
               this.setStartEndClsDates();
            }
            if (this.greentopClassifyData.currentClasificare.endPerioada == null
                || this.greentopClassifyData.currentClasificare.endPerioada === "") {
                throw Error("Sfarsit perioada nu este specificat");
            }
            this.checkClsDatesVsDesDates(
                this.greentopClassifyData.currentClasificare.startPerioada,
                this.greentopClassifyData.currentClasificare.endPerioada,
                this.greentopClassifyData.currentClasificare.descarcare.startPerioada,
                this.greentopClassifyData.currentClasificare.descarcare.endPerioada,
            )
            if (this.greentopClassifyData.currentClasificare.antrenament == null
                || this.greentopClassifyData.currentClasificare.antrenament.id == null) {
                throw Error("Antrenament model clasificare este obligatoriu");
            }
            this.checkIndiciDescrcareAntrenament(this.greentopClassifyData.currentClasificare.descarcare,
                this.greentopClassifyData.currentClasificare.antrenament);
            //
            let geomwgs = (this.greentopClassifyData.currentClasificare.geom as Polygon).clone().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326') as any;
            let wkt = new WKTFormater();

            let sentData: IClasificareDateSatelitare = {
                denumire: this.greentopClassifyData.currentClasificare.denumire,
            descriere: this.greentopClassifyData.currentClasificare.descriere,
            startPerioada: this.greentopClassifyData.currentClasificare.startPerioada,
            endPerioada: this.greentopClassifyData.currentClasificare.endPerioada,
            geom: wkt.writeGeometry(geomwgs as Polygon),
            idDescarcare: this.greentopClassifyData.currentClasificare.descarcare.id,
            idAntrenament: this.greentopClassifyData.currentClasificare.antrenament.id,
            status: "initiat",
            mesajStatus: "",
            }
            this.greentopClassifyData.loadingActive = true;
            //
            this.mapCtrl.greentopDataService.setSalvareClasificareDateSatelitare(sentData)
            .then((result)=>{
                //to do switch to existente
                if (typeof result === "number"){
                    return result;
                }
                return null;
            }).catch((reason)=>{
                this.greentopClassifyData.errorMessage = "Eroare salvare planificare clasificare";
                this.$log.error("Erare salvare clasificare: ", getExMessage(reason));
            }).finally(()=>{
                this.greentopClassifyData.loadingActive = false;
            }).then((idClasificare)=>{
                if (typeof idClasificare === "number"){
                    this.greentopClassifyData.activeNewTab0 = false;
                    this.greentopClassifyData.activeExistsTab1 = true;
                    this.greentopClassifyData.activeTab = 1;
                    this.initExistingClassifyData(idClasificare as any);
                }
            }).catch((reason)=>{
                this.$log.error("Eroare la schimbare tab spre existente: ", getExMessage(reason));
            })
        } catch (error) {
            this.greentopClassifyData.errorMessage = error.message || "";
            return;
        }
    }
    private setStartEndClsDates(){
        if (this.greentopClassifyData.selectedAnPerioada == null){
            throw Error("An start clasificare nu este specificat");
        }
        let startYear = this.greentopClassifyData.selectedAnPerioada;
        let desStartDate = this.greentopClassifyData.currentClasificare.descarcare.startPerioada;
        let desEndDate = this.greentopClassifyData.currentClasificare.descarcare.endPerioada;
        let antStartDate = this.greentopClassifyData.currentClasificare.antrenament.descarcariAntrenament[0].startPerioada;
        let antEndDate = this.greentopClassifyData.currentClasificare.antrenament.descarcariAntrenament[0].endPerioada;
        
        let clsStartDate = antStartDate.clone();
        clsStartDate.set('year', startYear);
        this.greentopClassifyData.currentClasificare.startPerioada = clsStartDate.format(this.greentopClassifyData.optFormatDataPerioada);
        let endYear = startYear + (antEndDate.year() - antStartDate.year());
        let clsEndDate = antEndDate.clone();
        clsEndDate.set('year', endYear);
        this.greentopClassifyData.currentClasificare.endPerioada = clsEndDate.format(this.greentopClassifyData.optFormatDataPerioada);
        this.$log.error("clasificare start perioada: ", this.greentopClassifyData.currentClasificare.startPerioada);
        this.$log.error("clasificare end perioada: ",this.greentopClassifyData.currentClasificare.endPerioada);
        if (clsStartDate > desEndDate) {
            throw Error("Data calculata start clasificare " + this.greentopClassifyData.currentClasificare.startPerioada + " nu lasa sa intersecteze interval descarcare");
        }
        if (clsEndDate < desStartDate){
            throw Error("Data calculata end clasificare " + this.greentopClassifyData.currentClasificare.endPerioada+ "  nu lasa sa intersecteze interval descarcare");
        }
    }
    private checkClsDatesVsDesDates(clsStartP, clsEndP, desStartP, desEndP) {
        try {
            let dclsStartP = moment(clsStartP, this.greentopClassifyData.optFormatDataPerioada);
            let dclsEndP = moment(clsEndP, this.greentopClassifyData.optFormatDataPerioada);
            if (dclsStartP > dclsEndP) {
                throw Error("Inceput perioada mai mare decat sfarsit perioada");
            }
            if (dclsEndP < desStartP){
                throw Error("Sfarsit perioada nu permite intersectia cu perioada descarcare");
            }

            if (dclsStartP > desEndP){
                throw Error("Inceput perioada nu permite intersectia cu perioada descarcare");
            }
            
        } catch (error) {
            throw Error("Eroare validare perioada clasficare:" + error.message || '');
        }
    }
    private checkIndiciDescrcareAntrenament(descarcare: IDescarcareDateSatelitare, antrenament: IAntrenamentModelClasificare){
        
        if (descarcare.indiciDescarcare == null || descarcare.indiciDescarcare.length == 0 ){
            throw Error("nu sunt indici descarcare specificati");
        }
        if (antrenament.indiciAntrenament == null || antrenament.indiciAntrenament.length == 0 ){
            throw Error("nu sunt indici antrenament specificati");
        }
        let missingIndici = "";
        antrenament.indiciAntrenament.forEach((aitem)=>{
           let resindFil = descarcare.indiciDescarcare.filter((fitem)=>aitem.codIndice === fitem.codIndice);
           if (resindFil == null || resindFil.length == 0){
            missingIndici += " " + aitem["codIndice"];
           }
        });
        if (missingIndici.length > 0){
            throw Error("lipsesc indici antrenament din descarcare: " + missingIndici)
        }
    }

    public onChangeMomentDateSatelitare() {
        let tmpMoment = "1000-01-01";// no data
        if (this.greentopClassifyData.selectedMomentDateSatelitare && this.greentopClassifyData.selectedMomentDateSatelitare.length > 0) {
            tmpMoment = this.greentopClassifyData.selectedMomentDateSatelitare;
        }
        let tmpClsMoment = tmpMoment;
        if (this.greentopClassifyData.currentClasificare.antrenament.idStructuraDate === 2
            && this.greentopClassifyData.currentClasificare.startPerioada){
              let startClasif =  this.greentopClassifyData.currentClasificare.startPerioada as Moment;
              startClasif.set('month', 0);
              startClasif.set('date', 1);
              tmpClsMoment = startClasif.format(this.greentopClassifyData.optFormatDataPerioada);
        }
        
        this.setMomentOnTileLayer(this.greentopClassifyData.resultTileLayer, tmpClsMoment);


        this.greentopClassifyData.currentClasificare.antrenament.indiciAntrenament.forEach((indInd) => {
            if (indInd.stratAsociat && indInd.stratAsociat.internalLayer) {
                this.setMomentOnTileLayer(indInd.stratAsociat, tmpMoment);
            }
        })

    }
    onClickNextMomentDateSatelitare(){
        if (this.greentopClassifyData.selectedMomentDateSatelitare == null 
            && this.greentopClassifyData.availableMomenteDateSatelitare.length > 0){
                this.greentopClassifyData.selectedMomentDateSatelitare =   this.greentopClassifyData.availableMomenteDateSatelitare[0];
                this.onChangeMomentDateSatelitare();
                return;
        } else if (this.greentopClassifyData.selectedMomentDateSatelitare.length > 0 
            && this.greentopClassifyData.availableMomenteDateSatelitare.length > 0){
                let indCurrent = this.greentopClassifyData.availableMomenteDateSatelitare.indexOf(this.greentopClassifyData.selectedMomentDateSatelitare);
                if (indCurrent >= 0 && indCurrent < this.greentopClassifyData.availableMomenteDateSatelitare.length - 1){
                    this.greentopClassifyData.selectedMomentDateSatelitare = this.greentopClassifyData.availableMomenteDateSatelitare[indCurrent + 1];
                    this.onChangeMomentDateSatelitare();
                }
            }
    }
    onClickPrevMomentDateSatelitare(){
        if (this.greentopClassifyData.selectedMomentDateSatelitare == null 
            && this.greentopClassifyData.availableMomenteDateSatelitare.length > 0){
                this.greentopClassifyData.selectedMomentDateSatelitare =   this.greentopClassifyData.availableMomenteDateSatelitare[this.greentopClassifyData.availableMomenteDateSatelitare.length - 1];
                this.onChangeMomentDateSatelitare();
                return;
        } else if (this.greentopClassifyData.selectedMomentDateSatelitare.length > 0 
            && this.greentopClassifyData.availableMomenteDateSatelitare.length > 0){
                let indCurrent = this.greentopClassifyData.availableMomenteDateSatelitare.indexOf(this.greentopClassifyData.selectedMomentDateSatelitare);
                if (indCurrent > 0 && indCurrent <= this.greentopClassifyData.availableMomenteDateSatelitare.length){
                    this.greentopClassifyData.selectedMomentDateSatelitare = this.greentopClassifyData.availableMomenteDateSatelitare[indCurrent - 1];
                    this.onChangeMomentDateSatelitare();
                }
            }
    }
    private setMomentOnTileLayer(layer: ILayer, moment: string){
        let source = (layer.internalLayer as TileLayer<any>).getSource() as TileWMSSource;
            let params = source.getParams();
            params['time'] = moment;//time=2009-11-01
            source.updateParams(params);
            source.changed();
    }

    private setStyleOnTileLayer(layer: ILayer, style: string){
        let source = (layer.internalLayer as TileLayer<any>).getSource() as TileWMSSource;
        let params = source.getParams();
        params['STYLES'] = style;//
        params['exceptions'] = 'application/vnd.ogc.se_inimage';
        source.updateParams(params);
        source.changed();
    }
    
    //
    public onChangeStilClasificare(){
        if(this.greentopClassifyData.selectedStil){
            let tmpStil = this.greentopClassifyData.selectedStil.denumire;
            if (this.greentopClassifyData.selectedStil.id === -1){
                tmpStil = "";
            }
           this.setStyleOnTileLayer(this.greentopClassifyData.resultTileLayer, tmpStil);
        }
    }

    //
    //layers
    //
    public addSelectLayer(){
        this.greentopClassifyData.selectLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false }),
            style: this.clsStyle
        });
        this.mapCtrl.map.addLayer(this.greentopClassifyData.selectLayer);
    }
    public addSelectLayerInteraction() {
        if (this.greentopClassifyData.selectLayer == null){
            this.addSelectLayer;
        }
        this.greentopClassifyData.selectDrawInteraction = new Draw({
            source: this.greentopClassifyData.selectLayer.getSource(),
            type: 'Polygon',
            //geometryFunction: Draw.createBox()
        });
        this.mapCtrl.map.addInteraction(this.greentopClassifyData.selectDrawInteraction);
        this.greentopClassifyData.selectDrawInteraction.on('drawend', this.onDrawSelectBoxEnd);
        this.greentopClassifyData.selectDrawInteraction.on('drawstart', this.onDrawSelectBoxStart);
    }
    public removeSelectLayer(){
        if (this.greentopClassifyData.selectLayer) {
            this.mapCtrl.map.removeLayer(this.greentopClassifyData.selectLayer);
            this.greentopClassifyData.selectLayer = null;
        }
    }
    public removeSelectLayerInteraction() {
        if (this.greentopClassifyData.selectDrawInteraction) {
            this.mapCtrl.map.removeInteraction(this.greentopClassifyData.selectDrawInteraction);
            this.greentopClassifyData.selectDrawInteraction = null;
        }
    }
    public addResultLayer(){
        this.greentopClassifyData.resultLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false }),
            style: this.resultStyle
        });
        this.mapCtrl.map.addLayer(this.greentopClassifyData.resultLayer);
    }
    public removeResultLayer(){
        if (this.greentopClassifyData.resultLayer) {
            this.mapCtrl.map.removeLayer(this.greentopClassifyData.resultLayer);
            this.greentopClassifyData.resultLayer = null;
        }
    }
     //
     public addDownloadAreaLayer() {
        this.greentopClassifyData.downloadAreaLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false }),
            style: this.desStyle,
        });
        this.mapCtrl.map.addLayer(this.greentopClassifyData.downloadAreaLayer);
    }
    //
    public removeDownloadAreaLayer() {
        if (this.greentopClassifyData.downloadAreaLayer) {
            this.mapCtrl.map.removeLayer(this.greentopClassifyData.downloadAreaLayer);
            this.greentopClassifyData.downloadAreaLayer = null;
        }
    }
    public addResultTileLayer() {
        //
            this.greentopClassifyData.resultTileLayer = {
                id: -10,
                featureType: featureType.tile,
                name: "Resultat clasificare",
                projection: "EPSG:4326",
                internalLayer: null,
                visible: false,
                defaultIndex: 1000,
            } as any
            let sursaDate = this.greentopClassifyData.currentClasificare.descarcare.sursaDate;
        //
        var tileLayer = new TileLayer<any>({
            source: new TileWMSSource({
                url: AppSettings.serverPath + '/greentop/load-wms-clasify-result/' + this.greentopClassifyData.currentClasificare.antrenament.id,
                params: {
                    'format': 'image/png',
                    'VERSION': '1.1.1',
                    'sursaDate': sursaDate
                },
                tileLoadFunction: this.mapCtrl.mapOlInit.customLoadImageFromServer(this.greentopClassifyData.resultTileLayer),
                projection: this.greentopClassifyData.resultTileLayer.projection,
                serverType: 'geoserver'
            })
        });
        this.greentopClassifyData.resultTileLayer.internalLayer = tileLayer;
        tileLayer[MapController.appLayer] = this.greentopClassifyData.resultTileLayer;
        tileLayer.setZIndex(this.greentopClassifyData.resultTileLayer.defaultIndex);
        this.greentopClassifyData.resultTileLayer.internalLayer.setVisible(this.greentopClassifyData.resultTileLayer.visible);
        this.mapCtrl.map.addLayer(this.greentopClassifyData.resultTileLayer.internalLayer)
        //
        this.mapCtrl.mapCtrlLayerIsLoading.addEventsForTileLoadingState(this.greentopClassifyData.resultTileLayer);
    }
    public removeResultTileLayer(){
        if (this.greentopClassifyData.resultTileLayer && this.greentopClassifyData.resultTileLayer.internalLayer) {
            this.mapCtrl.map.removeLayer(this.greentopClassifyData.resultTileLayer.internalLayer);
            this.greentopClassifyData.resultTileLayer.internalLayer = null;
        }
    }

    
    //
    public onDrawSelectBoxStart = (event) => {
        this.greentopClassifyData.selectLayer.getSource().clear();
        this.greentopClassifyData.currentClasificare.geom = null;
    }

    public onDrawSelectBoxEnd = (event) => {
        let extentFeature = event.feature as Feature;
        this.greentopClassifyData.currentClasificare.geom = extentFeature.getGeometry() as any || null;
        this.greentopClassifyData.selectDrawInteraction.setActive(false);
        // to do get extent list details
        if (extentFeature) {
            let extentPolygon = extentFeature.getGeometry() as Polygon;
            
        }
    }
    public onAnimateResultTileLayer(){
        //activate strat asociat
        try {
            if (this.greentopClassifyData.resultTileLayer.visible === false) {
                this.greentopClassifyData.resultTileLayer.visible = true;
                this.mapCtrl.mapMenuLayers.showHideLayer(null, this.greentopClassifyData.resultTileLayer);
            }
            //
           // this.mapCtrl.mapCtrlAnimateTimeRaster.onAnimateTimeRasterAction(this.greentopClassifyData.resultTileLayer, this.greentopClassifyData.availableMomenteDateSatelitare);
           let dtst =  new Date( this.greentopClassifyData.currentClasificare.descarcare.startPerioada);
            let anDatestart = `${dtst.getFullYear()}-01-01`;
            this.mapCtrl.mapCtrlAnimateTimeRaster.onAnimateTimeRasterAction(this.greentopClassifyData.resultTileLayer, [anDatestart]);
        } catch (error) {
            this.$log.error("Eroare animatie rezultat raster", getExMessage(error));
        }

    }

    public canRestartClasificare(){
        if (this.greentopClassifyData.currentClasificare.actiune
            && this.greentopClassifyData.currentClasificare.actiune.toLowerCase() === TaskActiune.start) {
            return false;
        } else {
            return this.greentopClassifyData.currentClasificare.status.toLowerCase() !== TaskStatus.in_lucru;
                //&& this.greentopClassifyData.currentClasificare.status.toLowerCase() !== TaskStatus.finalizat;
        }
     }

     public onRestartClasificare(){
        if (this.greentopClassifyData.currentClasificare.status && 
            this.greentopClassifyData.currentClasificare.status.toLowerCase() === TaskStatus.finalizat){
            if (confirm("Confirmă că dorești sa repornești clasificarea de la început") === false){
                return;
            }
        }
        this.greentopClassifyData.errorMessage = "";
        this.greentopClassifyData.loadingActive = true;
            this.mapCtrl.greentopDataService.setNotificareClasificareDateSatelitare(this.greentopClassifyData.currentClasificare.id, null)
            .then((result)=>{
                //
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare restart clasificare");
            }).finally(()=>{
                this.onChangeClasificare();
            })
     }

     public canStopClasificare(){
        if (this.greentopClassifyData.currentClasificare.actiune
            && this.greentopClassifyData.currentClasificare.actiune.toLowerCase() === TaskActiune.stop) {
            return false;
        } else if (this.greentopClassifyData.currentClasificare.actiune
            && this.greentopClassifyData.currentClasificare.actiune.toLowerCase() === TaskActiune.start
            && this.greentopClassifyData.currentClasificare.status.toLowerCase() === TaskStatus.initiat) {
                return true;
        } else {
            return this.greentopClassifyData.currentClasificare.status.toLowerCase() === TaskStatus.in_lucru
        }
     }

     public onStopClasificare(){
        this.greentopClassifyData.errorMessage = "";
        this.greentopClassifyData.loadingActive = true;
            this.mapCtrl.greentopDataService.setNotificareClasificareDateSatelitare(this.greentopClassifyData.currentClasificare.id, {isStop: true})
            .then((result)=>{
                //
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare restart clasificare");
            }).finally(()=>{
                this.onChangeClasificare();
            })
     }
     //
     private configAutorefresh(): void {
         //init interval values
        this.greentopClassifyData.autorefreshInterval = -1;
        let indexOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.auto_refresh_interval, authAs.control_grentop_cl_button_visible, authType.object);
        if (indexOption && indexOption.idItem) {
            this.greentopClassifyData.autorefreshInterval = indexOption.idItem;
            if (this.greentopClassifyData.autorefreshInterval < 3) { this.greentopClassifyData.autorefreshInterval = 3; }
            if (this.greentopClassifyData.autorefreshInterval > 30) { this.greentopClassifyData.autorefreshInterval = 30; }
            this.greentopClassifyData.autorefreshTimeout = this.greentopClassifyData.autorefreshInterval;
        }
        //init interval instance
        if (this.greentopClassifyData.resIntervalRef == null){
         this.greentopClassifyData.resIntervalRef = setInterval(
             ()=>{
                try {
                    if (this.greentopClassifyData.autorefreshInterval > 0 ) {
                        
                        //
                        if (this.greentopClassifyData.autorefreshTimeout <= 0) {
                            if (this.greentopClassifyData.autorefreshInterval > 0) {
                                this.greentopClassifyData.autorefreshTimeout = this.greentopClassifyData.autorefreshInterval;
                            }
                            //toto refresh
                            if (this.greentopClassifyData.activeExistsTab1 
                                && this.greentopClassifyData.activeDataTab === 0
                                && this.greentopClassifyData.currentClasificare.status !== "finalizat") {
                                this.onChangeClasificare();
                            }
                            
                        } else {
                            if (this.greentopClassifyData.activeExistsTab1 
                                && this.greentopClassifyData.activeDataTab === 0) {
                                this.greentopClassifyData.autorefreshTimeout = this.greentopClassifyData.autorefreshTimeout - 1;
                            }
                        }
                    }
                } catch (error) {
                    this.$log.error("eroare refresh :", getExMessage(error));
                }
             }, 1000
            )
        } else {
            this.clearRefreshTimeout();
        }
     }

     private clearRefreshTimeout(){
         if (this.greentopClassifyData.resIntervalRef && this.greentopClassifyData.autorefreshInterval > 0){
            this.greentopClassifyData.autorefreshTimeout = this.greentopClassifyData.autorefreshInterval;
         }
     }

     public showHideIndiceLayer(indice){
        this.mapCtrl.mapMenuLayers.showHideLayer(null, indice.stratAsociat);
    }
    public showHideLayer(layer){
        this.mapCtrl.mapMenuLayers.showHideLayer(null, layer);
    }
}