<div class="button_wrapper" *ngIf="mapCtrl.showEditFeature || mapCtrl.showAddFeature" >
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlEditFeature.saveGeometry()"
        *ngIf="mapCtrl.showEditFeature">Salvează</button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlEditFeature.addEditFeatureInfo()"
        [disabled]="mapCtrl.newFeature == null" *ngIf="mapCtrl.showAddFeature">Adaugă informații</button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlEditFeature.cancelSaveGeometry()">Renunță</button>
    <span class="glyphicon glyphicon-info-sign fa-lg" 
        ngbPopover="{{mapCtrl.mapCtrlEditFeature.infoAdd}}"
        popoverTitle="Info"
        popoverClass="text-dark"
        triggers="mouseenter:mouseleave"
        *ngIf="mapCtrl.showAddFeature"></span>
    <span class="glyphicon glyphicon-info-sign fa-lg" 
        ngbPopover="{{mapCtrl.mapCtrlEditFeature.infoEdit}}"
        popoverTitle="Info"
        popoverClass="text-dark"
        triggers="mouseenter:mouseleave"
        *ngIf="!mapCtrl.showAddFeature"></span>

</div>
<div class="button_wrapper" *ngIf="mapCtrl.routeShowEdit !== 'hide'" >
    <div class="form-group">
        <label class="checkbox" title="zona limitata la judetul atribuit clientului">
            <input type="checkbox"
                [(ngModel)]="mapCtrl.restrictByClientArea" 
                [disabled]="mapCtrl.routeShowEdit !== 'show'" />Zona limitată
            pe client</label>
    </div>
    <div class="form-group">
        <ng-select name="ui_restrictypeselect" 
        [clearable]="false"
        title="restrictioneaza strazile prin aplicare coeficient maxim dat de zona in care se afla sectiunea de drum  "
        [(ngModel)]="mapCtrl.restrictTypeSelected"
        [disabled]="mapCtrl.routeShowEdit !== 'show'"
        placeholder="Selectează ...">
            <ng-option *ngFor="let itemSd of mapCtrl.restrictTypeList "
            [value]="itemSd">{{itemSd.text}}
            </ng-option>
        </ng-select>
    </div>
    <div class="form-group">
        <ng-select name="ui_routingtype" 
        [clearable]="false"
        title="Mersul pe jos nu tine cont de sensuri unice spre deosebire de mersul cu masina  "
        [(ngModel)]="mapCtrl.routingTypeSelected"
        [disabled]="mapCtrl.routeShowEdit !== 'show'"
        placeholder="Selectează ...">
            <ng-option *ngFor="let itemSd of mapCtrl.routingTypeList "
            [value]="itemSd">{{itemSd.text}}
            </ng-option>
        </ng-select>
    </div>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlTransportRoute.startGenerateRoute(false)"
        [disabled]="mapCtrl.routeShowEdit !== 'show'" title="pastreaza ordinea locatilor">Generează ruta
    </button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlTransportRoute.startGenerateRoute(true)"
        [disabled]="mapCtrl.routeShowEdit !== 'show'" title="ordinea optimizata fata de prima locatie">Generează
        ruta TSP</button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlTransportRoute.cancelGenerateRoute()"
        [disabled]="mapCtrl.routeShowEdit === 'disable'">Renunță</button>
</div>
<div class="button_wrapper" *ngIf="mapCtrl.routeAnimate !== false" >
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateRoute.animateStepBack()"><i
            class="fa fa-step-backward" aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateRoute.animatePlay()"><i class="fa fa-play"
            aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateRoute.animatePause()"><i class="fa fa-pause"
            aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateRoute.animateStop()"><i class="fa fa-stop"
            aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateRoute.animateStepForward()"><i
            class="fa fa-step-forward" aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateRoute.cancelRouteAnimate()"><i
            class="fa fa-close" aria-hidden="true"></i></button>
    <input type="range" value="1" min="1" max="10" style="width: 10%" [(ngModel)]="mapCtrl.animate.sliderValue"
        (change)="mapCtrl.mapCtrlAnimateRoute.sliderChanged()" />
</div>
<div class="info_wrapper" *ngIf="mapCtrl.timeRasterAnimate !== false" >
    <div>
        <!--start-->
        <div class="animate-raster-existing-mds-combo">
            <ng-select name="ui_animrastercmbst" required
            [clearable]="false" class="cmbanimrasterst"
            [(ngModel)]="mapCtrl.animateTimeRasterData.startInterval"
            (change)="mapCtrl.mapCtrlAnimateTimeRaster.onChangeComboStartInterval($event)"
            placeholder="Selectează moment START">
                <ng-option *ngFor="let item of mapCtrl.animateTimeRasterData.startSteps"
                [value]="item">{{item}}
                </ng-option>
            </ng-select>

        </div>
    </div>

    <div>
        <!-- <div class="text-shadow-map">{{mapCtrl.animateTimeRasterData.info}}</div> -->
        <div>
            <div class="animate-raster-existing-mds-combo">
                <ng-select name="ui_animrastercmbsel" required
                [clearable]="false"
                [(ngModel)]="mapCtrl.animateTimeRasterData.info"
                (change)="mapCtrl.mapCtrlAnimateTimeRaster.onChangeComboTime($event)"
                placeholder="Selectează moment ACTIV">
                    <ng-option *ngFor="let item of mapCtrl.animateTimeRasterData.selectSteps"
                    [value]="item">{{item}}
                    </ng-option>
                </ng-select>
    
            </div>
        </div>
    </div>
    <div>
        <!--stop-->
        <div class="animate-raster-existing-mds-combo">
            <ng-select name="ui_animrastercmbend" required 
            [clearable]="false" class="cmbanimrasterend"
            [(ngModel)]="mapCtrl.animateTimeRasterData.endInterval"
            (change)="mapCtrl.mapCtrlAnimateTimeRaster.onChangeComboEndInterval($event)"
            placeholder="Selectează moment STOP">
                <ng-option *ngFor="let item of mapCtrl.animateTimeRasterData.endSteps"
                [value]="item">{{item}}
                </ng-option>
            </ng-select>
        </div>
    </div>
    
</div>
<div class="button_wrapper" *ngIf="mapCtrl.timeRasterAnimate !== false" >
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateTimeRaster.animateStepBack()"
        [disabled]="mapCtrl.animateTimeRasterData.isAnimating"><i class="fa fa-step-backward"
            aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateTimeRaster.animatePlay()"
        [disabled]="mapCtrl.animateTimeRasterData.isAnimating"><i class="fa fa-play"
            aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateTimeRaster.animatePause()"><i class="fa fa-pause"
            aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateTimeRaster.animateStop()"><i class="fa fa-stop"
            aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateTimeRaster.animateStepForward()"
        [disabled]="mapCtrl.animateTimeRasterData.isAnimating"><i class="fa fa-step-forward"
            aria-hidden="true"></i></button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlAnimateTimeRaster.animateCancel()"><i
            class="fa fa-close" aria-hidden="true"></i></button>
    <input type="range" value="3" min="1" max="10" style="width: 10%"
        [(ngModel)]="mapCtrl.animateTimeRasterData.sliderValue"
        (change)="mapCtrl.mapCtrlAnimateTimeRaster.sliderChanged()" />
</div>
<div class="button_wrapper" *ngIf="mapCtrl.transportRouteShowEdit !== 'hide'" >
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlTransportRoute.returnToEditTransportRouteStations()"
        title="">Editare
        stații</button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlTransportRoute.generateTransportRoute()"
        title="">Previzualizează ruta</button>
    <button class="btn btn-light" (click)="mapCtrl.mapCtrlTransportRoute.cancelTransportRouteEdit()"
        title="">Renunță </button>
    <span class="glyphicon glyphicon-info-sign fa-lg" 
    ngbPopover="{{mapCtrl.mapCtrlTransportRoute.infoEdit}}"
    popoverTitle="Info"
    popoverClass="text-dark"
    triggers="mouseenter:mouseleave"></span>
</div>