<div class="modal-content">
    <form #editInfoForm="ngForm" novalidate>
        <fieldset [disabled]="disableInput">
            <div class="modal-header">
                <h5 class="modal-title" i18n>Editare informații</h5>
            </div>
            <!--properties-->
            <div class="modal-body" *ngIf="infoOrCamera">
                <div *ngFor="let property of featureProps">
                    <div class="form-group"  *ngIf="property.edit != 'hide'">
                    
                        <label for="name">{{property.name}}</label>
                        <!--text input-->
                        <div  *ngIf="property.control === controlType.text">
                            <input type="text" class="form-control" id="{{property.name}}" name="{{property.name}}" [(ngModel)]="property.value" [readonly]="property.edit == 'read'">
                        </div>
                        <!-- text area-->
                        <div *ngIf="property.control === controlType.textArea">
                            <textarea class="form-control" id="{{property.name}}" name="{{property.name}}" [(ngModel)]="property.value" rows="{{property.rows}}" [readonly]="property.edit == 'read'">{{property.value}}</textarea>
                        </div>
                        <!-- datetime input -->
                        <div class="row" *ngIf="property.control === controlType.dateTimeLocal">
                            <div class="col-12">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-light" [owlDateTimeTrigger]="dt" type="button">
                                            <i class="glyphicon glyphicon-calendar"></i>
                                        </button>
                                    </div>
                                    <input id="{{property.name}}" name="{{property.name}}"
                                    class="form-control"
                                    [owlDateTime]="dt"
                                    [(ngModel)]="property.value"/>
                                    <owl-date-time #dt></owl-date-time>
                                </div>
                            </div>
                        </div>
                        <!-- rate input -->
                        <div *ngIf="property.control === controlType.rate">
                            <!-- <ng-rate-it id="{{property.name}}" name="property.name" [(ngModel)]="property.value" min="property.min" max="property.max" step="property.step" read-only="property.edit == 'read'"></ng-rate-it><span> {{property.value}}</span> -->
                            <ngb-rating id="{{property.name}}"  [(rate)]="property.value" [readonly]="property.edit == 'read'" [max]="property.max">
                                <ng-template let-fill="fill">
                                    <i class="bi-star-fill">★
                                        <i  *ngIf="fill>0" class="bi-star-fill bi-star-filled" [style.width.%]="fill">★</i>
                                    </i>
                                </ng-template>
                            </ngb-rating>
                        </div>
                        <!-- select predefined text input-->
                        <div *ngIf="property.control === controlType.textPredefinit">

                            <ng-select id="{{property.name}}" name="{{property.name}}" 
                                    [(ngModel)]="property.value"
                                    i18n-placeholder placeholder="Selectează sau caută în listă">
                                    <ng-option *ngFor="let item of property.dictionar"
                                        [value]="item">{{item}}</ng-option>
                            </ng-select>
                        </div>

                    </div>
                </div>
            </div>
            <!--camera-->
            <div class="modal-body" [hidden]="infoOrCamera">
                <div class="camera" [hidden]="!cameraOrPicture">
                    <video id="videoCamera" style="width:100%"> </video>
                </div>
                <div class="picture" [hidden]="cameraOrPicture">
                    <img id="pictureCamera" style="width:100%" [src]="pictureToUrl?sanitize(pictureToUrl):' '" alt="Poza nu este disponibila" title="{{pictureName}}"/>
                </div>
                <canvas id="canvasCamera" style="display:none;"></canvas>

                <!-- <fieldset [disabled]="vm.disableInput"> -->
                    <fieldset *ngIf="isEditElseInsert">
                        <label for="" i18n>Listă imagini existente</label>
                        <div class="row">
                            
                            <div class="col-6 ">
                                <span i18n>Nume</span>
                            </div>
                            <div class="col-4 p-0">
                                <span i18n>Status</span>
                            </div>
                            <div class="col-1 p-0">
                                <span i18n>Info</span>
                            </div>
                    
                        </div>
                        <div class="" *ngFor="let loadfile of existingPictures">
                            <div class="row">
                                <div class="col-6 btn" (click)="displayEPicture(loadfile)" title="{{loadfile.id}}">
                                    <span>{{loadfile.fileName}}</span>
                                </div>
                                <div class="col-4 p-0">
                                    <span>{{loadfile.status}}</span>
                                </div>
                                <div class="col-1 p-0">
                                    <span class="glyphicon glyphicon-info-sign" 
                                    ngbPopover="{{loadfile.description}}"  
                                    popoverTitle="Info"
                                    popoverClass="text-dark"
                                    triggers="mouseenter:mouseleave"
                                        
                                        ></span><!-- title="{{loadfile.message}}" -->
                                </div>
                                <div class="col-1 p-0">
                                    <button type="button" name="btnRemoveFile" class="btn btn-xs btn-light "
                                        (click)="removeExistingPicture(loadfile)"> x </button>
                                </div>
                        
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                    <label for="" i18n>Listă imagini pentru incărcat</label>
                    <div class="row">
                        
                        <div class="col-6 ">
                            <span i18n>Nume</span>
                        </div>
                        <div class="col-4 p-0">
                            <span i18n>Status</span>
                        </div>
                        <div class="col-1 p-0">
                            <span i18n>Info</span>
                        </div>
                
                    </div>
                    <div class="" *ngFor="let loadfile of loadPictures">
                        <div class="row">
                            <div class="col-6 btn" (click)="displayLPicture(loadfile)" title="{{loadfile.id}}">
                                <span>{{loadfile.fileName}}</span>
                            </div>
                            <div class="col-4 p-0">
                                <span>{{loadfile.status}}</span>
                            </div>
                            <div class="col-1 p-0">
                                <span class="glyphicon glyphicon-info-sign" 
                                ngbPopover="{{loadfile.description}}"  
                                popoverTitle="Info"
                                popoverClass="text-dark"
                                triggers="mouseenter:mouseleave"
                                    
                                    ></span><!-- title="{{loadfile.message}}" -->
                            </div>
                            <div class="col-1 p-0">
                                <button type="button" name="btnRemoveFile" class="btn btn-xs btn-light "
                                    (click)="removeLoadPicture(loadfile)"> x </button>
                            </div>
                    
                        </div>
                    </div>
                    <div>
                        <div class="form-group" >
                            <label for="asset" i18n>Selecție imagini</label>
                            
                            <input type="file" class="form-control" id="fileList" name="fileList" accept=".jpg,.png,.jpeg,.gif" multiple (change)="multiFileSelected($event)">
                            <span *ngIf="editInfoForm.controls.fileList?.touched">
                                <span *ngIf="editInfoForm.controls.fileList?.errors['validFile']" i18n>Selectați imagini</span>
                            </span>
                        </div>
    
                    </div>
                </fieldset>
            </div>
            <div class="form-group">
                <span *ngIf="errorResponse">
                    <span>{{errorResponse}}</span>
                </span>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light"  *ngIf="cameraOrPicture" (click)="stopCamera()" i18n>Stop</button>
                <button type="button" class="btn btn-light"  *ngIf="!infoOrCamera" (click)="camerOrPictureChange()">
                    <span *ngIf="cameraOrPicture == true" i18n>Pozează</span>
                    <span *ngIf="cameraOrPicture == false" i18n>Start camera</span>
                </button>
                <button type="button" class="btn btn-light"  *ngIf="propertyPictureName" (click)="infoOrCameraChange()">
                    <span *ngIf="infoOrCamera == true" i18n>Imagini</span>
                    <span *ngIf="infoOrCamera == false" i18n>Informații</span>
                </button>
                <button type="button" class="btn btn-light" (click)="save()" [disabled]="!editInfoForm.valid" i18n>Salvează</button>
                <button type="button" class="btn" (click)="cancel()" i18n>Închide</button>
            </div>
        </fieldset>
    </form>
</div>