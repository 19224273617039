//NG
import { ILayer, formatDateTime, IFeatureInfo, authOpt, authType } from "../../definitions";
import { IUserSettingsService, UserSettingsService } from "../../services/user-settings-service";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NGXLogger } from "ngx-logger";
import { isNumber } from "../../map/map-utils";


import Feature from 'ol/Feature';


@Component({
    selector: 'info-feature-images',
    providers: [],
    templateUrl: './info-connected-features.component.html',
    styleUrls: ['./info-connected-features.component.css']
})
export class InfoConnectedFeaturesComponent implements OnInit {
    //
    public raportFeature: Feature;
    public connectedFeatures: Array<Feature>;
    public raportLayer: ILayer;
    public connectedLayer: ILayer;
    public selectedFeature: Feature;
    public selectedFeatureId: number;
    public connectedFeaturesCount: number;

    //
    public controlType = {
        text: "text",
        textArea: "textArea",
        dateTimeLocal: "dateTimeLocal",
        rate: "rate"
    }

    public dateFormat = formatDateTime.date;
    public timeFormat = formatDateTime.time;
    public dateTimeFormat = formatDateTime.dateTime
    //
    public raportFeatureProps: Array<IFeatureInfo>;
    public connectedFeatureProps: Array<IFeatureInfo>;
    public errorResponse: string = '';
    //
    @Input() data: any;
    //
    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
        //private $scope: any,
        //private $log: ng.ILogService
        // private $q: angular.IQService,
        //private moment: any
        @Inject(UserSettingsService) private userSettingsSrvs: IUserSettingsService) {

    }
    //
    public ngOnInit(): void {
        this.raportFeatureProps = []
        this.connectedFeatureProps = [];
        //
        if (("raportFeature" in this.data) && ("connectedFeatures" in this.data)
            && ("raportLayer" in this.data) && ("connectedLayer" in this.data)) {
            this.raportFeature = <Feature>this.data["raportFeature"];
            this.raportLayer = <ILayer>this.data["raportLayer"];
            this.connectedFeatures = <Array<Feature>>this.data["connectedFeatures"];
            this.connectedLayer = <ILayer>this.data["connectedLayer"];
            this.buildFeaturesInfo(this.raportLayer, this.raportFeature, this.raportFeatureProps);
            if (this.connectedFeatures.length > 0) {
                this.connectedFeaturesCount = this.connectedFeatures.length;
                this.selectedFeature = this.connectedFeatures[0];
                this.selectedFeatureId = 0;
                this.buildFeaturesInfo(this.connectedLayer, this.selectedFeature, this.connectedFeatureProps);
            }
        } else {
            this.$log.warn("elementul, lista de elemente sau straturile nu exista");
            // this.activeModal.close(false);
        }
    }

    private buildFeaturesInfo(layer: ILayer, feature: Feature, featureProps: Array<IFeatureInfo>) {
        let props: Array<IFeatureInfo> = layer.infoColumns;
        props.forEach((propItem: IFeatureInfo) => {

            let inInfo = this.userSettingsSrvs.isAuthForItemOption(authOpt.in_info_connected_features,
                layer.name, propItem.name, authType.layer);
            if (inInfo == null || inInfo != "false") {
                let propValue = feature.get(propItem.name) || '';
                let featureProp: IFeatureInfo =
                {
                    name: propItem.name,
                    type: propItem.type,
                    control: this.controlType.text,
                    value: propValue
                };
                // check if prop is datetime local
                let str = this.userSettingsSrvs.isAuthForItemOption(authOpt.input_text_area, layer.name, propItem.name, authType.layer);
                if (str && str.length > 0) {
                    featureProp.control = this.controlType.textArea;
                    featureProp['rows'] = 10;
                } else {
                    // check if prop is rate input
                    let ra = this.userSettingsSrvs.isAuthForItemOption_Name_FullInfo(authOpt.input_rate_item, layer.name, propItem.name, authType.layer);
                    if (ra && ra.idItem > -1) {
                        featureProp.control = this.controlType.rate;
                        featureProp['min'] = 0;
                        featureProp['max'] = 5;
                        featureProp['step'] = 0.2;

                        //check settings for rate
                        try {
                            let minSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_min, layer.name, ra.idItem, authType.layer);
                            if (minSetting && isNumber(minSetting.descriere)) {
                                featureProp['min'] = Number(minSetting.descriere);
                            }
                            let maxSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_max, layer.name, ra.idItem, authType.layer);
                            if (maxSetting && isNumber(maxSetting.descriere)) {
                                featureProp['max'] = Number(maxSetting.descriere);
                            }
                            let stepSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_step, layer.name, ra.idItem, authType.layer);
                            if (stepSetting && isNumber(stepSetting.descriere)) {
                                featureProp['step'] = Number(stepSetting.descriere);
                            }
                        } catch (e) {
                            this.$log.error("eroare extragere limite rate control")
                        }
                    }
                }

                //
                featureProps.push(featureProp);
            }
        });
    }

    //
    public next(): void {
        let indSel = this.connectedFeatures.indexOf(this.selectedFeature);
        if (this.connectedFeatures.length > 1 && indSel < this.connectedFeatures.length - 1) {
            this.selectedFeature = this.connectedFeatures[indSel + 1];
            this.selectedFeatureId = indSel + 1;
            this.connectedFeatureProps = []
            this.buildFeaturesInfo(this.connectedLayer, this.selectedFeature, this.connectedFeatureProps);
        }
    }
    //
    public prev(): void {
        let indSel = this.connectedFeatures.indexOf(this.selectedFeature);
        if (this.connectedFeatures.length > 1 && indSel > 0) {
            this.selectedFeature = this.connectedFeatures[indSel - 1];
            this.selectedFeatureId = indSel - 1;
            this.connectedFeatureProps = []
            this.buildFeaturesInfo(this.connectedLayer, this.selectedFeature, this.connectedFeatureProps);
        }
    }

    public enablePrev() {
        return this.selectedFeatureId > 0;
    }

    public enableNext() {
        return this.connectedFeatures.length - 1 > this.selectedFeatureId;
    }
    //
    public cancel(): void {
        this.activeModal.close(false);
    }

}
