//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MapController } from '../map-controller';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'map-bottomtop-controlbar',
    providers: [],
    templateUrl: './map-bottomtop-controlbar.component.html',
    styleUrls: ['./map-bottomtop-controlbar.component.css']
})
export class MapBottomtopControlbarComponent implements OnInit, OnChanges {

    @Input("mapctrl") mapCtrl: MapController = null;
    //
    public constructor(
        @Inject(DomSanitizer) private sanitizer:DomSanitizer
        ){
        //
    }
    //
    public ngOnInit(): void {
        //
    }
    //
    public ngOnChanges(changes: SimpleChanges) {
        //
    }

}