
<div class="integrate-landsat-hs-menu integrate-landsat-hs-height" *ngIf="mapCtrl.integrateLandsatHotspotButtonStateOn">
    <div id="integrate-landsat-hs-content">
        <div class="h6 d-flex" (click)="integrateLandsatHotspotData.ctrlSearchIsCollapsed = !integrateLandsatHotspotData.ctrlSearchIsCollapsed"> 
                <div class="flex-fill text-wrap">Identificare <br/> hotspoturi - coolspoturi</div>
                <div>
                    <span class="glyphicon glyphicon-info-sign"
                    ngbPopover="{{infoSearch}}"
                    popoverTitle="Info"
                    popoverClass="text-dark"
                    triggers="mouseenter:mouseleave"
                    placement="bottom" onclick="event.stopPropagation()"
                    ></span>
                </div>
                <div class="">
                    <span class="material-icons integrate-landsat-hs-menu-icon-close-colapse" *ngIf="integrateLandsatHotspotData.ctrlSearchIsCollapsed">arrow_drop_down</span>
                    <span class="material-icons integrate-landsat-hs-menu-icon-close-colapse" *ngIf="!integrateLandsatHotspotData.ctrlSearchIsCollapsed">arrow_drop_up</span>
                </div>
                <div class="" (click)="onClickClose(); $event.stopPropagation()">
                    <span class="material-icons integrate-landsat-hs-menu-icon-close-colapse">close</span>
                </div>
        </div>
        <!--form input-->
        <div #collapse="ngbCollapse" [(ngbCollapse)]="integrateLandsatHotspotData.ctrlSearchIsCollapsed">
            <form #hotspotForm="ngForm">
                <fieldset [disabled]="integrateLandsatHotspotData.loadingActive">
                    <!--selectie moment timp-->
                    <!--*ngIf="integrateLandsatHotspotData.resultTileLayer"-->
                    <div class="form-group">
                        <label for="uiClPlanificate">Moment date satelitare</label>
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <ng-select name="impMomTimp" required [disabled]="integrateLandsatHotspotData.loadingActive"
                                    [(ngModel)]="integrateLandsatHotspotData.selectedMomentDateSatelitare"
                                    (change)="onChangeMomentDateSatelitare()"
                                    placeholder="Selectează sau caută în listă">
                                    <ng-option *ngFor="let momentSat of integrateLandsatHotspotData.availableMomenteDateSatelitare"
                                        [value]="momentSat">{{momentSat}}</ng-option>
                                </ng-select>
                            </div>
            
                            <button class="btn btn-light px-2" (click)="onClickPrevMomentDateSatelitare()">
                                <i class="fa fa-step-backward"></i>
                            </button>
                            <button class="btn btn-light px-2" (click)="onClickNextMomentDateSatelitare()">
                                <i class="fa fa-step-forward "></i>
                            </button>
                        </div>
                    </div>
                    <!--zona analiza-->
                    <div class="form-group">
                        <!-- <label>Zonă </label> -->
                        <div class="input-group">
                            <button class="btn btn-success disabled"
                                [ngClass]="{'btn-warning':integrateLandsatHotspotData.selectedGeom == null}">
                                Zonă selecție
                            </button>
                            <div class="input-group-append ">
                                <button class="btn"
                                    [ngClass]="{'btn-primary':integrateLandsatHotspotData.selectButtonStateOn, 'btn-light': integrateLandsatHotspotData.selectButtonStateOn == false}"
                                    [disabled]="integrateLandsatHotspotData.loadingActive"
                                    (click)="onClickSelectButton()">
                                    <i class="glyphicon glyphicon-edit"></i>
                            </button>
                            </div>
                        </div>
                    </div>
                    <!--impermeabilitate  -->
                    <div class="form-group">
                        <label>Impermeabilitate</label>
                        <div class="">
                            <ng-select name="impImpermeabilitate" required [disabled]="integrateLandsatHotspotData.loadingActive"
                                [(ngModel)]="integrateLandsatHotspotData.selectedImpermeabilitate"
                                placeholder="Selectează sau caută în listă">
                                <ng-option *ngFor="let impSat of integrateLandsatHotspotData.availableImpermeabilitate"
                                    [value]="impSat">{{impSat.denumire}}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <!--prag hotspot-->
                    <div class="form-group">
                        <label>Intensitate hotspot - coolspot</label>
                        <div class="card card-body px-3 py-2 text-dark">
                            <div *ngFor="let prag of integrateLandsatHotspotData.availablePraguri">
                                <label for="enum_answer_{{prag.id}}">
                                    <input id="enum_answer_{{prag.id}}" required [value]='prag' type="radio" name="impPragDet"
                                        [(ngModel)]="integrateLandsatHotspotData.selectedPrag">
                                    {{prag.denumire}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!--nr hotspot-->
                    <div class="form-group">
                        <label>Nr. spoturi</label>
                        <div class="">
                            <input name="impNrHotspoturi" class="form-control" required type="number" min="0" max="1000" step="1"
                                [(ngModel)]="integrateLandsatHotspotData.numarHotspoturi" />
                        </div>
                    </div>
            
            
                </fieldset>
        </form>
        <!--straturi identificare-->
        <div>
            <!-- rezultat identificare imagine -->
            <label> Straturi date</label>
            <div *ngIf="integrateLandsatHotspotData && integrateLandsatHotspotData?.resultTileLayer?.name" class="integrate-landsat-hs-indice-item">
                <div class="integrate-landsat-hs-indice-item-ck">
                    <input type="checkbox" style="zoom:1.5"
                        [(ngModel)]="integrateLandsatHotspotData.resultTileLayer.visible"
                        (change)="onClickResult(integrateLandsatHotspotData.resultTileLayer)" />
                </div>
                <div class="integrate-landsat-hs-indice-item-name">Rezultat hotspot</div>
            </div>
            <div *ngIf="integrateLandsatHotspotData && integrateLandsatHotspotData?.resultTileLayer2?.name" class="integrate-landsat-hs-indice-item">
                <div class="integrate-landsat-hs-indice-item-ck">
                    <input type="checkbox" style="zoom:1.5"
                        [(ngModel)]="integrateLandsatHotspotData.resultTileLayer2.visible"
                        (change)="onClickResult(integrateLandsatHotspotData.resultTileLayer2)" />
                </div>
                <div class="integrate-landsat-hs-indice-item-name">Rezultat coolspot</div>
            </div>
        
            <div class="integrate-landsat-hs-rez-indice-list" *ngFor="let indice of integrateLandsatHotspotData.indiciHotspot">
                <!-- indici hotspot -->
                <div class="integrate-landsat-hs-indice-item">
                    <div *ngIf="indice.stratAsociat"  class="integrate-landsat-hs-rez-indice-item-ck" >
                        <input name="impInd{{indice.id}}" type="checkbox" style="zoom:1.5" [(ngModel)]="indice.stratAsociat.visible"
                            (change)="onClickIndice(indice.stratAsociat)" 
                            />
                    </div>
                    <div *ngIf="indice.stratAsociat == null" class="integrate-landsat-hs-rez-indice-item-ck">  </div>
                    
                    <div class="integrate-landsat-hs-rez-indice-item-name">{{indice.codIndice}}</div>
                     
                </div>
            </div>
        
        </div>
        <div *ngIf="integrateLandsatHotspotData.loadingActive === true && this.integrateLandsatHotspotData.doneActive === false;"class="tranz-spiner-box">
            <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
        </div>
        <div>
            <h6 *ngIf="integrateLandsatHotspotData.errorMessage && integrateLandsatHotspotData.errorMessage.length > 0 " class="text-danger">
                {{integrateLandsatHotspotData.errorMessage}}
            </h6>
        </div>
        <div class="input-group">
            <button class="btn btn-light integrate-landsat-action-wrp-buttons" 
            [disabled]="canContinueHotspot() === false"
            (click)="onStartHotspot(hotspotForm)" title="Pornește"><i class="fas fa-play-circle fa-2x"></i></button>
            <button class="btn btn-light integrate-landsat-action-wrp-buttons" 
            [disabled]="canStopHotspot() === false"
            (click)="onStopHotspot()" title="Oprește"><i class="fas fa-stop-circle fa-2x"></i></button>
            
            <button class="btn btn-light integrate-landsat-action-wrp-buttons ml-auto" 
            [disabled]="canRefreshHotspot() === false"
            (click)="onRefreshHotspot()" title="Reîncarcă rezultat"><i class="fas fa-sync fa-2x"></i></button>
            
        </div>
        </div>

    </div>

</div>