//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppSettings } from '../../app-settings';
import { IPositionData, geocodingLocation, authAs, authOpt, authType, featureType, ITranzitData, ILeg, legMode, ITripPlan, IItinerary, IPlace } from '../../definitions';
import { MapController } from '../map-controller';
import { NgbDatepickerI18n, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateMomentParserFormatter, NgbDateMomentAdapter } from '../../shared-components/ngb-moment-adapter-formater';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import moment, { Moment } from "moment";
import { NGXLogger } from 'ngx-logger';
import { getExMessage } from '../map-utils';

import VectorLayer from 'ol/layer/Vector';
import Modify from 'ol/interaction/Modify';
import Feature from 'ol/Feature';
import Collection from 'ol/Collection';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import LineString from 'ol/geom/LineString';
import * as olExtent from 'ol/extent';
import VectorSource from "ol/source/Vector";
import PolylineFormater from 'ol/format/Polyline';
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import IconStyle from "ol/style/Icon";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olCoordinate from 'ol/coordinate';
import * as olProj from 'ol/proj';

@Component({
    selector: 'map-tranzit',
    providers: [ I18n,
        { provide: NgbDatepickerI18n, 
             useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
        { provide: NgbDateParserFormatter, 
            useFactory: () => { return new NgbDateMomentParserFormatter("YYYY-MM-DD") } },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},],
    templateUrl: './map-tranzit.component.html',
    styleUrls: ['./map-tranzit.component.css']
})
export class MapTranzitComponent implements OnInit, OnChanges {
    //
    @Input("mapctrl") mapCtrl: MapController = null;
    //
    public tranzitData: ITranzitData;
    public infoTranzSearch = `Alegeți pe hartă punctul de pornire și destinația sau introduceți coordonatele GPS ale acestora. Coordonatele introduse trebuie să fie în format grade zecimale, folosind punct pentru separarea zecimalelor și spatiu pentru separarea coordonatelor longitudine și latitudine (ex 23.57588 47.65630). Coordonatele pentru longitudine să fie plasate înaintea celor pentru latitudine. Primul număr din coordonata pentru longitudine să fie cuprins între -180 și 180, iar primul număr din coordonata pentru latitudine să fie cuprins între -90 și 90.
    În secțiunea Moment plecare e necesar ca data și ora introduse să fie valide (data: zz/ll/aaaa; ora: hh/mm).
    În secțiunea Opțiuni se introduce distanța maximă de mers pe jos în cadrul rutelor posibile afișate, exprimată în metri`;
    public infoTranzRute = 'Alegeți una dintre rute pentru a vizualiza detalii despre aceasta'
    //
    public svgPointStop = `<svg viewBox="0 0 384 512" height="30" width="30" focusable="false" role="img" fill="currentColor"
    xmlns="http://www.w3.org/2000/svg" class="">
    <path fill="red"
    d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z">
    </path>
    </svg>`;
    public svgPointStart = `<svg viewBox="0 0 384 512" height="30" width="30" focusable="false" role="img" fill="currentColor"
    xmlns="http://www.w3.org/2000/svg" class="">
    <path fill="green"
    d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z">
    </path>
    </svg>`;
    private styleStartFeature = [new OlStyle({
        image: new IconStyle({
            anchor: [0.5, 1],
            src: 'data:image/svg+xml;utf8,' + this.svgPointStart
            //src: './../img/startFlag.png'
        })
    }),
    new OlStyle({
        text: new TextStyle({
            text: 'Plecare',
            scale: 1.5,
            offsetX: 0.5,
            offsetY: -35,
            fill: new FillStyle({
                color: 'black'
            })
        })
    })];
    private styleStopFeature = [new OlStyle({
        image: new IconStyle({
            anchor: [0.5, 1],
            src: 'data:image/svg+xml;utf8,' + this.svgPointStop
        })
    }),
    new OlStyle({
        text: new TextStyle({
            text: 'Sosire',
            scale: 1.5,
            offsetX: 0.5,
            offsetY: -35,
            fill: new FillStyle({
                color: 'black'
            })
        })
    })];
    private styleWalkLegFeature = new OlStyle({
        stroke: new StrokeStyle({
            color: '#56bc8a',
            width: 4
        })
    })
    private styleBusLegFeature = new OlStyle({
        stroke: new StrokeStyle({
            color: '#0f52ba',
            width: 4
        })
    })
    private styleCarLegFeature = new OlStyle({
        stroke: new StrokeStyle({
            color: '#ffafc1',
            width: 4
        })
    })
    private styleHighlightLeg = new OlStyle({
        stroke: new StrokeStyle({
            color: 'rgba(255, 50, 30, 0.4)',
            width: 10,

        })
    })

    public constructor(
        @Inject(NgbDateParserFormatter) private formaterService: NgbDateParserFormatter,
        @Inject(NGXLogger) private $log: NGXLogger){

    }
    //
    public ngOnInit(): void {
        //
        this.tranzitData = this.mapCtrl.tranzitData;
        this.mapCtrl.tranzitData.funcInit = this.initTranzitTool;
        this.mapCtrl.tranzitData.funcClear = this.clearTranzitTool;
        this.mapCtrl.tranzitData.funcSetStartCoordinates = this.setStartCoordinates;
        this.mapCtrl.tranzitData.funcSetStopCoordinates = this.setStopCoordinates;
    }
    //
    public ngOnChanges(changes: SimpleChanges) {
        //
    }
    
    public onClickTranzitClose(){
        this.mapCtrl.tranzitButtonStateOn = false;
        this.clearTranzitTool();
    }
    //
    

    public initTranzitTool = () => {
        try {
            let client = this.mapCtrl.userSettingsSrvs.getCurrentUser().client;
            if (client && client.formatDate && client.formatDate !== "") {
                this.tranzitData.optFormatDataPlecare = client.formatDate;
                (this.formaterService as NgbDateMomentParserFormatter).setFormat(client.formatDate);
            }
            if (this.tranzitData.stratTranzit == null) {
                this.tranzitData.stratTranzit = new VectorLayer<any>({
                    source: new VectorSource({ wrapX: false })
                })
            }
            this.tranzitData.stratTranzit.getSource().clear();

            this.mapCtrl.map.addLayer(this.tranzitData.stratTranzit);
            //
            if (this.tranzitData.stratLocatii == null) {
                this.tranzitData.stratLocatii = new VectorLayer<any>({
                    source: new VectorSource({ wrapX: false })
                });
            }
            this.tranzitData.stratLocatii.getSource().clear();
            //
            
            
            
            //
            if (this.tranzitData.locatii.start == null) {
                if (this.tranzitData.coordonate.start == null) {
                    this.tranzitData.coordonate.start = [0.0];
                }
                this.tranzitData.locatii.start = new Feature({
                    geometry: new Point(this.tranzitData.coordonate.start as any),
                    name: "locatie plecare"
                })
                this.tranzitData.locatii.start.setStyle(this.styleStartFeature);
            }
            
            if (this.tranzitData.locatii.stop == null) {
                if (this.tranzitData.coordonate.stop == null) {
                    this.tranzitData.coordonate.stop = [0.0];
                }
                this.tranzitData.locatii.stop = new Feature({
                    geometry: new Point(this.tranzitData.coordonate.stop as any),
                    name: "locatie sosire"
                    
                })
                this.tranzitData.locatii.stop.setStyle(this.styleStopFeature);
            }

            //
            this.mapCtrl.map.addLayer(this.tranzitData.stratLocatii);
            //
            if (this.tranzitData.locatiiModifyInteraction.start == null) {
                let modifcollectionstart = new Collection();
                modifcollectionstart.push(this.tranzitData.locatii.start)
                this.tranzitData.locatiiModifyInteraction.start = new Modify({
                    //source: this.tranzitData.stratLocatii.getSource(),
                    features: modifcollectionstart
                } as any);
                
                this.tranzitData.locatiiModifyInteraction.start.on("modifyend",(eventarg: any)=>{
                    this.$log.log("end modif")
                    var features = eventarg.features.getArray() as Array<Feature>;
                    if (features.length = 1){
                        let coord4326 = olProj.transform( (features[0].getGeometry() as Point).getCoordinates(), this.mapCtrl.map.getView().getProjection(),'EPSG:4326')
                        this.setStartCoordinates(coord4326 as any, this.setFromSource.fromMapDrag);
                    }
                });
                
                this.mapCtrl.map.addInteraction(this.tranzitData.locatiiModifyInteraction.start);
            }
            //
            if (this.tranzitData.locatiiModifyInteraction.stop == null) {
                let modifcollectionstop = new Collection();
                modifcollectionstop.push(this.tranzitData.locatii.stop)
                this.tranzitData.locatiiModifyInteraction.stop = new Modify({
                    //source: this.tranzitData.stratLocatii.getSource(),
                    features: modifcollectionstop
                } as any);
                
                this.tranzitData.locatiiModifyInteraction.stop.on("modifyend",(eventarg: any)=>{
                    this.$log.log("end modif")
                    var features = eventarg.features.getArray() as Array<Feature>;
                    if (features.length = 1){
                        let coord4326 = olProj.transform( (features[0].getGeometry() as Point).getCoordinates(), this.mapCtrl.map.getView().getProjection(),'EPSG:4326')
                        this.setStopCoordinates(coord4326 as any, this.setFromSource.fromMapDrag);
                    }
                });
                
                this.mapCtrl.map.addInteraction(this.tranzitData.locatiiModifyInteraction.stop);
            }
            //
            if (this.tranzitData.coordonate.start != null
                && this.tranzitData.coordonate.start[0] !== 0
                && this.tranzitData.coordonate.start[1] !== 0) {
                this.tranzitData.stratLocatii.getSource().addFeature(this.tranzitData.locatii.start);
            }
            if (this.tranzitData.coordonate.stop != null
                && this.tranzitData.coordonate.stop[0] !== 0
                && this.tranzitData.coordonate.stop[1] !== 0) {
                this.tranzitData.stratLocatii.getSource().addFeature(this.tranzitData.locatii.stop);
            }
            //this.tranzitData.optDataPlecare = moment(new Date()).format(this.tranzitData.optFormatDataPlecare);
            this.tranzitData.optDataPlecare = moment(new Date());
            this.tranzitData.activeNoTrips = false;
            this.tranzitData.toGetStartOrStopFromGeolocation = null;
            this.destroyTranzitPointOverlay()
        } catch (reason) {
            this.$log.error("eroare initalizare tranzit tool", getExMessage(reason));
        }
    }

    public clearTranzitTool = () => {
        try {
            //remove layer
            this.mapCtrl.map.removeLayer(this.tranzitData.stratLocatii);
            this.mapCtrl.map.removeLayer(this.tranzitData.stratTranzit);
            this.tranzitData.tripPlan = null;
        } catch (reason) {
            this.$log.error("eroare dezactivare tranzit tool", getExMessage(reason));
        }
    }
    
    public changeConfigTab(index: string){
        if (index === "0"){
            if (this.tranzitData.activeConfigTab0){
                this.tranzitData.activeConfigTab0 = false
            } else {
                this.tranzitData.activeConfigTab1 = false;
                this.tranzitData.activeConfigTab0 = true;
            }
            
        } else if (index === "1"){
            if (this.tranzitData.activeConfigTab1){
                this.tranzitData.activeConfigTab1 = false
            } else {
                this.tranzitData.activeConfigTab0 = false;
                this.tranzitData.activeConfigTab1 = true;
            }
        }
    }

    public startInputChanged(input: any) {
        if (input.valid) {
            //
            let inputData = this.convertInputStringToArry(this.tranzitData.input.start);
            this.setStartCoordinates(inputData, this.setFromSource.fromInput);
        }
    }
    

    public stopInputChanged(input: any){
        if (input.valid) {
            //
            let inputData = this.convertInputStringToArry(this.tranzitData.input.stop);
            this.setStopCoordinates(inputData, this.setFromSource.fromInput);
        }
    }

    private convertInputStringToArry(inputStr: string) : [number, number]{
        try {
            let inputData = inputStr.trim().replace(/\s/g," ");
           inputData = inputData.replace(/;/," ").replace(/,/, " ");
           let inputArr = inputData.split(" ");
           return [Number(inputArr[0]),Number(inputArr[1])];
        } catch (error) {
            this.$log.error("eroare parsare input");
            return [0,0]
        }
    }

    public  setFromSource = {
        fromInput: 'fromInput',
        fromMapClick: 'fromMapClick',
        fromMapDrag: 'fromMapDrag'
    } 

    public setStartCoordinates = (coord: [number, number], source: string = this.setFromSource.fromMapClick) => {
        this.tranzitData.coordonate.start = coord;
        if (source !== this.setFromSource.fromInput) {
            this.tranzitData.input.start = `${coord[0].toFixed(5)} ${coord[1].toFixed(5)}`;
        }
        //set geom
        //this.tranzitData.stratLocatii.getSource().removeFeature( this.tranzitData.locatii.start);
        if (source !== this.setFromSource.fromMapDrag) {
            let mapcoord = olProj.transform(this.tranzitData.coordonate.start as any, 'EPSG:4326', this.mapCtrl.map.getView().getProjection())
            this.tranzitData.locatii.start.setGeometry(new Point(mapcoord));
            if (this.tranzitData.stratLocatii.getSource().getFeatures().indexOf(this.tranzitData.locatii.start) < 0) {
                this.tranzitData.stratLocatii.getSource().addFeature(this.tranzitData.locatii.start);
            }
        }
        //do search
        if (this.tranzitData.coordonate.stop && this.tranzitData.coordonate.stop[0] != 0 && this.tranzitData.coordonate.stop[1] != 0){
            this.getTranzitPlanFromServer();
        }
    }

    public setStopCoordinates = (coord: [number, number], source: string = this.setFromSource.fromMapClick) => {
        this.tranzitData.coordonate.stop = coord;
        if (source !== this.setFromSource.fromInput) {
            this.tranzitData.input.stop = `${coord[0].toFixed(5)} ${coord[1].toFixed(5)}`;
        }
        //set geom
        //this.tranzitData.stratLocatii.getSource().removeFeature( this.tranzitData.locatii.stop);
        if (source !== this.setFromSource.fromMapDrag) {
            let mapcoord = olProj.transform(this.tranzitData.coordonate.stop as any, 'EPSG:4326', this.mapCtrl.map.getView().getProjection())
            this.tranzitData.locatii.stop.setGeometry(new Point(mapcoord));
            if (this.tranzitData.stratLocatii.getSource().getFeatures().indexOf(this.tranzitData.locatii.stop) < 0) {
                this.tranzitData.stratLocatii.getSource().addFeature(this.tranzitData.locatii.stop);
            }
        }
        //do search
        if (this.tranzitData.coordonate.start && this.tranzitData.coordonate.start[0] != 0 && this.tranzitData.coordonate.start[1] != 0){
            this.getTranzitPlanFromServer();
        }
    }

    public clearStartInput(){
        this.tranzitData.input.start = ""
        this.tranzitData.coordonate.start = null;
        this.tranzitData.locatii.start.setGeometry(null);
        this.clearTranzitData();
    }
    public clearStopInput(){
        this.tranzitData.input.stop = ""
        this.tranzitData.coordonate.stop = null;
        this.tranzitData.locatii.stop.setGeometry(null);
        this.clearTranzitData();
    }
    //
    public switchCoordinateData(){
        let tmpStartCoord = [this.tranzitData.coordonate.start[0], this.tranzitData.coordonate.start[1]];
        let tmpStopCoord = [this.tranzitData.coordonate.stop[0], this.tranzitData.coordonate.stop[1]];
        this.setStopCoordinates(tmpStartCoord as any,this.setFromSource.fromMapClick);
        this.setStartCoordinates(tmpStopCoord as any,this.setFromSource.fromMapClick);
    }
    public setStartFromGeolocation(){
        this.tranzitData.toGetStartOrStopFromGeolocation = 'start';
        this.checkGeolocation();
        
    }
    public setStopFromGeolocation(){
        this.tranzitData.toGetStartOrStopFromGeolocation = 'stop';
        this.checkGeolocation();
    }
    private checkGeolocation(){
        if (this.tranzitData.geoLocation == null){
            this.tranzitData.geoLocation =new Geolocation({
                tracking: true,
                projection: this.mapCtrl.map.getView().getProjection()
            });
            this.tranzitData.geoLocation.on('change', () => {
                let position = this.tranzitData.geoLocation.getPosition();
                if (this.tranzitData.toGetStartOrStopFromGeolocation === 'start' 
                || this.tranzitData.toGetStartOrStopFromGeolocation === 'stop'){
                    if (position) {
                        const coord = olProj.transform(position as any, this.mapCtrl.map.getView().getProjection(), 'EPSG:4326');
                        if (this.tranzitData.toGetStartOrStopFromGeolocation === 'start'){
                            this.setStartCoordinates(coord as any, this.setFromSource.fromMapClick);
                        } else if (this.tranzitData.toGetStartOrStopFromGeolocation === 'stop'){
                            this.setStopCoordinates(coord as any, this.setFromSource.fromMapClick);
                        }
                    }
                    this.tranzitData.toGetStartOrStopFromGeolocation = null;
                }
                this.$log.log("position changed");
            });
        } else {
            this.tranzitData.geoLocation.changed();
        }
    }

    public optChangePlecareRbt(){
        if (this.tranzitData.optPlecareImediata === "atdatetime"){
            this.tranzitData.optDataPlecare = moment( new Date());
            this.tranzitData.optOraMinPlecare = 
            {
                hour: new Date().getHours(),
                minute: new Date().getMinutes()
            }
        }
    }

    public getOptPlecareState(){
        if (this.tranzitData.optPlecareImediata === "now"){
            return "Acum";
        } else if (this.tranzitData.optPlecareImediata === "atdatetime"){
            let tmpdatestr = ""
            let tmphourstr = ""
            if (this.tranzitData.optDataPlecare){
                tmpdatestr = (this.tranzitData.optDataPlecare as any).format(this.tranzitData.optFormatDataPlecare);
            }
            if (this.tranzitData.optOraMinPlecare){
                tmphourstr = `${this.tranzitData.optOraMinPlecare.hour}:${this.tranzitData.optOraMinPlecare.minute}`;
            }
            return tmpdatestr + " " + tmphourstr;
        } else {
            return "";
        }
    }

    public clearTranzitData(){
        this.tranzitData.tripPlan = null;
        this.tranzitData.activeNoTrips = false;
        this.tranzitData.stratTranzit.getSource().clear();
    }

    public submitFormData(form: any){
        //close option tabs
        this.tranzitData.activeConfigTab1 = false;
        this.tranzitData.activeConfigTab0 = false;
        //
        this.$log.log("form tranz");
        if (form.valid){
            this.getTranzitPlanFromServer();
        }
    }

    public getTranzitPlanFromServer(){
        //
        if (this.tranzitData.optPlecareImediata == "now"){
            this.tranzitData.optDataPlecare = moment(new Date());//.format(this.tranzitData.optFormatDataPlecare);
            this.tranzitData.optOraMinPlecare =
            {
                hour: new Date().getHours(),
                minute: new Date().getMinutes()
            }
        }
        let tmpDate = this.tranzitData.optDataPlecare.format("MM-DD-YYYY");
        //
        let tmpTime = `${this.tranzitData.optOraMinPlecare.hour}:${this.tranzitData.optOraMinPlecare.minute}` ;
        //
        this.tranzitData.activeNoTrips = false;
        this.tranzitData.activeSearchTrips = true;
        //
        this.mapCtrl.transportDataService.getTranzitPlan(
            this.tranzitData.coordonate.start as any, this.tranzitData.coordonate.stop as any,
            tmpDate, tmpTime, this.tranzitData.optMaxWalkDistance)
            .then((data)=>{
                //todo with data
                if (data == null
                    || data.itineraries == null 
                    || data.itineraries.length === 0){
                    throw new Error("no answer form server or no path found");
                } else {
                    this.createTripPlanControls(data);
                }
            })
            .catch((reason)=>{
                this.tranzitData.tripPlan = null;
                this.tranzitData.activeNoTrips = true;
                this.tranzitData.stratTranzit.getSource().clear();
                this.$log.error("eroare plan tansport ", getExMessage(reason));
            })
            .finally(()=>{
                this.tranzitData.activeSearchTrips = false;
            });
    }

    private createTripPlanControls(plan: ITripPlan){
        this.tranzitData.tripPlan = plan;
        this.tranzitData.stratTranzit.getSource().clear();
        //
        if (plan.itineraries.length > 0){
           for (let indexi = 0; indexi < plan.itineraries.length; indexi++) {
               const itinerary = plan.itineraries[indexi];
               itinerary.ctrlId = indexi + 1;
               itinerary.ctrlIsCollapsed = true;
               //create geometry features for legs
               for (let indexl = 0; indexl < itinerary.legs.length; indexl++) {
                   const leg = itinerary.legs[indexl];
                 let formater = new PolylineFormater();
                 let geomLine = formater.readGeometry(leg.legGeometry.points,{dataProjection:'EPSG:4326', featureProjection:'EPSG:4326'})
                 leg.legFeature = new Feature( geomLine.transform('EPSG:4326', this.mapCtrl.map.getView().getProjection()));
                 //set feature style
                 this.setLegStyle(leg, indexi === 0);
                 //test only add to layer
                    this.tranzitData.stratTranzit.getSource().addFeature(leg['legFeature']);
               }
           } 
           this.tranzitData.ctrlRuteIsCollapsed = false;
           //
           if( window.innerWidth < 770) {
               this.tranzitData.ctrlSearchIsCollapsed = true;
           }
        }
    }

    private setLegStyle(leg: ILeg, highlight: boolean){
        let tmpStyle = [];
        if (highlight){
            tmpStyle.push(this.styleHighlightLeg);
         }
         if (leg.mode == legMode.WALK){
             tmpStyle.push(this.styleWalkLegFeature);
         } else if(leg.mode == legMode.BUS){
            tmpStyle.push(this.styleBusLegFeature);
         } else if(leg.mode == legMode.CAR){
            tmpStyle.push(this.styleCarLegFeature);
         }
         //
         
         if (tmpStyle.length > 0){
            leg.legFeature.setStyle(tmpStyle);
         }
    }

    public setActiveItinerary(id: number) {
        this.destroyTranzitPointOverlay();
        this.tranzitData.stratTranzit.getSource().clear();
        if (id == this.tranzitData.tripPlan.activeItineraryId) {
            this.tranzitData.tripPlan.activeItineraryId = -1;
            //add all
            this.tranzitData.tripPlan.itineraries.forEach((ititem) => {
                ititem.ctrlIsCollapsed = true;
                ititem.legs.forEach((lgitem) => {
                    if (lgitem.legFeature) {
                        this.tranzitData.stratTranzit.getSource().addFeature(lgitem.legFeature);
                    }
                })
            })
        } else {
            this.tranzitData.tripPlan.activeItineraryId = id;
            this.tranzitData.tripPlan.itineraries.forEach((item) => {
                if (item.ctrlId == id) {
                    item.ctrlIsCollapsed = false;
                    item.legs.forEach((lgitem) => {
                        if (lgitem.legFeature) {
                            this.tranzitData.stratTranzit.getSource().addFeature(lgitem.legFeature);
                            this.setLegStyle(lgitem,false);
                        }
                    })
                    this.centerMapOnItinerary(item);
                    //
                } else {
                    item.ctrlIsCollapsed = true;
                }
            })
            
        }
    }

    public getdaytimeformat(time: number): string {
        let daytimeStr = "";
           daytimeStr = moment(time).format(this.tranzitData.optFormatOraPlecare);
        return daytimeStr;
    }

    public getDuration(timestart: number, timestop:number): string {
      let duration = moment.duration( moment(timestop).diff(moment(timestart)));
      let durStr = duration.days() > 1 ? `${duration.days()} zile` : (duration.days() == 1 ? `1 zi` : '');
       durStr += duration.hours() > 1 ? `${duration.hours()} ore` : (duration.hours() == 1 ? `1 ora` : '');
       durStr += duration.minutes() > 1 ? `${duration.minutes()} min` :  '';
       if (duration.asMinutes() < 1){
           durStr = `${duration.seconds()} sec`;
       }

      return durStr;
    }
    public centerMapOnItinerary(it: IItinerary){
        let extent = null;
        it.legs.forEach((itleg)=>{
            this.setLegStyle(itleg, false);
            if (extent == null){
                extent = itleg.legFeature.getGeometry().getExtent();
            } else {
                olExtent.extend(extent, itleg.legFeature.getGeometry().getExtent());
            }
        });
        let trExtent = extent;

        if (window.innerWidth < 770) {
            let geoExt = new LineString([
                olExtent.getBottomLeft(extent),
                olExtent.getBottomRight(extent),
                [
                    olExtent.getTopLeft(extent)[0],
                    olExtent.getTopLeft(extent)[1] + olExtent.getHeight(extent)
                ],
                [
                    olExtent.getTopRight(extent)[0],
                    olExtent.getTopRight(extent)[1] + olExtent.getHeight(extent)
                ],
            ])
            trExtent = geoExt.getExtent();
        } else {
            let geoExt = new LineString([
                olExtent.getBottomRight(extent),
                olExtent.getTopRight(extent),
                [
                    olExtent.getTopLeft(extent)[0] - olExtent.getWidth(extent)/2,
                    olExtent.getTopLeft(extent)[1] 
                ],
                [
                    olExtent.getBottomLeft(extent)[0] - olExtent.getWidth(extent)/2,
                    olExtent.getBottomLeft(extent)[1] 
                ],
            ])
            trExtent = geoExt.getExtent();
        }
       
        this.mapCtrl.map.getView().fit(trExtent);
        //center
        this.centerWithOffset(olExtent.getCenter(extent));
    }

    public centerMapOnPoint(lon: number, lat:number){
        if( typeof(lon)=== 'number' && typeof(lat) === 'number'){
            let tmpCenter = olProj.transform([lon,lat] as any, 'EPSG:4326', this.mapCtrl.map.getView().getProjection())
            this.centerWithOffset(tmpCenter);
        }
    }
    
    public centerMapOnLeg(leg: ILeg){
       let tmpCenter = olExtent.getCenter( leg.legFeature.getGeometry().getExtent());
       this.centerWithOffset(tmpCenter);
    }

    public centerWithOffset(coord: olCoordinate.Coordinate) {
        let newCenter = [coord[0], coord[1]];
        let mapExtent = this.mapCtrl.map.getView().calculateExtent(this.mapCtrl.map.getSize());              
        if (window.innerWidth < 770) {
            newCenter = [
                coord[0],
                coord[1] + olExtent.getHeight(mapExtent) / 6,
            ]
        } else {
            newCenter = [
                coord[0] - olExtent.getWidth(mapExtent) /6,
                coord[1]
            ]
        }
        this.mapCtrl.map.getView().animate({ center: newCenter as any, duration: 1000 })
    }

    public onSelectLegMenu(itinerary: IItinerary,leg:ILeg){
        this.destroyTranzitPointOverlay();
        itinerary.legs.forEach((itleg)=>{
            this.setLegStyle(itleg, false);
        });
        this.setLegStyle(leg, true);
        this.centerMapOnLeg(leg);
    }

    public onSelectPointMenu(itinerary: IItinerary, time: number, place: IPlace){
        itinerary.legs.forEach((itleg)=>{
            this.setLegStyle(itleg, false);
        });
        this.centerMapOnPoint(place.lon, place.lat);
        setTimeout(() => {
            this.buildTranzitPointOverlay(place.name, time, place.lon, place.lat);
        }, 1000);
        
    }

    public onClickRoutesClose(){
        this.tranzitData.ctrlSearchIsCollapsed = false;
        this.clearTranzitData();
        this.destroyTranzitPointOverlay();
    }

    public buildTranzitPointOverlay(name: string, time:number, lon:number, lat:number) {
        let tmpName = name;
        if (name == "Origin"){
            tmpName = "Plecare";
        }
        if (name == "Destination"){
            tmpName = "Sosire";
        }
        let coordinate = 
            olProj.transform( [lon, lat],'EPSG:4326', this.mapCtrl.map.getView().getProjection())
        
        let popup = this.mapCtrl.infoOverlay.getElement();
        
        this.mapCtrl.infoOverlay.setPosition(coordinate);
        (this.mapCtrl.infoOverlay as any).hide();
        //
        let title = $('<div style="min-width:100px"><span> Locație</span></div>');
        let closeBtn = $('<span id="infoPopupCloseBtn"  class="float-right" > X </span>');
        $(title).append(closeBtn);

        let content = $('<div> </div>');
        let card = $('<div class="card info-popover-tranz-point"> </div>');
        let header = $('<h5 class="card-header"></h5>');
        header.append(title);
        let body = $(`<div class="card-body p-2"> <div class="h6">  ${tmpName}</div> <div> <span class="material-icons tranz-plan-it-clock">schedule </span> ${this.getdaytimeformat(time)}</div></div>`);
        card.append(header);
        card.append(body);
        content.append(card);
        //
        (this.mapCtrl.infoOverlay as any).show(coordinate, content.html());

        //close on header click
        $('#infoPopupCloseBtn').on("click", () => {
            (this.mapCtrl.infoOverlay as any).hide();
        });
        
    }

    public destroyTranzitPointOverlay(){
        (this.mapCtrl.infoOverlay as any).hide();
    }

}
