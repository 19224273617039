<div class="modal-content">
    <form #addRouteForm="ngForm" novalidate>
        <div class="modal-header">
            <h5 class="modal-title" *ngIf="isAddElseEdit">Adăugare traseu</h5>
            <h5 class="modal-title" *ngIf="!isAddElseEdit">Modificare traseu</h5>
        </div>
        <div class="modal-body">
            <fieldset [disabled]="disableInput">
                <div class="form-group" *ngIf="!isAddElseEdit">
                    <label for="name">Nume</label>
                    <ng-select id="uiSelectRoute" name="uiSelectRoute" required
                     #newroute="ngModel"
                     [(ngModel)]="newRoute" 
                     (change)="onChangeRoute($event)"
                     placeholder="Selectează sau caută în listă">
                        <ng-option *ngFor="let item of routeList" [value]="item">
                            {{item.name}}
                        </ng-option>
                    </ng-select>

                </div>
                <div class="form-group" *ngIf="isAddElseEdit">
                    <label for="name">Nume</label>
                    <input type="text" class="form-control" id="username" name="username" #namer="ngModel" [(ngModel)]="name" required>
                    <span *ngIf="addRouteForm.submitted || namer.touched">
                        <span *ngIf="namer.errors?.required">Nume rută este obligatoriu</span>
                    </span>
                </div>
                <div class="form-group">
                    <label>Stații</label>
                    <div class="addnewpointwell card card-body p-2">
                        <div class="routePointsClass d-flex" *ngFor="let rtPoint of pointList; let index=index">
                            <button type="button" id="btnRemoveRtPoint" class="btn btn-light"
                             (click)="removeRoutePoint(rtPoint)"> x </button>
                            <input type="text" [name]="'rtPointName'+index"
                             class="form-control flex-fill"
                             [ngClass]="{'alert-danger' : rtPoint.inactiv}"
                             readonly [(ngModel)]="rtPoint.name" title="{{rtPoint.inactiv?'stație inactivă':''}}" />
                            <button type="button" id="btnMoveUpRtPoint" class="btn btn-light"
                             (click)="moveUpRoutePoint(rtPoint)"> <span class="glyphicon glyphicon-chevron-up"></span> </button>
                            <button type="button" id="btnMoveDownRtPoint" class="btn btn-light"
                             (click)="moveDownRoutePoint(rtPoint)"> <span class="glyphicon glyphicon-chevron-down"></span></button>

                        </div>
                        <!-- Add new point-->
                        <div class="routeNewPointClass d-flex mt-2" *ngIf="(!isAddElseEdit && newRoute) || isAddElseEdit">
                            <ng-select id="uiSelectPoint" name="uiSelectPoint"
                            class="flex-fill"
                            #newroute="ngModel"
                            [(ngModel)]="newPoint" 
                            placeholder="Selectează sau caută în listă">
                                <ng-option *ngFor="let item of selectList" [value]="item">
                                    {{item.name}}
                                </ng-option>
                            </ng-select>
                            <button type="button" id="btnAddPoint" class="btn btn-light" (click)="addRoutePoint()">Adaugă stație</button>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <span *ngIf="errorResponse">
                        <span>{{errorResponse}}</span>
                    </span>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light float-left"
                     (click)="editOnMap()"
                     [disabled]="!enableEditLocatii()">Editează locații</button>
                    <!-- <button type="button" class="btn btn-light" 
                        (click)="delete()" 
                        *ngIf="!isAddElseEdit" [disabled]="!newRoute.text">Sterge</button> -->
                    <button type="button" class="btn btn-light" 
                     (click)="save()"
                     [disabled]="!enableSaveButton()">Salvează</button>
                    <button type="button" class="btn" (click)="cancel()">Renunță</button>
                </div>
            </fieldset>
        </div>
    </form>
</div>