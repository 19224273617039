<div id="positionMenu" class="position-menu position-menu-height" *ngIf="mapCtrl.positionButtonStateOn" >
    <div class="position-title-bar">
        <div class="position-title-text">Localizare</div>
        
        <div>
            <span class="glyphicon glyphicon-info-sign position-title-info"
                ngbPopover="{{infoPositionSearch}}"
                popoverTitle="Info"
                popoverClass="text-dark"
                triggers="mouseenter:mouseleave"
                placement="bottom" 
                onclick="event.stopPropagation()">
            </span>
        </div>
        <div  (click)="onClickPositionClose(); $event.stopPropagation()">
            <span class="material-icons position-title-close">close</span>
        </div>
        
    </div>
    <div>
        <form #positionForm="ngForm" novalidate (submit)="onSearchPositionByGeocoding()">
            <div class="row position-no-margin">
                <ul ngbNav #nav="ngbNav" [(activeId)]="positionData.selectedOption" class="nav-tabs nav-fill w-100">
                    <li [ngbNavItem]="'search'"
                    (click)="onChangePositionOption('search')">
                        <a ngbNavLink>Căutare locație</a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="'gps'"
                    (click)="onChangePositionOption('gps')">
                        <a ngbNavLink>GPS</a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="'center'"
                    (click)="onChangePositionOption('center')">
                        <a ngbNavLink>Centru</a>
                        <ng-template ngbNavContent>
                        </ng-template>
                    </li>
                </ul>
            </div> 
            <div class="position-search" >
                <div class="position-search-input">
                    <input type="search" name="search" [(ngModel)]="positionData.searchText" class="form-control" [disabled]="positionData.disableSearch">
                </div>
                <div class="position-search-button">
                    <button title="Caută locații" type="submit" class="position-search-button btn btn-light" [disabled]="positionData.disableSearch">
                        <i class="fa fa-search "></i></button>
                </div>
            </div>
            <div class="position-search-option-uat">
                <input name="unitatiTeritoriale" id="unitatiTeritoriale" type="checkbox"
                 [(ngModel)]="positionData.numaiUnitatiTeritoriale" [disabled]="positionData.disableSearch"/>
                <label for="unitatiTeritoriale" style="font-weight: unset;"> Numai unități teritoriale</label>
            </div>
            <div>
                <!-- search results-->
                <div *ngFor="let location of positionData.locations">
                    <div class="position-result" 
                    (click)="onClickPositionResults(location)"
                    *ngIf="isVisibleLocation(location) === true">
                        <div class="position-result-figure">
                            <span class="material-icons position-result-figure-icon">location_on</span>
                        </div>
                        <div class="position-result-content">
                            {{location.display_name}}
                        </div>
                        
                    </div>
                </div>
                <h6 *ngIf="positionData.activeSearchLocations" class="position-spiner-box">
                    <i id="spinner" class="fa fa-circle-o-notch fa-spin position-spiner"></i>
                </h6>
            </div>
        </form>
    </div>
</div>