//NG
import { Component, OnInit, Input, Inject, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NGXLogger } from "ngx-logger";
import { IInfoUtilizareTeren, GreentopDataService } from "../../services/greentop-data-service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BaseChartDirective } from "ng2-charts";
import { getExMessage } from "../../map/map-utils";
declare var _: any;

export const graphType = {
    bar: "bar",
    line: "line",
    bubble: "bubble"
}

@Component({
    selector: 'info-click-graph',
    providers: [],
    templateUrl: './info-click-graph.component.html',
    styleUrls: ['./info-click-graph.component.css']
})
export class InfoClickGraphComponent implements OnInit {
    public barChartType='bar'

    public graphData = { name: 'a', data: null, id: 0, type: graphType.bar, coordinate: [0, 0] };
    private status: { countReq: 0, countFin: 0 };
    private runOnceStaus = false;
    public labels = ["January", "February", "March", "April", "May", "June", "July"];
    public data = [{ data:[0,], type: "line", label:"" },
                    { data:[0,], type: "line", label:"" }];
    public codIndice = 'NDVI';
    public availableTipUtilizareTeren: Array<IInfoUtilizareTeren> = [];
    public selectedTipUtilizareTeren: IInfoUtilizareTeren = null;
    public dateReferintaIndiceUtilizareTeren: Array<any> = [];
    public utYears: Array<any> = [];
    public mainSerieData = {data:[], type: "line", label: "main"};
    public utSerieData = {data:[], type: "line", label: ""};
    public enableTool = true;
    public enableGraficTipUtilizareTeren = true;
    public noUtValue: IInfoUtilizareTeren = {
        id: -1,
        denumire: 'Fara Utilizare teren',
        cod: null, descriere: null, startPerioadaUtilizare: null, endPerioadaUtilizare: null
    }
    //
    public onClick = function (points, evt) {
        this.$log.log(points, evt);
    };
    //
    public options = {
        maintainAspectRatio: false,
        tooltips: {
            mode: 'nearest'
        },
        scales: {
            yAxes: [
                {
                    id: 'y-axis-0',
                    type: 'linear',
                    display: true,
                    position: 'left'
                },
                //
            ],
            xAxes: [
                {
                    id: 'x-axis-0',
                    type: 'time',
                    time: {
                        ticks: {
                            source: 'data'
                        },
                        displayFormats: {
                            day: 'YYYY MM DD'
                        }
                    }
                },
                //
            ]
        }
    };
    public colors = ['#45b7cd', '#ff6384', '#ff8e72'];

    @Input() diaData: any;
    @ViewChild(BaseChartDirective) chart?: BaseChartDirective;
    
    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(GreentopDataService) private greentopDataService: GreentopDataService) {
        //

    }

    public ngOnInit() {
        if ("status" in this.diaData) {
            this.status = this.diaData["status"];
        }

        this.runOnceStaus = false;
        //simulate onInit
        //wait data to load;
        setInterval(() => {
            if (this.status.countFin >= this.status.countReq
                && this.runOnceStaus === false
            ) {
                this.runOnceStaus = true;
                this.onInit();
            }
        }, 1000)
    }

    public onInit = () => {
        if ("graphData" in this.diaData) {
            this.graphData = this.diaData["graphData"];
            //
            if ("name" in this.graphData) {
                this.mainSerieData.label = this.graphData["name"];
            }
            //set chart type
            if ("type" in this.graphData) {
                if (this.graphData["type"] === graphType.bar
                    || this.graphData["type"] === graphType.line
                    || this.graphData["type"] === graphType.bubble) {
                        //
                        this.mainSerieData.type = this.graphData["type"];
                }
            }
            //
            if ("coordonate" in this.graphData) {

            }
            if ("enableGraficTipUtilizareTeren" in this.diaData) {
                this.enableGraficTipUtilizareTeren = this.diaData["enableGraficTipUtilizareTeren"];
            }
            //set chart data
            if ("data" in this.graphData) {

                let idata = this.graphData["data"];
                if ("steps" in idata) {
                    this.labels = idata["steps"];
                }
                if ("values" in idata) {
                    let tmpSeries = []
                    this.data.length = 0;
                    let mydata = [];
                    //create object data {x, y, r}
                    for (let index = 0; index < this.labels.length; index++) {
                        const element = {
                            x: this.labels[index],
                            y: idata["values"][index] || null,
                            r: 10
                        };
                        if (this.labels[index] && this.labels[index].length > 10) {
                            element.x = this.labels[index].substring(0, 10);
                        }
                        //
                        if (idata["values"][index]) {
                            mydata.push(element as any);
                        }
                    }
                    this.mainSerieData.data = mydata;
                    //
                    tmpSeries.push(this.mainSerieData);
                    tmpSeries.push(this.utSerieData);
                    this.data = tmpSeries;
                    this.chart.update();
                }
                //
                this.getYearsFromLabels();
                this.selectedTipUtilizareTeren = null;
                this.availableTipUtilizareTeren.length = 0;
                if (this.enableGraficTipUtilizareTeren === true) {
                    this.enableTool = false;
                    if (this.codIndice && this.codIndice.length > 0) {
                        this.greentopDataService.getReferintaUtilizareTeren(this.codIndice)
                            .then(data => {
                                this.availableTipUtilizareTeren = data;
                                this.availableTipUtilizareTeren.unshift(this.noUtValue);
                            }).catch((error) => {
                                this.$log.error("eroare interogare tip utilizari teren", getExMessage(error));
                            }).finally(() => {
                                this.enableTool = true;
                            })
                    }
                }

            } else {
                this.$log.warn("nu exista date grafic in date");
            }
        } else {
            this.$log.warn("nu exista graph data");
        }
    }

    private getYearsFromLabels() {
        this.utYears.length = 0;
        for (const lb of this.labels) {
            if (lb && lb.length > 4) {
                let tmpYear = lb.substring(0, 4);
                let rezFind = this.utYears.filter((fitem) => fitem === tmpYear);
                if (_.isInteger(Number(tmpYear)) && rezFind.length == 0) {
                    this.utYears.push(tmpYear);
                }
            }
        }
    }

    private convertNDecadeToString(decada: number): string {
        let ret = null;
        if (_.isInteger(decada)) {
            if (decada > 123) {
                ret = "12-21"
            } else if (decada < 11) {
                ret = "01-01"
            } else {
                let month = _.floor(decada / 10);
                let dec = _.floor(decada % 10)
                //decada
                let strDec = '01'
                let strMonth = '01'
                if (dec >= 3) {
                    strDec = '21';
                } else if (dec == 2) {
                    strDec = `11`
                } else if (dec <= 1) {
                    strDec = '01';
                }
                //month
                if (month <= 1) {
                    strMonth = '01';
                } else if (month >= 12) {
                    strMonth = '12';
                } else if (month <= 9 && month >= 2) {
                    strMonth = `0${month}`;
                } else {
                    strMonth = `${month}`;
                }
                ret = `${strMonth}-${strDec}`;
            }
        }

        return ret;
    }

    public onChangeTipUtilizareTeren() {
        if (this.selectedTipUtilizareTeren) {
            this.utSerieData.data.length = 0;
            this.enableTool = false;
            this.greentopDataService.getReferintaIndiciUtilizareTeren(this.codIndice, this.selectedTipUtilizareTeren.id)
                .then(data => {
                    this.dateReferintaIndiceUtilizareTeren = data;
                    for (const iyear of this.utYears) {
                        for (const itut of this.dateReferintaIndiceUtilizareTeren) {
                            let tmputItem = {
                                x: `${iyear}-${this.convertNDecadeToString(itut.decada)}`,
                                y: itut.valoare || null,
                                r: 10
                            }
                            this.utSerieData.data.push(tmputItem);
                        }
                    }
                    this.utSerieData.label = this.selectedTipUtilizareTeren.denumire;
                    this.chart.update();
                }).catch((error) => {
                    this.$log.error("eroare interogare date referinta indice tip utilizari teren", getExMessage(error));
                }).finally(() => {
                    this.enableTool = true;
                })
        }
    }

    public cancel(): void {
        this.activeModal.close(false);
    }
}
