<div class="modal-content">

    <div print-section print-landscape class="modal-header">
        <h5 class="modal-title" i18n>Grafic locatie pentru: {{graphData.name}} -  Coordonate (long lat):  {{graphData.coordinate[0]}}; {{graphData.coordinate[1]}}</h5>
    </div>
    <!--properties-->
    <div print-section class="modal-body">
        <div style="height: 80vh;overflow: auto;">
            <canvas id="line" class="chart" 
            baseChart 
            [chartType]="'line'"
            [datasets]="data"
            [options]="options"
            [labels]="labels"
            chart-click="onClick">

             <!--
                [chartType]="barChartType"
                [colors]="colors"
                [series]="series"  
            -->
        </canvas>
        </div>
    </div>

    <div class="modal-footer">
        <div class="info-chart-bottom">
            <div class="info-chart-bottm-label" *ngIf="enableGraficTipUtilizareTeren == true">
                <label for="uiImpLayer" class="" i18n>Sursă utilizare teren</label>
            </div>
            <div class="info-chart-select-combo" *ngIf="enableGraficTipUtilizareTeren == true">
                
                <ng-select id="uiImpLayer" 
                    name="uiImpLayer"
                    [(ngModel)]="selectedTipUtilizareTeren"
                    (change)="onChangeTipUtilizareTeren()"
                    i18n-placeholder placeholder="Selectează tip utilizare teren">
                    <ng-option *ngFor="let item of availableTipUtilizareTeren" [value]="item">
                        [{{item.id }}]{{item.denumire}}
                    </ng-option>
                </ng-select>
            </div>
            <div class="info-chart-btns">
                <button type="button" class="btn" (click)="cancel()" i18n>Închide</button>
            </div>
        </div>
    </div>
</div>