//NG
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ISearchSettings, ILayer, IMapConfig, ISelectedFeatures, ISelectFeatureConnected } from "../definitions";
import { ComponentFactoryResolver, Inject, Injectable, Compiler, Injector } from "@angular/core";
import { EditFeatureInfoComponent } from "./features-components/edit-feature-info.component";
import { InfoGroupFeaturesComponent } from "./features-components/info-group-features.component";
import { InfoFeatureImagesComponent } from "./features-components/info-feature-images.component";
import { InfoConnectedFeaturesComponent } from "./features-components/info-connected-features.component";
import { DetailsFeaturesInfoComponent } from "./features-components/details-features-info.component";
import { PrintFeaturesInfoComponent } from "./features-components/print-features-info.component";
import { FisaSpatiuluiVerdeComponent } from "./features-components/fisa-spatiului-verde.component";
import { InfoClickGraphComponent } from "./features-components/info-click-graph.component";
import { InfoClick3DGraphComponent } from "./features-components/info-click-3d-graph.component";
import { EditSearchInfoComponent } from "./features-components/edit-search-info.component";



import Feature from 'ol/Feature';
import Collection from "ol/Collection";
import Layer from 'ol/layer/Layer';

export interface IInfoDialogsServiceNg {
    showEditFeatureInfoDialog(feature: Feature, layer: Layer, isEditElseInsert: boolean, data: Array<{ key: string, value: any }>): any;
    showEditSearchInfoDialog(searchSettings: ISearchSettings, layers: Array<ILayer>, mapConfig: IMapConfig): any;
    showDetailsFeatureInfoDialog(selectedFeatures: Collection<ISelectedFeatures>): any;
    showPrintFeaturesInfoDialog(selectedFeatures: Collection<ISelectedFeatures>, mapImgUrl: any): any;
    showInfoConnectedFeaturesDialog(raportFeature, connectedFeatures, raportLayer, connectedLayer): any;
    showFisaSpatiuluiVerdeDialog(selectedFeaturesConnectedOnLayers: Array<ISelectFeatureConnected>, mapImgUrl: string): any;
    showInfoFeatureImagesDialog( imageData: {imagename: string, imageurl: string}[]): any;
    showInfoClickGraphDialog(graphData: {name: string, data:any}, enableGraficTipUtilizareTeren: boolean, status: any):any;
    showGroupFeatureInfoDialog(selectedFeaturesOnLayer: ISelectedFeatures): any;
    showInfoClick3DGraphDialog(graphData: { layer: ILayer,  bbox: Array<number>, coordinate: any, time: string, width: number, offset: number, offsetOsm: number, scale:number, nullValue: number, data: any}): any;
}

@Injectable({
    providedIn: 'root',
  })
export class InfoDialogsServiceNg implements IInfoDialogsServiceNg {

    constructor(
        @Inject(NgbModal) private modalService,
        @Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver,
        @Inject(Compiler) private compiler: Compiler,
        @Inject(Injector) private injector: Injector
        ) {

    }

    //
    public showEditFeatureInfoDialog(feature: Feature, layer: Layer, isEditElseInsert: boolean, data: Array<{ key: string, value: any }>) {
        const modalOpt = { backdrop: false }
        return import('./features-components/edit-feature-info.module')
            .then((result) => {
                const modalRef = this.modalService.open(EditFeatureInfoComponent, modalOpt);
                modalRef.componentInstance.data = { feature, layer, isEditElseInsert: isEditElseInsert, insertData: data };
                return modalRef.result;
            })
    }

    public showGroupFeatureInfoDialog(selectedFeaturesOnLayer: ISelectedFeatures) {
        const modalOpt = { backdrop: false }
        return import('./features-components/info-group-features.module').then((result) => {
            const modalRef = this.modalService.open(InfoGroupFeaturesComponent, modalOpt);
            modalRef.componentInstance.data = { selectedFeaturesOnLayer };
            return modalRef.result;
        })
    }

    public showInfoFeatureImagesDialog(imageData: { imagename: string, imageurl: string }[]) {
        const modalOpt = { backdrop: false, windowClass: 'modalimgview', fullscreen: true }
        return import('./features-components/info-feature-images.module')
            .then((result) => {
                const modalRef = this.modalService.open(InfoFeatureImagesComponent, modalOpt);
                modalRef.componentInstance.data = { imageData };
                return modalRef.result;
            })

    }

    public showInfoConnectedFeaturesDialog(raportFeature, connectedFeatures, raportLayer, connectedLayer) {
        const modalOpt = { backdrop: false }
        return import('./features-components/info-connected-features.module')
            .then((result) => {
                const modalRef = this.modalService.open(InfoConnectedFeaturesComponent, modalOpt);
                modalRef.componentInstance.data = { raportFeature, connectedFeatures, raportLayer, connectedLayer };
                return modalRef.result;
            })
    }

    public showDetailsFeatureInfoDialog(selectedFeaturesOnLayers: Collection<ISelectedFeatures>) {
        const modalOpt = { backdrop: false, windowClass: 'modalFeaturesDetailsView', fullscreen: true }
        return import('./features-components/details-features-info.module')
            .then((result) => {
                const modalRef = this.modalService.open(DetailsFeaturesInfoComponent, modalOpt);
                modalRef.componentInstance.data = { selectedFeaturesOnLayers };
                return modalRef.result;
            })
    }

    public showPrintFeaturesInfoDialog(selectedFeaturesOnLayers: Collection<ISelectedFeatures>, mapImgUrl: any) {
        const modalOpt = { backdrop: false, windowClass: 'modalFeaturesPrintView', fullscreen: true }
        return import('./features-components/print-features-info.module').then((result) => {
            const modalRef = this.modalService.open(PrintFeaturesInfoComponent, modalOpt);
            modalRef.componentInstance.data = { selectedFeaturesOnLayers, mapImgUrl };
            return modalRef.result;
        })

    }
    
    //
    public showFisaSpatiuluiVerdeDialog(selectedFeaturesConnectedOnLayers: Array<ISelectFeatureConnected>, mapImgUrl: string) {
        const modalOpt = { backdrop: false, windowClass: 'modalFisaSpatiuVerde', fullscreen: true }
        return import('./features-components/fisa-spatiului-verde.module')
            .then((result) => {
                const modalRef = this.modalService.open(FisaSpatiuluiVerdeComponent, modalOpt);
                modalRef.componentInstance.data = { selectedFeaturesConnectedOnLayers, mapImgUrl };
                return modalRef.result;
            })
    }

    public showInfoClickGraphDialog(graphData: { name: string, data: any }, enableGraficTipUtilizareTeren: boolean, status: any) {
        const modalOpt = { backdrop: false, windowClass: 'modalInfoClickGraph', fullscreen: true }
        return import('./features-components/info-click-graph.module')
            .then((result) => {
                const modalRef = this.modalService.open(InfoClickGraphComponent, modalOpt);
                modalRef.componentInstance.diaData = { graphData, enableGraficTipUtilizareTeren, status };
                return modalRef.result;
            })
    }

    //
    public showInfoClick3DGraphDialog(graphData: { layer: ILayer, bbox: Array<number>, coordinate: any, time: string, width: number, offset: number, offsetOsm: number, scale: number, nullValue: number, data: any }) {
        const modalOpt = { backdrop: false, windowClass: 'modalInfoClick3DGraph', fullscreen: true }
        return import('./features-components/info-click-3d-graph.module').then((result) => {
            const modalRef = this.modalService.open(InfoClick3DGraphComponent, modalOpt);
            modalRef.componentInstance.data = { graphData };
            return modalRef.result;
        })

    }

    //
    public showEditSearchInfoDialog(searchSettings: ISearchSettings, layers: Array<ILayer>, mapConfig: IMapConfig): Promise<any> {
        const modalOpt = { backdrop: false, windowClass: 'modalEditSearchInfo', fullscreen: true }
        return import('./features-components/edit-search-info.module').then((result) => {
            const modalRef = this.modalService.open(EditSearchInfoComponent, modalOpt);
            modalRef.componentInstance.data = { searchSettings, layers, mapConfig };
            return modalRef.result;
        })
    }
}