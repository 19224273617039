<div class="greentop-dw-menu greentop-dw-height" *ngIf="mapCtrl.greentopDwonloadButtonStateOn">
    <div id="greentop-dw-content">
        <div class="h6" (click)="greentopDownloadData.ctrlSearchIsCollapsed = !greentopDownloadData.ctrlSearchIsCollapsed"> 
            Descărcare date satelitare
            <span class="float-right" (click)="onClickClose(); $event.stopPropagation()">
                <span class="material-icons greentop-dw-menu-icon-close-colapse">close</span>
            </span>
            <span class="float-right">
                <span class="material-icons greentop-dw-menu-icon-close-colapse" *ngIf="greentopDownloadData.ctrlSearchIsCollapsed">arrow_drop_down</span>
                <span class="material-icons greentop-dw-menu-icon-close-colapse" *ngIf="!greentopDownloadData.ctrlSearchIsCollapsed">arrow_drop_up</span>
            </span>
            <div class="glyphicon glyphicon-info-sign float-right "
            ngbPopover="{{infoSearch}}"
            popoverTitle="Info"
            popoverClass="text-dark"
            triggers="mouseenter:mouseleave"
            placement="bottom"
            onclick="event.stopPropagation()"></div>
        </div>
        <div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="greentopDownloadData.activeTab" class="nav-tabs nav-fill w-100">
                <li [ngbNavItem]="1"
                (click)="changeNewExistsTab('1')">
                    <a ngbNavLink>Existente</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="0"
                (click)="changeNewExistsTab('0')">
                    <a ngbNavLink>Nouă</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
            </ul>
        </div>
        <!--existing downloads-->
        <div class="greentop-dw-existing-dw">
            <!--autocompleat search-->
            <div class="greentop-dw-existing-dw-combo">
                <div class="form-group greentop-dw-existing-combo-mb" *ngIf="greentopDownloadData.activeExistsTab1">
                    <label for="uiDesPlanificate">Descărcări planificate</label>
                    <ng-select name="uiDesPlanificate" required 
                        [items]="greentopDownloadData.availableDescarcari"
                        [(ngModel)]="greentopDownloadData.selectedDescarcare"
                        (change)="onChangeDescarcare()" 
                        bindLabel="denumire"
                        placeholder="Selectează descarcare planificată">
                        <ng-template ng-label-tmp let-item="item">
                            <div class="greentop-ng-select-wrap">
                                {{item.denumire}}
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="greentop-ng-select-wrap">
                                [{{item.id }}] {{item.denumire}}
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <!--form input-->
        <div>
            <div [(ngbCollapse)]="greentopDownloadData.ctrlSearchIsCollapsed">
                <div *ngIf="true">
                    <form #greentopDownloadForm="ngForm" novalidate>
                        
                        <!--new des info-->
                        <fieldset *ngIf="greentopDownloadData.activeNewTab0"
                        [disabled]="greentopDownloadData.loadingSearchProductsActive === true"
                        >
                        <div class="greentop-dw-existing-dw-combo">
                            <div class="form-group greentop-dw-existing-combo-mb">
                                <label for="uiSursaDate">Sursa date satelitare</label>
                                <ng-select name="uiSursaDate" required 
                                [(ngModel)]="greentopDownloadData.selectedSursaDate"
                                    (change)="onChangeSursaDate()" placeholder="Selectează sursa date">
                                    <ng-option *ngFor="let itemsd of greentopDownloadData.availableSursaDate"
                                        [value]="itemsd">{{itemsd.denumire}}</ng-option>
                                </ng-select>

                                <!-- <span *ngIf="greentopDownloadData.errorResponse || greentopDownloadForm.uiSursaDate.$touched">
                                    <span *ngIf="greentopDownloadForm.uiSursaDate.$error.required">Nume sursa date este obligatoriu</span>
                                </span> -->
                            </div>
                        </div>
                            <div class="form-group">
                                <label>Perioada</label>
                                <div class="greentop-dw-form-period-inputs">
                                    <div class="input-group">
                                        <input
                                            class="form-control px-2"
                                            placeholder="început"
                                            name="desPerioadaStart"
                                            [(ngModel)]="greentopDownloadData.currentDescarcare.startPerioada"
                                            ngbDatepicker
                                            #dstart="ngbDatepicker"
                                            (ngModelChange)="clearSearchResults()"
                                        />
                                        <div class="input-group-append">
                                            <button class="btn btn-light" (click)="dstart.toggle()" type="button">
                                                <i class="glyphicon glyphicon-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <input
                                            class="form-control px-2"
                                            placeholder="sfârșit"
                                            name="desPerioadaEnd"
                                            [(ngModel)]="greentopDownloadData.currentDescarcare.endPerioada"
                                            ngbDatepicker
                                            placement="bottom-right"
                                            #dend="ngbDatepicker"
                                            (ngModelChange)="clearSearchResults()"
                                        />
                                        <div class="input-group-append">
                                            <button class="btn btn-light" (click)="dend.toggle()" type="button">
                                                <i class="glyphicon glyphicon-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="greentop-dw-form-search-buttons" *ngIf="greentopDownloadData.activeNewTab0">
                                <div class="input-group">
                                    <button class="btn btn-success disabled"
                                    [ngClass]="{'btn-warning':greentopDownloadData.currentDescarcare.geom == null}">
                                     Zonă selecție
                                    </button>
                                    <div class="input-group-append ">
                                        <span class="btn"
                                         [ngClass]="{'btn-primary':greentopDownloadData.selectButtonStateOn, 'btn-light':greentopDownloadData.selectButtonStateOn == false}"
                                            (click)="onClickSelectButton()">
                                            <i class="glyphicon glyphicon-edit"></i>
                                        </span>
                                    </div>
                                    
                                </div>
                                <button class="btn btn-light" (click)="onSearchAvailableProductsClick()"><i
                                        class="fa fa-search "></i></button>
                            </div>
                            <h6 (click)="onctrlDownloadDetailsIsColapsed()">
                                <span *ngIf="greentopDownloadData.activeNewTab0 === true">
                                    <span *ngIf="greentopDownloadData.ctrlDownloadDetailsIsColapsed === true" class="btn btn-light">Planifică descărcare</span>
                                    <span *ngIf="greentopDownloadData.ctrlDownloadDetailsIsColapsed === false">Detalii descarcare</span>
                                </span>
                                <span class="float-right">
                                    <span class="material-icons greentop-dw-menu-icon-close-colapse" *ngIf="greentopDownloadData.ctrlDownloadDetailsIsColapsed">arrow_drop_down</span>
                                    <span class="material-icons greentop-dw-menu-icon-close-colapse" *ngIf="!greentopDownloadData.ctrlDownloadDetailsIsColapsed">arrow_drop_up</span>
                                </span>
                            </h6>
                            <div [(ngbCollapse)]="greentopDownloadData.ctrlDownloadDetailsIsColapsed">
                                <div class="form-group" *ngIf="greentopDownloadData.activeNewTab0 == true">
                                    <label>Denumire</label>
                                    <input class="form-control" type="text" name="denumire" required
                                        [(ngModel)]="greentopDownloadData.currentDescarcare.denumire"/>
                                </div>
                                <div class="form-group" >
                                    <label>Descriere</label>
                                    <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                        [(ngModel)]="greentopDownloadData.currentDescarcare.descriere"
                                        ></textarea>
                                </div>
                                <!--indici-->
                                <div *ngIf="greentopDownloadData.currentDescarcare">
                                    <div>
                                        <h6>Listă indici:</h6>
                                        <div class="greentop-dw-indice-list" 
                                            *ngFor="let indice of greentopDownloadData.currentDescarcare.indiciDescarcare">
                                            <div class="greentop-dw-indice-item">
                                                <div class="greentop-dw-indice-item-name">&#160;{{indice.codIndice}}</div>
                                                <div class="btn-xs btn-light" 
                                                    (click)="onRemoveIndice(indice)">
                                                    <span class="greentop-dwindice-item-bt">x</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="greentop-dw-select-dw-combo">
                                            <div class="form-group greentop-dw-existing-combo-mb">
                                                <!-- <label for="uinewIndice">Indici</label> -->
                                                <ng-select name="uinewIndice" required 
                                                    [(ngModel)]="greentopDownloadData.selectednewIndice"
                                                    (change)="onChangenewIndice()" placeholder="Selectează indice">
                                                    <ng-option *ngFor="let itemInd of greentopDownloadData.filteredIndici"
                                                        [value]="itemInd">{{itemInd.codIndice}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div>
                                            <button class="btn btn-defalut greentop-dw-btn-dark" (click)="onAddNewIndice()">Adaugă
                                                indice
                                            </button>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="form-group">
                                    <button class="btn btn-light" (click)="onClickSaveDownloadProducts()">
                                        Descarcă produse
                                    </button>
                                </div>
                            </div>
                        </fieldset>
    
                        <!--existing des info-->
                        <!--*ngIf="greentopDownloadData.activeExistsTab1"-->
                        <fieldset  
                            [disabled]="greentopDownloadData.loadingSearchProductsActive === true"
                            *ngIf="greentopDownloadData.activeExistsTab1 && (greentopDownloadData.selectedDescarcare == null && greentopDownloadData.activeExistsTab1 === true) === false">
                            
                            <h6 (click)="onctrlExistingDetailsIsColapsed()">
                                <span >Detalii descărcare</span>
                                <span class="float-right">
                                    <span class="material-icons greentop-dw-menu-icon-close-colapse" *ngIf="greentopDownloadData.ctrlExistingDetalisIsColapsed">arrow_drop_down</span>
                                    <span class="material-icons greentop-dw-menu-icon-close-colapse" *ngIf="!greentopDownloadData.ctrlExistingDetalisIsColapsed">arrow_drop_up</span>
                                </span>
                                <span class="float-right" 
                                (click)="onClickReloadExisting(); $event.stopPropagation()">
                                    <span class="material-icons greentop-dw-menu-icon-refresh">refresh</span>
                                </span>
                            </h6>
                            <div [(ngbCollapse)]="greentopDownloadData.ctrlExistingDetalisIsColapsed">
                                <div class="form-group">
                                    <label>Sursa date satelitare</label>
                                    <!-- <input class="form-control" type="text" name="status" ng-model="greentopDownloadData.currentDescarcare.sursaDate"
                                        readonly /> -->
                                        <div class="form-control" type="text" name="status"
                                         >{{bindSursaDate()}}</div>
                                </div>
                                <div class="form-group">
                                    <label>Perioada</label>
                                    <div class="greentop-dw-form-period-inputs">
                                        <div class="input-group">
                                            <input
                                                disabled
                                                class="form-control px-2"
                                                placeholder="început"
                                                name="impPerioadaStart"
                                                [(ngModel)]="greentopDownloadData.currentDescarcare.startPerioada"
                                                ngbDatepicker
                                                #dstart="ngbDatepicker"
                                            />
                                            <div class="input-group-append">
                                                <button disabled class="btn btn-light" (click)="dstart.toggle()" type="button">
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <input
                                                disabled
                                                class="form-control px-2"
                                                placeholder="sfârșit"
                                                name="impPerioadaEnd"
                                                [(ngModel)]="greentopDownloadData.currentDescarcare.endPerioada"
                                                ngbDatepicker
                                                #dend="ngbDatepicker"
                                            />
                                            <div class="input-group-append">
                                                <button disabled class="btn btn-light" (click)="dend.toggle()" type="button">
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="form-group" *ngIf="greentopDownloadData.activeNewTab0 == true">
                                    <label>Denumire</label>
                                    <input class="form-control" type="text" name="denumire"
                                        [(ngModel)]="greentopDownloadData.currentDescarcare.denumire" 
                                        [disabled]="greentopDownloadData.activeExistsTab1"/>
                                </div>
                                <div class="form-group" >
                                    <label>Descriere</label>
                                    <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                        [(ngModel)]="greentopDownloadData.currentDescarcare.descriere" 
                                        [disabled]="greentopDownloadData.activeExistsTab1"></textarea>
                                </div>
                                <div class="form-group">
                                    <label class="label-full-width"><span>Status</span>
                                        <span class="glyphicon glyphicon-info-sign float-right" 
                                        *ngIf="(greentopDownloadData.currentDescarcare.mesajStatus == null || greentopDownloadData.currentDescarcare.mesajStatus.length == 0) == false"
                                        ngbPopover="{{greentopDownloadData.currentDescarcare.mesajStatus}}"
                                        popoverTitle="Info"
                                        popoverClass="text-dark"
                                        triggers="mouseenter:mouseleave"
                                        placement="bottom" 
                                        popover-trigger="'outsideClick'"
                                         popover-popup-close-delay="1000"
                                         onclick="event.stopPropagation()"></span>
                                         <!-- popover-trigger="'outsideClick'"
                                         popover-popup-close-delay="1000" -->
                                    </label>
                                    <input class="form-control" type="text" name="status"
                                        [(ngModel)]="greentopDownloadData.currentDescarcare.status" readonly />
                                </div>
                                <div class="form-group">
                                    <label>Indici:</label>
                                    <div class="" *ngFor="let indice of greentopDownloadData.currentDescarcare.indiciDescarcare">
                                        &#160;{{indice.codIndice}}
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="greentopDownloadData.currentDescarcare.actiune">
                                    <label>Cerere acțiune</label>
                                    <input class="form-control" type="text" name="status"
                                        [(ngModel)]="greentopDownloadData.currentDescarcare.actiune" readonly />
                                </div>
                                <div class="input-group">
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    [disabled]="canContinueDownload() === false"
                                    (click)="onContinueDownload()" title="Continuă"><i class="fas fa-play-circle fa-2x"></i></button>
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    [disabled]="canRestartDownload() === false"
                                    (click)="onRestartDownload()" title="Repornește"><i class="fas fa-redo fa-2x"></i></button>
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    [disabled]="canStopDownload() === false"
                                    (click)="onStopDownload()" title="Oprește"><i class="fas fa-stop-circle fa-2x"></i> </button>
                                    <button class="btn btn-light greentop-action-wrp-buttons" 
                                    (click)="onInitNewFromExisting()" title="Nou"><i class="fas fa-plus-circle fa-2x"></i> </button>
                                    
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
        <!--search results-->
        <div>
            <h6 (click)="onClickColapseProducts()">
                Produse disponibile:{{greentopDownloadData.currentDescarcare.produseDescarcare.length || ""}}
                <span class="float-right" 
                *ngIf="greentopDownloadData.activeNewTab0"
                (click)="onClickProductsClose(); $event.stopPropagation()">
                    <span class="material-icons greentop-dw-menu-icon-close-colapse">close</span>
                </span>
                <span class="float-right">
                    <span class="material-icons greentop-dw-menu-icon-close-colapse"
                        *ngIf="greentopDownloadData.ctrlProductsIsCollapsed">arrow_drop_down</span>
                    <span class="material-icons greentop-dw-menu-icon-close-colapse"
                        *ngIf="!greentopDownloadData.ctrlProductsIsCollapsed">arrow_drop_up</span>
                </span>
                <span class="glyphicon glyphicon-info-sign float-right" 
                    ngbPopover="{{infoProducts}}"
                    popoverTitle="Info"
                    popoverClass="text-dark"
                    triggers="mouseenter:mouseleave"
                    placement="bottom"
                    onclick="event.stopPropagation();"></span>
                    <!-- popover-trigger="'outsideClick'"  -->

            </h6>
            <div *ngIf="greentopDownloadData.loadingSearchProductsActive === true"class="tranz-spiner-box">
                <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
            </div>
            <h6 *ngIf="greentopDownloadData.errorMessage && greentopDownloadData.errorMessage.length > 0" class="text-danger">
                {{greentopDownloadData.errorMessage}}
            </h6>
            <div [(ngbCollapse)]="greentopDownloadData.ctrlProductsIsCollapsed">
             <div *ngIf="greentopDownloadData.loadingSearchProductsActive === false;">
                 <div *ngFor="let produs of greentopDownloadData.currentDescarcare.produseDescarcare"
                    (click)="onProducListItemClick(produs)">
                     <div class="greentop-dw-products-title"  >
                        <div *ngIf="greentopDownloadData.activeNewTab0"  class="greentop-tr-indice-item-ck" >
                            <input type="checkbox" style="zoom:1.5" [(ngModel)]="produs.selected"
                                (click)="$event.stopPropagation()" />
                        </div>
                        <div class="greentop-dw-products-title-text">
                           <div class="greentop-dw-products-title-text-in"> {{produs.nr}}.{{produs.numeProdus}}</div>  
                        </div> 
                     </div>
                    <div class="greentop-dw-products-item">
                        <div>
                            <img src="{{produs.previzualizareS}}" alt="previzualizare" width="64" height="64"
                                onerror="this.onerror=null;this.src='./img/no-image.jpg';" 
                                (click)="onProductListImageClick(produs)"
                                class="greentop-dw-products-img"/>
                        </div>
                        <div>
                            <div> Data: {{produs.dataProdus}}</div>
                            <div *ngIf="greentopDownloadData.activeNewTab0">Disponibil local: {{produs.disponibilLocal?'Da': 'Nu'}}</div>
                            <div *ngIf="greentopDownloadData.activeExistsTab1"> 
                                <span> Status: {{produs.status}} </span>
                                <span class="glyphicon glyphicon-info-sign " 
                                    *ngIf="produs.mesajStatus && produs.mesajStatus.length > 0"
                                    ngbPopover="{{produs.mesajStatus}}"
                                    popoverTitle="Info"
                                    popoverClass="text-dark"
                                    triggers="mouseenter:mouseleave"
                                    placement="bottom"        
                                           onclick="event.stopPropagation()"></span>
                                           <!-- popover-trigger="'outsideClick'"
                                         popover-popup-close-delay="100" -->
    
                            </div>
                            <div *ngIf="greentopDownloadData.activeExistsTab1"> Indici: {{produs.statusIndici}}</div>
                        </div>
                    
                    </div>
                 </div>
             </div>
            </div>
        </div>
    </div>
</div>