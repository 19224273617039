<div class="tranzit-menu tranzit-menu-height" *ngIf="mapCtrl.tranzitButtonStateOn">
    <div class="h6" (click)="tranzitData.ctrlSearchIsCollapsed = !tranzitData.ctrlSearchIsCollapsed"> 
        Planificare călătorie
        <span class="float-right" (click)="onClickTranzitClose(); $event.stopPropagation()">
            <span class="material-icons tranz-menu-icon-close-colapse">close</span>
        </span>
        <span class="float-right">
            <span class="material-icons tranz-menu-icon-close-colapse" *ngIf="tranzitData.ctrlSearchIsCollapsed">arrow_drop_down</span>
            <span class="material-icons tranz-menu-icon-close-colapse" *ngIf="!tranzitData.ctrlSearchIsCollapsed">arrow_drop_up</span>
        </span>
        <span class="glyphicon glyphicon-info-sign float-right "
            ngbPopover="{{infoTranzSearch}}"
            popoverTitle="Info"
            popoverClass="text-dark"
            triggers="mouseenter:mouseleave"
            placement="bottom"
           onclick="event.stopPropagation()"></span>
    </div>
    <div>
        <div [(ngbCollapse)]="tranzitData.ctrlSearchIsCollapsed">
            <form #tranzitForm="ngForm" novalidate (submit)="submitFormData(tranzitForm)">
    
                <div class="row m-0">
                    <!--start stop-->
                    <div class="col-10 p-0 m-0">
                        <div class="row m-0">
                            <div class="input-group" ngbDropdown  dropdown-append-to-body>
                                <div ngbDropdownToggle class="input-group-prepend remove-caret"
                                    title="Locație start călătorie de la coordonate dispozitiv">
                                    <span class="input-group-text"><svg viewBox="0 0 384 512" height="13" width="13" focusable="false" role="img"
                                            fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="">
                                            <!-- <path fill="green"
                                                                    d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z">
                                                                </path> -->
                                            <path fill="green"
                                                d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z">
                                            </path>
                                        </svg></span>
                                </div>
                                <ul ngbDropdownMenu  role="menu" aria-labelledby="btn-append-to-body">
                                    <li ngbDropdownItem  class="p-1" (click)="setStartFromGeolocation()">
                                        <div class="tranz-in-menu-action">
                                            <a class="fa fa-dot-circle-o"></a>
                                            <span>Locație curentă</span>
                                        </div>
                                    </li>
                                    <li ngbDropdownItem  class="p-1" (click)="switchCoordinateData()">
                                        <div class="tranz-in-menu-action">
                                            <a class="fa fa-exchange fa-rotate-90"></a>
                                            <span>Inversează locații</span>
                                        </div>
                                    </li>
                                </ul>
                                <input id="tranzInStart" name="startLocation" class="form-control tranz-in-startstop"
                                    placeholder="Plecare click pe hartă" required
                                    title="Locație plecare click pe hartă sau introduce coordonate"
                                    [(ngModel)]="tranzitData.input.start"
                                    (change)="startInputChanged(tranzitForm.controls.startLocation)"
                                    pattern="^([\-+]?\d{1,3}[.]\d+)[,\s*]([\-+]?\d{1,2}[.]\d+)$"/>
                                <span *ngIf="tranzitData.input.start && tranzitForm.controls.startLocation?.valid"
                                    (click)="clearStartInput()"
                                    class="glyphicon glyphicon-remove form-control-feedback"
                                    style="cursor: pointer; pointer-events: all; color: black;" ngbTooltip="șterge"></span>
                                <!-- <span *ngIf="tranzitForm.controls.startLocation?.invalid && tranzitForm.controls.startLocation?.touched"
                                     class="glyphicon glyphicon-warning-sign form-control-feedback"
                                    style="color:red;" ngbTooltip="eroare la validare"></span> -->
    
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="input-group"  ngbDropdown dropdown-append-to-body>
                                    <div ngbDropdownToggle class="input-group-prepend remove-caret" title="Locație stop călătorie de la coordonate dispozitiv">
                                        <span class="input-group-text"><svg viewBox="0 0 384 512" height="13" width="13" focusable="false"
                                                role="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="">
                                                <path fill="red"
                                                    d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z">
                                                </path>
                                            </svg>
                            
                                        </span>
                                    </div>
                                    <ul ngbDropdownMenu  role="menu" aria-labelledby="btn-append-to-body">
                                        <li ngbDropdownItem class="p-1">
                                            <div class="tranz-in-menu-action" (click)="setStopFromGeolocation()">
                                                <a class="fa fa-dot-circle-o"></a>
                                                <span>Locație curentă</span>
                                            </div>
                                        </li>
                                        <li ngbDropdownItem  class="p-1" (click)="switchCoordinateData()">
                                            <div class="tranz-in-menu-action">
                                                <a class="fa fa-exchange fa-rotate-90"></a>
                                                <span>Inversează locații</span>
                                            </div>
                                        </li>
                                    </ul>
                                
                                    <input id="tranzInStop" name="stopLocation" class="form-control tranz-in-startstop"
                                    placeholder="Sosire click pe hartă" required
                                    title="Locație sosire click pe hartă sau introduce coordonate"
                                    pattern="^([\-+]?\d{1,3}[.]\d+)[,\s*]([\-+]?\d{1,2}[.]\d+)$"
                                    (change)="stopInputChanged(tranzitForm.controls.stopLocation)"
                                    [(ngModel)]="tranzitData.input.stop" />
                                <span *ngIf="tranzitData.input.stop && tranzitForm.controls.stopLocation?.valid"
                                    (click)="clearStopInput()"
                                    class="glyphicon glyphicon-remove form-control-feedback"
                                    style="cursor: pointer; pointer-events: all;  color: black;" ngbTooltip="șterge"></span>
                                <!-- <span *ngIf="tranzitForm.controls.stopLocation?.invalid && tranzitForm.controls.stopLocation?.touched"
                                    class="glyphicon glyphicon-warning-sign form-control-feedback"
                                    style="color:red" ngbTooltip="eroare la validare"> 
                                </span>-->
                            </div>
    
                        </div>
                    </div>
                    <div class="col-2 p-0 m-0">
                        <!-- <div class="tranz-switch-button-container">
                                                <button title="Inverseaza locatiile" type="submit" class="tranz-switch-button btn btn-light">
                                                    <i class="fa fa-exchange fa-rotate-90"></i></button>
                                            </div> -->
                        <div class="tranz-switch-button-container">
                            <button title="Caută direcții" type="submit" class="tranz-switch-button btn btn-light">
                                <i class="fa fa-search "></i></button>
                        </div>
                    </div>
                </div>
                <div class="tranz-options">
                    <!--options-->
                    <div>
                        <ul ngbNav #nav="ngbNav" 
                        [(activeId)]="tranzitData.activeConfigTab" 
                        class="nav-tabs nav-fill w-100">
                            <li [ngbNavItem]="0"
                            (click)="changeConfigTab('0')">
                                <a ngbNavLink>
                                    <div>Moment plecare</div>
                                    <div>{{getOptPlecareState()}}</div>
                                </a>
                                <ng-template ngbNavContent>
                                </ng-template>
                            </li>
                            <li [ngbNavItem]="1"
                            (click)="changeConfigTab('1')">
                                <a ngbNavLink>
                                    <div>Opțiuni</div>
                                    <div>&nbsp;</div>
                                </a>
                                <ng-template ngbNavContent>
                                </ng-template>
                            </li>
                        </ul>
                    </div>
                    <div *ngIf="tranzitData.activeConfigTab0">
                        <div class="p-1">Moment plecare</div>
                        <div class="radio">
                            <label><input type="radio" [(ngModel)]="tranzitData.optPlecareImediata" value="now"
                                    name="optradio" checked="checked"
                                    (change)="optChangePlecareRbt()">
                                    Plecare imediată</label>
                        </div>
                        <div class="radio">
                            <label><input type="radio" [(ngModel)]="tranzitData.optPlecareImediata" value="atdatetime"
                                    name="optradio" (change)="optChangePlecareRbt()">
                                    Data și ora pentru plecare</label>
    
                        </div>
                        <div *ngIf="tranzitData.optPlecareImediata === 'atdatetime'">
                            <div class="d-inline-flex p-1 align-items-center">
                                <div>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-light" (click)="dstart.toggle()" type="button">
                                                <i class="glyphicon glyphicon-calendar"></i>
                                            </button>
                                        </div>
                                        <input
                                            class="form-control px-2"
                                            placeholder="Selectie data"
                                            name="optDataPlecare"
                                            [(ngModel)]="tranzitData.optDataPlecare"
                                            ngbDatepicker
                                            #dstart="ngbDatepicker"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <ngb-timepicker name="optOraMinPlecare"
                                    [(ngModel)]="tranzitData.optOraMinPlecare"
                                    [hourStep]="1"
                                    [minuteStep]="5"
                                ></ngb-timepicker> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="tranzitData.activeConfigTab1">
                        <div class="form-group">
                            <label>Distanța maximă mers pe jos</label>
                            <div class="input-group">
                                <input type="number" min="500" [(ngModel)]="tranzitData.optMaxWalkDistance"
                                    class="form-control" name="maxWalkDistance" />
                                <span *ngIf="tranzitForm.controls.maxWalkDistance?.invalid && tranzitForm.controls.maxWalkDistance?.touched"
                                    class="glyphicon glyphicon-warning-sign form-control-feedback"
                                    style="color:red;" title="eroare la validare"></span>
                                    <div class="input-group-append"> 
                                        <span class="input-group-text">
                                            metri
                                        </span>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h6 *ngIf="tranzitForm.dirty && tranzitForm.invalid" class="text-danger">Probleme validare date de intrare <br> (citește info pentru detalii de completare)
                </h6>
            </form>
        </div>
    </div>
    <div>
        <!--rute posibile-->
        <h6 (click)="tranzitData.ctrlRuteIsCollapsed = !tranzitData.ctrlRuteIsCollapsed">Rute posibile:
            <span class="float-right" (click)="onClickRoutesClose()">
                <span class="material-icons tranz-menu-icon-close-colapse">close</span>
            </span>
            <span class="float-right">
                <span class="material-icons tranz-menu-icon-close-colapse"
                    *ngIf="tranzitData.ctrlRuteIsCollapsed">arrow_drop_down</span>
                <span class="material-icons tranz-menu-icon-close-colapse"
                    *ngIf="!tranzitData.ctrlRuteIsCollapsed">arrow_drop_up</span>
            </span>
            <span class="glyphicon glyphicon-info-sign float-right"
                ngbPopover="{{infoTranzRute}}"
                popoverTitle="Info"
                popoverClass="text-dark"
                triggers="mouseenter:mouseleave"
                placement="bottom" 
                onclick="event.stopPropagation();"></span>
        </h6>
        <div [(ngbCollapse)]="tranzitData.ctrlRuteIsCollapsed">
            <div *ngFor="let itinerary of tranzitData.tripPlan?.itineraries" class="card card-body p-2 tranz-plan-itinerary">
                <div class="tranz-plan-it-head" (click)="setActiveItinerary(itinerary.ctrlId)">
                    <div class="tranz-plan-it-head-left">
                        <span class="tranz-plan-it-id">{{itinerary.ctrlId}}.</span>
                        <span class="material-icons tranz-plan-it-clock">schedule</span>
                        <span class="tranz-plan-it-time">{{getdaytimeformat(itinerary.startTime)}}</span>
                        <span class="material-icons tranz-plan-it-arow">arrow_right_alt</span>
                        <span class="tranz-plan-it-time">{{getdaytimeformat(itinerary.endTime)}}</span>
                    </div>
                    <div class="tranz-plan-it-head-right">
                        <span class="">
                            <span *ngFor="let leg of itinerary.legs">
                                <span *ngIf="leg.mode == 'WALK'"
                                    class="material-icons tranz-plan-it-legicon">directions_walk</span>
                                <span *ngIf="leg.mode == 'BUS'"
                                    class="material-icons tranz-plan-it-legicon">directions_bus</span>
                                <span *ngIf="leg.mode == 'CAR'"
                                    class="material-icons tranz-plan-it-legicon">directions_car</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div [(ngbCollapse)]="itinerary.ctrlIsCollapsed" class="tranz-plan-it-body">
                    <div *ngFor="let leg of itinerary.legs" class="tranz-plan-leg">
                        <!--start or begin point-->
                        <div class="tranz-plan-point"
                            (click)="onSelectPointMenu(itinerary,leg.startTime, leg.from)">
                            <div class="tranz-plan-point-head">
                                <span>{{getdaytimeformat(leg.startTime)}}</span>
                            </div>
                            <div class="tranz-plan-point-figure">
                                <span *ngIf="leg.from.name === 'Origin'"
                                    class="material-icons tranz-plan-point-figure-icon">location_on</span>
                                <span *ngIf="leg.from.name != 'Origin'"
                                    class="material-icons tranz-plan-point-figure-icon">gps_not_fixed</span>
                            </div>
                            <div class="tranz-plan-point-content">
                                <span *ngIf="leg.from.name === 'Origin'">Plecare</span>
                                <span *ngIf="leg.from.name != 'Origin'">{{leg.from.name}}</span>
                            </div>
                        </div>
                        <!-- path-->
                        <div class="tranz-plan-path" (click)="onSelectLegMenu(itinerary, leg)">
                            <div class="tranz-plan-path-head">
                                <span *ngIf="leg.mode == 'WALK'"
                                    class="material-icons tranz-plan-path-head-icon">directions_walk</span>
                                <span *ngIf="leg.mode == 'BUS'"
                                    class="material-icons tranz-plan-path-head-icon">directions_bus</span>
                                <span *ngIf="leg.mode == 'CAR'"
                                    class="material-icons tranz-plan-path-head-icon">directions_car</span>
        
                            </div>
                            <div class="tranz-plan-path-figure"><span class="tranz-plan-path-bar"></span></div>
                            <div class="tranz-plan-path-content">
                                <div *ngIf="leg.mode == 'BUS'">
                                    <span class="h6">{{leg.route}}</span>
                                </div>
                                <div>
                                    <span>{{getDuration(leg.startTime, leg.endTime)}}</span>
                                    <span>{{leg.distance.toFixed(0)}} m</span>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <!--stop or arive point-->
                        <div class="tranz-plan-point" *ngIf="leg.to.name === 'Destination'"
                            (click)="onSelectPointMenu(itinerary,leg.endTime, leg.to)">
                            <div class="tranz-plan-point-head">
                                <span>{{getdaytimeformat(leg.endTime)}}</span>
                            </div>
                            <div class="tranz-plan-point-figure">
                                <span *ngIf="leg.to.name === 'Destination'"
                                    class="material-icons tranz-plan-point-figure-icon">location_on</span>
                            </div>
                            <div class="tranz-plan-point-content">Sosire</div>
                        </div>
                    </div>
        
                </div>
            </div>
            <h6 *ngIf="tranzitData.activeSearchTrips" class="tranz-spiner-box">
                <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
            </h6>
            <h6 *ngIf="tranzitData.activeNoTrips">Nu a fost găsită nici o rută</h6>
        </div>
    </div>
</div>