<div class="modal-content">
    <div class="modal-header" *ngIf="actionType == null">
        <h5 class="modal-title" i18n>Administrare utilizator</h5>
    </div>
    <div class="modal-body" *ngIf="actionType == null">
        <div  class="list-group list-group-flush">
            <div *ngFor="let actionitem of actionList" class="list-group-item">
                <div class="d-flex">
                    <div class="flex-fill" (click)="onClickAction(actionitem)">
                        {{actionitem.description}}
                    </div>
                    <div><i class="fas fa-angle-right"></i></div>
                </div>
            </div>
        </div>
    </div>
    <!--user action tools-->
    <div *ngIf="actionType && actionType?.name !== 'user-roles'">
        <register-edit-user (closeEvent)="menuToolCosed($event)" [actionType]="actionType"></register-edit-user>
    </div>
    <div *ngIf="actionType?.name === 'user-roles'">
        <user-roles (closeEvent)="menuToolCosed($event)" [actionType]="actionType"></user-roles>
    </div>
    <div class="modal-footer" *ngIf="actionType == null">
        <button type="button" class="btn" (click)="close()" i18n>Închide</button>
    </div>
</div>