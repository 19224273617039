//NG+
import { Component, OnInit, Input, Inject } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NGXLogger } from "ngx-logger";

import { ILayer, ISearchOnLayer, IItem, IItemNT, ISearchSettings, IMapConfig, searchType, searchConditions, featureId, ISearchCondition } from "../../definitions";
import { MapController } from "../../map/map-controller";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, OperatorFunction } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { equals, isUndefined } from "../../map/map-utils";


import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import GeoJSONFormater from "ol/format/GeoJSON";

@Component({
    selector: 'edit-search-info',
    providers: [],
    templateUrl: './edit-search-info.component.html',
    styleUrls: ['./edit-search-info.component.css']
})
export class EditSearchInfoComponent implements OnInit {
    private layers: Array<ILayer>;
    public searchOnLayer: Array<ISearchOnLayer>;
    public newSearchOnLayerName: IItem;
    public newSearchOnLayerOptions: Array<IItem>;
    public conditionOperator: Array<IItemNT>;
    //
    public searchTab: string;//multilayer//layerfeature
    public bufferDistance: string = '10';
    private mapSearchSettings: ISearchSettings;
    public sugetionFeatues: Array<Feature>;
    public sugestionList: Array<any>;
    public mapConfig: IMapConfig;
    public disableInput;
    public errorResponse;

    @Input() data: any;
    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
    ) {
        //
    }

    public ngOnInit(): void {
        this.searchTab = searchType.multilayer;
        this.searchOnLayer = [];
        this.layers = [];
        this.conditionOperator = [];
        this.sugetionFeatues = [];
        this.sugestionList = [];
        this.newSearchOnLayerOptions = [];
        //
        if (this.data.layers && this.data.searchSettings && this.data.mapConfig) {
            this.layers = this.data["layers"] as Array<ILayer>
            this.mapSearchSettings = this.data["searchSettings"] as ISearchSettings;
            this.searchTab = this.mapSearchSettings.type;
            this.bufferDistance = this.mapSearchSettings.bufferDistance;
            this.mapConfig = this.data["mapConfig"];
            this.buildLayerSelectList();
            this.newSearchOnLayerName = null;
        }
        else {
            this.$log.warn("straturile pentru cautare nu exista");
            this.activeModal.close(false);
        }
        //
        this.layers.forEach((litem) => {
            if (litem.search && litem.search.conditions && litem.search.conditions.length > 0) {
                this.searchOnLayer.push(litem.search);
            }
        })
        //
        this.conditionOperator = searchConditions;
        //
        if (this.searchTab === searchType.layerfeature) {
            if (this.mapSearchSettings.feature) {
                this.sugetionFeatues.push(this.mapSearchSettings.feature);
                this.buildSugestionList();

            }
        }
    }

    public changeSearchType(tab: any): void {
        this.searchTab = tab;
        //
        if (this.searchTab === searchType.multilayer) {

        }
        else if (this.searchTab === searchType.layerfeature) {
            //only one layer must remain
            if (this.searchOnLayer.length > 1) {
                for (var i = this.searchOnLayer.length - 1; i >= 1; i--) {
                    this.removeSearchLayer(this.searchOnLayer[i]);
                }
            }
        }
    }

    public addSearchVisible() {
        if (this.searchTab === searchType.multilayer) {
            //return this.buildLayerSelectList().length > 0;
            return (this.layers.length - this.searchOnLayer.length) > 0;
        }
        else if (this.searchTab === searchType.layerfeature) {
            if (this.searchOnLayer.length > 0) {
                return false;
            }
            else {
                return (this.layers.length - this.searchOnLayer.length) > 0;
                //return this.buildLayerSelectList().length > 0;
            }
        }
    }

    public addSearchLayer(): void {
        if (this.newSearchOnLayerName) {
            let layersWithId: ILayer[] = this.layers.filter((lay, index, ar) => {
                return (lay.id === this.newSearchOnLayerName.id)
            });
            if (layersWithId && layersWithId.length > 0) {
                let layer = layersWithId[0];
                layer.search = { layer: layer, conditions: [] };
                this.searchOnLayer.push(layer.search);
                //
            }
        }
        //
        if (this.searchTab === searchType.layerfeature) {
            this.clearSugestions();
        }
        this.buildLayerSelectList();
        this.newSearchOnLayerName = null;
    }

    public removeSearchLayer(layer: ISearchOnLayer): void {
        if (layer) {
            let idSrc = this.searchOnLayer.indexOf(layer);
            let newSrcOn: IItem = { id: layer.layer.id, text: layer.layer.name };
            layer.layer.search = null;
            this.searchOnLayer.splice(idSrc, 1);
        }
        //
        if (this.searchTab === searchType.layerfeature) {
            this.clearSugestions();
        }
        this.buildLayerSelectList();
        this.newSearchOnLayerName = null;
    }

    //Typeahead search
    public searchPropertyList: (propertyName: string, srchLayer: ISearchOnLayer, filterValue: string) => OperatorFunction<string, readonly string[]>
        = (propertyName: string, srchLayer: ISearchOnLayer, filterValue: string) => (text$: Observable<string>) => {
            return text$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                map((term) => {
                    return this.buildPropertySearchList(propertyName, srchLayer, term).slice(0, 10);
                }),
            );
        }
    //Typeahead search list for property text
    public buildPropertySearchList(propertyName: string, srchLayer: ISearchOnLayer, filterValue: string) {
        let returnList = new Array<string>();
        if (isUndefined(propertyName) || isUndefined(srchLayer) || isUndefined(filterValue)) {
            return returnList;
        }
        let features = (srchLayer.layer.internalLayer as VectorLayer<any>).getSource().getFeatures();
        //
        features.forEach((fitem) => {
            let itemValue = '';
            if (propertyName === featureId) {
                itemValue = fitem.getId().toString();
            } else {

                itemValue = fitem.get(propertyName);
            }
            //
            itemValue = (itemValue == null) ? "" : itemValue.toString();
            //
            if (itemValue) {
                if (filterValue === "" || itemValue.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0) {
                    let itemExist = returnList.filter((ritem) => { return ritem === itemValue });
                    if (!itemExist || itemExist.length <= 0) {
                        returnList.push(itemValue);
                    }
                }
            }
        });

        return returnList;
    }

    //combobox select layer drop down list
    public buildLayerSelectList() {
        let returnList = new Array<IItem>();
        //
        this.layers.forEach((litem) => {
            let layerExsits = this.searchOnLayer.filter((srcLayItem) => { return srcLayItem.layer.name === litem.name; })
            if (layerExsits && layerExsits.length > 0) {
            } else {
                let tmpitm: IItem = { id: litem.id, text: litem.name };
                returnList.push(tmpitm);
            }
        });
        //
        if (!equals(returnList, this.newSearchOnLayerOptions)) {
            this.newSearchOnLayerOptions = returnList;
        }
        //
        return this.newSearchOnLayerOptions;
    }

    //combobox select drop down list filtered
    public buildPropertySelectList(srchLayer: ISearchOnLayer) {
        if (!srchLayer.newSrcPropItems) {
            srchLayer.newSrcPropItems = [];
        }
        let returnList = new Array<string>();
        let searchList = new Array<string>();
        //
        searchList.push(featureId);
        srchLayer.layer.infoColumns.forEach((infoItem) => {
            searchList.push(infoItem.name);
        });
        //
        searchList.forEach((infColItem) => {
            let conditionExist = srchLayer.conditions.filter((condItem) => { return condItem.propertyName === infColItem; });
            if (conditionExist && conditionExist.length > 0) {
            } else {
                returnList.push(infColItem);
            }
        });
        if (!equals(srchLayer.newSrcPropItems, returnList)) {
            srchLayer.newSrcPropItems = returnList;
        }
        return srchLayer.newSrcPropItems;
    }



    public addSearchProperty(searchLayer: ISearchOnLayer): void {
        if (searchLayer && searchLayer.newSrcProperty) {
            let tmpcond: ISearchCondition = { propertyName: searchLayer.newSrcProperty, condition: this.conditionOperator[0], searchText: '' };
            searchLayer.conditions.push(tmpcond);
        }
        searchLayer.newSrcProperty = null;
        //
        if (this.searchTab === searchType.layerfeature) {
            this.clearSugestions();
        }
    }

    public removeSearchProperty(searchLayer: ISearchOnLayer, searchCond: ISearchCondition): void {
        if (searchLayer && searchCond) {
            let srcId = searchLayer.conditions.indexOf(searchCond);
            searchLayer.conditions.splice(srcId, 1);
        }
        //
        if (this.searchTab === searchType.layerfeature) {
            this.clearSugestions();
        }
    }

    public buildSugestionList() {
        this.sugestionList = [];
        if (this.searchOnLayer.length > 0 && this.sugetionFeatues.length > 0) {
            let tmpSearchOnLayer = this.searchOnLayer[0];
            if (tmpSearchOnLayer.conditions && tmpSearchOnLayer.conditions.length > 0) {
                this.sugetionFeatues.forEach((ifeature) => {
                    let props = ifeature.getProperties();
                    let tmpItem = {
                        id: ifeature.getId(), values: {}
                    };
                    tmpSearchOnLayer.conditions.forEach((icond) => {
                        if (icond.propertyName != featureId) {
                            tmpItem.values[icond.propertyName] = ifeature.get(icond.propertyName);
                        }
                    })
                    this.sugestionList.push(tmpItem);
                });
            }
        }
    }

    public clearSugestions() {
        this.sugetionFeatues = [];
        this.sugestionList = [];
    }

    public selectSugestion(item: any) {
        //keep only one sugestion
        if (this.sugestionList.length > 1) {
            this.sugestionList = [];
            this.sugestionList.push(item);
        }
    }

    public enableSugestButton() {
        return (this.searchOnLayer.length > 0);
    }

    public sugest() {
        if (this.searchOnLayer.length > 0) {
            let tmpSearchOnLayer = this.searchOnLayer[0];
            if (tmpSearchOnLayer.conditions && tmpSearchOnLayer.conditions.length > 0) {
                tmpSearchOnLayer.layer.search = tmpSearchOnLayer;
                //
                let tmpFeatures = (tmpSearchOnLayer.layer.internalLayer as VectorLayer<any>).getSource().getFeatures();
                this.sugetionFeatues = [];
                for (var i = 0; i < tmpFeatures.length; i++) {
                    let feature = tmpFeatures[i];
                    if (MapController.advanceSearchInFeature(feature, tmpSearchOnLayer.layer)) {
                        this.sugetionFeatues.push(feature);
                    }
                    // only first 5 
                    if (this.sugetionFeatues.length > 5) { break; }
                }
                //
                this.buildSugestionList();
            }
            else {
                // no conditions
            }
        }
        else {
            //todo
        }

    }

    public enableSearchButton() {
        if (this.searchTab === searchType.multilayer) {
            return this.searchOnLayer.length > 0;
        }
        else {
            return (this.searchOnLayer.length > 0) && (this.sugestionList.length === 1)
        }
    }

    public search(): void {
        this.mapSearchSettings.type = this.searchTab;
        if (this.searchTab === searchType.layerfeature) {
            if (this.sugestionList.length != 1) {
                this.$log.info("trebuie sa fie un obiectiv in lista sugestii");
                return;
            }
            //
            this.mapSearchSettings.layer = this.searchOnLayer[0].layer;
            //
            let tmpid = this.sugestionList[0]["id"];
            let tmpfeature = this.sugetionFeatues.filter((feature) => feature.getId() === tmpid)[0];
            this.mapSearchSettings.feature = tmpfeature;
            let geomProj = tmpfeature.clone().getGeometry().transform(this.mapConfig.projection, 'EPSG:4326');
            var geojsonFormat = new GeoJSONFormater();
            var polySelectionGeoJson = geojsonFormat.writeGeometryObject(geomProj);
            this.mapSearchSettings.geometry = turf.buffer(polySelectionGeoJson as any, Number(this.bufferDistance), { units: 'meters' });
            this.mapSearchSettings.bufferDistance = this.bufferDistance;
        }
        this.layers.forEach((litem) => {
            litem.internalLayer.changed();
        });
        let hasSearch = this.searchOnLayer.length > 0 ? true : false;
        this.activeModal.close(hasSearch);
    }

    public cancel(): void {
        this.layers.forEach((litem) => {
            if (litem.search && litem.search.conditions) {
                litem.search.conditions = [];
            }
            litem.internalLayer.changed();
        });
        this.mapSearchSettings.type = searchType.multilayer;
        this.activeModal.close(false);
    }
}
