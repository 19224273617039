//NG
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment, { Moment } from "moment";

import { IFeatureInfo, ILayerFile, formatDateTime, ILayer, authOpt, authType, authItemDateTime, authItemAccess, editFeatureInfo, layerFileType, layerFilesStatus, WFSTActionType } from "../../definitions";
import { MapController } from "../../map/map-controller";
import { UserSettingsService } from "../../services/user-settings-service";
import { WindowMessageService } from "../../services/window-message-service";
import { NGXLogger } from 'ngx-logger';
import { NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateMomentAdapter, NgbDateMomentParserFormatter } from '../../shared-components/ngb-moment-adapter-formater';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { DateTimePickerFormatsProvider } from '../../shared-components/datetime-picker-formater';
import { getExMessage, isNumber } from '../../map/map-utils';


import Feature from 'ol/Feature';
import Layer from 'ol/layer/Layer';

export const MY_MOMENT_FORMATS = {
    parseInput: 'DD/MM/YYYY HH:mm:ss',//'l LT',
    fullPickerInput: 'DD-MM-YYYY HH:mm:ss',//'l LT',
    datePickerInput: 'DD/MM/YYYY',//'l',
    timePickerInput: 'HH:mm:ss',//'LT',
   // monthYearLabel: 'MMM YYYY',//'MMM YYYY',
   // dateA11yLabel: 'LL',//'LL',
   // monthYearA11yLabel: 'MMMM YYYY',//'MMMM YYYY',
};

@Component({
    selector: 'edit-feature-info',
    providers: [I18n,
       // { provide: NgbDatepickerI18n, 
        //     useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
       // { provide: NgbDateParserFormatter, 
       //     useFactory: () => { return new NgbDateMomentParserFormatter("YYYY-MM-DD") } },
       // { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},
        { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
       // { provide: OWL_DATE_TIME_LOCALE, useValue: 'ro'},
       
       {
        provide: OWL_DATE_TIME_FORMATS,
        useFactory: (datepickerFormatsProvider: DateTimePickerFormatsProvider) => datepickerFormatsProvider.getFormats(),
        deps: [DateTimePickerFormatsProvider],
      },
       DateTimePickerFormatsProvider
    ],
    templateUrl: './edit-feature-info.component.html',
    styleUrls: ['./edit-feature-info.component.css']
})
export class EditFeatureInfoComponent implements OnInit {
    public featureProps: Array<IFeatureInfo>;
    public errorResponse: string = '';
    private feature: Feature;
    private layer: Layer;
    private featureGeometryName: string;
    public disableInput: boolean = false;
    public isEditElseInsert: boolean = false;
    //
    public infoOrCamera: boolean = true;
    public cameraOrPicture: boolean = false;
    public hasNewPicture: boolean = false;
    public cameraStream: MediaStream = null;
    public propertyPictureName: string = '';//'LINKPOZA';
    public propertyIdName: string = '';
    public pictureName: string = '';
    public pictureToUrl: any = null;

    public featureId = null;
    public selectedPictures = null;
    public pictureIds: Array<number> = [];
    public existingPictures: Array<ILayerFile> = [];
    public loadPictures: Array<ILayerFile> = [];
    //
    public controlType = {
        text: "text",
        textArea: "textArea",
        dateTimeLocal: "dateTimeLocal",
        rate: "rate",
        textPredefinit: "textPredefinit"
    }
    //public typeDateTimeLocal: string = "dateTimeLocal";
    //public typeRateInput: string = "typeRateInput";
    //
    public dateFormat = formatDateTime.date;
    public timeFormat = formatDateTime.time;
    public dateTimeFormat = formatDateTime.dateTime;
    private insertData: Array<{ key: string, value: any }>;

    @Input() data: any;
    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(UserSettingsService) private userSettingsSrvs: UserSettingsService,
        @Inject(WindowMessageService) private windowMessageSrvs: WindowMessageService,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
        //@Inject(NgbDateParserFormatter) private formaterService: NgbDateParserFormatter,
        //@Inject(DateTimeAdapter) private dateTimeAdapter: DateTimeAdapter<any>,
        @Inject(DateTimePickerFormatsProvider)private datepickerFormatsProvider: DateTimePickerFormatsProvider
        ) {
           // this.dateTimeAdapter.setLocale('ro-RO');
    }

    public ngOnInit(): void {

        let client = this.userSettingsSrvs.getCurrentUser().client;
        this.datepickerFormatsProvider.setFormatsFromClient(client);
        //
        if (client && client.formatDateTime && client.formatDateTime !== "") {
            this.dateTimeFormat = client.formatDateTime;
        }
        if (client && client.formatDate && client.formatDate !== "") {
            this.dateFormat = client.formatDate;
        }
        if (client && client.formatTime && client.formatTime !== "") {
            this.timeFormat = client.formatTime;
        }
        //
        if (("feature" in this.data) && ("layer" in this.data)) {
            this.feature = <Feature>this.data["feature"];
            this.layer = <Layer>this.data["layer"];
            this.isEditElseInsert = this.data['isEditElseInsert'];
            this.insertData = this.data['insertData'];
            this.featureProps = [];
            if (MapController.appLayer in this.layer) {
                //
                this.buildFeaturesInfo();
                this.addFeatureEditableInfo();
                //
                let geoms: Array<IFeatureInfo> = (<ILayer>this.layer[MapController.appLayer]).infoGeometry;
                if (geoms.length > 0) {
                    this.featureGeometryName = geoms[0].name;
                } else {
                    this.$log.error("elementul nu are info geometrie");
                    this.activeModal.close(false);
                }
                //load image from store
                if (this.pictureIds && this.pictureIds.length > 0) {
                    this.loadImagesStatusFromStore()
                        .then((result) => {
                            //todo
                        })
                        .catch((err) => {
                            this.$log.error('Eroare in incarcarea imaginii');
                        })
                }
            }
        }
        else {
            this.$log.warn("elementul sau stratul nu exista");
            this.activeModal.close(false);
        }
    }

    public multiFileSelected = (event) => {
        this.selectedPictures = event.target.files;
        this.prepareSelectedPictures();
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }

    private buildFeaturesInfo() {
        let props: Array<IFeatureInfo> = (<ILayer>this.layer[MapController.appLayer]).infoColumns;
        props.forEach((propItem: IFeatureInfo) => {
            let propValue = this.feature.get(propItem.name) || '';
            let featureProp: IFeatureInfo =
            {
                name: propItem.name,
                type: propItem.type,
                control: this.controlType.text,
                value: propValue
            };
            //set default value from enviroment value
            this.defaultValueFromOptionAtInsert(featureProp);
            //set default value form insert message data
            this.defaultValueFromDataAtInsert(featureProp);
            let isSpecialInput = false;
            // check if prop is datetime local
            let str = this.userSettingsSrvs.isAuthForItemOption(authOpt.in_utc_to_local_convert, (<ILayer>this.layer[MapController.appLayer]).name, propItem.name, authType.layer);
            if (str && str.length > 0) {
                isSpecialInput = true;
                let momDate = null;
                let format = this.dateTimeFormat;
                if (str === authItemDateTime.dateTime) {
                    momDate = moment(featureProp.value, this.dateTimeFormat);
                    format = this.dateTimeFormat;
                } else if (str === authItemDateTime.date) {
                    momDate = moment(featureProp.value, this.dateFormat);
                    format = this.dateFormat;
                } else if (str === authItemDateTime.time) {
                    momDate = moment(featureProp.value, this.timeFormat);
                    format = this.timeFormat;
                }
                //
                featureProp.value = momDate;
                featureProp.control = this.controlType.dateTimeLocal;
                featureProp['format'] = format;
                //
            }
            //text area
            if (isSpecialInput === false) {
                let str = this.userSettingsSrvs.isAuthForItemOption(authOpt.input_text_area, (<ILayer>this.layer[MapController.appLayer]).name, propItem.name, authType.layer);
                if (str && str.length > 0) {
                    isSpecialInput = true;
                    featureProp.control = this.controlType.textArea;
                    featureProp['rows'] = 10;
                }
            }
            if (isSpecialInput === false) {
                // check if prop is rate input and set config
                isSpecialInput = this.propRateInput(featureProp);
            }
            if (isSpecialInput === false) {
                //check if prop is text predefinit and set config
                isSpecialInput = this.propTextPredefinit(featureProp, propValue);
            }
            //
            this.featureProps.push(featureProp);
        });
    }
    //
    private propRateInput(featureProp: IFeatureInfo): boolean {
        let isRateInput = false;
        let ra = this.userSettingsSrvs.isAuthForItemOption_Name_FullInfo(authOpt.input_rate_item, this.layer['appLayer']['name'], featureProp.name, authType.layer);
        if (ra && ra.id >= 0) {
            isRateInput = true;
            featureProp.control = this.controlType.rate;
            featureProp['min'] = 0;
            featureProp['max'] = 5;
            featureProp['step'] = 0.2;
            //check settings for rate
            try {
                let minSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_min, this.layer['appLayer']['name'], ra.idItem, authType.layer);
                if (minSetting && isNumber(minSetting.descriere)) {
                    featureProp['min'] = Number(minSetting.descriere);
                }
                let maxSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_max, this.layer['appLayer']['name'], ra.idItem, authType.layer);
                if (maxSetting && isNumber(maxSetting.descriere)) {
                    featureProp['max'] = Number(maxSetting.descriere);
                }
                let stepSetting = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_rate_step, this.layer['appLayer']['name'], ra.idItem, authType.layer);
                if (stepSetting && isNumber(stepSetting.descriere)) {
                    featureProp['step'] = Number(stepSetting.descriere);
                }
            } catch (e) {
                this.$log.error("eroare extragere limite rate control")
            }
        }
        return isRateInput;
    }
    //
    private propTextPredefinit(featureProp: IFeatureInfo, propValue: any): boolean {
        let isTextPredefInput = false;
        let ra = this.userSettingsSrvs.isAuthForItemOption_Name_FullInfo(authOpt.input_text_predefinit, this.layer['appLayer']['name'], featureProp.name, authType.layer);
        if (ra && ra.id >= 0) {
            isTextPredefInput = true;
            try {
                let grupSettings = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_text_predefinit_grup, this.layer['appLayer']['name'], ra.idItem, authType.layer);
                if (grupSettings && grupSettings.descriere && grupSettings.descriere.length > 0) {
                    featureProp.control = this.controlType.textPredefinit;
                    featureProp['grup'] = grupSettings.descriere;
                    featureProp['dictionar'] = [];

                    //get no value input_text_predefinit_novalue
                    let novalueSettings = this.userSettingsSrvs.isAuthForItemOption_Id_FullInfo(authOpt.input_text_predefinit_novalue, this.layer['appLayer']['name'], ra.idItem, authType.layer);
                    if (novalueSettings) {
                        if (propValue !== novalueSettings.descriere) {
                            featureProp['dictionar'].push(novalueSettings.descriere);
                        }
                    }

                    // add curent value to selection list
                    if (propValue != null) {
                        featureProp['dictionar'].push(propValue);
                    }

                    //get list of predefined text from server
                    this.userSettingsSrvs.grupText.getTextPredefinitDictionar(grupSettings.descriere)
                        .then((value) => {
                            if (value && value.length > 0) {
                                value.forEach((item) => {
                                    if (item.text !== propValue) {
                                        featureProp['dictionar'].push(item.text);
                                    }
                                })
                            }
                        })
                        .catch((reason) => {
                            this.$log.error("eroare extragere dictionar text predefinit pentru grup ", getExMessage(grupSettings.descriere));
                        })
                }
            } catch (e) {
                this.$log.error("eroare extragere grup text control")
            }
        }
        return isTextPredefInput;
    }

    private defaultValueFromOptionAtInsert(featureProp: IFeatureInfo) {
        try {
            if (this.isEditElseInsert === false) {
                let str = this.userSettingsSrvs.isAuthForItemOption(authOpt.in_add_feature_default_idclient, (<ILayer>this.layer[MapController.appLayer]).name, featureProp.name, authType.layer);
                if (str && str.length > 0) {
                    featureProp.value = this.userSettingsSrvs.getCurrentUser().idClient
                }
            }
        } catch (error) {
            this.$log.error("Eroare la extragere valoare default pentru camp info: ", getExMessage(error));
        }
    }

    private defaultValueFromDataAtInsert(featureProp: IFeatureInfo) {
        try {
            if (this.isEditElseInsert === false) {
                let str = this.userSettingsSrvs.isAuthForItemOption(authOpt.in_add_feature_default_from_msg, (<ILayer>this.layer[MapController.appLayer]).name, featureProp.name, authType.layer);
                if (str && str.length > 0) {
                    if (this.insertData) {
                        let res = this.insertData.filter((fitem) => fitem.key === featureProp.name);
                        if (res && res.length > 0) {
                            featureProp.value = res[0].value;
                        }
                    }
                }
            }
        } catch (error) {
            this.$log.error("Eroare la extragere valoare default fom data messaj", getExMessage(error));
        }
    }

    addFeatureEditableInfo() {
        this.featureProps.forEach((ifprop) => {
            try {
                let in_edit_else_in_insert = this.isEditElseInsert ? authOpt.in_edit_feature : authOpt.in_add_feature;
                let itemAccess = this.userSettingsSrvs.isAuthForItemOption(in_edit_else_in_insert,
                    (<ILayer>this.layer[MapController.appLayer]).name, ifprop.name, authType.layer);
                //
                if (itemAccess && itemAccess.indexOf(authItemAccess.YEdit) >= 0) {
                    ifprop.edit = editFeatureInfo.write;
                } else if (itemAccess && itemAccess.indexOf(authItemAccess.YView) >= 0) {
                    ifprop.edit = editFeatureInfo.read;
                } else if (itemAccess && itemAccess.indexOf(authItemAccess.NView) >= 0) {
                    ifprop.edit = editFeatureInfo.hide;
                } else {
                    ifprop.edit = editFeatureInfo.write;
                }
                //
                if (itemAccess && itemAccess.indexOf(authItemAccess.NSave) >= 0) {
                    ifprop.save = authItemAccess.NSave;
                } else {
                    ifprop.save = authItemAccess.YSave;
                }
                //
                if (this.propertyPictureName == null || this.propertyPictureName == '') {
                    let itemPicture = this.userSettingsSrvs.isAuthForItemOption(authOpt.in_feature_is_picture,
                        (<ILayer>this.layer[MapController.appLayer]).name, ifprop.name, authType.layer);
                    if (itemPicture) {
                        this.propertyPictureName = ifprop.name;
                        if (ifprop.value) {
                            if (ifprop.value == null || ifprop.value === '') {
                                this.pictureIds = [];
                            } else {
                                let vals = (ifprop.value as string).split(',');
                                this.pictureIds = [];
                                vals.forEach((itval) => {
                                    let ind = Number(itval);
                                    if (isNaN(ind) === false) {
                                        this.pictureIds.push(ind);
                                    }
                                })
                            }
                            //this.pictureIds = ifprop.value;
                            // this.pictureName = ifprop.value;
                        }
                    }
                }
                if (this.propertyIdName == null || this.propertyIdName == '') {
                    let itemId = this.userSettingsSrvs.isAuthForItemOption(authOpt.in_feature_is_id,
                        (<ILayer>this.layer[MapController.appLayer]).name, ifprop.name, authType.layer);
                    if (itemId) {
                        this.propertyIdName = ifprop.name;
                        if (ifprop.value !== null && ifprop.value !== '') {
                            this.featureId = ifprop.value;
                        }
                    }
                }

            } catch (e) {
                this.$log.error("eroare extragere informatii editare ", getExMessage(ifprop.name));
                ifprop.edit = editFeatureInfo.read;
            }
        });
    }

    private setUserMedia() {
        window.navigator['getUserMedia'] = window.navigator['getUserMedia'] ||
            window.navigator['webkitGetUserMedia'] ||
            window.navigator['mozGetUserMedia'] ||
            window.navigator['msGetUserMedia'] || null;
    }

    private setCameraSource() {
        this.errorResponse = '';
        this.setUserMedia();
        if (window.navigator['getUserMedia']) {
            var videoPlaying = false;
            var constraints = {
                video: true,
                audio: false
            };
            var video: HTMLVideoElement = document.getElementById('videoCamera') as any;

            var media = (window.navigator as any).getUserMedia(constraints, (stream) => {
                this.cameraStream = stream;
                // URL Object is different in WebKit
                var url = window.URL || window['webkitURL'];

                // create the url and set the source of the video element
                //video.src = url ? url.createObjectURL(stream) : stream as any;
                video.srcObject = stream as any;
                // Start the video
                video.play();
                videoPlaying = true;
            }, (error) => {
                this.cameraOrPicture = false;
                this.errorResponse = ("eroare camera video");
                this.$log.error("eroare camera video", getExMessage(error));
            });
        } else {
            this.$log.error("camera foto nu poate fi accesata");
            this.errorResponse = "camera foto nu poate fi accesata";
            this.cameraOrPicture = false;
        }
    }
    //
    private capturePicture() {
        try {
            var video: HTMLVideoElement = document.getElementById('videoCamera') as any;
            var picture: HTMLImageElement = document.getElementById('pictureCamera') as any;
            var canvas: HTMLCanvasElement = document.getElementById('canvasCamera') as any;
            if (!video.paused) {
                //convert video to image
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0);
                let data = canvas.toDataURL('image/png');
                //convert image to file
                var blobBin = atob(data.split(',')[1]);
                var array = [];
                for (var i = 0; i < blobBin.length; i++) {
                    array.push(blobBin.charCodeAt(i));
                }
                let seqTime = (new Date()).getTime();
                let fblob = new Blob([new Uint8Array(array)], { type: 'image/png' });
                var file = new File([fblob], `li_${seqTime}.png`);
                //

                let fileLoad: ILayerFile = {
                    id: -1,
                    type: layerFileType.image,
                    seqTime: seqTime * (-1),
                    idRefLayerItem: this.featureId,
                    idClient: this.userSettingsSrvs.getCurrentUser().idClient as any,
                    idLayer: (this.layer['appLayer'] as ILayer).id,
                    idColName: this.propertyIdName,
                    fileColName: this.propertyPictureName,
                    fileName: `$li_${seqTime}.png`,
                    file: file,
                    status: layerFilesStatus.initializat,
                    description: '',
                    fileLocalURL: URL.createObjectURL(fblob)
                }
                this.loadPictures.push(fileLoad);
                this.pictureToUrl = fileLoad.fileLocalURL;
                this.pictureName = fileLoad.fileName;
                //
                video.pause();
                //video.src = null;
            }

        } catch (error) {
            this.$log.error("eroare la capturare imagine ", getExMessage(error));
        }
    }
    //
    public displayEPicture(loadfile: ILayerFile) {
        if (loadfile.id == null) {
            this.pictureToUrl = null;
            this.pictureName = '';
            return;
        }
        if (loadfile.fileLocalURL) {
            this.pictureToUrl = loadfile.fileLocalURL;
            this.pictureName = loadfile.fileName;
            return;
        }
        //load picuture form server
        this.userSettingsSrvs.layerFiles.getDownloadFile(loadfile.id)
            .then((image) => {
                if (image) {
                    loadfile.fileLocalURL = URL.createObjectURL(image);
                    this.pictureToUrl = loadfile.fileLocalURL;
                    this.pictureName = loadfile.fileName;

                }
            }).catch((reason) => {
                this.$log.error("eroare returnare imagine", getExMessage(reason));
                this.pictureToUrl = null;
            })

    }
    //
    public displayLPicture(loadfile: ILayerFile) {
        if (loadfile.fileLocalURL !== null) {
            this.pictureToUrl = loadfile.fileLocalURL;
        }
    }
    //old
    private setPictureSource() {
        var video: HTMLVideoElement = document.getElementById('videoCamera') as any;
        var picture: HTMLImageElement = document.getElementById('pictureCamera') as any;
        var canvas: HTMLCanvasElement = document.getElementById('canvasCamera') as any;
        if (!video.paused) {
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0);
            var data = canvas.toDataURL('image/webp');
            this.pictureToUrl = data;
            this.hasNewPicture = true;
            //generate new picture name if does't exist
            if (!this.pictureName) {
                this.pictureName = this.generateImageName();
                //set coresponding feature property for image name;
                if (this.propertyPictureName) {
                    let tmpPictureProp = this.featureProps.filter((fitem) => { return fitem.name === this.propertyPictureName; })[0];
                    if (tmpPictureProp) {
                        tmpPictureProp.value = this.pictureName;
                    } else {
                        this.$log.warn('proprietatea corespunzatoare denumirii imaginii nu exista');
                    }
                } else {
                    this.$log.warn('proprietatea corespunzatoare denumirii imaginii nu este definita');
                }
            }
            //picture.setAttribute('src', data);
            video.pause();
            //video.src = null;
        }
    }

    public infoOrCameraChange() {
        try {
            this.infoOrCamera = !this.infoOrCamera;
            if (this.infoOrCamera) {
                this.cameraOrPicture = false;
                if (this.cameraStream) {
                    this.cameraStream.getVideoTracks().forEach(function (track) {
                        track.stop();
                    });
                }
            } else {
                //this.setCameraSource();
            }
        } catch (error) {
            this.$log.error("eroare schimbare info sau poze: ", getExMessage(error));
        }
    }

    public camerOrPictureChange() {
        try {
            this.cameraOrPicture = !this.cameraOrPicture;
            if (this.cameraOrPicture) {
                this.setCameraSource();
            } else {
                //this.setPictureSource();
                this.capturePicture();
            }
        } catch (error) {
            this.$log.error("eroare schimbare camera sau poze: ", getExMessage(error));
        }
    }
    //
    public stopCamera() {
        if (this.cameraStream) {
            this.cameraStream.getVideoTracks().forEach(function (track) {
                track.stop();
            });
        }
        this.cameraOrPicture = false;
    }


    private loadImagesStatusFromStore(): Promise<any> {
        //load images status
        this.existingPictures.length = 0;
        let promisses: any = [];
        this.pictureIds.forEach((item) => {
            promisses.push(this.userSettingsSrvs.layerFiles.getFileStatus(item));
        })
        return Promise.all(promisses)
            .then((results) => {
                if (results && Array.isArray(results) && results.length > 0) {
                    results.forEach((result) => {
                        this.existingPictures.push(result as any);
                    });
                }
            })
        //load images files
    }


    private generateImageName(): string {
        return "l" + (<ILayer>this.layer[MapController.appLayer]).id + "-f" + this.feature.getId() + "-n" + Math.random().toString(36).substring(2, 15);
    }

    public save(): void {
        //salveaza in gis server
        this.errorResponse = ".. salvare modificari";
        let iLayerInf = null;
        let tmpFeature: Feature = null;
        try {
            iLayerInf = (<ILayer>this.layer[MapController.appLayer]);
            tmpFeature = new Feature();// this.feature.clone();
            let mapProjection = this.userSettingsSrvs.getCurrentUser().mapConfig['projection'];
            tmpFeature.setId(this.feature.getId());
            tmpFeature.setGeometry(this.feature.getGeometry().transform(mapProjection, iLayerInf.projection || mapProjection));
            tmpFeature.setGeometryName(this.featureGeometryName);
            this.featureProps.forEach((propItem) => {
                if (propItem.save === authItemAccess.YSave) {
                    if (propItem.control === this.controlType.dateTimeLocal) {
                        tmpFeature.set(propItem.name, (propItem.value).toISOString() || null)
                        //
                    } else {
                        tmpFeature.set(propItem.name, propItem.value);
                    }
                }
            });
        } catch (e) {
            this.errorResponse = "eroare pregatire feature"
            return;
        }

        //
        this.disableInput = true;
        Promise.resolve(true).then(() => {
            //save image if configuration and property is defined
            if (this.propertyPictureName) {
                //d1 todo replace with images
                return (this.deleteExistingPictures() as any)
                    .then((result) => {
                        return this.saveLoadPictures()
                            .then((res) => {
                                let newPictureIds = [];
                                this.existingPictures.forEach((eitem) => {
                                    if (eitem.status === layerFilesStatus.incarcat
                                        || eitem.status === layerFilesStatus.finalizat
                                        || eitem.status === layerFilesStatus.eroare) {
                                        newPictureIds.push(eitem.id);
                                    }
                                });
                                this.loadPictures.forEach((litem) => {
                                    if (litem.id >= 0 && litem.status == layerFilesStatus.incarcat) {
                                        newPictureIds.push(litem.id);
                                    }
                                })
                                tmpFeature.set(this.propertyPictureName, newPictureIds);
                                return true;
                            })
                    })
            } else {
                return null;
            }
        })
            .then((success) => {
                if (success == null || success == true) {
                    //save feature to server
                    return this.userSettingsSrvs.setFeatureToGisServerWFST(
                        iLayerInf, tmpFeature, this.isEditElseInsert ? WFSTActionType.updateInfo : WFSTActionType.insertFeature) as any;
                } else {
                    // todo
                    throw new Error("error in save image");
                }
            })
            .then((result) => {
                if (result === false) {
                    this.errorResponse = "erroare in salvare modificari tfs";
                    throw new Error(this.errorResponse);
                }
                // finalizare incarcare imagini
                if (this.loadPictures && this.loadPictures.length > 0) {
                    let featureId = null;
                    if (this.isEditElseInsert === false) {

                        let newFeatureFullId = result["insertIds"][0] as string;
                        if (newFeatureFullId == null) {
                            throw new Error("eroare id returnat de tranzactia wfst");
                        }
                        featureId = Number(newFeatureFullId.split('.').pop());
                    }
                    return this.finalizeLoadPictures(featureId)
                        .then((res) => {
                            return result;
                        })
                } else {
                    return result;
                }
            })
            .then((result) => {
                //send window message
                if (this.isEditElseInsert) {
                    this.windowMessageSrvs.sendWindowMessageFeatureChanged(iLayerInf.name, WFSTActionType.updateInfo, null, tmpFeature)
                } else {
                    this.windowMessageSrvs.sendWindowMessageFeatureChanged(iLayerInf.name, WFSTActionType.insertFeature, result, null)
                }

                //modifica local
                this.featureProps.forEach((propItem) => {
                    this.feature.set(propItem.name, propItem.value);
                });
                this.errorResponse = "";
                this.activeModal.close(true);

            })
            .catch((reason) => {
                this.$log.error("exceptie in salvarea modificarilor", getExMessage(reason));
                this.errorResponse = "exceptie in salvarea modificarilor"
            })
            .finally(() => {
                this.disableInput = false;
            });
    }

    public cancel(): void {
        this.activeModal.close(false);
    }

    //pictures
    public prepareSelectedPictures() {
        this.errorResponse = '';
        this.loadPictures.length = 0;
        let seqTime = (new Date()).getTime() * (-1)
        if (this.selectedPictures == null || this.selectedPictures.length == 0) {
            return;
        }
        //
        for (let index = 0; index < this.selectedPictures.length; index++) {
            const file = this.selectedPictures[index];
            try {
                const filename = file.name.split('.');
                if (filename.length !== 2) {
                    throw Error('denumire fisier nu contine doua parti(nume, extensie)');
                }
                if (filename[1].toLowerCase() !== 'jpg'
                    && filename[1].toLowerCase() !== 'jpeg'
                    && filename[1].toLowerCase() !== 'png'
                    && filename[1].toLowerCase() !== 'gif'
                ) {
                    throw Error('extensia fisier trebuie sa fie .jpg .jpeg .png .gif');
                }
                //
                let fileLoad: ILayerFile = {
                    id: -1,
                    type: layerFileType.image,
                    seqTime: seqTime - index,
                    idRefLayerItem: this.featureId,
                    idClient: this.userSettingsSrvs.getCurrentUser().idClient as any,
                    idLayer: (this.layer['appLayer'] as ILayer).id,
                    idColName: this.propertyIdName,
                    fileColName: this.propertyPictureName,
                    fileName: file.name,
                    file: file,
                    status: layerFilesStatus.initializat,
                    description: '',
                    fileLocalURL: URL.createObjectURL(file)
                }
                this.loadPictures.push(fileLoad);
                if (index === 0) {
                    this.pictureToUrl = fileLoad.fileLocalURL;
                    this.pictureName = fileLoad.fileName;
                }
            } catch (error) {
                this.$log.error('eroare pregatire fisier ', getExMessage(file.name));
            }
        }
    }
    //
    public deleteExistingPictures(): Promise<any> {
        if (this.propertyPictureName && this.existingPictures && this.existingPictures.length > 0) {
            let promisses: Promise<{ id: number, success: boolean, message: string }>[] = [];
            this.existingPictures.forEach((item) => {
                if (item.status == layerFilesStatus.pt_inactivare) {
                    promisses.push(this.userSettingsSrvs.layerFiles.setFileStatus(item.id, layerFilesStatus.inactiv, '', null));
                }
            });
            return Promise.all(promisses)
                .then((results) => {
                    if (results && Array.isArray(results) && results.length > 0) {
                        let hasError = false;
                        results.forEach((item) => {
                            if (item.success == false) {
                                hasError = true;
                            }
                            let existingres = this.existingPictures.filter((fitm) => fitm.id === item.id);
                            if (existingres && existingres.length > 0) {
                                if (item.success) {
                                    existingres[0].status == layerFilesStatus.inactiv;
                                    existingres[0].description = '';
                                } else {
                                    existingres[0].status == layerFilesStatus.eroare;
                                    existingres[0].description = item.message;
                                }
                            }
                        });
                        //
                        if (hasError) {
                            throw new Error('Eroare la stergere imagine atasata');
                        }
                        return true;
                    } else {
                        return Promise.resolve(null);
                    }
                })
        } else {
            return Promise.resolve(null);
        }
    }
    //
    public saveLoadPictures(): Promise<any> {
        if (this.propertyPictureName && this.loadPictures && this.loadPictures.length > 0) {

            let promisses: any = [];
            this.loadPictures.forEach((item) => {
                if (item.id < 0) {
                    promisses.push(this.userSettingsSrvs.layerFiles.setUploadFile(item, item.file));
                }
            });
            //
            return Promise.all(promisses)
                .then((results) => {
                    if (results && Array.isArray(results) && results.length > 0) {
                        let hasError = false;
                        for (let index = 0; index < results.length; index++) {
                            const element = results[index] as ILayerFile;
                            //
                            let findLoadItem = this.loadPictures.filter((item) => item.seqTime === element.seqTime);
                            if (findLoadItem && findLoadItem.length > 0) {
                                let loadItem = findLoadItem[0];
                                //
                                if (element.status === layerFilesStatus.eroare) {
                                    hasError = true;
                                } else {
                                    loadItem.id = element.id;
                                }
                                //
                                loadItem.status = element.status;
                                loadItem.description = element.description;
                            }
                        }
                        if (hasError) {
                            throw new Error("Eroare in upload imagine");
                        }
                        return true;
                    } else {
                        return Promise.resolve(null);
                    }
                })
        } else {
            return Promise.resolve(null);
        }
    }
    //
    public finalizeLoadPictures(idFeature: number): Promise<any> {
        if (this.propertyPictureName && this.loadPictures && this.loadPictures.length > 0) {

            let promisses: any = [];
            this.loadPictures.forEach((item) => {
                if (item.id >= 0 && item.status == layerFilesStatus.incarcat) {
                    promisses.push(this.userSettingsSrvs.layerFiles.setFileStatus(item.id, layerFilesStatus.finalizat, 'client app', idFeature));
                }
            })
            //
            return Promise.all(promisses)
                .then((results) => {
                    if (results && Array.isArray(results) && results.length > 0) {
                        let hasError = false;
                        for (let index = 0; index < results.length; index++) {
                            const element = results[index] as ILayerFile;
                            //
                            let findLoadItem = this.loadPictures.filter((item) => item.id === element.id);
                            if (findLoadItem && findLoadItem.length > 0) {
                                let loadItem = findLoadItem[0];
                                //
                                if (element.status === layerFilesStatus.eroare) {
                                    hasError = true;
                                } else {
                                    loadItem.idRefLayerItem = element.idRefLayerItem;
                                }
                                //
                                loadItem.status = element.status;
                                loadItem.description = element.description;
                            }
                        }
                        if (hasError) {
                            throw new Error("Eroare in finalizare upload imagine");
                        }
                        return true;
                    } else {
                        return Promise.resolve(null);
                    }
                })
        } else {
            return Promise.resolve(null);
        }
    }
    //
    public removeLoadPicture(file: ILayerFile) {
        if (file) {
            //if curent picture display
            if (file.fileLocalURL && file.fileLocalURL === this.pictureToUrl) {
                this.pictureToUrl = null;
                this.pictureName = '';
            }
            //get picture index
            let index = this.loadPictures.indexOf(file);
            this.loadPictures.splice(index, 1);
        }
    }
    //
    public removeExistingPicture(file: ILayerFile) {
        if (file) {
            if (file.status === layerFilesStatus.incarcat
                || file.status === layerFilesStatus.finalizat
                || file.status === layerFilesStatus.eroare
            ) {
                file.status = layerFilesStatus.pt_inactivare;
            }
        }
    }
    //
    public sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }
}
