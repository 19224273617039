<div class="modal-content">

    <div class="modal-header">
        <h5 class="modal-title" i18n>Detalii locatie </h5>
    </div>
    <!--properties-->
    <div class="modal-body">
        <label i18n>Informatii locatie:</label>
        <button class="btn-xs btn-light float-right" data-toggle="collapse" data-target="#collapseRapEatureInfo">V</button>
        <div class="collapse" id="collapseRapEatureInfo">
            <div class="form-group" *ngFor="let property of raportFeatureProps" [hidden]="property.edit == 'hide'">
                <label for="name">{{property.name}}</label>
                <div *ngIf="property.control === controlType.text">
                    <p type="text" class="form-control" id="{{property.name}}">{{property.value}}</p>
                </div>
                <!-- text area-->
                <div *ngIf="property.control === controlType.textArea">
                    <textarea class="form-control" rows="{{property.rows}}" readonly>{{property.value}} </textarea>
                </div>
                <!-- rate input -->
                <div *ngIf="property.control === controlType.rate">
                    <ngb-rating id="{{property.name}}" name="property.name" [max]="property.max" [(rate)]="property.value" [readonly]="true">
                        <ng-template let-fill="fill">
                            <i class="bi-star-fill">★
                                <i *ngIf="fill>0" class="bi-star-fill bi-star-filled" [style.width.%]="fill">★</i>
                            </i>
                        </ng-template>
                    </ngb-rating>
                    <!-- <ng-rate-it id="{{property.name}}" name="property.name" [(ngModel)]="property.value" min="property.min" max="property.max" step="property.step" read-only="true"></ng-rate-it> -->
                </div>
            </div>
        </div>
        <div *ngIf="selectedFeature">
            <label i18n>Informatii conexe:  {{selectedFeatureId + 1}} of {{connectedFeaturesCount}}</label>
            <div class="form-group" *ngFor="let property of connectedFeatureProps" [hidden]="property.edit == 'hide'">
                <label for="name">{{property.name}}</label>
                <div *ngIf="property.control === controlType.text">
                    <p type="text" class="form-control" id="{{property.name}}">{{property.value}}</p>
                </div>
                <!-- text area-->
                <div *ngIf="property.control === controlType.textArea">
                    <textarea class="form-control" rows="{{property.rows}}" readonly>{{property.value}} </textarea>
                </div>
                <!-- rate input -->
                <div *ngIf="property.control === controlType.rate">
                    <ngb-rating id="{{property.name}}" name="property.name" [max]="property.max" [(rate)]="property.value" [readonly]="true">
                        <ng-template let-fill="fill">
                            <i class="bi-star-fill">★
                                <i  *ngIf="fill>0" class="bi-star-fill bi-star-filled" [style.width.%]="fill">★</i>
                            </i>
                        </ng-template>
                    </ngb-rating>
                    <!-- <ng-rate-it id="{{property.name}}" name="property.name" [(ngModel)]="property.value" min="property.min" max="property.max" step="property.step" read-only="true"></ng-rate-it> -->
                </div>
            </div>
        </div>
    </div>


    <div class="modal-footer">
        <button type="button" class="btn btn-light float-left" (click)="prev()" ng-disabled="!enablePrev()" i18n>Precedent</button>
        <button type="button" class="btn btn-light float-left" (click)="next()" ng-disabled="!enableNext()" i18n>Urmator</button>

        <button type="button" class="btn float-right" (click)="cancel()" i18n>Închide</button>
    </div>

</div>