 
 
//
import { NGXLogger } from "ngx-logger";
import { AppSettings } from "../app-settings";
import { ICategory, ILayer, authOpt, authType, featureType, isFeatureTypeForCluster, styleType, searchType, featureTypeForVector, ILayerReportSettings, formatDateTime, authItemDateTime, basemaps } from "../definitions";
import { UserSettingsService } from "../services/user-settings-service";
import { MapController } from "./map-controller";
import { GeoserverSldStyleParser } from "./map-sldRaserStyle";
import { getExMessage, isDefined } from "./map-utils";
import { reportFormulaProcessData, reportFormulaCheckParams } from "./report-layer-formulas";
import moment, { Moment } from "moment";


import { Map as olMap } from "ol";
import {View as olView} from "ol";
import {defaults as olctrldefaults} from 'ol/control/defaults';
import BaseLayer from 'ol/layer/Base';
import VectorLayer from 'ol/layer/Vector';
import Select from 'ol/interaction/Select';
import Feature from 'ol/Feature';
import  MultiPolygon  from "ol/geom/MultiPolygon";
import TileLayer from 'ol/layer/Tile';
import { TileGrid } from "ol/tilegrid";
import HeatmapLayer from "ol/layer/Heatmap";
import ImageLayer from 'ol/layer/Image';
import * as olExtent from 'ol/extent';
import VectorSource from "ol/source/Vector";
import  TileWMSSource  from "ol/source/TileWMS";
import ClusterSource from 'ol/source/Cluster';
import OSMSource from 'ol/source/OSM';
import ImageWMSSource from 'ol/source/ImageWMS';
import GeoJSONFormater from "ol/format/GeoJSON";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olProj from 'ol/proj';
import * as olLoadingstrategy from 'ol/loadingstrategy';
import MapBrowserEvent from "ol";
import SelectCluster from 'ol-ext/interaction/SelectCluster';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';

import proj4 from 'proj4';
import {get as getProjection, transformExtent} from 'ol/proj.js';
import {register} from 'ol/proj/proj4.js';

//declare var proj4: any;
declare var saveAs: (blob: any, filename: any) => any;


    export class MapOlInitialization {

        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };

        public buildOlMap() {
            if (this.mapCtrl.map) {
                this.mapCtrl.map = null;
            }
            //
            this.mapCtrl.map = new olMap({
                target: 'map',
                layers: [],
                //view: this.buildMapView(),
                controls: olctrldefaults({
                    attributionOptions: ({
                        collapsible: true
                    })
                })
            });

            this.setMapView();
            this.addBasemap();
            
            this.mapCtrl.map.updateSize();
            this.setZoomControlIcons();
            //
            this.mapCtrl.mapCtrlSelectFeature.buildSelectFeatureInteraction();
            this.buildSelectClusterInteraction();//test         
        }

        public addBasemap() {
            if (this.mapCtrl.basemap) {
                this.mapCtrl.map.removeLayer(this.mapCtrl.basemap);
                this.mapCtrl.basemap = null;
            }
            if (this.mapCtrl.mapConfig.basemap) {
                let basemapItem =  basemaps.find((item)=>item.text === this.mapCtrl.mapConfig.basemap);
                //if (this.mapCtrl.mapConfig.basemap.toUpperCase() === "OSM") {
                if (basemapItem && basemapItem.id >= 0){
                    this.mapCtrl.basemap = new TileLayer<any>({
                        source: new OSMSource({
                            url: AppSettings.serverPath + `/layer/osm/${basemapItem["tip"]}/{z}/{x}/{y}.png`
                            // url direct
                            //url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                        })
                    })
                } else {
                    this.$log.log("nu exista basemap specificat")
                }
                //
                if (this.mapCtrl.basemap) {
                    this.mapCtrl.map.addLayer(this.mapCtrl.basemap);
                    this.mapCtrl.basemap.setZIndex(-10000);
                }
            } else {
                this.$log.log("nu este stat basemap")
            }
        }

        public buildMapView() {
            var projection: any = this.mapCtrl.mapConfig.projection;
            try {
                //make all map projections available
                this.mapCtrl.userSettingsSrvs.getCurrentUser().mapProjections.forEach((projItem) => {
                    proj4.defs(projItem.proiectie, projItem.proj4text);
                });
                //get projection for map view
                if (proj4.defs(this.mapCtrl.mapConfig.projection)) {
                    projection = olProj.get(this.mapCtrl.mapConfig.projection);
                } else {
                    let projections = this.mapCtrl.userSettingsSrvs.getCurrentUser().mapProjections.filter(projItem => projItem.proiectie.toUpperCase() === this.mapCtrl.mapConfig.projection.toUpperCase());
                    if (projections && projections.length > 0) {
                        proj4.defs(this.mapCtrl.mapConfig.projection, projections[0].proj4text);
                        projection = olProj.get(this.mapCtrl.mapConfig.projection);
                    }
                }
                register(proj4);
            } catch (e) {
                this.$log.error(e.message);
            }
            return new olView({
                projection: projection,
                center: olProj.fromLonLat(this.mapCtrl.mapConfig.center, this.mapCtrl.mapConfig.projection),
                zoom: this.mapCtrl.mapConfig.zoom,
                minZoom: this.mapCtrl.mapConfig.minZoom,
                maxZoom: this.mapCtrl.mapConfig.maxZoom
            });
        }

        public setZoomControlIcons(){
            $(".ol-zoom-out").html('<i class="fas fa-search-minus"></i>');
            $(".ol-zoom-in").html('<i class="fas fa-search-plus"></i>');
        }

        public setMapView(){
            this.mapCtrl.map.setView(this.buildMapView());
            this.mapCtrl.map.getView().on('change:resolution', this.onMapChangeZoom);
        }

        public onMapChangeZoom = (event: any) => {
            //fix for cluster layer selection on zoom change
            let tmp = this.mapCtrl.selectCluster as Select;
            if (tmp){
                //tmp.setActive(false);
                (tmp as any).clear();
                tmp.changed();
            }
            //hide over zoom level
            
            this.mapCtrl.categories.forEach((citem: ICategory, index: number) => {
                if (citem.layers) {
                    citem.layers.forEach((litem, lindex: number) => {
                        this.setVizibleFromZoomLevel(litem);
                    });
                }
            });
       }

        public setVizibleFromZoomLevel(lItem: ILayer) {
            let zoomOption = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.hide_layer_from_zoom_level, lItem.name, authType.layer);
            if (zoomOption && zoomOption.idItem && zoomOption.idItem >= 0 ) {
                const maxVisibleZoom = zoomOption.idItem;
                const currentZoom = this.mapCtrl.map.getView().getZoom();
                if (currentZoom >= maxVisibleZoom) {
                    lItem.disableFromZoom = null;
                    if (lItem.visible != lItem.internalLayer.getVisible()) {
                        lItem.internalLayer.setVisible(lItem.visible);
                        if (lItem.visible === true) {
                            lItem.internalLayer.changed();
                        }
                    }
                } else {
                    lItem.disableFromZoom = maxVisibleZoom;
                    if (lItem.internalLayer.getVisible() === true) {
                        lItem.internalLayer.setVisible(false);
                    }
                }
            }


            
        }

        public clearTileAndVectorLayers() {
            let remLayers = [];
            this.mapCtrl.map.getLayers().forEach((litem) => {
                //exclude all but the base layer
                if (MapController.appLayer in litem) {
                    remLayers.push(litem);
                }
            })
            for (let i = 0; i < remLayers.length; i++) {
                this.mapCtrl.map.removeLayer(remLayers[i]);
            }
        }

        //
        //Vector and Tile Layers
        //
        public addTileAndVectorLayers() {
            this.mapCtrl.categories.forEach((citem: ICategory, index: number) => {
                if (citem.layers) {
                    citem.layers.forEach((litem, lindex: number) => {
                        //var tileft = <string>featureType.tile;
                        if (litem.featureType === featureType.tile) {
                            this.addTileLayer(litem);
                        } else if (litem.featureType === featureType.image) {
                            this.addImageLayer(litem);
                        } else if (litem.featureType === featureType.heatmap) {
                            this.addHeatmapLayer(litem);
                        } else if (isFeatureTypeForCluster(litem.featureType)) {
                            this.addClusterLayer(litem);
                        } else {
                            this.addVectorLayer(litem);
                        }
                    });
                }
            });
        }

        public addTileLayer(layer: ILayer) {
            //
            var tileLayer = new TileLayer<any>({
                source: new TileWMSSource({
                    url: AppSettings.serverPath + '/layer/load-wms/' + layer.id,
                    params: {
                        'format': 'image/png',
                        'VERSION': '1.1.1'
                    },
                    tileLoadFunction: this.customLoadImageFromServer(layer),
                    projection: layer.projection,
                    serverType: 'geoserver'
                })
            });
            layer.internalLayer = tileLayer;
            tileLayer[MapController.appLayer] = layer;
            tileLayer.setZIndex(layer.defaultIndex);
            layer.internalLayer.setVisible(layer.visible);
            this.mapCtrl.map.addLayer(layer.internalLayer)
            //
            this.mapCtrl.mapCtrlLayerIsLoading.addEventsForTileLoadingState(layer);
      
        }

       

        //
        public addImageLayer(layer: ILayer) {
            //
            var imageLayer = new ImageLayer({
                source: new ImageWMSSource({
                    url: AppSettings.serverPath + '/layer/load-wms/' + layer.id,
                    params: {
                        'format': 'image/png',
                        'VERSION': '1.1.1',
                    },
                    imageLoadFunction: this.customLoadImageFromServer(layer),
                    projection: layer.projection,
                    //serverType: 'geoserver'
                })
            });
            layer.internalLayer = imageLayer;
            imageLayer[MapController.appLayer] = layer;
            imageLayer.setZIndex(layer.defaultIndex);
            layer.internalLayer.setVisible(layer.visible);
            this.mapCtrl.map.addLayer(layer.internalLayer)
        }

        public customLoadImageFromServer(layer: ILayer) {
            //
            return (tile, src) => {
                let tmpSrc = src;
                if (layer.cqlFilter && layer.cqlFilter.hasFilter && layer.cqlFilter.settings && layer.cqlFilter.settings.length > 0) {
                    tmpSrc += encodeURI('&cql_filter=(' + this.mapCtrl.cqlFilterService.getFilterString(layer.cqlFilter) + ')');
                }
                let data = null;
                if (layer.sldStyle && layer.sldStyle.xmlResult && layer.sldStyle.xmlResult.length > 0){
                    data = layer.sldStyle.xmlResult;
                }
                //
                this.mapCtrl.gisDataService.customLoadImageDataFromServer(tmpSrc, data)
                .then((data) => {
                    var arrayBufferView = Uint8Array.from(data as any);
                    var blob = new Blob([data as any], { type: 'image/png' });
                    var urlCreator = window.URL || window['webkitURL'];
                    var imageUrl = urlCreator.createObjectURL(blob);
                    tile.getImage().src = imageUrl;
                }).catch((reason) => {
                    this.$log.error("Eroare interogare tile geoserver ", getExMessage(reason));
                    tile.getImage().src = null;
                });
            }
        }

       

        public addVectorLayer(layer: ILayer) {
            this.mapCtrl.mapOlLayerstyle.buildMultiStyleForVectorLayer(layer);
            var layerStyle = this.mapCtrl.mapOlLayerstyle.buildDefaultStyleForVectorLayer(layer);
            //
            if (layerStyle) {
                //filtrare elemente prin suprimare style
                var functLayerStyle = (feature: Feature): OlStyle[] => {
                    return this.getStyleForFeature(layer, feature, false);
                };

                let newVecSrc = new VectorSource({
                    format: new GeoJSONFormater,
                    loader: extent => this.loadVectorFromServer(layer, newVecSrc)(extent),
                    strategy: olLoadingstrategy.bbox
                });

                layer.internalLayer = new VectorLayer<any>({
                    source: newVecSrc,
                    style: functLayerStyle
                });

                layer.internalLayer.setVisible(layer.visible);
                layer.internalLayer[MapController.appLayer] = layer;
                layer.internalLayer.setZIndex(layer.defaultIndex);
                this.mapCtrl.map.addLayer(layer.internalLayer);
                this.setVizibleFromZoomLevel(layer);
            }
        }

        public getStyleForFeature(layer: ILayer, feature: Feature, styleOnSelect: boolean): OlStyle[] {
            //default style
            let featureStyle: OlStyle[] = layer.style.default.map((m)=>m);

            //custom single style
            if (layer.styleType === styleType.singleStyle
                && layer.style.list && layer.style.list.length > 0) {
                    if (styleOnSelect && layer.style.list[0].styleOnSelect){
                        featureStyle = layer.style.list[0].styleOnSelect.map((m)=>m);
                    } else {
                        featureStyle = layer.style.list[0].style.map((m)=>m);
                    }
                    
            }

            //multistyle
            if (layer.styleType === styleType.multiStyle
                && layer.styleKeyColumn && layer.styleKeyColumn != ''
                && layer.style.list && layer.style.list.length > 0
            ) {
                //get feature 
                let featureStyleKey = feature.get(layer.styleKeyColumn);
                if (featureStyleKey) {
                    let styles = layer.style.list.filter((item) => item.key === featureStyleKey.toString());
                    if (styles && styles.length > 0) {
                        featureStyle.length = 0;
                        if (styleOnSelect && styles[0].styleOnSelect[0]){
                           featureStyle = styles[0].styleOnSelect.map((m)=>m);
                        } else {
                           featureStyle = styles[0].style.map((m)=>m);
                        }
                    }
                }
                //
            }

            //set text for styles with label
            if (layer.textKeyColumn && layer.textKeyColumn.length > 0
                && (layer.featureType === featureType.pointText
                    || layer.featureType === featureType.polyReport
                    || layer.featureType === featureType.polyText
                    || layer.featureType === featureType.clusterText
                    )) {
                //
                let featureStyleText = "";
                layer.textKeyColumn.forEach((itemkey) => {
                    let proptext = feature.get(itemkey);
                    if (isDefined(proptext)){
                        if (proptext == null){
                            proptext = "";
                        }
                        if (featureStyleText === ""){
                            featureStyleText = proptext.toString();
                        } else {
                            featureStyleText += "\n " + proptext.toString();
                        }
                    }
                })
                // set text content
                if (featureStyle.length > 1){
                    featureStyle[1].getText().setText(featureStyleText);
                }
            }

            //
            if (this.mapCtrl.searchSettings.type === searchType.multilayer) {
                if (this.mapCtrl.searchTextInFeature(this.mapCtrl.searchText, feature, layer)) {
                    feature[MapController.searchFilterOut] = "false";
                    return featureStyle;
                } else {
                    feature[MapController.searchFilterOut] = "true";
                    return [new OlStyle()];
                }
            } else if (this.mapCtrl.searchSettings.type === searchType.layerfeature) {
                if (this.mapCtrl.mapOlFeatures.isFeatureInPolygon(layer.featureType, feature, this.mapCtrl.searchSettings.geometry)) {
                    feature[MapController.searchFilterOut] = "false";
                    return featureStyle;
                } else {
                    feature[MapController.searchFilterOut] = "true";
                    return [new OlStyle()];
                }
            }
        }
        
        //
        public reloadAllStyles() {
            this.mapCtrl.userSettingsSrvs.updateCurrentUserLayerStylesFromStorage();
            this.mapCtrl.categories.forEach((citem: ICategory, index: number) => {
                if (citem.layers) {
                    citem.layers.forEach((litem, lindex: number) => {
                        //var tileft = <string>featureType.tile;
                        if (featureTypeForVector(litem.featureType) === true) {
                            this.mapCtrl.mapOlLayerstyle.buildMultiStyleForVectorLayer(litem);
                            this.mapCtrl.mapOlLayerstyle.buildDefaultStyleForVectorLayer(litem);
                        }
                    });
                }
            });

        }

        public loadVectorFromServer(layer: ILayer, newVecSrc: VectorSource) {
            return (extent) => {
                //report layers loads only on refresh action
                if (layer.featureType === featureType.polyReport) {
                    if (layer.manualRefresh === false) {
                        return;
                    } else {
                        layer.manualRefresh = false;
                    }
                }
                //
                let sourceProjection: any = layer.projection || this.mapCtrl.mapConfig.projection;
                let cqlQuery = this.buildCQLQueryString(layer, extent);
                //
                var mapProjection: any = this.mapCtrl.mapConfig.projection;
                let readerGeoJson = this.builReaderFeatureToMapProjection(sourceProjection, mapProjection);
                //mark layer load start
                this.mapCtrl.mapCtrlLayerIsLoading.layerStartLoading(layer);
                //
                this.mapCtrl.gisDataService.loadVectorDataFromServer(layer, sourceProjection, cqlQuery)
                .then((data) => {
                    var features = readerGeoJson.readFeatures(data);
                    //convert features to local
                    this.convertFeaturesToLocal(layer, features);
                    newVecSrc.addFeatures(features);
                    if (layer.featureType === featureType.polyReport) {
                        this.processReportLayerData(layer)
                    }
                    this.mapCtrl.mapAutorefreshLayers.setDelayedRefreshForRaport(layer);
                }).catch((reason) => {
                    this.$log.error("Eroare interogare date geoserver ", getExMessage(reason));
                }).finally(() => {
                    this.mapCtrl.mapCtrlLayerIsLoading.layerEndLoading(layer);
                })
            }
        }
       
        public loadShapefileFromServer(layer: ILayer, extent:any) {
                //
                let sourceProjection: any = layer.projection || this.mapCtrl.mapConfig.projection;
                let cqlQuery = this.buildCQLQueryString(layer, extent);
                //
                //mark layer load start
                this.mapCtrl.mapCtrlLayerIsLoading.layerStartLoading(layer);
                //
                this.mapCtrl.gisDataService.loadShapeFileDataFromServer(layer, sourceProjection, cqlQuery)
                .then((response) => {
                    var blob = new Blob([(response as any).data], { type: 'application/zip' });
                    var filename = layer.name +".zip";
                    //
                    saveAs(blob, filename);
                }).catch((reason) => {
                    this.$log.error("Eroare interogare shapefile geoserver ", getExMessage(reason));
                }).finally(() => {
                    this.mapCtrl.mapCtrlLayerIsLoading.layerEndLoading(layer);
                })
        }

        public loadRasterfileFromServerNewWindow(layer: ILayer, extent:olExtent.Extent){
            this.$log.log("test raster file download")
            try {
            //layer crs
            let crs = layer.projection;
            //convert extent to layer crs
            let projExtent = olProj.transformExtent(extent, this.mapCtrl.map.getView().getProjection(), crs);
            //get layer pixel-resolution
            let pixelResolution = 0.001;
            let infauth = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.download_rasterfile_pixel_resolution, layer.name, authType.layer)
            if (infauth == null || infauth.descriere == null || infauth.descriere.length == 0){
                throw Error("nu este setat resolutie pixel pentru descarcare");
            }
            pixelResolution = Number(infauth.descriere);
            if (isNaN(pixelResolution)){
                throw Error("resolutie pixel nu este numeric");
            }
            //get layer time
            let source = (layer.internalLayer as TileLayer<any>).getSource() as TileWMSSource;
            let params = source.getParams();
            let time = params['time'] || null;
            //token
            let token = this.mapCtrl.userSettingsSrvs.getCurrentUser().token;
                //
                    let urlDwn = AppSettings.serverPath + `/layer/download-wcs-tiff/${layer.id}?`;
                    urlDwn += "crs=" + crs;
                    urlDwn += "&bbox=" + projExtent.join(',');
                    urlDwn += "&res=" + pixelResolution;
                    urlDwn += "&token=" + token;
                    if (time){
                        urlDwn += "&time=" + time;
                    }
                    //FileSaver.saveAs(urlDwn, "raster.tiff");
                    this.download(urlDwn, layer.name);
                
            } catch(reason){
                this.$log.error('eroare descarcare tiff ', getExMessage(reason));
            }
        }
        //
        private download(url, name) {
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }

        public buildCQLQueryString(layer: ILayer, extent: any) {
            let cqlQuery = 'cql_bbox=(bbox(' + layer.infoGeometry[0].name + ',' + extent.join(',') + ',%27' + this.mapCtrl.mapConfig.projection + '%27))'
            //
            if (layer.cqlFilter && layer.cqlFilter.hasFilter && layer.cqlFilter.settings && layer.cqlFilter.settings.length > 0) {
                cqlQuery += '&cql_filter=(' + this.mapCtrl.cqlFilterService.getFilterString(layer.cqlFilter) + ')'
            }
            //viewParams
            if (layer.viewParams && layer.viewParams.hasParams && layer.viewParams.params && layer.viewParams.params.length > 0) {
                cqlQuery += '&viewparams=' + encodeURIComponent(layer.viewParams.params);
            }
            return cqlQuery;   
        }

        //reportlayer
        public processReportLayerData(layer: ILayer) {
            try {
                this.checkReportLayerInfo(layer);
                let errorMessage = "";
                //clear report data links
                layer.reports.forEach((repItemLink) => {
                    repItemLink.dataFeatures = [];
                });
                //
                (layer.internalLayer as VectorLayer<any>).getSource().forEachFeature((fitem) => {
                    let fgeom = fitem.getGeometry();
                    let fext = fitem.getGeometry().getExtent();
                    if (layer.reports && layer.reports.length > 0) {
                        layer.reports.forEach((repItem) => {
                            try {
                                if (repItem.check === true) {
                                    //get features in geometry
                                    let intersectFeatures = this.getFeaturesForReportLayerItem(repItem, fitem);
                                    //link data to feature
                                    repItem.dataFeatures.push({ reportFeature: fitem, dataFeatures: intersectFeatures });
                                    //procees formula for data
                                    reportFormulaProcessData(repItem, fitem, intersectFeatures);
                                }
                            } catch (e) {
                                errorMessage += "locatie strat raport : " + e.message;
                            }
                        });
                    }
                });
                //
                if (errorMessage.length > 0) {
                    throw new Error(errorMessage);
                }
            } catch (reason) {
                this.$log.error("Eroare procesare strat raport", getExMessage(reason));
            }
        }

        public checkReportLayerInfo(layer: ILayer) {
            layer.reports.forEach((repItem) => {
                repItem.check = false;
                try {
                    //check if report elements can be identified
                    if (repItem.nameResData == undefined || repItem.nameResData.length === 0
                        || repItem.reportColumns == undefined 
                        || repItem.dataColumns == undefined 
                    ) {
                        throw new Error('lipsesc date identificare elemente' + repItem.id);
                    }
                    //check if target columns exist
                    for (let repit in repItem.reportColumns) {
                        let repColumnInfo = layer.infoColumns.filter((infItem) => { return infItem.name === repItem.reportColumns[repit] });
                        if (repColumnInfo == undefined || repColumnInfo.length === 0) {
                            throw new Error('coloana destinatie nu poate fi identificata: ' + repItem.reportColumns[repit]);
                        }
                        //repColumnInfo = repColumnInfo[0] as any;
                    }
                    
                    //get the data layer
                    let dataLayer = this.mapCtrl.mapOlFeatures.searchForVectorLayer(repItem.nameResData);
                    if (dataLayer == null) {
                        throw new Error('stratul sursa nu este disponibil' + repItem.nameResData);
                    }
                    repItem.dataLayer = dataLayer;
                    //check if data column exists
                    for (let repit in repItem.dataColumns) {
                        let dataColumnInfo = dataLayer.infoColumns.filter((infItem) => { return infItem.name === repItem.dataColumns[repit] });
                        if (dataColumnInfo == undefined || dataColumnInfo.length === 0) {
                            throw new Error('coloana date nu poate fi identificata' + repItem.dataColumns[repit]);
                        }
                    }
                    //check formula params
                    if (!reportFormulaCheckParams(repItem)) {
                        throw new Error('parametri formulei nu sunt prezenti');
                    }
                    repItem.check = true;
                } catch (reason) {
                    this.$log.error("Eroare procesare info strat raport", getExMessage(reason));
                }
            });
        }

        public getFeaturesForReportLayerItem(repItem: ILayerReportSettings, fitem: Feature): Array<Feature> {
            //
            let fgeom = fitem.getGeometry();
            let fext = fitem.getGeometry().getExtent();
            let intersectFeatures: Array<Feature> = [];
            let extFeatures = (repItem.dataLayer.internalLayer as VectorLayer<any>).getSource().getFeaturesInExtent(fext);
            if (extFeatures && extFeatures.length > 0) {
                let geomWGS = (fgeom as any).clone().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326');
                //if multipolygon take first
                if (geomWGS.getType() === "MultiPolygon") {
                    let polygons = (geomWGS as MultiPolygon).getPolygons();
                    if (polygons.length > 0) {
                        geomWGS = polygons[0];
                    }
                }
                let geojsonFormat = new GeoJSONFormater();
                let polySelectionGeoJson = geojsonFormat.writeGeometryObject(geomWGS);
                //find extent features intersect with polygon
                extFeatures.forEach((extFitem) => {
                    if (this.mapCtrl.mapOlFeatures.isFeatureInPolygon(repItem.dataLayer.featureType, extFitem, polySelectionGeoJson as any)) {
                        intersectFeatures.push(extFitem);
                    }
                });
            }
            return intersectFeatures;
        }

        //
        public addHeatmapLayer(layer: ILayer) {
            let newVecSrc = new VectorSource({
                format: new GeoJSONFormater,
                loader: extent => this.loadVectorFromServer(layer, newVecSrc)(extent),
                strategy: olLoadingstrategy.bbox
            });

            newVecSrc.on('addfeature', (event) => {
                event.feature.set('weight', 1);
            });

            var clusterSource = new ClusterSource<any>({
                distance: 40,
                source: newVecSrc
            });

            var styleCache = {};

            layer.internalLayer = new HeatmapLayer({
                source: clusterSource,
                //gradient: ['#00f', '#00a1ff', '#0ff', '#00ffa1', '#0f0', '#a1ff00', '#ff0', '#ffa100', '#ff6100', '#f00'],
                blur: 50,
                radius: 5,
                weight: (feature) => {
                    if (this.mapCtrl.searchSettings.type === searchType.multilayer) {
                        if (this.mapCtrl.searchTextInFeature(this.mapCtrl.searchText, feature, layer)) {
                            feature[MapController.searchFilterOut] = "false";
                            return 1;
                        } else {
                            feature[MapController.searchFilterOut] = "true";
                            return 0;
                        }
                    } else if (this.mapCtrl.searchSettings.type === searchType.layerfeature) {
                        if (this.mapCtrl.mapOlFeatures.isFeatureInPolygon(layer.featureType, feature, this.mapCtrl.searchSettings.geometry)) {
                            feature[MapController.searchFilterOut] = "false";
                            return 1;
                        } else {
                            feature[MapController.searchFilterOut] = "true";
                            return 0;
                        }
                    }
                }
            } as any);


            layer.internalLayer.setVisible(layer.visible);
            layer.internalLayer[MapController.appLayer] = layer;
            layer.internalLayer.setZIndex(layer.defaultIndex);
            this.mapCtrl.map.addLayer(layer.internalLayer);
        }

        public addClusterLayer(layer: ILayer) {

            this.mapCtrl.mapOlLayerstyle.buildMultiStyleForVectorLayer(layer);
            var layerStyle = this.mapCtrl.mapOlLayerstyle.buildDefaultStyleForVectorLayer(layer);
            
            
            //pentru strategia denincarcare tile
            var projExtent = olProj.get('EPSG:3857').getExtent();
            var startResolution = olExtent.getWidth(projExtent) / 256;
            var resolutions = new Array(22);
            for (var i = 0, ii = resolutions.length; i < ii; ++i) {
              resolutions[i] = startResolution / Math.pow(2, i);
            }
            var tileGrid = new TileGrid({
              extent: [-13884991, 2870341, -7455066, 6338219],
              resolutions: resolutions,
              tileSize: [512, 256]
            });

            //
            let newVecSrc = new VectorSource({
                format: new GeoJSONFormater,
                loader: extent => this.loadVectorFromServer(layer, newVecSrc)(extent),
                //strategy: olLoadingstrategy.tile(tileGrid)
                strategy: olLoadingstrategy.bbox
            });

            newVecSrc.on('addfeature', (event) => {
                event.feature.set('weight', 1);
            });

            var clusterSource = new ClusterSource<any>({
                distance: 40,
                source: newVecSrc
            });

            var styleCache = {};
            var clusterStyleFunction = (feature: Feature): OlStyle[] => {
                return this.getclusterStyleForFeature(layer, feature);
            };

            layer.internalLayer = new AnimatedCluster({
                name: 'Cluster',
                source: clusterSource,
                animationDuration:  700,
                // Cluster style
                style: clusterStyleFunction
              } as any);
            
            layer.internalLayer.setVisible(layer.visible);
            layer.internalLayer[MapController.appLayer] = layer;
            layer.internalLayer.setZIndex(layer.defaultIndex);
            this.mapCtrl.map.addLayer(layer.internalLayer);
            
        }

        getclusterStyleForFeature(layer: ILayer, feature: Feature) {
            let styleArr = [];
            var size = feature.get('features').length;
            //var style = styleCache[size];
            if (size == 1) {
                styleArr = this.getStyleForFeature(layer, feature.get('features')[0], false);
            } else {
                let calcRadius = Math.max(10, Math.min(size*0.75, 20));
                let tmpColor = '#3399CC';
                if (layer.color){
                    tmpColor = layer.color;
                }
                var style = new OlStyle({
                    image: new CircleStyle({
                        radius: calcRadius,
                        stroke: new StrokeStyle({
                            color: tmpColor,
                            width: 1.25
                        }),
                        fill: new FillStyle({
                            color: tmpColor
                        })
                    }),
                    text: new TextStyle({
                        text: size.toString(),
                        fill: new FillStyle({
                            color: '#fff'
                        })
                    })
                });
                styleArr.push(style);
            }
            // styleCache[size] = style;
            //}
            return styleArr;
        }


        //
        buildSelectClusterInteraction(){
            // Style for selection
            
            var defStyleSpreadFeatures = new OlStyle({
                image: new CircleStyle({
                    radius: 5,
                    stroke: new StrokeStyle({
                        color: "rgba(0,255,255,1)",
                        width: 1
                    }),
                    fill: new FillStyle({
                        color: "rgba(0,255,255,0.3)"
                    })
                }),
                // Draw a link beetween points (or not)
                stroke: new StrokeStyle({
                    color: "#fff",
                    width: 1
                })
            });

            var defStyleSelectedFeature = new OlStyle({
                image: new CircleStyle ({
                  stroke: new StrokeStyle({ color: "rgba(0,0,255,0.5)", width:2 }),
                  fill: new FillStyle({ color: "rgba(0,0,255,0.3)" }),
                  radius:5
                })
              })
            var defStyleSelectedFeatureGroup = new OlStyle({
                image: new CircleStyle({
                    stroke: new StrokeStyle({ color: "rgba(0,0,255,0.2)", width: 2 }),
                    fill: new FillStyle({ color: "rgba(0,0,255,0.1)" }),
                    radius: 1
                })
            })
            //var tmpClusterStyleFunction = this.clusterStyleFunction;

        this.mapCtrl.selectCluster = new SelectCluster({
            filter: this.filterClusterSelect,
            // Point radius: to calculate distance between the features
            pointRadius:15,
            // circleMaxObjects: 40,
            // spiral: false,
            autoClose: true,
            animate: true,
            // Feature style when it springs apart
            featureStyle: (feature) => {
                try {
                    var cluster = feature.get('features');
                    //
                    if (feature.get('selectclusterfeature') === true) {
                        if (cluster.length > 0) {
                            let appLayer = this.getappLayerForSelectClusterFeature(feature as any);
                            if (appLayer) {
                                return this.getStyleForFeature(appLayer, cluster[0], false)
                            }
                        }
                    }
                } catch (reason) {
                    this.$log.error("Eroare stil selectie cluster locatii desfasurate: ", getExMessage(reason));
                }
                //valoare default
                return defStyleSpreadFeatures;
            },
            // selectCluster: false,	// disable cluster selection
            // Style to draw cluster when selected
            style: (feature: Feature,res)=>{
                try {
                    var cluster = feature.get('features');
                    //
                    if (cluster.length > 1){
                        //make center feature less visible
                       return defStyleSelectedFeatureGroup;
                    } else if (cluster.length === 1) {
                        let appLayer = this.getappLayerForSelectClusterFeature(feature);
                        if (appLayer && appLayer.style && appLayer.style.list
                            && appLayer.style.list.length > 0) {
                            // let stOnSelect = appLayer.style.list[0].styleOnSelect;
                            // if (stOnSelect) {
                            //     return stOnSelect;
                            // }
                            return this.getStyleForFeature(appLayer, cluster[0], true);
                        }
                    }
                } catch (reason) {
                    this.$log.error("Eroare stil selectie cluster locatie selectata: ", getExMessage(reason));
                }
                //valoare default 
                return [defStyleSelectedFeature];
            }
          });
          //fix forteaza propagarea evenimentului spre selectia implicita
          let originalHandle = (this.mapCtrl.selectCluster as any).handleEvent;
          (this.mapCtrl.selectCluster as any).handleEvent = function(mapBrowserEvent){
            originalHandle.apply(this, arguments);
            //
            return true;
          }
          //
          this.mapCtrl.map.addInteraction(this.mapCtrl.selectCluster);
          //
        }
        //
        private filterClusterSelect = (feature: Feature, layer: BaseLayer) => {
            try {
                if (feature.get('selectclusterfeature') === true){
                    return true;
                }
                if (!this.mapCtrl.mapCtrlSelectFeature.canUseSelectTool()) {
                    return false;
                }
                let selLayer = layer["appLayer"] as ILayer;
                if (
                    //this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.select_layer_feature, selLayer.name, authType.layer)
                    selLayer.featureType && isFeatureTypeForCluster(selLayer.featureType)
                ) {
                    return true;
                }
                return false;
            } catch (e) {
                this.$log.error("eroare ");
                return false
            }
        }
        //find Ilayer for selected feature to determine style
        getappLayerForSelectClusterFeature(feature: Feature): ILayer {
            //check first last found
            let found = false;
            let findLayer = null;
            let feat = feature.get("features")[0];
            if (this.mapCtrl.selectCluster.lastILayer){
                let source = ((this.mapCtrl.selectCluster.lastILayer.internalLayer as VectorLayer<any>).getSource() as ClusterSource<any>).getSource();
                let findFeature = source.getFeatureById(feat.getId());
                if (findFeature && (findFeature as any).ol_uid === (feat as any).ol_uid){
                    found = true;
                    return this.mapCtrl.selectCluster.lastILayer;
                }
            }
            //
            this.mapCtrl.categories.forEach((catItem) => {
                if (found === false) {
                    catItem.layers.forEach((layItem) => {
                        if (found === false) {
                            if (isFeatureTypeForCluster(layItem.featureType)) {
                                let source = ((layItem.internalLayer as VectorLayer<any>).getSource() as ClusterSource<any>).getSource();
                                let findFeature = source.getFeatureById(feat.getId());
                                if (findFeature && (findFeature as any).ol_uid === (feat as any).ol_uid){
                                    found = true;
                                    findLayer = layItem;
                                }
                            }
                        }
                    })
                }
            })
            if (findLayer && feat){
                this.mapCtrl.selectCluster.lastILayer = findLayer as any;
            }
            return findLayer;
        }


        //
        public builReaderFeatureToMapProjection(sourceProjection: string, mapProjection: string): any {
            try {
                if (proj4.defs(mapProjection)) {
                    mapProjection = olProj.get(mapProjection) as any;
                } else {
                    let projections = this.mapCtrl.userSettingsSrvs.getCurrentUser().mapProjections.filter(projItem => projItem.proiectie.toUpperCase() === mapProjection.toUpperCase());
                    if (projections && projections.length > 0) {
                        proj4.defs(mapProjection, projections[0].proj4text);
                        mapProjection = olProj.get(mapProjection) as any;
                    }
                }
                if (proj4.defs(sourceProjection)) {
                    sourceProjection = olProj.get(sourceProjection) as any;
                } else {
                    let projections = this.mapCtrl.userSettingsSrvs.getCurrentUser().mapProjections.filter(projItem => projItem.proiectie.toUpperCase() === sourceProjection.toUpperCase());
                    if (projections && projections.length > 0) {
                        proj4.defs(sourceProjection, projections[0].proj4text);
                        sourceProjection = olProj.get(sourceProjection) as any;
                    }
                }
            }
            catch (e) {
                this.$log.error('eroare extragere proiectii')
            }
            //
            return  new GeoJSONFormater({
                dataProjection: sourceProjection,
                featureProjection: mapProjection,
            });
        }
        //
        public convertFeaturesToLocal(layer: ILayer, features: Feature[]): boolean {
            if (!layer || !layer.infoColumns || layer.infoColumns.length === 0 || !features || features.length === 0) {
                return false;
            }
            //
            let bResult = true;
            let localDateColumns: Array<{ name: string, type: string }> = [];
            layer.infoColumns.forEach((infoItem) => {
                //todo
                let str = this.mapCtrl.userSettingsSrvs.isAuthForItemOption(authOpt.in_utc_to_local_convert, layer.name, infoItem.name, authType.layer);
                if (str && str.length > 0) {
                    localDateColumns.push({ name: infoItem.name, type: str });
                }
            })
            if (features && features.length > 0 && localDateColumns.length > 0) {
                features.forEach((fitem) => {
                    localDateColumns.forEach((timeColumn) => {
                        let valDate = fitem.get(timeColumn.name);
                        if (valDate) {
                            try {
                                //let newDate1 = moment(valDate);
                                //let format = formatDateTime.dateTime;
                                //let client = this.mapCtrl.userSettingsSrvs.getCurrentUser().client;
                                //if (timeColumn.type === authItemDateTime.dateTime && client && client.formatDateTime && client.formatDateTime !== "") {
                                //    format = client.formatDateTime;
                                //} else if (timeColumn.type === authItemDateTime.date && client && client.formatDate && client.formatDate !== "") {
                                //    format = client.formatDate;
                                //} else if (timeColumn.type === authItemDateTime.time && client && client.formatTime && client.formatTime !== "") {
                                //    format = client.formatTime;
                                //}

                               // valDate = newDate1.format(format);
                                valDate = this.convertValueToLocalTime(valDate, timeColumn.type);
                                //
                                fitem.set(timeColumn.name, valDate);
                            } catch (e) {
                                bResult = false;
                                console.error("Eroare la conversie coloana data ora" + e.message);
                            }
                        }
                    })

                })
            }
            return bResult;
        }

        public convertValueToLocalTime(valDate, type): any {
            if (valDate) {
                if (type) {
                    try {
                        let newDate1 = moment(valDate);
                        let format = formatDateTime.dateTime;
                        let client = this.mapCtrl.userSettingsSrvs.getCurrentUser().client;
                        if (type === authItemDateTime.dateTime && client && client.formatDateTime && client.formatDateTime !== "") {
                            format = client.formatDateTime;
                        } else if (type === authItemDateTime.date && client && client.formatDate && client.formatDate !== "") {
                            format = client.formatDate;
                        } else if (type === authItemDateTime.time && client && client.formatTime && client.formatTime !== "") {
                            format = client.formatTime;
                        }
                        return newDate1.format(format);
                        //
                    } catch (e) {
                        throw new Error("Eroare la conversie coloana data ora" + e.message);
                    }
                } else {
                    return valDate;
                }
            } else {
                return null;
            }
        }

         //
         public prepareRasterStyle(layer: ILayer){
            //raster-with-style-script
           let authRasterScript =  this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.raster_with_style_script, layer.name, authType.layer);
            if (authRasterScript && layer.sldStyle && layer.sldStyle.xmlSrv){
                var parser = new GeoserverSldStyleParser();
                layer.sldStyle.xmlResult = '';
                //
                parser.readStyle(layer.sldStyle.xmlSrv)
                .then(({ output: sldObject }) => {
                    //only the first-default style will be used
                    while( (sldObject.rules as Array<any>).length > 1){
                        (sldObject.rules as Array<any>).pop();
                    }
                    //
                    sldObject['jiffleScript'] = layer.sldStyle.script || null; // `dest=src*5.1;`
                    layer.sldStyle.sld = sldObject;
                    return parser.writeStyle(sldObject);
                })
                .then((sld)=>{
                    layer.sldStyle.xmlResult = sld.output || null;
                })
                .catch((reason) => {
                    this.$log.error("eroare la pregatire stil sld raster: ", getExMessage(reason));
                });
            }
        }
        
    }


   
