//NG+
import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { ILayer, authAs, authOpt, authType, featureType } from '../../definitions';
import { MapController } from '../map-controller';
import { listaSursaDateClasificare } from "./map-greentop-definitions";
import { IDescarcareDateSatelitareIL, IUnitateAdministrativaDescarcareIL, IIntegrateLandsatDownloadData } from "./map-integrate.definitions";
import { IProdusDescarcare, TaskActiune, TaskStatus } from '../../services/greentop-data-service';
import { I18n, NgbCustomDatepickerI18n } from '../../shared-components/ngb-datepicker-i18n';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateMomentAdapter, NgbDateMomentParserFormatter } from '../../shared-components/ngb-moment-adapter-formater';
import moment, { Moment } from "moment";
import { createGeomFromWKT, getExMessage } from '../map-utils';
import { NGXLogger } from 'ngx-logger';

import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorSource from "ol/source/Vector";
import WKTFormater from "ol/format/WKT";
import OlStyle from "ol/style/Style";
import StrokeStyle from "ol/style/Stroke";
import FillStyle from "ol/style/Fill";
import TextStyle from "ol/style/Text";
import * as olColor from 'ol/color';

@Component({
    selector: 'integrate-landsat-download',
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, 
             useFactory: () => { return new NgbCustomDatepickerI18n({language : 'ro'})} },
        { provide: NgbDateParserFormatter, 
            useFactory: () => { return new NgbDateMomentParserFormatter("DD-MM-YYYY") } },
        { provide: NgbDateAdapter, useClass: NgbDateMomentAdapter},
    ],
    templateUrl: './map-integrate-landsat-download.component.html',
    styleUrls: ['./map-integrate-landsat-download.component.css']
})
export class IntegrateLandsatDownloadComponent implements OnInit, OnChanges {
    @Input("mapctrl") mapCtrl: MapController = null;
    //
    public infoSearch = "test info search download"
    public infoProducts = "test info products download"
    public integrateLandsatDownloadData : IIntegrateLandsatDownloadData = null;
    //
    private desStyle = new OlStyle({
        fill: new FillStyle({
            color: olColor.asArray('rgba(50,0,0,0)')
        }),
        stroke: new StrokeStyle({
            color: 'rgba(255,0,0,1)',
            width: 2
        })
    });
    private zoneStyle = [
        new OlStyle({
            fill: new FillStyle({
                color: olColor.asArray('rgba(0,255,0,0)')
            }),
            stroke: new StrokeStyle({
                color: 'rgba(0,255,0,1)',
                width: 2,
            }),

        }),
        new OlStyle({
            fill: new FillStyle({
                color: olColor.asArray('rgba(0,255,0,0)')
            }),
            stroke: new StrokeStyle({
                color: 'rgba(255,0,0,1)',
                width: 2,
                lineDash: [4, 8],
            }),

        }),
    
];
    private zoneText(text: string) {
        return new OlStyle({
            text: new TextStyle({
                text: text,
                scale: 1,
                offsetX: 0,
                offsetY: 0,
                fill: new FillStyle({
                    color: 'rgba(0, 0, 0, 1)'
                })
            })
        })
    }

    constructor(
        @Inject(NGXLogger) private $log: NGXLogger,
    ) {
        //
    }

    public ngOnInit(): void {
        //
        if (this.mapCtrl && this.mapCtrl.integrateLandsatDownloadData) {
            this.integrateLandsatDownloadData = this.mapCtrl.integrateLandsatDownloadData;
            this.mapCtrl.integrateLandsatDownloadData.funcInit = this.initIntegrateLandsatDownloadTool;
            this.mapCtrl.integrateLandsatDownloadData.funcClear = this.clearIntegrateLandsatDownloadTool;

        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        //
    }


    public initIntegrateLandsatDownloadTool = () => {
        this.addSelectLayer();
        this.addProductsLayer();
        this.integrateLandsatDownloadData.activeExistsTab1 = true;
        this.integrateLandsatDownloadData.activeNewTab0 = false;
        this.integrateLandsatDownloadData.activeTab = 1;
        //this.initNewDescarcareData();
        this.initExistingDescarcareDate(null);
    }

    

    public clearIntegrateLandsatDownloadTool = () => {
        this.removeSelectLayer();
        this.removeProducstLayer();
    }

    public changeNewExistsTab(index){
        if (index === "0"){
            if (this.integrateLandsatDownloadData.activeNewTab0){
               // this.integrateLandsatDownloadData.activeNewTab0 = false
            } else {
                this.integrateLandsatDownloadData.activeExistsTab1 = false;
                this.integrateLandsatDownloadData.activeNewTab0 = true;
                this.integrateLandsatDownloadData.activeTab = 0;
                this.initNewDescarcareData();
            }
            
        } else if (index === "1"){
            if (this.integrateLandsatDownloadData.activeExistsTab1){
               // this.integrateLandsatDownloadData.activeExistsTab1 = false
            } else {
                
                this.integrateLandsatDownloadData.activeExistsTab1 = true;
                this.integrateLandsatDownloadData.activeNewTab0 = false;
                this.integrateLandsatDownloadData.activeTab = 1
                this.initExistingDescarcareDate(null);
            }
        }
    }

    private initNewDescarcareData(){
        this.integrateLandsatDownloadData.productsLayer.getSource().clear();
        this.integrateLandsatDownloadData.selectLayer.getSource().clear();
        this.integrateLandsatDownloadData.selectButtonStateOn = false;
        this.integrateLandsatDownloadData.currentDescarcare = {
            denumire: "",
            descriere: "",
            sursaDate: null,
            startPerioada:  moment(new Date()),//.format(this.integrateLandsatDownloadData.optFormatDataPerioada),
            endPerioada: moment(new Date()),//.format(this.integrateLandsatDownloadData.optFormatDataPerioada),
            geom: null,
            status: "initiat",
            mesajStatus: "",
            unitatiAdministrativeDescarcare: [],
            produseDescarcare: []
        }
        this.integrateLandsatDownloadData.errorMessage = "";
        this.integrateLandsatDownloadData.loadingSearchProductsActive = true;
        this.mapCtrl.integrateLandsatDataService.getUnitatiAdministrativeActive()
            .then((data)=>{
                this.integrateLandsatDownloadData.availableUnitatiAdministrative = data;
                return true;
            }).catch((reason)=>{
                this.integrateLandsatDownloadData.errorMessage = "Eroare interogare unitati administrative";
                this.$log.error("Eroare interogare unitati administrative: ", getExMessage(reason));
            }).finally(()=>{
                this.integrateLandsatDownloadData.loadingSearchProductsActive = false;
            })


    }

    private initExistingDescarcareDate(idPreselected: number){
        this.integrateLandsatDownloadData.productsLayer.getSource().clear();
        this.integrateLandsatDownloadData.selectLayer.getSource().clear();
        this.integrateLandsatDownloadData.selectedDescarcare = null;
        this.integrateLandsatDownloadData.selectButtonStateOn = false;
        this.integrateLandsatDownloadData.currentDescarcare = {
            denumire: "",
            descriere: "",
            sursaDate: null,
            startPerioada: "",
            endPerioada: "",
            geom: null,
            status: "",
            mesajStatus: "",
            unitatiAdministrativeDescarcare: [],
            produseDescarcare: []
        }
        
        this.integrateLandsatDownloadData.errorMessage = "";
        this.integrateLandsatDownloadData.loadingSearchProductsActive = true;
        this.mapCtrl.integrateLandsatDataService.getDescarcariDateSatelitare(null)
            .then((data)=>{
                this.integrateLandsatDownloadData.availableDescarcari = data;
                return true;
            }).catch((reason)=>{
                this.integrateLandsatDownloadData.errorMessage = "Eroare interogare descarcari";
                this.$log.error("Eroare interogare descarcari: ", getExMessage(reason));
            }).finally(()=>{
                this.integrateLandsatDownloadData.loadingSearchProductsActive = false;
            }).then((state)=>{
                //load preselected
                if(state as any === true && typeof idPreselected === "number" ){
                  let tmpPres =  this.integrateLandsatDownloadData.availableDescarcari.filter((item)=>item.id === idPreselected);
                  if (tmpPres && tmpPres.length > 0){
                      this.integrateLandsatDownloadData.selectedDescarcare = tmpPres[0];
                      this.onChangeDescarcare();
                  }
                } 
            }).catch((reason)=>{
                this.$log.error("Eroare activare preselectie: ", getExMessage(reason))
            })
    }

    public onClickClose(){
        this.clearIntegrateLandsatDownloadTool();
        this.mapCtrl.integrateLandsatDwonloadButtonStateOn = false;
    }
    //
    public onChangeDescarcare = ()=>{
        if (this.integrateLandsatDownloadData.selectedDescarcare == null){
            return;
        } 
        this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.length = 0;
        this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.length = 0;
        this.integrateLandsatDownloadData.errorMessage = "";
        this.integrateLandsatDownloadData.loadingSearchProductsActive = true;
        this.mapCtrl.integrateLandsatDataService.getDescarcareDateSatelitare(this.integrateLandsatDownloadData.selectedDescarcare.id)
            .then((data)=>{
                this.integrateLandsatDownloadData.currentDescarcare = data;
                this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.forEach((item,index)=>{
                    item.nr = index + 1;
                })
                //
                this.addcurrentUatToLayer();
                this.addcurrentProductsToLayer();
                this.integrateLandsatDownloadData.ctrlExistingDetalisIsColapsed = false;
            }).catch((reason)=>{
                this.integrateLandsatDownloadData.errorMessage = "Eroare interogare descarcare";
                this.$log.error("Eroare interogare descarcare: ", getExMessage(reason));
            }).finally(()=>{
                this.integrateLandsatDownloadData.loadingSearchProductsActive = false;
                this.integrateLandsatDownloadData.ctrlProductsIsCollapsed = false;
            })
    }
    public onClickReloadExisting(){
        this.onChangeDescarcare();
    }

    public onctrlDownloadDetailsIsColapsed(){
        this.integrateLandsatDownloadData.ctrlDownloadDetailsIsColapsed = ! this.integrateLandsatDownloadData.ctrlDownloadDetailsIsColapsed;
    }
    public onctrlDownloadUnitatiAdministrativeIsColapsed(){
        this.integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed = !this.integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed;
    }
    public onctrlExistingDetailsIsColapsed(){
        this.integrateLandsatDownloadData.ctrlExistingDetalisIsColapsed = !this.integrateLandsatDownloadData.ctrlExistingDetalisIsColapsed;
    }

    public onChangeSelectedUnitateAdministrativa(){
        
    }

    public onAddUnitateAdministrativa(){
        try {
            if (this.integrateLandsatDownloadData.selectedUnitateAdministrativa) {
                //
                this.clearSearchResults();
                //check if exists
                let resultExists = this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare
                    .filter(fitem => fitem.idUnitateAdministrativa === this.integrateLandsatDownloadData.selectedUnitateAdministrativa.id);
                if (resultExists && resultExists.length > 0) {
                    this.$log.error("unitatea administrativa exista deja in lista");
                    return;
                }
                let tmpUat: IUnitateAdministrativaDescarcareIL = {
                    idUnitateAdministrativa: this.integrateLandsatDownloadData.selectedUnitateAdministrativa.id,
                    denumire: this.integrateLandsatDownloadData.selectedUnitateAdministrativa.denumire,
                    geom: this.integrateLandsatDownloadData.selectedUnitateAdministrativa.geom
                }
                //
                this.createZoneFeature(tmpUat);
                this.integrateLandsatDownloadData.selectLayer.getSource().addFeature(tmpUat.feature);
                //
                this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.push(tmpUat);
            }
        } catch (error) {
            this.$log.error("eroare la adaugare unitate administrativa");
        }
    }

    public onAddAllUnitateAdministrativa(){
        try {
            this.clearSearchResults();
            this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.length = 0;
            this.integrateLandsatDownloadData.selectLayer.getSource().clear();
            //
            this.integrateLandsatDownloadData.availableUnitatiAdministrative.forEach((itemunit)=>{
                let tmpUat: IUnitateAdministrativaDescarcareIL = {
                    idUnitateAdministrativa: itemunit.id,
                    denumire: itemunit.denumire,
                    geom: itemunit.geom
                }
                //
                this.createZoneFeature(tmpUat);
                this.integrateLandsatDownloadData.selectLayer.getSource().addFeature(tmpUat.feature);
                //
                this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.push(tmpUat);
            })
        } catch (error) {
            this.$log.error("eroare la adaugare unitate administrativa");
        }
    }

    public onRemoveUnitateAdministrativa(uat: IUnitateAdministrativaDescarcareIL) {
        try {
            if (uat) {
                this.clearSearchResults();
                let iduat = this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare
                    .indexOf(uat);
                if (iduat >= 0) {
                    this.integrateLandsatDownloadData.selectLayer.getSource().removeFeature(uat.feature);
                    this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.splice(iduat, 1);

                }
            }
        } catch (error) {
            this.$log.error("eroare la stergere zona uat");
        }
    }
    private createZoneFeature(zone: IUnitateAdministrativaDescarcareIL) {
        try {
            let tmpgeom: string | Polygon = zone.geom;
            if (typeof tmpgeom === "string") {
                tmpgeom = createGeomFromWKT(tmpgeom, "EPSG:4326", this.mapCtrl.mapConfig.projection);
            }
            zone.feature = new Feature(tmpgeom as any);
            zone.feature.set("utilizare", zone.idUnitateAdministrativa);
            zone.feature.setStyle([
                this.zoneStyle[0],
                this.zoneStyle[1],
                this.zoneText(zone.denumire)
            ])
        } catch (error) {
            this.$log.error("eroare creare locatie zona");
        }
    }
    //
    private updateZoneFeature(zone: IUnitateAdministrativaDescarcareIL) {
        if (zone.feature && zone.geom) {
            zone.feature.setGeometry(zone.geom as any);
            zone.feature.set("utilizare", zone.idUnitateAdministrativa);
            zone.feature.setStyle([
                this.zoneStyle[0],
                this.zoneStyle[1],
                this.zoneText(zone.denumire)
            ])
        }
    }
    //
    public onSearchAvailableProductsClick(){
        this.integrateLandsatDownloadData.errorMessage = "";
        try {
            this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.length = 0;
            this.checkSearchInputs();
            //
                this.integrateLandsatDownloadData.ctrlDownloadDetailsIsColapsed = true;
                let geomwgs = (this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare
                    .map((item)=> item.feature.clone().getGeometry().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326') as any) as Array<Polygon>);
                this.integrateLandsatDownloadData.loadingSearchProductsActive = true;
                this.integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed = true;
                this.mapCtrl.integrateLandsatDataService.getProduseDisponibileDescarcarePentruZonaInteres(
                    this.integrateLandsatDownloadData.currentDescarcare.startPerioada,
                    this.integrateLandsatDownloadData.currentDescarcare.endPerioada,
                    geomwgs, [this.integrateLandsatDownloadData.selectedSursaDate.tip] // ["sentinel-2"]
                ).then((data)=>{
                    //this.$log.log(data.length);
                    this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare = data;
                    this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.forEach((item,index)=>{
                        item.nr = index + 1;
                        item.selected = true;
                    })
                    this.addAvailableProductsToLayer();
                    if (data.length === 0){
                        this.integrateLandsatDownloadData.errorMessage = "Nu au fost gasite produse disponibile";
                    }
                    return true;
                //}).then((status)=>{
                //    return this.mapCtrl.integrateLandsatDataService.getGranuleSatelitarePentruZonaInteres(geomwgs,["sentinel-2"]);
                //}).then((data)=>{
                    //this.$log.log(data);
                 //   this.integrateLandsatDownloadData.currentDescarcare.granuleSatelitare = data;
                }).catch((reason)=>{
                    this.integrateLandsatDownloadData.errorMessage ="Eroare interogare server ";
                    this.$log.error("Eroare interogare server", getExMessage(reason));

                }).finally(()=>{
                    this.integrateLandsatDownloadData.loadingSearchProductsActive = false;
                })

                //
        } catch (error) {
            this.$log.error("Eroare cautare produse disponibile:", getExMessage(error));
            this.integrateLandsatDownloadData.errorMessage = "Eroare cautare produse disponibile: " + error.message ||"";
        }
    }
    private checkSearchInputs(){
        if (this.integrateLandsatDownloadData.selectedSursaDate == null){
            throw Error("Nu este selectata sursa date satelitare");
        }
        if (this.integrateLandsatDownloadData.currentDescarcare.startPerioada == null) {
            throw Error("Inceput perioada nu este specificat");
        }
        if (this.integrateLandsatDownloadData.currentDescarcare.endPerioada == null) {
            throw Error("Sfarsit perioada nu este specificat");
        }
        if (this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare === null
            || this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.length === 0){
                throw Error("Nu sunt specificate unitati administrative");
            }
    }
    public clearSearchResults(){
        if (this.integrateLandsatDownloadData.activeNewTab0 === true) {
            this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.length = 0;
            this.integrateLandsatDownloadData.productsLayer.getSource().clear();
        }
    }

    public onClickColapseProducts(){
        this.integrateLandsatDownloadData.ctrlProductsIsCollapsed = !this.integrateLandsatDownloadData.ctrlProductsIsCollapsed;
        if(this.integrateLandsatDownloadData.ctrlProductsIsCollapsed === true){
            this.integrateLandsatDownloadData.productsLayer.getSource().clear();
            this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                if(item.feature){
                    this.integrateLandsatDownloadData.productsLayer.getSource().addFeature(item.feature);
                }
            })
        }
    }
    //
    public onClickProductsClose(){
        this.clearSearchResults();
    }
    //
    //
    //
    onClickSaveDownloadProducts(){
        //
        this.integrateLandsatDownloadData.errorMessage = "";
        try {
            this.checkSearchInputs();
            if (this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare == null
                || this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.length == 0) {
                throw Error("Nu sunt produse pentru descarcare");
            }
            if (this.integrateLandsatDownloadData.currentDescarcare.denumire == null
                || this.integrateLandsatDownloadData.currentDescarcare.denumire.length == 0){
                    throw Error("Denumire descarcare este obligatoriu");
                }
            //
            this.integrateLandsatDownloadData.loadingSearchProductsActive = true;
            //let geomwgs = (this.integrateLandsatDownloadData.currentDescarcare.geom as Polygon).clone().transform(this.mapCtrl.mapConfig.projection, 'EPSG:4326') as any;
            //let wkt = new WKTFormater();
            let sentData: IDescarcareDateSatelitareIL = {
                sursaDate: this.integrateLandsatDownloadData.selectedSursaDate.tip,
                denumire: this.integrateLandsatDownloadData.currentDescarcare.denumire,
                descriere: this.integrateLandsatDownloadData.currentDescarcare.descriere || "",
                geom: null,// wkt.writeGeometry(geomwgs as Polygon),
                startPerioada: this.integrateLandsatDownloadData.currentDescarcare.startPerioada.format("YYYY-MM-DD"),
                endPerioada: this.integrateLandsatDownloadData.currentDescarcare.endPerioada.format("YYYY-MM-DD"),
                status: "initiat",
                mesajStatus: "",
                unitatiAdministrativeDescarcare: [],
                produseDescarcare: [],
            }
            this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.forEach((item)=>{
                sentData.unitatiAdministrativeDescarcare.push({
                    //id: item.id,
                    idUnitateAdministrativa: item.idUnitateAdministrativa,
                    denumire: item.denumire,
                    geom: item.geom
                })
            })
            this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                if (item.selected === true){
                    sentData.produseDescarcare.push({
                        codGranula: item.codGranula,
                        sursaDate: item.sursaDate,
                        numeProdus: item.numeProdus,
                        dataProdus: item.dataProdus,
                        geom: item.geom,
                        uuid: item.uuid,
                        previzualizareS: item.previzualizareS,
                        previzualizareL: item.previzualizareL,
                        status: "initiat",
                        mesajStatus: ""
                    })
                }
            })

            this.mapCtrl.integrateLandsatDataService.setSalvareDescarcareDateSatelitare(sentData)
            .then((result)=>{
                //to do switch to existente
                if (typeof result === "number"){
                    return result;
                }
                return null;
            }).catch((reason)=>{
                this.integrateLandsatDownloadData.errorMessage = "Eroare salvare planificare descarcare";
                this.$log.error("Erare salvare descarcare: ", getExMessage(reason));
            }).finally(()=>{
                this.integrateLandsatDownloadData.loadingSearchProductsActive = false;
            }).then((idDescarcare)=>{
                if (typeof idDescarcare === "number"){
                    this.integrateLandsatDownloadData.activeNewTab0 = false;
                    this.integrateLandsatDownloadData.activeExistsTab1 = true;
                    this.integrateLandsatDownloadData.activeTab = 1;
                    this.initExistingDescarcareDate(idDescarcare as any);
                }
            }).catch((reason)=>{
                this.$log.error("Eroare la schimbare tab spre existente: ", getExMessage(reason));
            })

        } catch (error) {
            let resErr = "Eroare salvare produse descarcare: " + error.message;
            this.integrateLandsatDownloadData.errorMessage = resErr;
            this.$log.error(resErr);
        }
    }


    
    //
    public onChangeSursaDate(){
        this.clearSearchResults();
    }
    //
    public addSelectLayer(){
        this.integrateLandsatDownloadData.selectLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.integrateLandsatDownloadData.selectLayer);
    }
    public addProductsLayer(){
        this.integrateLandsatDownloadData.productsLayer = new VectorLayer<any>({
            source: new VectorSource({ wrapX: false })
        });
        this.mapCtrl.map.addLayer(this.integrateLandsatDownloadData.productsLayer);
    }
   
    public removeSelectLayer(){
        if (this.integrateLandsatDownloadData.selectLayer) {
            this.mapCtrl.map.removeLayer(this.integrateLandsatDownloadData.selectLayer);
            this.integrateLandsatDownloadData.selectLayer = null;
        }
    }
    public removeProducstLayer(){
        if (this.integrateLandsatDownloadData.productsLayer) {
            this.mapCtrl.map.removeLayer(this.integrateLandsatDownloadData.productsLayer);
            this.integrateLandsatDownloadData.productsLayer = null;
        }
    }
    
    //
    public addAvailableProductsToLayer(){
        this.integrateLandsatDownloadData.productsLayer.getSource().clear();
        if (this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare){
            this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                // 
                let tmpGeom = createGeomFromWKT(item.geom, "EPSG:4326", this.mapCtrl.mapConfig.projection)
                //
                item.feature = new Feature(tmpGeom);
                item.feature.set("uuid", item.uuid);
                this.integrateLandsatDownloadData.productsLayer.getSource().addFeature(item.feature);
            })
        }
    }
    //
    public addcurrentProductsToLayer(){
        this.integrateLandsatDownloadData.productsLayer.getSource().clear();
        if (this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare){
            this.integrateLandsatDownloadData.currentDescarcare.produseDescarcare.forEach((item)=>{
                let wkt = new WKTFormater();
                let geomwgs = wkt.readGeometry(item.geom) as Polygon;
                let geom = geomwgs.transform("EPSG:4326",this.mapCtrl.mapConfig.projection ) as Polygon;
                item.feature = new Feature(geom);
                item.feature.set("uuid", item.uuid);
                this.integrateLandsatDownloadData.productsLayer.getSource().addFeature(item.feature);
            })
        }
    }

    public addcurrentUatToLayer(){
        this.integrateLandsatDownloadData.selectLayer.getSource().clear();
        if (this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare){
            this.integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.forEach((item)=>{
                let wkt = new WKTFormater();
                let geomwgs = wkt.readGeometry(item.geom) as Polygon;
                let geom = geomwgs.transform("EPSG:4326",this.mapCtrl.mapConfig.projection ) as Polygon;
                item.feature = new Feature(geom);
                item.feature.set("uuid", item.idUnitateAdministrativa);
                item.feature.setStyle([
                this.zoneStyle[0],
                this.zoneStyle[1],
                this.zoneText(item.denumire)
            ])
                this.integrateLandsatDownloadData.selectLayer.getSource().addFeature(item.feature);
            })
        }
    }

    public flipwktPolygonCoordinates(polygonWKT: string): {geom: Polygon, wkt: string}{
        let wkt = new WKTFormater();
        let geomwgs = wkt.readGeometry(polygonWKT) as Polygon;
        let coords = geomwgs.getCoordinates();
        if (coords.length === 1) {
            coords = coords[0] as any;
        }
        let revCoord = coords.map((point) => {
            return [point[1], point[0]]
        })
        let newPolygon = new Polygon([revCoord as any]);
        return {
            geom: newPolygon,
            wkt: wkt.writeGeometry(newPolygon)
        }
    }

    public onProducListItemClick(product: IProdusDescarcare){
        this.integrateLandsatDownloadData.productsLayer.getSource().clear();
        this.integrateLandsatDownloadData.productsLayer.getSource().addFeature(product.feature);
    }

    public onProductListImageClick(product: IProdusDescarcare){
        try {
            if (product.previzualizareL){
                let imgData = [{imagename: product.numeProdus, imageurl: product.previzualizareL}]
                this.mapCtrl.mapDialogs.showInfoFeatureImagesDialog(imgData);
            }
        }catch (e) {
            this.$log.error("Eroare afisare imagine", getExMessage(e));
        }
    }

    public onDrawSelectBoxStart = (event) => {
        this.integrateLandsatDownloadData.selectLayer.getSource().clear();
        this.integrateLandsatDownloadData.currentDescarcare.geom = null;
    }

    public onDrawSelectBoxEnd = (event) => {
        let extentFeature = event.feature as Feature;
        this.integrateLandsatDownloadData.currentDescarcare.geom = extentFeature.getGeometry() as any || null;
        this.integrateLandsatDownloadData.selectDrawInteraction.setActive(false);
        // to do get extent list details
        if (extentFeature) {
            let extentPolygon = extentFeature.getGeometry() as Polygon;
            
        }
    }

    public canRestartDownload() {
        if (this.integrateLandsatDownloadData.currentDescarcare.actiune
            && this.integrateLandsatDownloadData.currentDescarcare.actiune.toLowerCase() === TaskActiune.start) {
            return false;
        } else {
            return this.integrateLandsatDownloadData.currentDescarcare.status.toLowerCase() !== TaskStatus.in_lucru
                && this.integrateLandsatDownloadData.currentDescarcare.status.toLowerCase() !== TaskStatus.finalizat;
        }
    }

    public onRestartDownload() {
        this.integrateLandsatDownloadData.errorMessage = "";
        this.integrateLandsatDownloadData.loadingSearchProductsActive = true;
            this.mapCtrl.integrateLandsatDataService.setNotificareDescarcareDateSatelitare(this.integrateLandsatDownloadData.currentDescarcare.id, null)
            .then((result)=>{
                //
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare restart descarcare");
            }).finally(()=>{
                this.onChangeDescarcare();
            });
            
    }

    public canStopDownload() {
        if (this.integrateLandsatDownloadData.currentDescarcare.actiune
            && this.integrateLandsatDownloadData.currentDescarcare.actiune.toLowerCase() === TaskActiune.stop) {
            return false;
        } else if (this.integrateLandsatDownloadData.currentDescarcare.actiune
            && this.integrateLandsatDownloadData.currentDescarcare.actiune.toLowerCase() === TaskActiune.start
            && this.integrateLandsatDownloadData.currentDescarcare.status.toLowerCase() === TaskStatus.initiat) {
                return true;
        } else {
            return this.integrateLandsatDownloadData.currentDescarcare.status.toLowerCase() === TaskStatus.in_lucru
        }
    }

    public onStopDownload() {
        this.integrateLandsatDownloadData.errorMessage = "";
        this.integrateLandsatDownloadData.loadingSearchProductsActive = true;
            this.mapCtrl.integrateLandsatDataService.setNotificareDescarcareDateSatelitare(this.integrateLandsatDownloadData.currentDescarcare.id, {isStop: true})
            .then((result)=>{
                //
                return true;
            }).catch((reason)=>{
                this.$log.error("Eroare stop descarcare");
            }).finally(()=>{
                this.onChangeDescarcare();
            });
            
    }

    public bindSursaDate(){
        if (this.integrateLandsatDownloadData.currentDescarcare.sursaDate){
            let rez = listaSursaDateClasificare.filter(item=> item.tip === this.integrateLandsatDownloadData.currentDescarcare.sursaDate);
            if (rez && rez.length > 0){
                return rez[0].denumire;
            } else {
                return this.integrateLandsatDownloadData.currentDescarcare.sursaDate;
            }
        }
        //
        return "";
    }


}