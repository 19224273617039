<div class="integrate-landsat-dw-menu integrate-landsat-dw-height" *ngIf="mapCtrl.integrateLandsatDwonloadButtonStateOn">
    <div id="integrate-landsat-dw-content">
        <div class="h6" (click)="integrateLandsatDownloadData.ctrlSearchIsCollapsed = !integrateLandsatDownloadData.ctrlSearchIsCollapsed"> 
            Descărcare date satelitare
            <span class="float-right" (click)="onClickClose(); $event.stopPropagation()">
                <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse">close</span>
            </span>
            <span class="float-right">
                <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse" *ngIf="integrateLandsatDownloadData.ctrlSearchIsCollapsed">arrow_drop_down</span>
                <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse" *ngIf="!integrateLandsatDownloadData.ctrlSearchIsCollapsed">arrow_drop_up</span>
            </span>
            <span class="glyphicon glyphicon-info-sign float-right " 
            ngbPopover="{{infoSearch}}"
            popoverTitle="Info"
            popoverClass="text-dark"
            triggers="mouseenter:mouseleave"
            placement="bottom" 
            onclick="event.stopPropagation()"></span>
        </div>
        <div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="integrateLandsatDownloadData.activeTab" class="nav-tabs nav-fill w-100">
                <li [ngbNavItem]="1"
                (click)="changeNewExistsTab('1')">
                    <a ngbNavLink>Existente</a>
                    <ng-template ngbNavContent>
                        
                    </ng-template>
                </li>
                <li [ngbNavItem]="0"
                (click)="changeNewExistsTab('0')">
                    <a ngbNavLink>Nouă</a>
                    <ng-template ngbNavContent>
                        
                    </ng-template>
                </li>
            </ul>
        </div>
        <!--existing downloads-->
        <div class="integrate-landsat-dw-existing-dw">
            <!--autocompleat search-->
            <div class="integrate-landsat-dw-existing-dw-combo">
                <div class="form-group integrate-landsat-dw-existing-combo-mb" *ngIf="integrateLandsatDownloadData.activeExistsTab1">
                    <label for="uiDesPlanificate">Descărcări planificate</label>
                    <ng-select name="impExistente" required 
                        [(ngModel)]="integrateLandsatDownloadData.selectedDescarcare" (change)="onChangeDescarcare()"
                        placeholder="Selectează descarcare planificată">
                        <ng-option *ngFor="let itmDes of integrateLandsatDownloadData.availableDescarcari"
                            [value]="itmDes">{{itmDes.denumire}}</ng-option>
                    </ng-select>

                    <!-- <span *ngIf="errorResponse || resourceForm.rol_uiSelRole.$touched">
                        <span *ngIf="resourceForm.rol_uiSelRole.$error.required">Nume descarcare este obligatoriu</span>
                    </span> -->
                </div>
            </div>
            <!-- options -->
            <div>
    
            </div>
        </div>
        <!--form input-->
        <div>
            <div [(ngbCollapse)]="integrateLandsatDownloadData.ctrlSearchIsCollapsed">
                <div *ngIf="true">
                    <form #integrateLandsatDownloadForm="ngForm" novalidate>
                        
                        <!--new des info-->
                        <fieldset *ngIf="integrateLandsatDownloadData.activeNewTab0"
                        [disabled]="integrateLandsatDownloadData.loadingSearchProductsActive === true"
                        >
                        <div class="integrate-landsat-dw-existing-dw-combo">
                            <div class="form-group integrate-landsat-dw-existing-combo-mb">
                                <label for="uiSursaDate">Sursa date satelitare</label>
                                <ng-select name="uiSursaDate" required [(ngModel)]="integrateLandsatDownloadData.selectedSursaDate"
                                    (change)="onChangeSursaDate()" placeholder="Selectează descarcare planificată">
                                    <ng-option *ngFor="let itemDs of integrateLandsatDownloadData.availableSursaDate"
                                        [value]="itemDs">{{itemDs.denumire}}</ng-option>
                                </ng-select>
                                <!-- <span *ngIf="errorResponse || integrateLandsatDownloadForm.uiSursaDate.$touched">
                                    <span *ngIf="integrateLandsatDownloadForm.uiSursaDate.$error.required">Nume sursa date este obligatoriu</span>
                                </span> -->
                            </div>
                        </div>
                            <div class="form-group">
                                <label>Perioada</label>
                                <div class="integrate-landsat-dw-form-period-inputs">

                                    <div class="input-group">
                                        <input
                                            class="form-control px-2"
                                            placeholder="început"
                                            name="impPerioadaStart"
                                            [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.startPerioada"
                                            ngbDatepicker
                                            #dstart="ngbDatepicker"
                                            (ngModelChange)="clearSearchResults()"
                                        />
                                        <div class="input-group-append">
                                            <button class="btn btn-light" (click)="dstart.toggle()" type="button">
                                                <i class="glyphicon glyphicon-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <input
                                            class="form-control px-2"
                                            placeholder="sfârșit"
                                            name="impPerioadaEnd"
                                            [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.endPerioada"
                                            ngbDatepicker
                                            placement="bottom-right"
                                            #dend="ngbDatepicker"
                                            (ngModelChange)="clearSearchResults()"
                                        />
                                        <div class="input-group-append">
                                            <button class="btn btn-light" (click)="dend.toggle()" type="button">
                                                <i class="glyphicon glyphicon-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--unitati administrative-->
                            <h6 (click)="onctrlDownloadUnitatiAdministrativeIsColapsed()">
                                <span *ngIf="integrateLandsatDownloadData.activeNewTab0 === true">
                                    <span *ngIf="integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed === true" class="btn btn-light">
                                        Unități administrative: {{integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.length || 0}}</span>
                                    <span *ngIf="integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed === false">Unități administrative</span>
                                </span>
                                <span class="float-right">
                                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse" *ngIf="integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed">arrow_drop_down</span>
                                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse" *ngIf="!integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed">arrow_drop_up</span>
                                </span>
                            </h6>
                            <div [(ngbCollapse)]="integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed">
                                <div class="integrate-landsat-dw-unitati-zone">
                                    <!-- lista unitati administrative -->
                                    <div class="integrate-landsat-dw-uat-list" *ngFor="let uat of integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare">
                                         <div class="integrate-landsat-dw-uat-item">
                                            <div class="integrate-landsat-dw-uat-item-name">{{uat.denumire}}</div>
                                            <div class="btn-xs btn-light"><span class="integrate-landsat-dw-uat-item-bt" (click)="onRemoveUnitateAdministrativa(uat)">x</span></div>
                                        </div>
                                    </div>
                                    <!--adauga unitate-->
                                    <div class="integrate-landsat-dw-select-dw-combo">
                                        <div class="form-group integrate-landsat-dw-existing-combo-mb">
                                            <ng-select name="uinewIndice" required [(ngModel)]="integrateLandsatDownloadData.selectedUnitateAdministrativa"
                                                (change)="onChangeSelectedUnitateAdministrativa()" placeholder="Selectează descarcare planificată">
                                                <ng-option *ngFor="let itemSd of integrateLandsatDownloadData.availableUnitatiAdministrative "
                                                    [value]="itemSd">{{itemSd.denumire}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="integrate-dw-btn-dark">
                                        <button class="btn btn-defalut" (click)="onAddUnitateAdministrativa()">Adaugă selecție</button>
                                        <button class="btn btn-defalut" (click)="onAddAllUnitateAdministrativa()">Adaugă toate</button>
                                    </div>
                                </div>
                            </div>
                            <!---->
                            <div class="integrate-landsat-dw-form-search-buttons">
                                <div></div>
                                <button class="btn btn-light p-1" (click)="onSearchAvailableProductsClick()" title="Caută produse"> 
                                    <i class="fa fa-search fa-2x"></i>
                                </button>
                            </div>


                            <!--detalii descarcare-->
                            <h6 (click)="onctrlDownloadDetailsIsColapsed()">
                                <span *ngIf="integrateLandsatDownloadData.activeNewTab0 === true">
                                    <span *ngIf="integrateLandsatDownloadData.ctrlDownloadDetailsIsColapsed === true" class="btn btn-light">Planifică descărcare</span>
                                    <span *ngIf="integrateLandsatDownloadData.ctrlDownloadDetailsIsColapsed === false">Detalii descărcare</span>
                                </span>
                                <span class="float-right">
                                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse" *ngIf="integrateLandsatDownloadData.ctrlDownloadDetailsIsColapsed">arrow_drop_down</span>
                                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse" *ngIf="!integrateLandsatDownloadData.ctrlDownloadDetailsIsColapsed">arrow_drop_up</span>
                                </span>
                            </h6>
                            <div [(ngbCollapse)]="integrateLandsatDownloadData.ctrlDownloadDetailsIsColapsed">
                                <div class="form-group" *ngIf="integrateLandsatDownloadData.activeNewTab0 == true">
                                    <label>Denumire</label>
                                    <input class="form-control" type="text" name="denumire"
                                        [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.denumire"/>
                                </div>
                                <div class="form-group" >
                                    <label>Descriere</label>
                                    <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                        [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.descriere"
                                        ></textarea>
                                </div>
                                <div class="form-group">
                                    <button class="btn btn-light p-1" title="Descarcă produse"
                                    (click)="onClickSaveDownloadProducts()">
                                        <i class="fas fa-cloud-download-alt fa-2x"></i>
                                    </button>
                                </div>
                            </div>
                        </fieldset>
    
                        <!--existing des info-->
                        <fieldset  *ngIf="integrateLandsatDownloadData.activeExistsTab1 && integrateLandsatDownloadData.selectedDescarcare"
                            [disabled]="integrateLandsatDownloadData.loadingSearchProductsActive === true">
                            
                            <h6 (click)="onctrlExistingDetailsIsColapsed()">
                                <span >Detalii descărcare</span>
                                <span class="float-right">
                                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse" *ngIf="integrateLandsatDownloadData.ctrlExistingDetalisIsColapsed">arrow_drop_down</span>
                                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse" *ngIf="!integrateLandsatDownloadData.ctrlExistingDetalisIsColapsed">arrow_drop_up</span>
                                </span>
                                <span class="float-right" 
                                (click)="onClickReloadExisting(); $event.stopPropagation()">
                                    <span class="material-icons integrate-landsat-dw-menu-icon-refresh">refresh</span>
                                </span>
                            </h6>
                            <div [(ngbCollapse)]="integrateLandsatDownloadData.ctrlExistingDetalisIsColapsed">
                                <div class="form-group">
                                    <label>Sursă date satelitare</label>
                                    <!-- <input class="form-control" type="text" name="status" ng-model="integrateLandsatDownloadData.currentDescarcare.sursaDate"
                                        readonly /> -->
                                        <div class="form-control" type="text" name="status">{{bindSursaDate()}}</div>
                                </div>
                                <div class="form-group">
                                    <label>Perioada</label>
                                    <div class="integrate-landsat-dw-form-period-inputs">
                                        <div class="input-group">
                                            <input
                                                disabled
                                                class="form-control px-2"
                                                placeholder="început"
                                                name="impPerioadaStart"
                                                [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.startPerioada"
                                                ngbDatepicker
                                                #dstart="ngbDatepicker"
                                            />
                                            <div class="input-group-append">
                                                <button disabled class="btn btn-light" (click)="dstart.toggle()" type="button">
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <input
                                                disabled
                                                class="form-control px-2"
                                                placeholder="sfârșit"
                                                name="impPerioadaEnd"
                                                [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.endPerioada"
                                                ngbDatepicker
                                                #dend="ngbDatepicker"
                                            />
                                            <div class="input-group-append">
                                                <button disabled class="btn btn-light" (click)="dend.toggle()" type="button">
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="form-group" *ngIf="integrateLandsatDownloadData.activeNewTab0 == true">
                                    <label>Denumire</label>
                                    <input class="form-control" type="text" name="denumire"
                                        [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.denumire" 
                                        [disabled]="integrateLandsatDownloadData.activeExistsTab1"/>
                                </div>
                                <div class="form-group" >
                                    <label>Descriere</label>
                                    <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                        [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.descriere" 
                                        [disabled]="integrateLandsatDownloadData.activeExistsTab1"></textarea>
                                </div>
                                <div class="form-group">
                                    <label>Status</label>
                                    <input class="form-control" type="text" name="status"
                                        [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.status" readonly />
                                </div>
                                <div class="form-group" *ngIf="integrateLandsatDownloadData.currentDescarcare.actiune">
                                    <label>Cerere acțiune</label>
                                    <input class="form-control" type="text" name="status"
                                        [(ngModel)]="integrateLandsatDownloadData.currentDescarcare.actiune" readonly />
                                </div>
                                <!--unitati admin-->
                                <h6 (click)="onctrlDownloadUnitatiAdministrativeIsColapsed()">
                                    <span>
                                        <span>
                                            Unități administrative:
                                            {{integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare.length || 0}}</span>
                                    </span>
                                    <span class="float-right">
                                        <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse"
                                            *ngIf="integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed">arrow_drop_down</span>
                                        <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse"
                                            *ngIf="!integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed">arrow_drop_up</span>
                                    </span>
                                </h6>
                                <div [(ngbCollapse)]="integrateLandsatDownloadData.ctrlDownloadUnitatiAdministrativeIsColapsed">
                                    <div class="integrate-landsat-dw-unitati-zone">
                                        <!-- lista unitati administrative -->
                                        <div class="integrate-landsat-dw-uat-list"
                                        *ngFor="let uat of integrateLandsatDownloadData.currentDescarcare.unitatiAdministrativeDescarcare">
                                    
                                            <div class="integrate-landsat-dw-uat-item">
                                                <div class="integrate-landsat-dw-uat-name">{{uat.denumire}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--stop restart buttons-->
                                <div class="input-group">
                                    <button class="btn btn-light p-1 my-1 ml-1 mr-0" 
                                    [disabled]="canRestartDownload() === false"
                                    (click)="onRestartDownload()" title="Repornește"><i class="fas fa-redo fa-2x"></i></button>
                                    <button class="btn btn-light p-1 my-1 ml-1 mr-0" 
                                    [disabled]="canStopDownload() === false"
                                    (click)="onStopDownload()" title="Oprește"><i class="fas fa-stop-circle fa-2x"></i></button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
        <!--search results-->
        <div>
            <h6 (click)="onClickColapseProducts()">
                Produse disponibile:{{integrateLandsatDownloadData.currentDescarcare.produseDescarcare.length || ""}}
                <span class="float-right" 
                *ngIf="integrateLandsatDownloadData.activeNewTab0"
                (click)="onClickProductsClose(); $event.stopPropagation()">
                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse">close</span>
                </span>
                <span class="float-right">
                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse"
                        *ngIf="integrateLandsatDownloadData.ctrlProductsIsCollapsed">arrow_drop_down</span>
                    <span class="material-icons integrate-landsat-dw-menu-icon-close-colapse"
                        *ngIf="!integrateLandsatDownloadData.ctrlProductsIsCollapsed">arrow_drop_up</span>
                </span>
                <span class="glyphicon glyphicon-info-sign float-right" 
                ngbPopover="{{infoProducts}}"
                popoverTitle="Info"
                popoverClass="text-dark"
                triggers="mouseenter:mouseleave"
                placement="bottom" 
                onclick="event.stopPropagation();"></span>
            </h6>
            <div *ngIf="integrateLandsatDownloadData.loadingSearchProductsActive === true;"class="tranz-spiner-box">
                <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
            </div>
            <h6 *ngIf="integrateLandsatDownloadData.errorMessage && integrateLandsatDownloadData.errorMessage.length > 0" class="text-danger">
                {{integrateLandsatDownloadData.errorMessage}}
            </h6>
            <div [(ngbCollapse)]="integrateLandsatDownloadData.ctrlProductsIsCollapsed">
             <div *ngIf="integrateLandsatDownloadData.loadingSearchProductsActive === false;">
                 <div *ngFor="let produs of integrateLandsatDownloadData.currentDescarcare.produseDescarcare"
                 (click)="onProducListItemClick(produs)">
                     <div class="integrate-landsat-dw-products-title"  >
                        <div *ngIf="integrateLandsatDownloadData.activeNewTab0"  class="integrate-landsat-tr-indice-item-ck" >
                            <input type="checkbox" style="zoom:1.5" [(ngModel)]="produs.selected"
                                (click)="$event.stopPropagation()" />
                        </div>
                        <div class="integrate-landsat-dw-products-title-text">
                           <div class="integrate-landsat-dw-products-title-text-in"> {{produs.nr}}.{{produs.numeProdus}}</div>  
                        </div> 
                     </div>
                    <div class="integrate-landsat-dw-products-item">
                        <div>
                            <img src="{{produs.previzualizareS}}" alt="previzualizare" width="64" height="64"
                                onerror="this.onerror=null;this.src='./img/no-image.jpg';" 
                                (click)="onProductListImageClick(produs)"
                                class="integrate-landsat-dw-products-img"/>

                        </div>
                        <div>
                            <div> Data: {{produs.dataProdus}}</div>
                            <div *ngIf="integrateLandsatDownloadData.activeExistsTab1"> Status: {{produs.status}}</div>
                            
                        </div>
                    
                    </div>
                 </div>
             </div>
            </div>
        </div>
    </div>
</div>