import { Component, OnInit, Input, Inject } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { UserSettingsService } from "../../services/user-settings-service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { IItem, actionOption, authAs, authType, userActionList as defaultActionList } from "../../definitions";

@Component({
    selector: 'user-main',
    providers: [],
    templateUrl: './user-main.component.html',
    styleUrls: ['./user-main.component.css']
})
export class UserMainComponent implements OnInit {

    public reloadOnClose = false;
    public errorResponse: string;
    public disableInput: boolean = false;
    //
    public actionType: actionOption;
    public actionList: Array<actionOption>;
    //
    public constructor(
        @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
        @Inject(NGXLogger) private $log: NGXLogger,
        @Inject(UserSettingsService) private userSettingsSrvs: UserSettingsService
    ) {
        //
    }

    public ngOnInit(): void {
        this.actionType = null;
        this.actionList = [];
        //check if user is admin
        if (this.userSettingsSrvs.isAuthForResource(authAs.auth_change_current_password, authType.route)) {
            this.actionList.push(defaultActionList[0]);
        }
        if (this.userSettingsSrvs.isAuthForResource(authAs.auth_change_password, authType.route)) {
            this.actionList.push(defaultActionList[1]);
        }
        if (this.userSettingsSrvs.isAuthForResource(authAs.data_save_current_user_info, authType.route)) {
            this.actionList.push(defaultActionList[2]);
        }
        if (this.userSettingsSrvs.isAuthForResource(authAs.data_save_user_info, authType.route) &&
            this.userSettingsSrvs.isAuthForResource(authAs.data_user_info, authType.route)
        ) {
            this.actionList.push(defaultActionList[3]);
        }
        if (this.userSettingsSrvs.isAuthForResource(authAs.auth_local_register, authType.route)) {
            this.actionList.push(defaultActionList[4]);
        }
        if (this.userSettingsSrvs.isAuthForResource(authAs.data_save_user_roles, authType.route)) {
            this.actionList.push(defaultActionList[5]);
        }
        //
        if (this.actionList.length == 0){
            this.activeModal.close(false);
        }
    }

    public onClickAction(action){
        if (action) {
            this.actionType = action;
        }
    }

    
    public close(): void {
        this.activeModal.close(this.reloadOnClose);
    }

    public menuToolCosed(value: string){
        if (value === "cancel"){
            this.actionType = null;
        } else if (value === "changed"){
            this.actionType = null;
            this.reloadOnClose = true;
        }
    }
}