<div class="greentop-cl-menu greentop-cl-height" *ngIf="mapCtrl.greentopClassifyButtonStateOn">
    <div id="greentop-cl-content">
        <div class="h6"
        (click)="greentopClassifyData.ctrlSearchIsCollapsed = !greentopClassifyData.ctrlSearchIsCollapsed">
        Clasificare date satelitare
        
        <span class="float-right" (click)="onClickClose(); $event.stopPropagation()">
            <span class="material-icons greentop-cl-menu-icon-close-colapse">close</span>
        </span>
        <span class="float-right">
            <span class="material-icons greentop-cl-menu-icon-close-colapse"
                *ngIf="greentopClassifyData.ctrlSearchIsCollapsed">arrow_drop_down</span>
            <span class="material-icons greentop-cl-menu-icon-close-colapse"
                *ngIf="!greentopClassifyData.ctrlSearchIsCollapsed">arrow_drop_up</span>
        </span>
        <span class="glyphicon glyphicon-info-sign float-right "
        ngbPopover="{{infoSearch}}"
        popoverTitle="Info"
        popoverClass="text-dark"
        triggers="mouseenter:mouseleave"
        placement="bottom"
            onclick="event.stopPropagation()">
        </span>
        <span class="float-right" (click)="onClickMinimize(); $event.stopPropagation()">
            <span class="material-icons greentop-cl-menu-icon-close-colapse">minimize</span>
        </span>
    </div>
    <div>
        <ul ngbNav #nav="ngbNav" [(activeId)]="greentopClassifyData.activeTab" class="nav-tabs nav-fill w-100">
            <li [ngbNavItem]="1"
            (click)="changeNewExistsTab('1')">
                <a ngbNavLink>Existentă</a>
                <ng-template ngbNavContent>
                </ng-template>
            </li>
            <li [ngbNavItem]="0"
            (click)="changeNewExistsTab('0')">
                <a ngbNavLink>Nouă</a>
                <ng-template ngbNavContent>
                </ng-template>
            </li>
        </ul>
    </div>

    <!--existing classifications-->
    <div class="greentop-cl-existing-cl">
        <!--autocompleat search-->
        <div class="greentop-cl-existing-cl-combo">
            <div class="form-group greentop-cl-existing-combo-mb" *ngIf="greentopClassifyData.activeExistsTab1">
                <label for="uiClPlanificate">Clasificări planificate</label>
                <ng-select name="uiClPlanificate" required class="w-100"
                    multiline="true"
                    [items]="greentopClassifyData.availableClasificari"
                    [(ngModel)]="greentopClassifyData.selectedClasificare"
                    (change)="onChangeClasificare()" 
                    bindLabel="denumire"
                    placeholder="Selectează clasificare planificată">
                        <ng-template ng-label-tmp let-item="item">
                            <div class="greentop-ng-select-wrap">
                                {{item.denumire}}
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="greentop-ng-select-wrap">
                                [{{item.id }}] {{item.denumire}}
                            </div>
                        </ng-template>
                </ng-select>
            </div>
        </div>
        <!-- options -->
        <div>

        </div>
    </div>
    <!--content tabs-->
    <div>
        <ul ngbNav #nav="ngbNav" [(activeId)]="greentopClassifyData.activeDataTab" class="nav-tabs nav-fill w-100">
            <li [ngbNavItem]="0"
            (click)="changeDataTab('0')">
                <a ngbNavLink>Detalii</a>
                <ng-template ngbNavContent>
                </ng-template>
            </li>
            <li [ngbNavItem]="1"
            (click)="changeDataTab('1')">
                <a ngbNavLink>Model</a>
                <ng-template ngbNavContent>
                </ng-template>
            </li>
            <li [ngbNavItem]="2"
            (click)="changeDataTab('2')">
                <a ngbNavLink>Rezultate</a>
                <ng-template ngbNavContent>
                </ng-template>
            </li>
        </ul>
    </div>

    <!---->
    <div>
        <div [(ngbCollapse)]="greentopClassifyData.ctrlSearchIsCollapsed">
            <form #greentopClassifyForm="ngForm" novalidate>
                <!-- new training-->
                <div *ngIf="greentopClassifyData.activeNewTab0">
                    <fieldset [disabled]="greentopClassifyData.loadingActive === true">
                        <!--detalii-->
                        <div *ngIf="greentopClassifyData.activeDataTab === 0">
                            <!-- zona-->

                            <div>
                                <div class="form-group">
                                    <label>Denumire</label>
                                    <input class="form-control" type="text" name="uiClsDenumire"
                                        [(ngModel)]="greentopClassifyData.currentClasificare.denumire" />
                                </div>
                                <div class="form-group">
                                    <label>Descriere</label>
                                    <textarea class="form-control textarea-noresize" rows="2" name="uiClsDescriere"
                                        [(ngModel)]="greentopClassifyData.currentClasificare.descriere"></textarea>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Zonă clasificare</label>
                                <div class="input-group">
                                    <button class="btn btn-success disabled"
                                        [ngClass]="{'btn-warning':greentopClassifyData.currentClasificare.geom == null}">
                                        Zonă selecție
                                    </button>
                                    <div class="input-group-append ">
                                        <span class="btn"
                                        [ngClass]="{'btn-primary':greentopClassifyData.selectButtonStateOn, 'btn-light': greentopClassifyData.selectButtonStateOn == false}"
                                        (click)="onClickSelectButton()"
                                        >
                                        <i class="glyphicon glyphicon-edit"></i>
                                    </span>
                                    </div>
                                    
                                </div>
                            </div>

                            <div class="form-group" *ngIf="greentopClassifyData?.currentClasificare?.antrenament?.idStructuraDate == 1">
                                <label>Perioadă clasificare</label>
                                <div class="greentop-cl-form-period-inputs">
                                    <div class="input-group">
                                        <input
                                            class="form-control px-2"
                                            placeholder="început"
                                            name="uiClsStartPerioada"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.startPerioada"
                                            ngbDatepicker
                                            #cdstart="ngbDatepicker"
                                            (ngModelChange)="clearSearchResults()"
                                            
                                        />
                                        <div class="input-group-append">
                                            <button class="btn btn-light" (click)="dcstart.toggle()" type="button">
                                                <i class="glyphicon glyphicon-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <input
                                            class="form-control px-2"
                                            placeholder="sfârșit"
                                            name="uiClsEndPerioada"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.endPerioada"
                                            ngbDatepicker
                                            placement="bottom-right"
                                            #cdend="ngbDatepicker"
                                            (ngModelChange)="clearSearchResults()"
                                            
                                        />
                                        <div class="input-group-append">
                                            <button class="btn btn-light" (click)="cdend.toggle()" type="button">
                                                <i class="glyphicon glyphicon-calendar"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="greentop-cl-select-dw" *ngIf="greentopClassifyData?.currentClasificare?.antrenament?.idStructuraDate == 2">
                                <!--autocompleat search-->
                                <div class="greentop-cl-select-dw-combo">
                                    <div class="form-group greentop-cl-existing-dw-combo">
                                        <label for="uiAnDesPlanificate">An start clasificare</label>
                                        
                                        <ng-select name="uiAnDesPlanificate" required class="w-100" multiline="true"
                                            [items]="greentopClassifyData.availableAniPerioada" 
                                            [(ngModel)]="greentopClassifyData.selectedAnPerioada"
                                            (change)="onChangeAnPerioada()" 
                                            placeholder="Selectează sau caută in listă...">
                                            <ng-template ng-label-tmp let-item="item">
                                                <div class="greentop-ng-select-wrap">
                                                    {{item}}
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                <div class="greentop-ng-select-wrap">
                                                    {{item}}
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>

                            <div class="greentop-cl-select-dw">
                                <!--autocompleat search-->
                                <div class="greentop-cl-select-dw-combo">
                                    <div class="form-group greentop-cl-existing-dw-combo">
                                        <label for="uiDesPlanificate">Descărcare planificată</label>
                                        <ng-select name="uiDesPlanificate" required class="w-100"
                                        multiline="true"
                                        [items]="greentopClassifyData.availableDescarcari"
                                        [(ngModel)]="greentopClassifyData.selectedDescarcare"
                                        (change)="onChangeDescarcare()" 
                                        bindLabel="denumire"
                                        placeholder="Selectează sau caută in listă...">
                                            <ng-template ng-label-tmp let-item="item">
                                                <div class="greentop-ng-select-wrap">
                                                    {{item.denumire}}
                                                </div>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                <div class="greentop-ng-select-wrap">
                                                    [{{item.id }}] {{item.denumire}}
                                                </div>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div  *ngIf="greentopClassifyData.currentClasificare.descarcare">
                                <div class="form-group">
                                    <label>Perioadă descarcare</label>
                                    <div class="greentop-cl-form-period-inputs">
                                        <div class="input-group">
                                            <input
                                                class="form-control px-2"
                                                placeholder="început"
                                                name="uiDesStartPerioada"
                                                [(ngModel)]="greentopClassifyData.currentClasificare.descarcare.startPerioada"
                                                ngbDatepicker
                                                #dstart="ngbDatepicker"
                                                (ngModelChange)="clearSearchResults()"
                                                disabled
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-light" (click)="dstart.toggle()" type="button" disabled>
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <input
                                                class="form-control px-2"
                                                placeholder="sfârșit"
                                                name="uiDesEndPerioada"
                                                [(ngModel)]="greentopClassifyData.currentClasificare.descarcare.endPerioada"
                                                ngbDatepicker
                                                placement="bottom-right"
                                                #dend="ngbDatepicker"
                                                (ngModelChange)="clearSearchResults()"
                                                disabled
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-light" (click)="dend.toggle()" type="button" disabled>
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div class="form-group" >
                                    <label>Descriere</label>
                                    <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                        ng-model="descarcare.descriere" 
                                        [disabled]="true"></textarea>
                                </div> -->
                            </div>

                            <div class="input-group">
                                <button class="btn btn-light greentop-action-wrp-buttons"
                                    (click)="onSaveClassify()" title="Salvează"><i class="fas fa-cloud-upload-alt fa-2x"></i></button>
                                <button class="btn btn-light greentop-action-wrp-buttons"
                                    (click)="onCancelClassify()" title="Renunță"><i class="fas fa-window-close fa-2x"></i></button>
                            </div>
                        </div>
                        <!--antrenament-->
                        <div *ngIf="greentopClassifyData.activeDataTab === 1">
                            <div class="greentop-cl-existing-cl-combo">
                                <div class="form-group greentop-cl-existing-cl-combo">
                                    <label for="uiTrPlanificate">Antrenament</label>
                                    <ng-select name="uiTrPlanificate" required class="w-100"
                                    multiline="true"
                                    [items]="greentopClassifyData.availableAntrenamente "
                                    [(ngModel)]="greentopClassifyData.selectedAntrenament"
                                    (change)="onChangeAntrenament()" 
                                    bindLabel="denumire"
                                    placeholder="Selectează antrenament planificat">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div class="greentop-ng-select-wrap">
                                                {{item.denumire}}
                                            </div>
                                        </ng-template>
                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                            <div class="greentop-ng-select-wrap">
                                                [{{item.id }}] {{item.denumire}}
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                            <div *ngIf="greentopClassifyData.selectedAntrenament && greentopClassifyData.currentClasificare.antrenament">
                                <div>
                                    <div class="form-group">
                                        <label>Descriere</label>
                                        <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.descriere"
                                            [disabled]="true"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Perioada antrenament</label>
                                        <div class="greentop-cl-form-period-inputs">
                                            <div class="input-group">
                                                <input
                                                    class="form-control px-2"
                                                    placeholder="început"
                                                    name="uiAntStartPerioada"
                                                    [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.descarcariAntrenament[0].startPerioada"
                                                    ngbDatepicker
                                                    #adstart="ngbDatepicker"
                                                    disabled
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-light" (click)="adstart.toggle()" type="button" disabled>
                                                        <i class="glyphicon glyphicon-calendar"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="input-group">
                                                <input
                                                    class="form-control px-2"
                                                    placeholder="sfârșit"
                                                    name="uiAntEndPerioada"
                                                    [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.descarcariAntrenament[0].endPerioada"
                                                    ngbDatepicker
                                                    placement="bottom-right"
                                                    #adend="ngbDatepicker"
                                                    disabled
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-light" (click)="adend.toggle()" type="button" disabled>
                                                        <i class="glyphicon glyphicon-calendar"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>Algoritm clasificare</label>
                                        <input class="form-control" type="text" name="denumire"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.denumireAlgoritm"
                                            [disabled]="true" />
                                    </div>
                                    <div>
                                        <h6>Listă indici:</h6>
                                        <div class="greentop-cl-indice-list"
                                        *ngFor="let indice of greentopClassifyData.currentClasificare.antrenament.indiciAntrenament">
                                            <div>{{indice.codIndice}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h6 *ngIf="greentopClassifyData.errorMessage && greentopClassifyData.errorMessage.length > 0" class="text-danger">
                                {{greentopClassifyData.errorMessage}}
                            </h6>
                        </div>
                    </fieldset>
                </div>

                <!-- exist clasifiare-->
                <div *ngIf="greentopClassifyData.activeExistsTab1">
                    <fieldset [disabled]="greentopClassifyData.loadingActive === true">
                        <!--detalii-->
                        <div *ngIf="greentopClassifyData.activeDataTab === 0">
                    
                            <div *ngIf="greentopClassifyData.currentClasificare.antrenament">
                                
                                <div>
                                    <div class="form-group">
                                        <label>Descriere</label>
                                        <span class="float-right"
                                            (click)="onClickReloadExisting(); $event.stopPropagation()">
                                            <span class="material-icons greentop-cl-menu-icon-refresh">refresh</span>
                                        </span>
                                        <textarea class="form-control textarea-noresize" rows="2" name="descriere"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.descriere" [disabled]="true"></textarea>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="greentopClassifyData.currentClasificare.status !== 'finalizat'">
                                    <label>Progres</label>
                                    <div>
                                        <ngb-progressbar max="100" [value]="greentopClassifyData.currentClasificare.progresStatus" type="success"><span style="color:black; white-space:nowrap;"> {{greentopClassifyData.currentClasificare.progresStatus}}% {{greentopClassifyData.currentClasificare.progresDetalii}}</span></ngb-progressbar>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Perioada clasificare</label>
                                    <div class="greentop-cl-form-period-inputs">
                                        <div class="input-group">
                                            <input
                                                class="form-control px-2"
                                                placeholder="început"
                                                name="uiClsStartPerioada"
                                                [(ngModel)]="greentopClassifyData.currentClasificare.startPerioada"
                                                ngbDatepicker
                                                #cdstart="ngbDatepicker"
                                                disabled
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-light" (click)="dcstart.toggle()" type="button" disabled>
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <input
                                                class="form-control px-2"
                                                placeholder="sfârșit"
                                                name="uiClsEndPerioada"
                                                [(ngModel)]="greentopClassifyData.currentClasificare.endPerioada"
                                                ngbDatepicker
                                                placement="bottom-right"
                                                #cdend="ngbDatepicker"
                                                disabled
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-light" (click)="cdend.toggle()" type="button" disabled>
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="label-full-width"><span>Status</span>
                                        <span class="glyphicon glyphicon-info-sign float-right" 
                                        *ngIf="greentopClassifyData.currentClasificare.mesajStatus && greentopClassifyData.currentClasificare.mesajStatus.length > 0"
                                        ngbPopover="{{greentopClassifyData.currentClasificare.mesajStatus}}"
                                        popoverTitle="Info"
                                        popoverClass="text-dark"
                                        triggers="mouseenter:mouseleave"
                                        placement="bottom"
                                          onclick="event.stopPropagation()"
                                         ></span>
                                    </label>
                                    <input class="form-control" type="text" name="status"
                                        [(ngModel)]="greentopClassifyData.currentClasificare.status" [disabled]="true" />
                                </div>
                                <div class="form-group" *ngIf="greentopClassifyData.currentClasificare.actiune">
                                    <label>Cerere acțiune</label>
                                    <input class="form-control" type="text" name="status"
                                        [(ngModel)]="greentopClassifyData.currentClasificare.actiune" readonly />
                                </div>
                    
                                <!-- descarcare existant-->
                                <div *ngIf="greentopClassifyData.currentClasificare.descarcare">
                                    <div class="form-group">
                                        <label>Descarcare</label>
                                        <input class="form-control" type="text" name="denumire"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.descarcare.denumire" [disabled]="true" />
                                    </div>
                                    <div class="form-group">
                                        <label>Perioada descarcare</label>
                                        <div class="greentop-cl-form-period-inputs">
                                        <div class="input-group">
                                            <input
                                                class="form-control px-2"
                                                placeholder="început"
                                                name="uiDesStartPerioada"
                                                [(ngModel)]="greentopClassifyData.currentClasificare.descarcare.startPerioada"
                                                ngbDatepicker
                                                #cdastart="ngbDatepicker"
                                                disabled
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-light" (click)="cdastart.toggle()" type="button" disabled>
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="input-group">
                                            <input
                                                class="form-control px-2"
                                                placeholder="sfârșit"
                                                name="uiDesEndPerioada"
                                                [(ngModel)]="greentopClassifyData.currentClasificare.descarcare.endPerioada"
                                                ngbDatepicker
                                                placement="bottom-right"
                                                #cdaend="ngbDatepicker"
                                                disabled
                                            />
                                            <div class="input-group-append">
                                                <button class="btn btn-light" (click)="cdaend.toggle()" type="button" disabled>
                                                    <i class="glyphicon glyphicon-calendar"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                    
                                <div class="input-group">
                                    <button class="btn btn-light greentop-action-wrp-buttons"
                                        [disabled]="canRestartClasificare() === false"
                                        (click)="onRestartClasificare()" title="Repornește"><i class="fas fa-redo fa-2x"></i></button>
                                        <button class="btn btn-light greentop-action-wrp-buttons"
                                        [disabled]="canStopClasificare() === false"
                                        (click)="onStopClasificare()" title="Oprește"><i class="fas fa-stop-circle fa-2x"></i></button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="greentopClassifyData.activeDataTab === 1">
                    
                            <div *ngIf="greentopClassifyData.currentClasificare.antrenament">
                                <div>
                                    <div class="form-group">
                                        <label>Antrenament</label>
                                        <input class="form-control" type="text" name="uiExAntDenumire"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.denumire" [disabled]="true" />
                                    </div>
                                    <div class="form-group">
                                        <label>Descriere</label>
                                        <textarea class="form-control textarea-noresize" rows="2" name="uiExAntDescriere"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.descriere"
                                            [disabled]="true"></textarea>
                                    </div>
                                    <div class="form-group">
                                        <label>Perioada antrenament</label>
                                        <div class="greentop-cl-form-period-inputs">
                                            <div class="input-group">
                                                <input
                                                    class="form-control px-2"
                                                    placeholder="început"
                                                    name="uiAntStartPerioada"
                                                    [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.descarcariAntrenament[0].startPerioada"
                                                    ngbDatepicker
                                                    #adstart="ngbDatepicker"
                                                    disabled
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-light" (click)="adstart.toggle()" type="button" disabled>
                                                        <i class="glyphicon glyphicon-calendar"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="input-group">
                                                <input
                                                    class="form-control px-2"
                                                    placeholder="sfârșit"
                                                    name="uiAntEndPerioada"
                                                    [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.descarcariAntrenament[0].endPerioada"
                                                    ngbDatepicker
                                                    placement="bottom-right"
                                                    #adend="ngbDatepicker"
                                                    disabled
                                                />
                                                <div class="input-group-append">
                                                    <button class="btn btn-light" (click)="adend.toggle()" type="button" disabled>
                                                        <i class="glyphicon glyphicon-calendar"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label>Algoritm clasificare</label>
                                        <input class="form-control" type="text" name="uiExAntAlgDenumire"
                                            [(ngModel)]="greentopClassifyData.currentClasificare.antrenament.denumireAlgoritm"
                                            [disabled]="true" />
                                    </div>
                                   
                                    <div>
                                        <h6>Lista indici:</h6>
                                        <div class="greentop-cl-indice-list"
                                        *ngFor="let indice of greentopClassifyData.currentClasificare.antrenament.indiciAntrenament">
                                            <div>{{indice.codIndice}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="greentopClassifyData.activeDataTab === 2">
                    
                            <div>
                                <!--autocompleat search-->
                                <div class="form-group greentop-cl-existing-combo-mb"
                                *ngIf="greentopClassifyData.selectedClasificare && greentopClassifyData.resultTileLayer">
                                    <label for="uiClPlanificate">Moment date satelitare</label>
                                    <div class="greentop-cl-existing-mds-inputs">
                                        <div class="greentop-cl-existing-mds-combo">
                                            <ng-select name="uiTrPlanificate" required class="w-100"
                                            multiline="true"
                                            [items]="greentopClassifyData.availableMomenteDateSatelitare"
                                            [(ngModel)]="greentopClassifyData.selectedMomentDateSatelitare"
                                            (change)="onChangeMomentDateSatelitare()" 
                                            placeholder="Selectează moment date satelitare">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <div class="greentop-ng-select-wrap">
                                                        {{item}}
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    <div class="greentop-ng-select-wrap">
                                                        {{item}}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                
                                        </div>
                                        <button class="btn-xs btn-light " (click)="onClickPrevMomentDateSatelitare()">
                                            <i class="fa fa-step-backward"></i>
                                        </button>
                                        <button class="btn-xs btn-light " (click)="onClickNextMomentDateSatelitare()">
                                            <i class="fa fa-step-forward "></i>
                                        </button>
                                    </div>
                                </div>

                                <div class="form-group greentop-cl-existing-combo-mb"
                                *ngIf="greentopClassifyData.selectedClasificare && greentopClassifyData.resultTileLayer">
                                    <label for="uiClPlanificate">Stil clasificare</label>
                                    <div class="greentop-cl-existing-mds-inputs">
                                        <div class="greentop-cl-existing-mds-combo">
                                            <ng-select name="uiClStiluri" required class="w-100"
                                            multiline="true"
                                            [items]="greentopClassifyData.availableStiluri"
                                            [(ngModel)]="greentopClassifyData.selectedStil"
                                            (change)="onChangeStilClasificare()" 
                                            bindLabel="denumire"
                                            placeholder="Selectează stil">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <div class="greentop-ng-select-wrap">
                                                        {{item.denumire}}
                                                    </div>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    <div class="greentop-ng-select-wrap">
                                                        [{{item.id }}] {{item.denumire}}
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <!-- rezultat clasificare imagine -->
                                    <div *ngIf="greentopClassifyData.selectedClasificare && greentopClassifyData.resultTileLayer" class="greentop-cl-indice-item">
                                        <div class="greentop-cl-indice-item-ck">
                                            <input type="checkbox" style="zoom:1.5" name="ckClassifLayer"
                                                [(ngModel)]="greentopClassifyData.resultTileLayer.visible"
                                                (change)="showHideLayer(greentopClassifyData.resultTileLayer)" />
                                        </div>
                    
                                        <div class="greentop-cl-indice-item-name">Clasificare imagine</div>
                                        <button class="btn-xs btn-light greentop-cl-indice-item-bt"
                                            (click)="onAnimateResultTileLayer()">
                                            <i class="fa fa-play"></i>
                                        </button>
                    
                                    </div>
                                </div>
                                <div>
                                    <div class="greentop-cl-rez-indice-list" *ngFor="let indice of greentopClassifyData?.currentClasificare?.antrenament?.indiciAntrenament">
                                        <!-- indici clasificare -->
                                        <div class="greentop-cl-indice-item">
                                            <div *ngIf="indice.stratAsociat"  class="greentop-cl-rez-indice-item-ck" >
                                                <input type="checkbox" name="ckIndice" style="zoom:1.5" [(ngModel)]="indice.stratAsociat.visible"
                                                    (change)="showHideIndiceLayer(indice)" />
                                            </div>
                                            <div *ngIf="indice.stratAsociat == null" class="greentop-cl-rez-indice-item-ck">&nbsp</div>
                                            
                                            <div class="greentop-cl-rez-indice-item-name">{{indice.codIndice}}</div>
                                            <button  *ngIf="indice.stratAsociat" class="btn-xs btn-light greentop-cl-rez-indice-item-bt" (click)="onAnimateIndiceLayer(indice)">
                                                <i class="fa fa-play"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-body p-2 greentop-cl-zonelistitem"
                                    *ngFor="let zoneitem of greentopClassifyData.currentClasificare.zoneRezultat"
                                    (click)="onClickZoneItem(zoneitem)">
                                    <div>Utilizare: {{zoneitem.denumireUtilizareTeren}} </div>
                                    <div>Data: {{zoneitem.dataProdus}}</div>
                                </div>
                            </div>
                        </div>
                    
                        <div *ngIf="greentopClassifyData.loadingActive === true" class="tranz-spiner-box">
                            <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
                        </div>
                    </fieldset>
                </div>
            </form>
        </div>
    </div>
    </div>
</div>
