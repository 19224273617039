 //NG+
import {  Component, OnInit, Input, Inject } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { IItemNT, IUserSettings, authAs, authType } from "../../definitions";
import { UserSettingsService } from "../../services/user-settings-service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { isObject } from "../../map/map-utils";


@Component({
    selector: 'settings-main',
    providers: [],
    templateUrl: './settings-main.component.html',
    styleUrls: ['./settings-main.component.css']
})
    export class SettingsMainComponent implements OnInit {
        public userSettings: IUserSettings;
        public errorResponse: string = '';
        public disableInput: boolean = false;
        public reloadOnClose: boolean = false;
        public selectedAction: IItemNT;
        public actionList: Array<IItemNT> = [];

        public data: any;
        public mapData: any;

        //
        public constructor(
            @Inject(NgbActiveModal) private activeModal: NgbActiveModal,
            @Inject(NGXLogger) private $log: NGXLogger,
            @Inject(UserSettingsService) private userSettingsSrvs: UserSettingsService
            ) {
            //
        }

        public ngOnInit(): void {
            if (isObject(this.data) && 'center' in this.data) {
                this.mapData = this.data;
            } else {
                this.$log.warn("datele pentru harta nu exista");
            }

            if (this.userSettingsSrvs.isAuthForResource(authAs.data_save_resursa_roles_optiuni, authType.route)) {
                this.actionList.push( { name: "res-rol-opt", text: "Modifică roluri-optiuni pe resursă" })
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_save_resursa_optiuni, authType.route)) {
                this.actionList.push({ name: "res-opt", text: "Modifică opțiuni pe resursă" });
            }

            //this.actionList.push({ name: 'rol-optres-edit', text: 'Modifică resurse-optiuni pe rol' });

            if (this.userSettingsSrvs.isAuthForResource(authAs.data_add_role, authType.route)) {
                this.actionList.push({ name: "rol-add", text: "Crează rol" });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_update_role, authType.route)) {
                this.actionList.push({ name: "rol-edit", text: "Modifică rol" });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_add_resursa_interna, authType.route)) {
                this.actionList.push({ name: "res-add", text: "Crează resursă internă" });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_update_resursa_interna, authType.route)) {
                this.actionList.push({ name: "res-edit", text: "Modifică resursă internă" });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_add_client, authType.route)) {
                this.actionList.push({ name: 'client-add', text: 'Adaugă client' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_edit_client, authType.route)) {
                this.actionList.push({ name: 'client-edit', text: 'Editează client' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_add_mapview_settings, authType.route)) {
                this.actionList.push({ name: 'mapcfg-add', text: 'Adaugă setări view hartă' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_save_mapview_settings, authType.route)) {
                this.actionList.push({ name: 'mapcfg-edit', text: 'Modifică setări view hartă' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_add_style_settings, authType.route)) {
                this.actionList.push({ name: 'style-add', text: 'Adaugă setări stil' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_save_style_settings, authType.route)) {
                this.actionList.push({ name: 'style-edit', text: 'Modifică setări stil' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_add_internal_resource, authType.route)) {
                this.actionList.push({ name: 'opt-res-cli-add', text: 'Adaugă opțiuni resursa la clienți' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.devices_generate_devices_day_routes, authType.route)) {
                this.actionList.push({ name: 'day-task-edit', text: 'Editează generare rute pe zi' });
            }

            
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_add_category, authType.route)) {
                this.actionList.push({ name: 'add-cat', text: 'Adaugă categorii' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_edit_category, authType.route)) {
                this.actionList.push({ name: 'edit-cat', text: 'Editează categorii' });
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_client_edit_category, authType.route)) {
                this.actionList.push({ name: 'client-cat', text: 'Categorii asignate' });
            }
            
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_add_gruptext_predefinit, authType.route)){
                this.actionList.push({name: 'add-grp-txt', text: 'Adaugă grup text predefinit'})
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_update_gruptext_predefinit, authType.route)){
                this.actionList.push({name: 'edit-grp-txt', text: 'Editează grup text predefinit'})
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.data_update_dictionar_gruptext_predefinit, authType.route)){
                this.actionList.push({name: 'edit-dic-grp-txt', text: 'Editează dicționar text predefinit'})
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.cadastru_load_carte_funciara, authType.route)){
                this.actionList.push({name: 'carte-func-add', text: 'Încarcă fișiere carte funciară'})
            }
            if (this.userSettingsSrvs.isAuthForResource(authAs.urbanism_load_doc_urbanism, authType.route)){
                this.actionList.push({name: 'doc-urb-add', text: 'Încarcă fișiere urbanism'})
            }
            if (this.actionList.length == 0){
                this.activeModal.close(false);
            }

            //
            if (this.userSettingsSrvs.getCurrentUser().name.text != '') {
                this.userSettings = this.userSettingsSrvs.getCurrentUser();
                //
            } else {
                this.$log.warn('no user provided');
                this.activeModal.close(false);
            }
        }

        public onClickAction(action){
            if (action) {
                this.errorResponse = "";
                this.selectedAction = action;
            }
        }
        public close(): void {
            this.activeModal.close(this.reloadOnClose);
        }

        //
        public menuToolCosed(value: any){
            if (value?.action === "cancel"){
                this.selectedAction = null;
                this.errorResponse = value?.message || "";
            } else if (value?.action === "changed"){
                this.selectedAction = null;
                this.reloadOnClose = true;
            }
        }
        
    }
