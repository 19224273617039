<section>
    <div class="form-group">
        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text  px-1">R</span>
            </div>
            
            <input type="number" min="0" max="255" class="form-control" [(ngModel)]="colorPickerData.color[0]" (change)="changeColor()" />
            <span class="input-group-text rounded-0 px-1" >G</span>
            <input type="number" min="0" max="255" class="form-control" [(ngModel)]="colorPickerData.color[1]" (change)="changeColor()"/>
            <span class="input-group-text rounded-0 px-1">B</span>
            <input type="number" min="0" max="255" class="form-control" [(ngModel)]="colorPickerData.color[2]" (change)="changeColor()" />
            <span class="input-group-text rounded-0 px-1">A</span>
            <input type="number" min="0" max="1" step="0.1" class="form-control" [(ngModel)]="colorPickerData.color[3]" (change)="changeColor()"/>
            <span class="input-group-append">
                <button type="button" id="idColor" class="btn"
                 (click)="isCollapsed = !isCollapsed"
                  [ngStyle]="buttonStyle"><img src="./img/painter-palette.png" /></button>
            </span>
        </div>
        <div id="idColorCollapse" class="collapse" [(ngbCollapse)]="isCollapsed">
            <compact-picker [(color)]="color" (click)="convertColor()" ></compact-picker>
            <!-- <color-picker [(ngModel)]="color" (click)="convertColor()"></color-picker> -->
        </div>
    </div>
</section>
