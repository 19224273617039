<div class="form-group">
    <!-- report settings list -->
    <div class="form-group" *ngFor="let repitem of settingsList">
        <!-- generic settings -->
        <div class="card card-body p-2" >
            <div class="form-group">
                <button name="remRepSett" class="btn-xs btn-light float-right" (click)="onRemoveReportSettings(repitem)">x</button>
                <label for="" i18n>Nume formula calcul</label>
                <input name="formula" type="text" class="form-control" [(ngModel)]="repitem.reportFormula"/>
            </div>
            <div class="form-group">
                <label for="" i18n>Descriere</label>
                <input name="desc" type="text" class="form-control" [(ngModel)]="repitem.description"/>
            </div>
            <div class="form-group">
                <label for="" i18n>Campuri din raport (JSON)</label>
                <textarea name="reportColumns" type="text" class="form-control" [(ngModel)]="repitem.reportColumns"></textarea>
            </div>
            <div class="form-group">
                <label for="" i18n>Strat sursă date</label>
                <input name="nameResData" type="text" class="form-control" [(ngModel)]="repitem.nameResData"/>
            </div>
            <div class="form-group">
                <label for="" i18n>Campuri din sursă (JSON)</label>
                <textarea name="dataColumns" type="text" class="form-control" [(ngModel)]="repitem.dataColumns"></textarea>
            </div>
            <div class="form-group">
                <label for="" i18n>Valori predefinite (JSON)</label>
                <textarea name="constants" type="text" class="form-control" [(ngModel)]="repitem.constants"></textarea>
            </div>
        </div>
        <!-- todo specific settings-->

    </div>
    <!-- add new setting -->
    <div class="form-group">
        <button name="addRepSett" (click)="onAddReportSettings()" i18n>Adaugă setare raport</button>
    </div>
</div>