//NG+
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Compiler, ComponentFactoryResolver, Inject, Injectable, Injector } from "@angular/core";
import { SettingsMainComponent } from "./settings-components/settings-main.component";

export interface ISettingsDialogsServiceNg {
    showSettingsDialog(data: any): any;
}

@Injectable({
    providedIn: 'root',
  })
export class SettingsDialogsServiceNg implements ISettingsDialogsServiceNg {
    public LoginDialog: any = null;
    //
    public constructor (
    @Inject(NgbModal) private modalService,
    @Inject(ComponentFactoryResolver) private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(Compiler) private compiler: Compiler,
    @Inject(Injector) private injector: Injector) {
        //
    }
    //
    public showSettingsDialog(data:any){

        const modalOpt = { backdrop: false, windowClass: 'modalSettingsMain',fullscreen:true }
        return import('./settings-components/settings-main.module')
            .then((result) => {
                const modalRef = this.modalService.open(SettingsMainComponent, modalOpt);
                modalRef.componentInstance.data = data;
                return modalRef.result;
            })
    }
    //
}