<div class="modal-content">

    <div print-section class="modal-header">
        <h5 class="modal-title" i18n>Grafic 3D locatie pentru: {{graphData.layer.name}} -  Coordonate (long lat):  {{graphData.coordinate[0]}}; {{graphData.coordinate[1]}}</h5>
    </div>
    <!--properties-->
    <div print-section print-landscape class="modal-body">
        <div style="height: 80vh;overflow: auto;">
            <!--toto 3D chart-->
            <div #graphElement id="graph-container"></div>
        </div>
    </div>


    <div class="modal-footer">
        <div class="info-chart-bottom">
             <div *ngIf="loading" class="info-chart-btns">
             <i id="spinner" class="fa fa-circle-o-notch fa-spin" style="font-size:24px"></i>
            </div>
            <div class="info-chart-btns">
                <button type="button" class="btn" (click)="cancel()" i18n>Închide</button>
            </div>
        </div>
    </div>
</div>