import {NgModule, enableProdMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JWT_OPTIONS, JwtModule } from "@auth0/angular-jwt";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { NgbAlertModule, NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule, NgbModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TreeModule } from '@circlon/angular-tree-component';
import { MatSliderModule } from'@angular/material/slider';
import { CQLFilterService } from './app/services/user-filter-service';
import { RouteMessageService } from './app/services/route-message-service';
import { UserSettingsService } from './app/services/user-settings-service';

import { ColorPickerModule } from '@iplab/ngx-color-picker';
import {IntegrateLandsatHotspotComponent} from './app/map/map-components/map-integrate-landsat-hotspot.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IntegrateLandsatDownloadComponent } from './app/map/map-components/map-integrate-landsat-download.component';
import { NgbDateMomentParserFormatter } from './app/shared-components/ngb-moment-adapter-formater.js';
import { NgbCustomDatepickerI18n } from './app/shared-components/ngb-datepicker-i18n.js';
import { OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker';
//import * from '@angular/localize/init';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
registerLocaleData(localeRo);
import { AgGridModule } from 'ag-grid-angular';
import { ChartsModule } from 'ng2-charts';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpNoAuthInterceptorService } from './app/services/http-interceptor-service';
import { GreentopDownloadComponent } from './app/map/map-components/map-greentop-download.component';
import { GreentopTrainingComponent } from './app/map/map-components/map-greentop-training.component';
import { IntegrateLandsatProcesareIndiceConexComponent } from './app/map/map-components/map-integrate-landsat-procesare-indice-conex.component';
import { GreentopClassifyComponent } from './app/map/map-components/map-greentop-classify.component';
import { MapLayersComponent } from './app/map/map-components/map-layers.component';
import { MapPositionComponent } from './app/map/map-components/map-position.component';
import { MapTranzitComponent } from './app/map/map-components/map-tranzit.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MapLegendComponent } from './app/map/map-components/map-legend.component';
import { AddEditLayerComponent } from './app/layer/layer-components/add-edit-layer.component';
import { EditRaportLayerSettingsComponent } from './app/layer/layer-components/edit-report-layer-settings.component';
import { AddEditRouteComponent } from './app/routes/route-components/add-edit-route.component';
import { AddEditStationComponent } from './app/routes/route-components/add-edit-station.component';
import { SharedModule } from './app/shared-components/shared.module';
import { IntegrateLandsatProcesareDateSezoniereComponent } from './app/map/map-components/map-integrate-landsat-procesare-date-sezoniere.component';
import { LoginComponent } from './app/auth/auth-components/login.component';
import { MapController } from './app/map/map-controller';
import { MapBottomtopControlbarComponent } from './app/map/map-components/map-bottomtop-controlbar.component';

let logConfig = {
  //serverLoggingUrl: '/api/logs',
  level: NgxLoggerLevel.DEBUG,
  serverLogLevel: NgxLoggerLevel.ERROR,
  timestampFormat: 'HH:mm:ss.SSS',
  //proxiedSteps : 30,
  enableSourceMaps : true
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoggerModule.forRoot(logConfig),
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgbAlertModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbTypeaheadModule,
    TreeModule,
    MatSliderModule,
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    AgGridModule,
    ChartsModule,
    ColorPickerModule,
    SharedModule,
    
    JwtModule.forRoot({
      jwtOptionsProvider: {
          provide: JWT_OPTIONS,
          deps: [UserSettingsService],
          useFactory: (dependency) => ({
            tokenGetter: () => dependency.getCurrentUser().token || 'no token',
            whitelistedDomains: ["localhost","localhost:80", "localhost:4200"]
        })
      }
  })
  ],
  providers: [
    //rootScopeProvider,
    { provide:'UserSettingsService', useClass: UserSettingsService},
    { provide: 'CQLFilterService', useClass:CQLFilterService},
    { provide: 'RouteMessageService', useClass: RouteMessageService},
    { provide: 'Window',  useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: HttpNoAuthInterceptorService, multi: true },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'ro'},
    // { provide: NgbDateParserFormatter, 
   //   useFactory: () => { return new NgbDateMomentParserFormatter("DD-MM-YYYY") } },
   // { provide: NgbDateParserFormatter, useClass: NgbDateMomentParserFormatter},
   // { provide: NgbDatepickerI18n, useClass: NgbCustomDatepickerI18n }
    
  ],
  declarations:[
    MapController,
    MapLayersComponent,
    MapLegendComponent,
    MapPositionComponent,
    MapTranzitComponent,
    IntegrateLandsatProcesareIndiceConexComponent,
    IntegrateLandsatHotspotComponent,
    IntegrateLandsatDownloadComponent,
    IntegrateLandsatProcesareDateSezoniereComponent,
    GreentopDownloadComponent,
    GreentopTrainingComponent,
    GreentopClassifyComponent,
    EditRaportLayerSettingsComponent,
    AddEditLayerComponent,
    AddEditRouteComponent,
    AddEditStationComponent,
    LoginComponent,
    MapBottomtopControlbarComponent
    
  ],
  entryComponents:[
    MapController,
    MapLayersComponent,
    MapLegendComponent,
    MapPositionComponent,
    MapTranzitComponent,
    IntegrateLandsatProcesareIndiceConexComponent,
    IntegrateLandsatHotspotComponent,
    IntegrateLandsatDownloadComponent,
    IntegrateLandsatProcesareDateSezoniereComponent,
    GreentopDownloadComponent,
    GreentopTrainingComponent,
    GreentopClassifyComponent,
    EditRaportLayerSettingsComponent,
    AddEditLayerComponent,
    AddEditRouteComponent,
    AddEditStationComponent,
    LoginComponent,
    MapBottomtopControlbarComponent
  ],
  bootstrap:[MapController]
})

export class AppModule {
}
//
enableProdMode();
// Bootstrap
platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
  console.log("Bootstrapping Angular");
});