<div class="modal-content">

    <div class="modal-header">
        <h5 class="modal-title" i18n>Detalii locații suprapuse </h5>
    </div>
    <!--properties-->
    <div class="modal-body">
        <div *ngIf="selectedFeature">
            <label i18n>Informatii:  {{selectedFeatureId + 1}} din {{featuresCount}}</label>
            <div class="form-group" *ngFor="let property of featureProps">
                <div  *ngIf="property.edit != 'hide'">
                    <label for="name">{{property.name}}</label>
                    <div *ngIf="property.control === controlType.text">
                        <p type="text" class="form-control" id="{{property.name}}">{{property.value}}</p>
                    </div>
                    <!-- text area-->
                    <div *ngIf="property.control === controlType.textArea">
                        <textarea class="form-control" rows="{{property.rows}}" readonly>{{property.value}} </textarea>
                    </div>
                    <!-- rate input -->
                    <div *ngIf="property.control === controlType.rate">
                        <ngb-rating id="{{property.name}}" name="property.name" [max]="property.max" [(rate)]="property.value" [readonly]="true">
                            <ng-template let-fill="fill">
                                <i class="bi-star-fill">★
                                    <i  *ngIf="fill>0" class="bi-star-fill bi-star-filled" [style.width.%]="fill">★</i>
                                </i>
                            </ng-template>
                        </ngb-rating>
                        <!-- <ng-rate-it id="{{property.name}}" name="property.name" ng-model="property.value" min="property.min" max="property.max" step="property.step" read-only="true"></ng-rate-it><span> {{property.value}}</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal-footer">
        <button type="button" class="btn btn-light float-left" (click)="prev()" [disabled]="!enablePrev()" i18n>Precedent</button>
        <button type="button" class="btn btn-light float-left" (click)="next()" [disabled]="!enableNext()" i18n>Următor</button>

        <button type="button" class="btn float-right" (click)="cancel()" i18n>Închide</button>
    </div>

</div>