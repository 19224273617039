<div class="integrate-landsat-ic-menu integrate-landsat-ic-height"
     *ngIf="mapCtrl.integrateLandsatProcIndConexButtonStateOn">
    <div id="integrate-landsat-ic-content">
        <div class="h6" (click)="integrateLandsatProcIndConexData.ctrlSearchIsCollapsed = !integrateLandsatProcIndConexData.ctrlSearchIsCollapsed"> 
            Procesare LST
            <span class="float-right" (click)="onClickClose(); $event.stopPropagation()">
                <span class="material-icons integrate-landsat-ic-menu-icon-close-colapse">close</span>
            </span>
            <span class="float-right">
                <span class="material-icons integrate-landsat-ic-menu-icon-close-colapse" *ngIf="integrateLandsatProcIndConexData.ctrlSearchIsCollapsed">arrow_drop_down</span>
                <span class="material-icons integrate-landsat-ic-menu-icon-close-colapse" *ngIf="!integrateLandsatProcIndConexData.ctrlSearchIsCollapsed">arrow_drop_up</span>
            </span>
            <span class="glyphicon glyphicon-info-sign float-right " 
            ngbPopover="{{infoSearch}}"
            popoverTitle="Info"
            popoverClass="text-dark"
            triggers="mouseenter:mouseleave"
            placement="bottom" 
            onclick="event.stopPropagation()"></span>
        </div>
        <div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="integrateLandsatProcIndConexData.activeTab" class="nav-tabs nav-fill w-100">
                <li [ngbNavItem]="1"
                (click)="changeNewExistsTab('1')">
                    <a ngbNavLink>Existente</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="0"
                (click)="changeNewExistsTab('0')">
                    <a ngbNavLink>Nouă</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
            </ul>
        </div>
        <!--existing downloads-->
        <div class="integrate-landsat-ic-existing-dw">
            <!--autocompleat search-->
            <div class="integrate-landsat-ic-existing-dw-combo">
                <div class="form-group integrate-landsat-ic-existing-combo-mb"
                    *ngIf="integrateLandsatProcIndConexData.activeExistsTab1">
                    <label for="uiDesPlanificate">Procesări planificate</label>
                    <ng-select name="uiDesPlanificate" required 
                        [items]="integrateLandsatProcIndConexData.availableProcesari"
                        [(ngModel)]="integrateLandsatProcIndConexData.selectedProcesare"
                        (change)="onChangeProcesare()" 
                        bindLabel="denumire"
                        placeholder="Selectează procesare planificată">
                        <ng-template ng-label-tmp let-item="item">
                            <div class="integrate-ng-select-wrap">
                                {{item.denumire}}
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="integrate-ng-select-wrap">
                                [{{item.id }}] {{item.denumire}}
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            
            <div *ngIf="integrateLandsatProcIndConexData.activeExistsTab1 && integrateLandsatProcIndConexData.loadingSearchProductsActive === true "class="tranz-spiner-box">
                <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
            </div>
        </div>

        <!--form input-->
        <div [(ngbCollapse)]="integrateLandsatProcIndConexData.ctrlSearchIsCollapsed">
            <form #integrateProcForm="ngForm" novalidate>
                    
            <!--new proc info-->
            <fieldset *ngIf="integrateLandsatProcIndConexData.activeNewTab0"
                        [disabled]="integrateLandsatProcIndConexData.loadingSearchProductsActive === true"
                        >
                        <div class="form-group">
                            <label>Denumire</label>
                            <input class="form-control" type="text" name="uiIcDenumire"
                                [(ngModel)]="integrateLandsatProcIndConexData.currentProcesare.denumire" required/>
                        </div>
                        <div class="form-group" >
                            <label>Descriere</label>
                            <textarea class="form-control textarea-noresize" rows="2" name="uiIcDescriere"
                                [(ngModel)]="integrateLandsatProcIndConexData.currentProcesare.descriere"
                                ></textarea>
                        </div>
                        <div class="form-group">
                            <label>Algoritm</label>
                            <ng-select name="uiIcAlgoritm" required 
                                [items]="integrateLandsatProcIndConexData.availableAlgoritmiIC"
                                [(ngModel)]="integrateLandsatProcIndConexData.selectedAlgoritmIC"
                                (change)="onChangeAlgoritm()" 
                                bindLabel="nume"
                                placeholder="Selectează algoritm procesare">
                                <ng-template ng-label-tmp let-item="item">
                                    <div class="integrate-ng-select-wrap">
                                        {{item.nume}}
                                    </div>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <div class="integrate-ng-select-wrap">
                                        [{{item.id }}] {{item.nume}}
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                        <div class="form-group">
                            <label for="uiMomentDate">Moment date satelitare</label>
                            <div class="integrate-landsat-ic-indice-item">
                                <div *ngIf="integrateLandsatProcIndConexData.indiceBaza && integrateLandsatProcIndConexData.indiceBaza.stratAsociat"  class="integrate-landsat-ic-indice-item-ck" >
                                    <input type="checkbox" name="ckIndice" style="zoom:1.5" [(ngModel)]="integrateLandsatProcIndConexData.indiceBaza.stratAsociat.visible"
                                    (change)="showHideIndiceLayer(integrateLandsatProcIndConexData.indiceBaza)" />
                                </div>
                                <span *ngIf="integrateLandsatProcIndConexData.indiceBaza == null || integrateLandsatProcIndConexData.indiceBaza.stratAsociat == null">&nbsp;&nbsp;&nbsp;</span>
                                <div class="integrate-landsat-ic-indice-item-name">{{(integrateLandsatProcIndConexData.indiceBaza?integrateLandsatProcIndConexData.indiceBaza.codIndice : "Indice baza")}}</div>
                            </div>
                            <div class="integrate-landsat-ic-existing-mds-inputs">
                                <div class="integrate-landsat-ic-existing-mds-combo">
                                    <ng-select name="uiProcBaza" required 
                                    [items]="integrateLandsatProcIndConexData.availableMomenteDSBaza"
                                    [(ngModel)]="integrateLandsatProcIndConexData.selectedMomentDSBaza"
                                    (change)="onChangeMomentDSBaza()" 
                                    placeholder="Selectează sau caută în listă">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div class="integrate-ng-select-wrap"> {{item}}</div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                        <div class="integrate-ng-select-wrap">{{item}}</div>
                                    </ng-template>
                                </ng-select>
                        
                                </div>
                                <button class="btn-xs btn-light" (click)="onClickPrevMomentDSBaza()">
                                    <i class="fa fa-step-backward"></i>
                                </button>
                                <button class="btn-xs btn-light" (click)="onClickNextMomentDSBaza()">
                                    <i class="fa fa-step-forward "></i>
                                </button>
                            </div>

                            <div class="integrate-landsat-ic-indice-item">
                                <div *ngIf="integrateLandsatProcIndConexData.indiceReferinta && integrateLandsatProcIndConexData.indiceReferinta.stratAsociat"  class="integrate-landsat-ic-indice-item-ck" >
                                    <input type="checkbox" name="ckIndice" style="zoom:1.5" [(ngModel)]="integrateLandsatProcIndConexData.indiceReferinta.stratAsociat.visible"
                                    (change)="showHideIndiceLayer(integrateLandsatProcIndConexData.indiceReferinta)" />
                                </div>
                                <span *ngIf="integrateLandsatProcIndConexData.indiceReferinta == null || integrateLandsatProcIndConexData.indiceReferinta.stratAsociat == null">&nbsp;&nbsp;&nbsp;</span>
                                <div class="integrate-landsat-ic-indice-item-name">{{(integrateLandsatProcIndConexData.indiceReferinta?integrateLandsatProcIndConexData.indiceReferinta.codIndice : "Indice referinta")}}</div>
                            </div>
                            <div class="integrate-landsat-ic-existing-mds-inputs">
                                <div class="integrate-landsat-ic-existing-mds-combo">
                                    <ng-select name="uiProcReferinta" required 
                                    [items]="integrateLandsatProcIndConexData.availableMomenteDSReferinta"
                                    [(ngModel)]="integrateLandsatProcIndConexData.selectedMomentDSReferinta"
                                    (change)="onChangeMomentDSReferinta()" 
                                    placeholder="Selectează sau caută în listă">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div class="integrate-ng-select-wrap"> {{item}}</div>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                        <div class="integrate-ng-select-wrap">{{item}}</div>
                                    </ng-template>
                                </ng-select>
                        
                                </div>
                                <button class="btn-xs btn-light" (click)="onClickPrevMomentDSReferinta()">
                                    <i class="fa fa-step-backward"></i>
                                </button>
                                <button class="btn-xs btn-light" (click)="onClickNextMomentDSReferinta()">
                                    <i class="fa fa-step-forward "></i>
                                </button>
                            </div>
                        </div>
                        <div>
                            <!--unitati administrative-->
                            <h6 (click)="onCtrlUnitatiAdministrativeIsColapsed()">
                                <span *ngIf="integrateLandsatProcIndConexData.activeNewTab0 === true">
                                    <span *ngIf="integrateLandsatProcIndConexData.ctrlUnitatiAdministrativeIsColapsed === true" class="btn btn-light">
                                        Unități administrative: {{integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeDescarcare.length || 0}}</span>
                                    <span *ngIf="integrateLandsatProcIndConexData.ctrlUnitatiAdministrativeIsColapsed === false">Unități administrative</span>
                                </span>
                                <span class="float-right">
                                    <span class="material-icons integrate-landsat-ic-menu-icon-close-colapse" *ngIf="integrateLandsatProcIndConexData.ctrlUnitatiAdministrativeIsColapsed">arrow_drop_down</span>
                                    <span class="material-icons integrate-landsat-ic-menu-icon-close-colapse" *ngIf="!integrateLandsatProcIndConexData.ctrlUnitatiAdministrativeIsColapsed">arrow_drop_up</span>
                                </span>
                            </h6>
                            <div [(ngbCollapse)]="integrateLandsatProcIndConexData.ctrlUnitatiAdministrativeIsColapsed">
                                <div class="integrate-landsat-ic-unitati-zone">
                                    <!-- lista unitati administrative -->
                                    <div class="integrate-landsat-ic-uat-list" *ngFor="let uat of integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare">
                                         <div class="integrate-landsat-ic-uat-item">
                                            <div class="integrate-landsat-ic-uat-item-name">{{uat.denumire}}</div>
                                            <div class="btn-xs btn-light"><span class="integrate-landsat-ic-uat-item-bt" (click)="onRemoveUnitateAdministrativa(uat)">x</span></div>
                                        </div>
                                    </div>
                                    <!--adauga unitate-->
                                    <div class="integrate-landsat-ic-select-dw-combo">
                                        <div class="form-group integrate-landsat-ic-existing-combo-mb">
                                            <ng-select name="uinewIndice" required [(ngModel)]="integrateLandsatProcIndConexData.selectedUnitateAdministrativa"
                                                (change)="onChangeSelectedUnitateAdministrativa()" placeholder="Selectează descarcare planificată">
                                                <ng-option *ngFor="let itemSd of integrateLandsatProcIndConexData.availableUnitatiAdministrative "
                                                    [value]="itemSd">{{itemSd.denumire}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="integrate-ic-btn-dark">
                                        <button class="btn btn-defalut" (click)="onAddUnitateAdministrativa()">Adaugă selecție</button>
                                        <button class="btn btn-defalut" (click)="onAddAllUnitateAdministrativa()">Adaugă toate</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    <div *ngIf="integrateLandsatProcIndConexData.loadingActive === true "class="tranz-spiner-box">
                        <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
                    </div>
                    <div>
                        <h6 *ngIf="integrateLandsatProcIndConexData.errorMessage && integrateLandsatProcIndConexData.errorMessage.length > 0 " class="text-danger">
                            {{integrateLandsatProcIndConexData.errorMessage}}
                        </h6>
                    </div>
                    <div class="input-group">
                        <button class="btn btn-light integrate-ic-action-wrp-buttons" 
                        [disabled]="canContinueProcesare() === false"
                        (click)="onStartProcesare(integrateProcForm)" title="Pornește"><i class="fas fa-play-circle fa-2x"></i></button>
                        <button class="btn btn-light integrate-ic-action-wrp-buttons" 
                        [disabled]="canStopProcesare() === false"
                        (click)="onStopProcesare()" title="Oprește"><i class="fas fa-stop-circle fa-2x"></i></button>
                        
                        
                    </div>
            </fieldset>

            <!--existing proc info-->
            <fieldset *ngIf="integrateLandsatProcIndConexData.activeExistsTab1 && integrateLandsatProcIndConexData.selectedProcesare"
                        [disabled]="integrateLandsatProcIndConexData.loadingActive === true">
                        
                        <div class="form-group" >
                            <label>Descriere</label>
                            <textarea class="form-control textarea-noresize" rows="2" name="uiIcDescriere"
                                [(ngModel)]="integrateLandsatProcIndConexData.currentProcesare.descriere"
                                disabled></textarea>
                        </div>
                        <div class="form-group">
                            <label>Status</label>
                            <input class="form-control" type="text" name="uiIcStatus"
                                [(ngModel)]="integrateLandsatProcIndConexData.currentProcesare.status" disabled/>
                        </div>
                        <div class="form-group">
                            <label>Algoritm</label>
                            <input class="form-control" type="text" name="uiIcAlgoritm"
                                [(ngModel)]="integrateLandsatProcIndConexData.currentProcesare.denumireAlogritmIC" disabled/>
                        </div>
                        <div class="form-group">
                            <label>Moment date satelitare</label>
                            <input class="form-control" type="text" name="uiIcMomentDateSat"
                                [(ngModel)]="integrateLandsatProcIndConexData.currentProcesare.dataIndiceBaza" disabled/>
                        </div>
                        <div class="form-group">
                            <label for="uiDateSat">Date satelitare</label>
                            <div class="integrate-landsat-ic-indice-item">
                                <div *ngIf="integrateLandsatProcIndConexData.indiceRezultat && integrateLandsatProcIndConexData.indiceRezultat.stratAsociat"  class="integrate-landsat-ic-indice-item-ck" >
                                    <input type="checkbox" name="ckIndice" style="zoom:1.5" [(ngModel)]="integrateLandsatProcIndConexData.indiceRezultat.stratAsociat.visible"
                                    (change)="showHideIndiceLayer(integrateLandsatProcIndConexData.indiceRezultat)" />
                                </div>
                                <span *ngIf="integrateLandsatProcIndConexData.indiceRezultat == null || integrateLandsatProcIndConexData.indiceRezultat.stratAsociat == null">&nbsp;&nbsp;&nbsp;</span>
                                <div class="integrate-landsat-ic-indice-item-name">{{(integrateLandsatProcIndConexData.indiceRezultat?integrateLandsatProcIndConexData.indiceRezultat.codIndice : "Indice rezultat")}}</div>
                            </div>
                            <div class="integrate-landsat-ic-indice-item">
                                <div *ngIf="integrateLandsatProcIndConexData.indiceBaza && integrateLandsatProcIndConexData.indiceBaza.stratAsociat"  class="integrate-landsat-ic-indice-item-ck" >
                                    <input type="checkbox" name="ckIndice" style="zoom:1.5" [(ngModel)]="integrateLandsatProcIndConexData.indiceBaza.stratAsociat.visible"
                                    (change)="showHideIndiceLayer(integrateLandsatProcIndConexData.indiceBaza)" />
                                </div>
                                <span *ngIf="integrateLandsatProcIndConexData.indiceBaza == null || integrateLandsatProcIndConexData.indiceBaza.stratAsociat == null">&nbsp;&nbsp;&nbsp;</span>
                                <div class="integrate-landsat-ic-indice-item-name">{{(integrateLandsatProcIndConexData.indiceBaza?integrateLandsatProcIndConexData.indiceBaza.codIndice : "Indice baza")}}</div>
                            </div>
                            <div class="integrate-landsat-ic-indice-item">
                                <div *ngIf="integrateLandsatProcIndConexData.indiceConex && integrateLandsatProcIndConexData.indiceConex.stratAsociat"  class="integrate-landsat-ic-indice-item-ck" >
                                    <input type="checkbox" name="ckIndice" style="zoom:1.5" [(ngModel)]="integrateLandsatProcIndConexData.indiceConex.stratAsociat.visible"
                                    (change)="showHideIndiceLayer(integrateLandsatProcIndConexData.indiceConex)" />
                                </div>
                                <span *ngIf="integrateLandsatProcIndConexData.indiceConex == null || integrateLandsatProcIndConexData.indiceConex.stratAsociat == null">&nbsp;&nbsp;&nbsp;</span>
                                <div class="integrate-landsat-ic-indice-item-name">{{(integrateLandsatProcIndConexData.indiceConex?integrateLandsatProcIndConexData.indiceConex.codIndice : "Indice conex")}}</div>
                            </div>
                        </div>
                        <div class="integrate-landsat-dw-unitati-zone">
                            <!-- lista unitati administrative -->
                            <label>Unități administrative</label>
                            <div class="integrate-landsat-dw-uat-list" *ngFor="let uat of integrateLandsatProcIndConexData.currentProcesare.unitatiAdministrativeProcesare">
                                 <div class="integrate-landsat-dw-uat-item">
                                    <div class="integrate-landsat-dw-uat-item-name">Denumire: {{uat.denumire}}</div>
                                    
                                </div>
                                <div>Status: {{uat.status}}</div>
                            </div>
                        </div>
                        <div *ngIf="integrateLandsatProcIndConexData.loadingActive === true "class="tranz-spiner-box">
                            <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
                        </div>
                        <div class="input-group">
                            <button class="btn btn-light integrate-ic-action-wrp-buttons"
                            (click)="onDownloadRaportIndiceInfo()" title="Raport"><i class="fas fa-scroll fa-2x"></i></button>
                            <button class="btn btn-light integrate-ic-action-wrp-buttons ml-auto" 
                            [disabled]="canRefreshProcesare() === false"
                            (click)="onRefreshProcesare()" title="Reîncarcă rezultat"><i class="fas fa-sync fa-2x"></i></button>
                        </div>
            </fieldset>
            </form>
        </div>



    </div>  
</div>