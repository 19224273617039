<div class="">

    <div class="modal-header">
        <h5 class="modal-title" i18n>Imagini locatie </h5>
    </div>
    <!--properties-->
    <div class="modal-body">
        <div style="height: 80vh;">
            <ngb-carousel  *ngIf="imageData" interval="10000" showNavigationArrows="true">
                <ng-template ngbSlide *ngFor="let slide of imageData" index="slide.id">
                    <img [src]="sanitize(slide.imageurl)" 
                    onerror="this.onerror=null;this.src='./img/no-image.jpg';" 
                    class="info-images-img">
                    <div class="carousel-caption">
                        <h5 i18n>Nume {{slide.imagename}}</h5>
                        <!-- <p>{{slide.text}}</p> -->
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn float-right" (click)="cancel()" i18n>Închide</button>
    </div>
</div>