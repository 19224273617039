<div *ngIf="mapCtrl.layersAndMenuIsVisible()">
    <div id="custom-search-input" *ngIf="mapCtrl.searchIsVisible()">
        <div class="search-input-group" *ngIf="mapCtrl.mapDialogs">
            <div>
                <button type="button" class="search-advance btn"
                [ngClass]="mapCtrl.searchActive ? 'btn-info' : 'btn-light'"
                (click)="mapCtrl.mapDialogs.showEditSearchDialog()" ><i class="fas fa-filter"></i></button>
            </div>
            <div class="search-input-field">
                <input type="text" class="search-query form-control" [disabled]="mapCtrl.searchActive"
                placeholder="{{mapCtrl.searchActive ? '<-Filtrare avansată activă' : 'Filtrare'}} "
                [(ngModel)]="mapCtrl.searchText" (ngModelChange)="mapCtrl.search()" />
            </div>
           
        </div>
    </div>

    <div print-hide class="nav-side-menu">
        <div class="menu-list">
            <ul id="menu-content" [(ngbCollapse)]="mapCtrl.isMenuCollapsed" class="menu-content">
                <div id="settings-bar" *ngIf="mapCtrl.menuIsVisible()">
                    <div class="btn-group d-flex" *ngIf="mapCtrl.mapDialogs">
                        <button name="btnLogin" type="button" class="btn btn-menu-custom w-100"
                            (click)="mapCtrl.mapDialogs.showLoginDialog(mapCtrl)">
                            <i class="glyphicon glyphicon-log-in"></i> Conect
                        </button>
                        <button name="btnRegister" type="button" class="btn btn-menu-custom w-100"
                            [disabled]="mapCtrl.enableUserButton === false"
                            (click)="mapCtrl.mapDialogs.showRegisterDialog(mapCtrl)">
                            <i class="glyphicon glyphicon-user"></i> Utilizator
                         </button>
                        <button name="btnLayerRoles" type="button" class="btn btn-menu-custom w-100"
                            [disabled]="!mapCtrl.showtools() || mapCtrl.enableSettingButton === false"
                            (click)="mapCtrl.mapDialogs.showEditSettingsDialog(mapCtrl)">
                            <i class="glyphicon glyphicon-list"></i> Setări
                        </button>
                    </div>
                </div>
                <!-- categories tree -->
                <div class="nav-side-tree">
                    <div class="row" *ngIf="mapCtrl.treeCatMenu && mapCtrl.treeCatMenu.length > 0">
                        <div class="col-sm-12">
                            <ol class="layer-tree-adjust"><!--[options]="layerTreeOptions"-->
                                <tree-root [nodes]="mapCtrl.treeCatMenu" >
                                    <ng-template #treeNodeFullTemplate let-node let-index="index" let-templates="templates">
                                        <div class="tree-node tree-node-line-fix"  *ngIf="mapCtrl.mapMenuLayers.hideCategoryIfEmpty(node.data) === false">
                                            <div (click)="node.data.layersColapsed = !node.data.layersColapsed; node.toggleExpanded()">
                                                <div class="cat-arrow" >
                                                    <div class="btn  btn-xs"  *ngIf="(node.data.children && node.data.children.length > 0) ||(node.data.layers && node.data.layers.length > 0)" data-nodrag>
                                                        <span class="glyphicon" [ngClass]="{'glyphicon-chevron-right': node.data.layersColapsed,'glyphicon-chevron-down': !node.data.layersColapsed}"></span>
                                                    </div>
                                                    <div class="btn  btn-xs"  data-nodrag *ngIf="(node.data.children == null || node.data.children.length == 0) && (node.data.layers == null || node.data.layers.length == 0)">
                                                        <span class="glyphicon glyphicon-chevron-right" style="opacity:0.3"></span>
                                                    </div>
                                                </div>
                                    
                                                <div>
                                                    <div class="tree-node tree-node-content"  title="{{node.data.name}}">
                                                        <div class="btn btn-xs cat-add-layer"  title="Adaugă layer"
                                                        *ngIf="mapCtrl.mapMenuLayers.showAddLayer()"
                                                        (click)="mapCtrl.mapDialogs.showAddLayerDialog(mapCtrl, node.data.code); $event.stopPropagation();" data-nodrag>
                                                            <span class="glyphicon glyphicon-plus"></span>
                                                        </div>
                                                        <div class="cat-name" >{{node.data.name}}</div>
                                                    </div>
                                    
                                                </div>
                                            </div>
                                            <!--subnodes tamplate-->
                                            <tree-node-children [node]="node" [templates]="templates">
                                            </tree-node-children>
                                            <!--layers-->
                                            <div class="sub-menu " id="layers-{{node.data.code}}" [ngClass]="{hidden: node.data.layersColapsed}">
                                                <div class="sub-menu-item" *ngFor="let layer of node.data.layers">
                                                        <!--layer visibility an menu-->
                                                        <p title="{{layer.name}}">{{layer.name}}</p>
                                                        <div class="row sub-menu-inputs">
                                                            <div id="inputs-col-1" class="col-1" title="Activeaza">
                                                                <input type="checkbox" style="zoom:1.5" [(ngModel)]="layer.visible" [disabled]="layer.disableFromZoom && layer.disableFromZoom > 0" (change)="mapCtrl.mapMenuLayers.onShowHideLayer(mapCtrl, layer)" />
                                                            </div>
                                                            <div id="inputs-col-2" class="col-10" title="Transparenta">
                                                                <mat-slider class="w-100 layerslider"
                                                                    color="primary"
                                                                    [disabled]="!layer.visible"
                                                                    [max]="10"
                                                                    [min]="0"
                                                                    [step]="1"
                                                                    [thumbLabel]="false"
                                                                    [(ngModel)]="layer.opacity"
                                                                    (input)="mapCtrl.mapMenuLayers.setOpacityToLayerAtValue(mapCtrl, layer, $event)">
                                                                </mat-slider>
                                                            </div>
                                                            <div id="inputs-col-3" class="col-1">
                                                                <div class="dropdown" *ngIf="layer.menuLayerItems && layer.menuLayerItems.length > 0" title="Actiuni">
                                                                    <div class="dropdown-toggle-menu" data-toggle="dropdown">
                                                                        &#x25BC;
                                                                    </div>
                                                                    <ul class="dropdown-menu dropdown-menu-right" *ngIf="mapCtrl.layerMenuIsVisible(layer)">
                                                                        <li *ngFor="let menuItem of layer.menuLayerItems" (click)="mapCtrl.mapMenuLayers.layerMenuAction(mapCtrl, layer, menuItem, null)">{{menuItem.name}}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!--sld script-->
                                                        <div class="flex-column sub-menu-sld-script"
                                                            *ngIf="layer.sldStyle && layer.sldStyle.xmlSrv && layer.sldStyle.xmlSrv.length > 0 && layer.sldStyle.visible == true">
                                                            <div class="">
                                                                <textarea class="form-control textarea-noresize" rows="3" [(ngModel)]="layer.sldStyle.script"></textarea>
                                                            </div>
                                                            <div class="">
                                                                <button class="btn btn-light" (click)="mapCtrl.mapMenuLayers.setRasterScript(layer)">Aplică</button>
                                                                <button class="btn btn-light" (click)="mapCtrl.mapMenuLayers.closeRasterScript(layer)">Închide</button>
                                                                <div class="glyphicon glyphicon-info-sign" 
                                                                    ngbPopover="{{mapCtrl.infoSearchRasterStyle}}"
                                                                    popoverTitle="Info"
                                                                    popoverClass="text-dark"
                                                                    triggers="mouseenter:mouseleave"
                                                                    placement="bottom"
                                                                    onclick="event.stopPropagation()">
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                        <!--animate raster group-->
                                                        <div class="form-group animate-grup-raster-combo-mb"
                                                        *ngIf="layer.animateGroup && layer.animateGroup.menuControlVisible === true">
                                                            <div class="animate-grup-raster-mds-label">
                                                                <label for="uiAnimGrupRaster" class="animate-grup-raster-mds-label-text">Moment grup date satelitare</label>
                                                                <div class="animate-grup-raster-mds-label-cancel" (click)="mapCtrl.mapMenuLayers.cancelControlAnimareGrupDateSatelitare(layer)"><span class="glyphicon glyphicon-remove"></span></div>
                                                            </div>  
                                                            
                                                            <div class="animate-grup-raster-mds-inputs">
                                                                <div class="animate-grup-raster-mds-combo">
                                                                    <ng-select name="uiAnimGrupRaster" required [items]="layer.animateGroup.availableMomenteDateSatelitare"
                                                                        [(ngModel)]="layer.animateGroup.selectedMomentDateSatelitare"
                                                                        (change)="mapCtrl.mapMenuLayers.onChangeMomentDateSatelitare(layer)"
                                                                        (focus)="mapCtrl.mapMenuLayers.onFocusControlAnimareGrupTimestaps(layer)"
                                                                        placeholder="Selectează sau caută în listă...">
                                                                        <ng-template ng-label-tmp let-item="item">
                                                                            <div class="greentop-ng-select-wrap">
                                                                                {{item}}
                                                                            </div>
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                            <div class="greentop-ng-select-wrap">
                                                                                {{item}}
                                                                            </div>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                </div>
                                                                <button class="btn-xs btn-light animate-grup-raster-mds-btn"
                                                                    (click)="mapCtrl.mapMenuLayers.onClickPrevMomentDateSatelitare(layer)">
                                                                    <i class="fa fa-step-backward"></i>
                                                                </button>
                                                                <button class="btn-xs btn-light animate-grup-raster-mds-btn"
                                                                    (click)="mapCtrl.mapMenuLayers.onClickNextMomentDateSatelitare(layer)">
                                                                    <i class="fa fa-step-forward "></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </ng-template>
                                </tree-root>
                            </ol>
                        </div>
                    </div>
                </div>
            </ul>
        </div>
    </div>
</div>


