<div class="modal-content" id="detailsFeaturesInfo">
    <div class="modal-header">
        <h5 class="modal-title" i18n>Detalii selecție</h5>
    </div>
    <div class="modal-body p-1">
        <!--T-->
        <div class="listOfTables mt-2" *ngFor="let featureTab of featuresInTables">
            <div class="row">
                <div class="col-sm-9">
                    <h6>{{featureTab.name}}</h6>
                </div>
                <div class="col-sm-2">
                    <button class="float-right" [hidden]="!isAdmin" (click)="saveDefaultOptions(featureTab)" i18n>Salvare optiuni
                        predefinite</button>
                </div>
                <div class="col-sm-1">
                    <button class="float-right"  (click)="featureTab.gridOptions.columnSelection = !featureTab.gridOptions.columnSelection"><span class="fa fa-bars" i18n-title title="Coloane"></span></button>
                </div>
            </div>
            <div class="d-inline-flex w-100">
                <div class="flex-fill">
                    <ag-grid-angular #mygrid class="ag-theme-balham gridDetalisClass" [rowData]="featureTab.data"
                        [columnDefs]="featureTab.gridOptions.columnDefs" [defaultColDef]="defaultColDef" [sideBar]="sideBarDef">
                    </ag-grid-angular>
                </div>
                <div *ngIf="featureTab.gridOptions && featureTab.gridOptions.columnSelection"
                class="border-left ml-1 p-1 gridColumnsClass" [ngClass]="{'gridHeight1': featuresInTables.length == 1, 'gridHeight2': featuresInTables.length > 1}">
                    <div *ngFor="let defCol of featureTab.gridOptions.columnDefs">
            
                        <div [title]="defCol.field">
                            <input type="checkbox" 
                            class="mr-1"
                            [ngModel]="!defCol.hide" 
                            (click)="gridColumnClick($event, mygrid, defCol)"
                            [ngModelOptions]="{standalone: true}" 
                                />{{defCol.headerName}}</div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="export()" i18n>Exporta csv</button>
        <button type="button" class="btn" (click)="cancel()" i18n>Inchide</button>
    </div>
</div>