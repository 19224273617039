<div class="modal-content">
    <form #layerform="ngForm"   novalidate>
        <fieldset ng-disabled="disableInput">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="isAddElseEdit" i18n>Adăugare strat</h5>
                <h5 class="modal-title" *ngIf="!isAddElseEdit" i18n>Modificare strat</h5>
            </div>
            <div class="modal-body">
                <div *ngIf="!isAddElseEdit" class="form-group">
                    <label i18n>Categorie</label>
                    <ng-select id="categorie" name="categorie" required
                     #category="ngModel"
                     [(ngModel)]="categoryModel" 
                     i18n-placeholder placeholder="Selectează sau caută în listă">
                        <ng-option *ngFor="let item of categories" [value]="item">
                            {{item.descriere}} ({{item.nume}})
                        </ng-option>
                    </ng-select>
                </div>
                <div class="form-group">
                    <label for="featureType" i18n>Tip</label>
                    <ng-select id="tip" name="tip" required
                        i18n-title title="Selecteaza tip strat"
                        #tipstrat="ngModel"
                        [(ngModel)]="selectedType" 
                        (change)="onTipLayerChanged()"
                        i18n-placeholder placeholder="Selectează sau caută în listă">
                       <ng-option *ngFor="let item of availableTypes" [value]="item">
                        {{item.text}}
                       </ng-option>
                    </ng-select>
                    <span *ngIf="layerform.submitted || tipstrat.touched">
                        <span *ngIf="tipstrat.errors?.required" class="text-danger" i18n>Tip strat este obligatoriu</span>
                    </span>
                </div>
                <div *ngIf="layer && selectedType">
                    <div class="form-group">
                        <label for="name" i18n>Denumire</label>
                        <input type="text" class="form-control" id="name"  name="name" #name="ngModel" [(ngModel)]="layer.name" required>
                        <span *ngIf="layerform.submitted || name.touched">
                            <span *ngIf="name.errors?.required" class="text-danger" i18n>Denumirea este obligatorie</span>
                        </span>
                    </div>
                    <div class="form-group">
                        <label for="description" i18n>Descriere</label>
                        <input type="text" class="form-control" id="description" name="description" [(ngModel)]="layer.description">
                    </div>
                    <div *ngIf="!isAddElseEdit" class="form-group">
                        <label for="privat" i18n>Tip strat pe client</label>
                        <div class="form-control">{{layer.idClient == null?"partajat":"privat"}}</div>
                    </div>
                    <div class="form-group">
                        <label for="url" i18n>Tip sursă</label>
                        <ng-select id="sourceType" name="sourceType" #sourcetype="ngModel" required
                            i18n-title title="Selecteaza tip sursa"
                            [hidden] = "layer.featureType == 'polyReportCP'"
                            [(ngModel)]="selectedSourceType" 
                            (change)="onTipSourceChanged()"
                            i18n-placeholder placeholder="Selectează sau caută în listă">
                            <ng-option *ngFor="let item of availableSourceType" [value]="item">
                                {{item.text}}
                            </ng-option>
                        </ng-select>
                        <span *ngIf="layerform.submitted || sourcetype.touched">
                            <span *ngIf="sourcetype.errors?.required" class="text-danger" i18n>Tip sursă este obligatoriu</span>
                        </span>
                        <div *ngIf="selectedSourceType && selectedSourceType.name === 'url'">
                            <label i18n>Url sursă</label>
                            <input type="url" class="form-control" id="url" name="url" #urlsursa="ngModel" [(ngModel)]="layer.url" required>
                            <span *ngIf="layerform.submitted || urlsursa.touched">
                                <span *ngIf="urlsursa.errors?.required" class="text-danger" i18n>Sursa este obligatorie</span>
                            </span>
                        </div>

                        <div *ngIf="selectedSourceType && selectedSourceType.name === 'upload'">
                            <div class="form-group">
                                <label for="url" i18n>Actiune</label>
                                <ng-select id="destActionType" name="destActionType" required
                                    i18n-title title="Selecteaza tip actiune"
                                    #destaction="ngModel"
                                    [(ngModel)]="selectedDestAction" 
                                    i18n-placeholder placeholder="Selectează sau caută în listă">
                                    <ng-option *ngFor="let item of availableDestinationActions" [value]="item">
                                        {{item.text}}
                                    </ng-option>
                                </ng-select>
                            </div>
                            <div class="form-group">
                                <label i18n-title title="Sunt necesare fisierele conexe conform standard shapefile: .shp, .shx, .dbf, .prj, .cst">Fisiere shapefiles</label>
                                <div class="custom-file">
                                    <input type="file" id="shapefiles" name="shapefiles"class="custom-file-input"
                                     accept=".shx,.shp,.cpg,.dbf,.qpj,.qix,.prj,.fix" multiple (change)="multiFileSelected($event, labelImportM)">
                                    <label class="custom-file-label" #labelImportM for="shapefiles" i18n> Selecteaza fisiere</label>
                                </div>
                                <!-- <span *ngIf="layerform.submitted">
                                    <span *ngIf="files == null || files.length == 0" class="text-danger">Fisierele sunt obligatorii</span>
                                </span> -->
                            </div>
                        </div>

                        <!--creaza tabel in baza de date-->
                        <div *ngIf="selectedSourceType && selectedSourceType.name === 'table'">
                            <div>
                                <div i18n>Tabel in baza de date</div>
                                <div class="card card-body p-2" *ngFor="let attr of layer.attributes">
                                    <div class="form-group" style="margin-bottom:10px">
                                        <label i18n>Coloana tip {{displayAttributeType(attr.type)}} </label>
                                        <button type="button" name="btnRemoveOption" class="btn btn-xs btn-light float-right"
                                            (click)="onRemoveAttributeFromLayer(attr)"> x </button>
                                    </div>
                                    <div>
                                        <div class="row" *ngIf="true">
                                            <h6 attr.for="attr_nume{{attr.idOrdine}}" class="control-label col-sm-5" i18n>Denumire</h6>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="attr_nume{{attr.idOrdine}}"
                                                    name="attr_nume{{attr.idOrdine}}" #tblAttr="ngModel" [(ngModel)]="attr.nume"  required [pattern]="columnNameFilter"/>
                                                    <span *ngIf="layerform.submitted || tblAttr.touched">
                                                        <span *ngIf="tblAttr.errors?.required" class="text-danger" i18n>Denumirea este obligatorie</span>
                                                        <span *ngIf="tblAttr.errors?.pattern" class="text-danger" i18n>Poate să conțină doar ascii _ -</span>
                                                    </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <h6 attr.for="attr_accepta_null{{attr.idOrdine}}" class="control-label col-sm-5" i18n> Acepta NULL </h6>
                                            <div class="col-sm-2">
                                                <input type="checkbox" class="form-control float-left" id="attr_accepta_null{{attr.idOrdine}}"
                                                    name="attr_accepta_null{{attr.idOrdine}}" [(ngModel)]="attr.nillable">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="true">
                                    <ng-select id="columntype" name="columntype"
                                        i18n-title title="Selecteaza tip coloană"
                                        [(ngModel)]="selectedAttributeType" 
                                        i18n-placeholder placeholder="Selectează sau caută în listă">
                                        <ng-option *ngFor="let item of attributeTypeList" [value]="item">
                                            {{item.nume}}
                                        </ng-option>
                                    </ng-select>
                                    <button type="button" name="btnAddAttrType" class="btn btn-light "
                                        (click)="onAddAttributeToLayer()" i18n>Adaugă coloană</button>
                                </div>
                            </div>

                            <div *ngIf="layer.featureType == 'polyReportCP'">
                                <div i18n>Tabel conex in baza de date</div>
                                <div class="card card-body p-2" *ngFor="let attr of connectedLayer.attributes">
                                    <div class="form-group" style="margin-bottom:10px">
                                        <label i18n>Coloana tip {{displayAttributeType(attr.type)}} </label>
                                        <button type="button" name="btnRemoveOption" class="btn btn-xs btn-light float-right"
                                            (click)="onRemoveAttributeFromConnectedLayer(attr)"> x </button>
                                    </div>
                                    <div>
                                        <div class="row" *ngIf="true">
                                            <h6 attr.for="attr_nume_cp{{attr.idOrdine}}" class="control-label col-sm-5" i18n>Denumire</h6>
                                            <div class="col-sm-7">
                                                <input type="text" class="form-control" id="attr_nume_cp{{attr.idOrdine}}"
                                                    name="attr_nume_cp{{attr.idOrdine}}" #attrConex="ngModel" [(ngModel)]="attr.nume" required ng-pattern="columnNameFilter">
                                                    <span *ngIf="layerform.submitted || attrConex.touched">
                                                        <span *ngIf="attrConex.errors?.required" class="text-danger" i18n>Denumirea este obligatorie</span>
                                                        <span *ngIf="attrConex.errors?.pattern" class="text-danger" i18n>Poate sa conțină doar ascii _ -</span>
                                                    </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <h6 attr.for="attr_cp_accepta_null{{attr.idOrdine}}" class="control-label col-sm-5" i18n> Acepta NULL </h6>
                                            <div class="col-sm-2">
                                                <input type="checkbox" class="form-control float-left" id="attr_cp_accepta_null{{attr.idOrdine}}"
                                                    name="attr_cp_accepta_null{{attr.idOrdine}}" [(ngModel)]="opt.nillable">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="true">
                                    <ng-select id="columntype" name="columntype"
                                        i18n-title title="Selecteaza tip coloană"
                                        [(ngModel)]="selectedAttributeTypeCL" 
                                        i18n-placeholder placeholder="Selectează sau caută în listă">
                                        <ng-option *ngFor="let item of attributeTypeList" [value]="item">
                                            {{item.nume}}
                                        </ng-option>
                                    </ng-select>
                                    <button type="button" name="btnAddAttrType" class="btn btn-light "
                                        (click)="onAddAttributeToConnectedLayer()" i18n>Adaugă coloană</button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-group">
                        <label attr.for="name" i18n>Proiecție</label>
                        <input type="text" class="form-control" id="projection" name="projection" #layerprojection="ngModel" [(ngModel)]="layer.projection" required>
                        <span *ngIf="layerform.submitted || layerprojection.touched">
                            <span *ngIf="layerprojection.errors?.required" class="text-danger" i18n>Proiectia este obligatorie</span>
                        </span>
                    </div>
                    <!-- setari strat raport-->
                    <div class="form-group" *ngIf="layer && (layer.featureType == 'polyReport' || layer.featureType == 'polyReportCP')">
                        <label attr.for="setariRaport" i18n>Setări generare raport</label>
                        <button class="btn-xs btn-light float-right" data-toggle="collapse" data-target="#collapseRepSettings">v</button>
                        <div class="collapse" id="collapseRepSettings">
                            <edit-raport-layer-settings name="setariRaport" settings-list="layer.reports"></edit-raport-layer-settings>
                        </div>
                    </div>
                    <!-- text label-->
                    <div id="textlabel" class="card card-body p-2" *ngIf="layer.featureType === 'polyText'|| layer.featureType === 'pointText' || layer.featureType === 'polyReport' ||layer.featureType === 'polyReportCP'||layer.featureType === 'clusterText'">
                        <label attr.for="text" i18n>Proprietăți sursă pentru etichetă</label>
                        <div >
                            <div class="d-flex" *ngFor="let textitem of layer.textKeyColumn; let i = index; trackBy:trackByIdentityOrIndex" >
                                <div class="flex-fill">
                                    <input type="text" class="form-control" id="'etprop_'+i" [name]="'etprop_'+i" #etpropa="ngModel" [(ngModel)]="layer.textKeyColumn[i]" required>
                                </div>
                                <div class="">
                                    <button type="button" name="btnRemoveStyle" class="btn btn-light" (click)="onRemovePropertyFromTextDescription(i)"> x </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-6" id="div-add-etprop-btn">
                                    <button  type="button" name="addNewTextCol" class="btn btn-light btn-block" (click)="onAddProperyForTextDescription()"> Adaugă proprietate </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- stil -->
                    <div class="form-group" *ngIf="layer  && layer.featureType != 'tile' && layer.featureType != 'heatmap'">
                        <label attr.for="styleType" i18n>Tip stil</label>
                        <ng-select id="styleType" name="styleType" required
                            i18n-title title="Selecteaza tip stil"
                            #styletype="ngModel"
                            [(ngModel)]="selectedStyleType" 
                            (change)="onTipStyleChanged()"
                            i18n-placeholder placeholder="Selectează sau caută în listă">
                            <ng-option *ngFor="let item of availableStyleType" [value]="item">
                                {{item.text}}
                            </ng-option>
                        </ng-select>
                        <span *ngIf="layerform.submitted || styletype.touched">
                            <span *ngIf="styletype.errors?.required" class="text-danger" i18n>Tip stil este obligatoriu</span>
                        </span>
                    </div>
                    <!-- stil baza -->
                    <div id="stildebaza" class="card card-body p-2">
                        <div class="form-group" *ngIf="layer && !isAddElseEdit &&  (layer.featureType == 'icon' || layer.featureType == 'clusterIcon')">
                            <label attr.for="name" i18n>Denumire imagine stil de baza</label>
                            <input type="text" class="form-control" id="filename" name="filename" [(ngModel)]="layer.asset" readonly>
                        </div>
                        <div class="form-group" *ngIf="layer && (layer.featureType == 'icon' || layer.featureType == 'clusterIcon')">
                            <label attr.for="asset" *ngIf="isAddElseEdit" i18n>Încarcă imagine, stil de bază</label>
                            <label attr.for="asset" *ngIf="!isAddElseEdit" i18n>Încarcă imagine nouă, stil de bază</label>
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="file" (change)="fileSelected($event, labelImport)">
                                <label class="custom-file-label" #labelImport for="file" i18n>Selecteaza imagine</label>
                            </div>
                             <!-- <span *ngIf="layerform.submitted">
                                <span *ngIf="file == null" class="text-danger">Selectați o imagine</span>
                                </span>  -->
                        </div>
                        <div class="form-group" *ngIf="layer && layer.featureType != 'icon' && layer.featureType != 'tile' && layer.featureType != 'heatmap'">
                            <label attr.for="color" i18n>Culoare, stil de bază</label>
                            <color-picker-rgba [colorPickerData]="defaultStyle" [idColor]="baseFill"></color-picker-rgba>
                        </div>
                        <input type="hidden" id="category" name="category" value="{{data.category}}" />
                    </div>
                    
                    <!-- multistyle -->
                    <div id="multistil" class="card card-body p-2" *ngIf="layer && layer.featureType != 'tile' && layer.featureType != 'heatmap' && (layer.styleType == 'multiStyle' || (selectedStyleType && selectedStyleType.name === 'singleStyle'))">
                        <div class="form-group" *ngIf="selectedStyleType && selectedStyleType.name !== 'singleStyle'">
                            <label attr.for="styleKeyCol" i18n>Proprietate element pentru selecție stil multiplu</label>
                            <input type="text" class="form-control" id="styleKeyCol" name="styleKeyCol" #stylekeycol="ngModel" [(ngModel)]="layer.styleKeyColumn" required>
                            <span *ngIf="layerform.submitted || stylekeycol.touched">
                                <span *ngIf="stylekeycol.errors?.required" i18n>Proprietate element este obligatoriu</span>
                            </span>
                        </div>

                        <div class="form-group">
                            <label attr.for="" i18n>Stiluri pentru strat</label>
                            <div class="card card-body p-2" *ngFor="let style of asignedLStyles">
                                <div class="form-group" style="margin-bottom:10px">
                                    <label i18n>Denumire stil: {{style.styleName}} </label>
                                    <button type="button" name="btnRemoveStyle" class="btn btn-xs btn-light float-right" (click)="onRemoveOptionStyle(style)"> x </button>
                                </div>
                                <div class="row" *ngIf="selectedStyleType && selectedStyleType.name !== 'singleStyle'">
                                    <h6 attr.for="res_opt_descriere{{style.text}}" class="control-label col-sm-5" i18n>Cheie selecție stil</h6>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" id="res_opt_descriere{{style.id}}" name="res_opt_descriere{{style.id}}" [(ngModel)]="style.styleKey">
                                    </div>
                                </div>
                                <div class="row" *ngIf="selectedStyleType && selectedStyleType.name !== 'singleStyle'">
                                    <h6 attr.for="res_opt_descriere{{style.text}}" class="control-label col-sm-5" i18n>Legenda</h6>
                                    <div class="col-sm-7">
                                        <input type="text" class="form-control" id="res_opt_legenda{{style.id}}" name="res_opt_legenda{{style.id}}" [(ngModel)]="style.legenda">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="row">
                                <div class="col-8" id="div-add-style-cmb">
                                    <ng-select id="style_uiTipStyle" name="style_uiTipStyle"
                                        i18n-title title="Selecteaza stil"
                                        [(ngModel)]="selectedLStyle" 
                                        i18n-placeholder placeholder="Selectează sau caută în listă">
                                        <ng-option *ngFor="let item of availableLStyles" [value]="item">
                                            {{item.text}}
                                        </ng-option>
                                    </ng-select> 
                                </div>
                                <div class="col-4" id="div-add-style-btn">
                                    <button [disabled]="asignedLStyles.length === 1 && selectedStyleType && selectedStyleType.name === 'singleStyle'"
                                     type="button" name="addNewStyleToLayer" 
                                     class="btn btn-light add-res-rol-option btn-block" 
                                     (click)="onAddStyleToLayer()" i18n> Adaugă stil </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="form-group">
                    <span *ngIf="errorResponse">
                        <span class="text-danger">{{errorResponse}}</span>
                    </span>
                </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-light" ng-disabled="!layerform.$valid" (click)="save(diaCtrl)">Salvează</button> -->
                <button *ngIf="canChangeAccess" type="button" class="btn btn-light" (click)="changeAccess()">
                    <span *ngIf="layer?.idClient == null" i18n>Set tip strat privat pe client</span>
                    <span *ngIf="layer?.idClient != null" i18n>Set tip strat partajat</span>
                </button>
                <button type="button" class="btn btn-light" (click)="save(layerform)" i18n>Salvează</button>
                <button type="button" class="btn" (click)="cancel()" i18n>Închide</button>
            </div>
        </fieldset>
    </form>
</div>