<div class="modal-content"  id="printFeaturesInfo">
    <div print-section class="modal-header">
        <h5 class="modal-title" i18n>Raport harta si detalii selectie</h5>
    </div>
    <div class="modal-body">
        <!-- Create map image-->
        <div print-section class="mapImageContainer">
            <img width="100%" id="mapImage" [src]="mapImgUrl" />
        </div>
        <!--Create table-->
        <div print-section class="listOfTables mt-2" *ngFor="let featureTab of featuresInTables">
            <div class="row">
                <div class="col-sm-9">
                    <h6>{{featureTab.name}}</h6>
                </div>
                <div class="col-sm-2">
                    <button print-remove class="float-right" [hidden]="!isAdmin" (click)="saveDefaultOptions(featureTab)" i18n>Salvare optiuni
                        predefinite</button>
                </div>
                <div class="col-sm-1">
                    <button print-remove class="float-right"  (click)="featureTab.gridOptions.columnSelection = !featureTab.gridOptions.columnSelection"><span class="fa fa-bars" i18n-title title="Coloane"></span></button>
                </div>
            </div>
            <!--d-inline-flex-->
            <div class="d-inline-flex gridPrintBlock w-100">
                <div class="flex-fill gridPrintOverflow">
                    <ag-grid-angular #mygrid 
                    class="ag-theme-balham gridPrintClass" 
                    [rowData]="featureTab.data"
                    [domLayout]="featureTab.gridOptions.domLayout"
                        [columnDefs]="featureTab.gridOptions.columnDefs" 
                        [defaultColDef]="defaultColDef" 
                        [sideBar]="sideBarDef">
                    </ag-grid-angular>
                </div>
                <div print-remove *ngIf="featureTab.gridOptions && featureTab.gridOptions.columnSelection"
                class="border-left ml-1 p-1 gridColumnsClass" >
                    <div *ngFor="let defCol of featureTab.gridOptions.columnDefs">
            
                        <div [title]="defCol.field">
                            <input type="checkbox" 
                            class="mr-1"
                            [ngModel]="!defCol.hide" 
                            (click)="gridColumnClick($event, mygrid, defCol)"
                            [ngModelOptions]="{standalone: true}" 
                                />{{defCol.headerName}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  class="modal-footer">
        <button class="btn btn-primary" print-btn print-landscape  i18n><i class="fa fa-print"></i> Print</button>
        <button type="button" class="btn" (click)="cancel()" i18n>Închide</button>
    </div>
</div>