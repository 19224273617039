<div class="integrate-landsat-ds-menu integrate-landsat-ds-height"
     *ngIf="mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn">
    <div id="integrate-landsat-ds-content">
        <div class="h6" (click)="integrateLandsatProcDateSezoniereData.ctrlSearchIsCollapsed = !integrateLandsatProcDateSezoniereData.ctrlSearchIsCollapsed"> 
            Procesare date sezoniere
            <span class="float-right" (click)="onClickClose(); $event.stopPropagation()">
                <span class="material-icons integrate-landsat-ds-menu-icon-close-colapse">close</span>
            </span>
            <span class="float-right">
                <span class="material-icons integrate-landsat-ds-menu-icon-close-colapse text-warning" *ngIf="integrateLandsatProcDateSezoniereData.ctrlSearchIsCollapsed">arrow_drop_down</span>
                <span class="material-icons integrate-landsat-ds-menu-icon-close-colapse" *ngIf="!integrateLandsatProcDateSezoniereData.ctrlSearchIsCollapsed">arrow_drop_up</span>
            </span>
            <span class="glyphicon glyphicon-info-sign float-right " 
            ngbPopover="{{infoSearch}}"
            popoverTitle="Info"
            popoverClass="text-dark"
            triggers="mouseenter:mouseleave"
            placement="bottom" 
            onclick="event.stopPropagation()"></span>
        </div>
        <div>
            <ul ngbNav #nav="ngbNav" [(activeId)]="integrateLandsatProcDateSezoniereData.activeTab" class="nav-tabs nav-fill w-100">
                <li [ngbNavItem]="1"
                (click)="changeNewExistsTab('1')">
                    <a ngbNavLink>Existente</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
                <li [ngbNavItem]="0"
                (click)="changeNewExistsTab('0')">
                    <a ngbNavLink>Nouă</a>
                    <ng-template ngbNavContent>
                    </ng-template>
                </li>
            </ul>
        </div>
        <!--existing downloads-->
        <div class="integrate-landsat-ic-existing-dw" 
            *ngIf="integrateLandsatProcDateSezoniereData.activeExistsTab1">
            <!--autocompleat search-->
            <div class="integrate-landsat-ic-existing-dw-combo">
                <div class="form-group integrate-landsat-ic-existing-combo-mb"
                    >
                    <label for="uiDesPlanificate">Procesări planificate</label>
                    <ng-select name="uiDesPlanificate" required 
                        [items]="integrateLandsatProcDateSezoniereData.availableProcesari"
                        [(ngModel)]="integrateLandsatProcDateSezoniereData.selectedProcesare"
                        (change)="onChangeProcesare()" 
                        bindLabel="denumire"
                        placeholder="Selectează procesare planificată">
                        <ng-template ng-label-tmp let-item="item">
                            <div class="integrate-ng-select-wrap">
                                {{item.denumire}}
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                            <div class="integrate-ng-select-wrap">
                                [{{item.id }}] {{item.denumire}}
                            </div>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div *ngIf="integrateLandsatProcDateSezoniereData.selectedProcesare == null && integrateLandsatProcDateSezoniereData.loadingActive === true "class="tranz-spiner-box">
                <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
            </div>
        </div>

<!--form input-->
<div [(ngbCollapse)]="integrateLandsatProcDateSezoniereData.ctrlSearchIsCollapsed">
    <form #integrateProcForm="ngForm" novalidate>
            
    <!--new proc info-->
    <fieldset *ngIf="integrateLandsatProcDateSezoniereData.activeNewTab0"
                [disabled]="integrateLandsatProcDateSezoniereData.loadingActive === true"
                >
                <div class="form-group">
                    <label>Denumire</label>
                    <input class="form-control" type="text" name="uiDenumire"
                        [(ngModel)]="integrateLandsatProcDateSezoniereData.currentProcesare.denumire" required/>
                </div>
                <div class="form-group" >
                    <label>Descriere</label>
                    <textarea class="form-control textarea-noresize" rows="2" name="uiDescriere"
                        [(ngModel)]="integrateLandsatProcDateSezoniereData.currentProcesare.descriere"
                        ></textarea>
                </div>
                <div class="form-group">
                    <label>Unitate administrativa</label>
                    <ng-select name="uiunitateAdm" required [(ngModel)]="integrateLandsatProcDateSezoniereData.selectedUnitateAdministrativa"
                            (change)="onChangeSelectedUnitateAdministrativa()" placeholder="Selectează descarcare planificată">
                            <ng-option *ngFor="let itemSd of integrateLandsatProcDateSezoniereData.availableUnitatiAdministrative "
                                [value]="itemSd">{{itemSd.denumire}}</ng-option>
                    </ng-select>
                    
                </div>
                <!--sezonier anual-->
                <div>
                    <label for="rbtsezonier" class="ml-3">
                        <input id="rbtsezonier" required [value]='true' type="radio" name="uiSezonierAnual"
                            [(ngModel)]="integrateLandsatProcDateSezoniereData.sezonierAnual">
                        Sezonier
                    </label>
                    <label for="rbtanual" class="ml-3">
                        <input id="rbtanual" required [value]='false' type="radio" name="uiSezonierAnual"
                            [(ngModel)]="integrateLandsatProcDateSezoniereData.sezonierAnual">
                        Anual
                    </label>
                </div>
                <div class="form-group" >
                    <label>An</label>
                    <input class="form-control textarea-noresize" type="number" step="1" min="1900" name="uiAn"
                        [(ngModel)]="integrateLandsatProcDateSezoniereData.currentProcesare.an"
                        />
                </div>
                <div class="form-group" *ngIf="integrateLandsatProcDateSezoniereData.sezonierAnual">
                    <label>Sezon</label>
                    <ng-select name="uiSezon" required [(ngModel)]="integrateLandsatProcDateSezoniereData.selectedSezonDate"
                                (change)="onChangeSelectedUnitateAdministrativa()" placeholder="Selectează descarcare planificată">
                                <ng-option *ngFor="let itemSd of integrateLandsatProcDateSezoniereData.availableSezonDate "
                                    [value]="itemSd">{{itemSd.denumire}}</ng-option>
                    </ng-select>
                </div> 

                <div *ngIf="integrateLandsatProcDateSezoniereData.loadingActive === true "class="tranz-spiner-box">
                    <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
                </div>
                <div>
                    <h6 *ngIf="integrateLandsatProcDateSezoniereData.errorMessage && integrateLandsatProcDateSezoniereData.errorMessage.length > 0 " class="text-danger">
                        {{integrateLandsatProcDateSezoniereData.errorMessage}}
                    </h6>
                </div>
                <div class="input-group">
                    <button class="btn btn-light integrate-ds-action-wrp-buttons" 
                    (click)="onStartProcesare(integrateProcForm)" title="Pornește"><i class="fas fa-play-circle fa-2x"></i></button>
                    <!-- <button class="btn btn-light integrate-ic-action-wrp-buttons" 
                    [disabled]="canStopProcesare() === false"
                    (click)="onStopProcesare()" title="Oprește"><i class="fas fa-stop-circle fa-2x"></i></button>
                     -->
                    
                </div>    

    </fieldset>

    <!--existing-->
    <fieldset *ngIf="integrateLandsatProcDateSezoniereData.activeExistsTab1 && integrateLandsatProcDateSezoniereData.selectedProcesare"
        [disabled]="integrateLandsatProcDateSezoniereData.loadingActive === true">
        <!-- <div class="form-group" >
            <label>Denumire</label>
            <div class="form-control ">
                {{integrateLandsatProcDateSezoniereData.currentProcesare.denumire}}
            </div>
        </div>       -->
        <div class="form-group" >
            <label>Descriere</label>
            <div class="form-control textbox-h-auto">
                {{integrateLandsatProcDateSezoniereData.currentProcesare.descriere}}
            </div>
        </div> 
        <div class="form-group" >
            <label>Unitate administrativa</label>
            <div class="form-control ">
                {{integrateLandsatProcDateSezoniereData.currentProcesare.unitatiAdministrativeProcesare[0]?.denumire}}
            </div>
        </div> 
        <div class="form-group" >
            <label>An</label>
            <div class="form-control ">
                {{integrateLandsatProcDateSezoniereData.currentProcesare.an}}
            </div>
        </div> 
        <div class="form-group" *ngIf="integrateLandsatProcDateSezoniereData.currentProcesare.denumireSezon">
            <label>Sezon</label>
            <div class="form-control ">
                {{integrateLandsatProcDateSezoniereData.currentProcesare.denumireSezon}}
            </div>
        </div> 
        <div class="form-group" >
            <label>Status</label>
            <div class="form-control ">
                {{integrateLandsatProcDateSezoniereData.currentProcesare.status}}
            </div>
        </div> 
        <div class="form-group" >
            <label>Status descriere</label>
            <div class="form-control textbox-h-auto">
                {{integrateLandsatProcDateSezoniereData.currentProcesare.mesajStatus}}
            </div>
        </div>
        <div *ngIf="integrateLandsatProcDateSezoniereData.loadingActive === true "class="tranz-spiner-box">
            <i id="spinner" class="fa fa-circle-o-notch fa-spin tranz-spiner"></i>
        </div>
        <div class="input-group">
            
            <button class="btn btn-light integrate-ds-action-wrp-buttons ml-auto" 
            [disabled]="canRefreshProcesare() === false"
            (click)="onRefreshProcesare()" title="Reîncarcă rezultat"><i class="fas fa-sync fa-2x"></i></button>
        </div>
    </fieldset>
    </form>
    </div>
</div>
